export const TAB_TYPE_URL_PARAM_NAME = 'tab';
export const SORTING_TYPE_URL_PARAM_NAME = 'sort';
export const PAGE_URL_PARAM_NAME = 'page';
export const FILTERS_URL_PARAM_NAME = 'filters';

export const TAB_TYPES = {
  allIdeas: 'allIdeas',
  myIdeas: 'myIdeas',
  likedIdeas: 'likedIdeas',
  draftIdeas: 'draftIdeas',
  archivedIdeas: 'archivedIdeas',
};

export const SORTING_TYPES = {
  likesAndComments: '-collaborations',
  newestIdeas: '-created_at',
  oldestIdeas: 'created_at',
};

export const FILTERING_TYPES = {
  BIZ_OBJECTIVE: 'bizCategoryIds',
};

export const CARDS_ID = 'ideasList';

export const TITLE_TRANSLATION_KEY_BY_ACTIVE_TAB_TYPE = {
  [TAB_TYPES.allIdeas]: 'ideas.ideasList.allIdeas.title',
  [TAB_TYPES.myIdeas]: 'ideas.ideasList.myIdeas.title',
  [TAB_TYPES.likedIdeas]: 'ideas.ideasList.likedIdeas.title',
  [TAB_TYPES.draftIdeas]: 'ideas.ideasList.draftIdeas.title',
  [TAB_TYPES.archivedIdeas]: 'ideas.ideasList.archivedIdeas.title',
};
