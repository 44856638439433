import { useSelector } from 'react-redux';

import { EVENT_STAGES } from '../constants/event';
import { activeEventActiveStageTypeSelector } from '../redux/slices/events';

export const useIsAbleToSubmitIdea = () => {
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);

  return activeStageType === EVENT_STAGES.IDEA_SUBMISSION;
};
