import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import yup from '../../../../yup';
import {
  FORM_FIELD_NAMES,
} from '../constants';
import { IDEA_TYPES } from '../../../../constants/idea';

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => (yup.object().shape({
    [FORM_FIELD_NAMES.IDEA_TITLE]: yup.string()
      .trim()
      .required(formatMessage({ id: 'generalOverviewForm.error.ideaTitleRequired' }))
      .min(3, formatMessage({ id: 'generalOverviewForm.error.ideaTitleMinLength' }))
      .max(150, formatMessage({ id: 'generalOverviewForm.error.ideaTitleMaxLength' })),
    [FORM_FIELD_NAMES.IDEA_TYPE]: yup.string()
      .nullable()
      .oneOf(
        [IDEA_TYPES.UNSOLVED_CHALLENGE, IDEA_TYPES.UNTAPPED_OPPORTUNITY],
        formatMessage({ id: 'generalOverviewForm.error.ideaTypeRequired' }),
      ),
    [FORM_FIELD_NAMES.REASON]: yup.string()
      .when(FORM_FIELD_NAMES.IDEA_TYPE, {
        is: (value) => value === IDEA_TYPES.UNSOLVED_CHALLENGE,
        then: (schema) => schema
          .trim()
          .required(formatMessage({ id: 'generalOverviewForm.error.unsolvedChallenge.reasonRequred' }))
          .min(25, formatMessage({ id: 'common.error.minLength' }, { minLength: 25 })),
      })
      .when(FORM_FIELD_NAMES.IDEA_TYPE, {
        is: (value) => value === IDEA_TYPES.UNTAPPED_OPPORTUNITY,
        then: (schema) => schema
          .trim()
          .required(formatMessage({ id: 'generalOverviewForm.error.untappedOpportunity.reasonRequred' }))
          .min(25, formatMessage({ id: 'common.error.minLength' }, { minLength: 25 })),
      }),
    [FORM_FIELD_NAMES.POTENTIAL_SOLUTION]: yup.string()
      .trim()
      .required(formatMessage({ id: 'generalOverviewForm.error.potentialSolutionRequired' }))
      .min(25, formatMessage({ id: 'common.error.minLength' }, { minLength: 25 })),
    [FORM_FIELD_NAMES.BUSINESS_BENEFITS]: yup.string()
      .trim()
      .required(formatMessage({ id: 'generalOverviewForm.error.businessBenefitsRequired' }))
      .min(25, formatMessage({ id: 'common.error.minLength' }, { minLength: 25 })),
  })), [formatMessage]);
};
