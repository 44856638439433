import { baseApi } from '../baseApi';
import { API_TAG_TYPES } from './constants';

export const metaDataApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAnnounceWinnerMetadata: build.query({
      query: ({ eventHrid }) => ({
        url: `/metadata/${eventHrid}/announce_winner`,
      }),
      providesTags: (result, error) => (
        error ? [] : [API_TAG_TYPES.announceWinnerMetaData]
      ),
    }),
  }),
});

export const {
  useGetAnnounceWinnerMetadataQuery,
} = metaDataApi;
