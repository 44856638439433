import React from 'react';
import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../Icon';

const LikeIcon = () => (
  <Icon
    data-testid="icon"
    type={ICON_TYPES.like}
    color={ICON_COLOR.inherit}
    size={ICON_SIZE.ssm}
  />
);

export default LikeIcon;
