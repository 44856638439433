import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { LeavePromptContext } from '../LeavePromptProvider';
import PageBlocker from '../PageBlockerProvider/PageBlocker';
import { useListener } from '../../hooks';
import {
  initializeAppListener,
  initializeAppActionCreator,
} from './listeners/initializeAppListener';
import {
  handleActiveEventTransitionListener,
  handleActiveEventTransitionActionCreator,
} from './listeners/handleActiveEventTransitionListener';
import { tokenSelector } from '../../redux/slices/access';
import { activeEventSelector } from '../../redux/slices/events';

const AppInitializer = ({ children }) => {
  const {
    disableLeavePrompt,
    enableLeavePrompt,
  } = useContext(LeavePromptContext);
  const [initializeApp] = useListener({
    listener: initializeAppListener,
    actionCreator: initializeAppActionCreator,
  });
  const [handleActiveEventTransition, abortHandleActiveEventTransition] = useListener({
    listener: handleActiveEventTransitionListener,
    actionCreator: handleActiveEventTransitionActionCreator,
  });
  const [isLoading, setIsLoading] = useState(true);
  const token = useSelector(tokenSelector);
  const activeEvent = useSelector(activeEventSelector);

  useEffect(() => {
    if (!activeEvent) {
      return;
    }

    handleActiveEventTransition({
      disableLeavePrompt,
      enableLeavePrompt,
    }).catch(() => { });

    return () => abortHandleActiveEventTransition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEvent?.human_readable_id]);

  useLayoutEffect(() => {
    const runAppInitialization = async () => {
      try {
        await initializeApp();
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      runAppInitialization();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading
    ? <PageBlocker data-testid="blocker" />
    : children;
};

AppInitializer.propTypes = {
  children: PropTypes.node,
};

AppInitializer.defaultProps = {
  children: null,
};

export default AppInitializer;
