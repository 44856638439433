import React from 'react';
import Icon, {
  ICON_TYPES,
  ICON_SIZE,
  ICON_COLOR,
} from '../../../Icon';

const TrashCanIcon = () => (
  <Icon
    type={ICON_TYPES.trashCan}
    color={ICON_COLOR.secondary}
    size={ICON_SIZE.xxs}
  />
);

export default TrashCanIcon;
