import {
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { useQueryParams } from './useQueryParams';

export const useGenerateLink = () => {
  const {
    hash,
    pathname,
  } = useLocation();
  const queryParams = useQueryParams();

  const generateLink = useCallback((newQueryParams) => ({
    pathname,
    hash,
    search: qs.stringify({ ...queryParams, ...newQueryParams }),
  }), [
    pathname,
    hash,
    queryParams,
  ]);

  return useMemo(() => ({
    generateLink,
  }), [generateLink]);
};
