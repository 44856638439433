import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Modal from '../../Modal';

const OnboardingModal = ({ abort, success, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      data-testid="modal"
      title={formatMessage({ id: 'onboardingModal.title' })}
      content={formatMessage({ id: 'onboardingModal.description' })}
      onClose={abort}
      onConfirm={success}
      confirmButtonCaption={formatMessage({ id: 'onboardingModal.confirmButton.label' })}
      {...props}
    />
  );
};

OnboardingModal.propTypes = {
  success: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
};

export default OnboardingModal;
