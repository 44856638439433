import React from 'react';

import Banner from './Banner';
import EventJourney from '../EventJourney';
import TopIdeas from '../TopIdeas';
import EventStats from '../EventStats';

const IdeaSubmissionWelcome = () => (
  <>
    <Banner data-testid="banner" />
    <EventJourney data-testid="eventJourney" />
    <TopIdeas data-testid="topIdeas" />
    <EventStats data-testid="eventStats" />
  </>
);

export default IdeaSubmissionWelcome;
