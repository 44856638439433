import React, { useLayoutEffect } from 'react';
import {
  UuiContext,
  HistoryAdaptedRouter,
  useUuiServices,
} from '@epam/uui';
import {
  Modals,
  Snackbar,
} from '@epam/uui-components';
import {
  skinContext,
} from '@epam/promo';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import history from '../../browserHistory';
import App from '../App/App';
import AppInitializer from '../AppInitializer/AppInitializer';
import ApiErrorsHandler from '../ApiErrorsHandler';
import PageBlockerProvider from '../PageBlockerProvider';
import LeavePromptProvider from '../LeavePromptProvider';
import { userIdSelector } from '../../redux/slices/access';
import {
  setMatomoUserId,
  resetMatomoUserId,
} from '../../utils';
import { DowntimePage } from '../pages';

export const UuiEnhancedApp = () => {
  const router = new HistoryAdaptedRouter(history);
  const { services } = useUuiServices({
    apiDefinition: () => Promise.resolve({}),
    router,
    skinContext,
  });
  const currentUserId = useSelector(userIdSelector);

  useLayoutEffect(() => {
    Sentry.setUser({ id: currentUserId });

    if (currentUserId) {
      setMatomoUserId(currentUserId);
    } else {
      resetMatomoUserId();
    }
  }, [currentUserId]);

  if (process.env.REACT_APP_IS_DOWNTIME === 'true') {
    return <DowntimePage />;
  }

  return (
    <UuiContext.Provider value={services}>
      <LeavePromptProvider>
        <PageBlockerProvider>
          <ApiErrorsHandler>
            <AppInitializer>
              <App />
            </AppInitializer>
          </ApiErrorsHandler>
          <Snackbar />
          <Modals />
        </PageBlockerProvider>
      </LeavePromptProvider>
    </UuiContext.Provider>
  );
};
