import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Tabs from '../../Tabs';
import { IDEAS_BOARD_TAB_IDES, IDEAS_TAB_PARAM_NAME } from './constants';
import IdeasTotalTab from './IdeasTotalTab';
import ArchivedIdeasTab from './ArchivedIdeasTab';
import IdeasVotingTab from './IdeasVotingTab';
import { activeEventActiveStageSelector } from '../../../redux/slices/events';
import IdeasDownSelectionTab from './IdeasDownSelectionTab';
import IdeasWinnerSelectionTab from './IdeasWinnerSelectionTab';
import { EVENT_STAGES } from '../../../constants/event';
import TabTimer from './TabTimer';

import styles from './IdeasBoard.module.scss';

const IdeasBoard = () => {
  const { formatMessage } = useIntl();
  const {
    end_at: stageEndDate,
    type: eventStage,
  } = useSelector(activeEventActiveStageSelector) || {};
  const tabItems = useMemo(() => ([
    {
      id: IDEAS_BOARD_TAB_IDES.IDEAS_TOTAL,
      caption: formatMessage({ id: 'adminPanel.ideasBoard.ideasTabs.ideasTotal.tabLabel' }),
      Component: IdeasTotalTab,
    },
    ...(
      eventStage === EVENT_STAGES.DOWN_SELECTION ? [{
        id: IDEAS_BOARD_TAB_IDES.IDEAS_DOWN_SELECTION,
        caption: (
          <>
            {
              formatMessage({ id: 'adminPanel.ideasBoard.ideasTabs.ideasDownSelection.tabLabel' })
            }
            <TabTimer
              tabId={IDEAS_BOARD_TAB_IDES.IDEAS_DOWN_SELECTION}
              endDate={stageEndDate}
              className={styles.timer}
            />
          </>
        ),
        Component: IdeasDownSelectionTab,
      }] : []
    ),
    ...(
      eventStage === EVENT_STAGES.VOTING ? [{
        id: IDEAS_BOARD_TAB_IDES.VOTING_IDEAS,
        caption: (
          <>
            {
              formatMessage({ id: 'adminPanel.ideasBoard.ideasTabs.ideasVoting.tabLabel' })
            }
            <TabTimer
              tabId={IDEAS_BOARD_TAB_IDES.VOTING_IDEAS}
              endDate={stageEndDate}
              className={styles.timer}
            />
          </>
        ),
        Component: IdeasVotingTab,
      }] : []
    ),
    ...(
      eventStage === EVENT_STAGES.ANNOUNCE_WINNER ? [{
        id: IDEAS_BOARD_TAB_IDES.IDEAS_WINNER_SELECTION,
        caption: (
          <>
            {
              formatMessage({ id: 'adminPanel.ideasBoard.ideasTabs.ideasWinnerSelection.tabLabel' })
            }
            <TabTimer
              tabId={IDEAS_BOARD_TAB_IDES.IDEAS_WINNER_SELECTION}
              endDate={stageEndDate}
              className={styles.timer}
            />
          </>
        ),
        Component: IdeasWinnerSelectionTab,
      }] : []
    ),
    {
      id: IDEAS_BOARD_TAB_IDES.ARCHIVED_IDEAS,
      caption: formatMessage({ id: 'adminPanel.ideasBoard.ideasTabs.archivedIdeas.tabLabel' }),
      Component: ArchivedIdeasTab,
    },
  ]), [
    formatMessage,
    stageEndDate,
    eventStage,
  ]);

  return (
    <div className={styles.container}>
      <Tabs
        items={tabItems}
        urlParamName={IDEAS_TAB_PARAM_NAME}
        tabsContentClassName={styles.tabsContent}
      />
    </div>
  );
};

export default IdeasBoard;
