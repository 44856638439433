import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import RadioGroupFieldBase from '../../RadioGroupField';

const RadioGroupField = ({
  name,
  deps,
  ...props
}) => {
  const {
    field: {
      onChange,
      onBlur,
      value,
    },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      deps,
    },
  });

  return (
    <RadioGroupFieldBase
      data-testid="radioGroupFieldBase"
      name={name}
      value={value}
      errorMessage={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  );
};

RadioGroupField.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  footer: PropTypes.node,
  name: PropTypes.string.isRequired,
  deps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

RadioGroupField.defaultProps = {
  label: null,
  isRequired: false,
  footer: null,
  items: [],
  deps: undefined,
};

export default RadioGroupField;
