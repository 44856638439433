import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { IDEA_TYPES } from '../../../../constants/idea';
import { getUserFullName } from '../../../../utils';
import IdeaDescription from '../../../IdeaDescription';
import IdeaDataTag from './IdeaDataTag';
import HasAccess from '../../../HasAccess';
import { ideaAuthorAccessConditions } from '../../../../utils/accessConditions';

import styles from './IdeaData.module.scss';

const IdeaData = ({
  idea,
  className,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={clsx(styles.wrapper, className)}>
      <IdeaDescription
        ideaDetails={idea}
        className={styles.ideaDescription}
        data-testid="ideaDescription"
      />
      <div className={styles.tags}>
        <HasAccess
          data-testid="authorTagHasAccess"
          conditions={ideaAuthorAccessConditions}
        >
          <IdeaDataTag
            data-testid="authorDataTag"
            label={formatMessage({ id: 'idea.ideaData.author' })}
            content={getUserFullName(idea.author)}
          />
        </HasAccess>
        <IdeaDataTag
          data-testid="bizCategoryDataTag"
          label={formatMessage({ id: 'idea.ideaData.businessObjective' })}
          content={idea.biz_category.name}
        />
      </div>
    </div>
  );
};

IdeaData.propTypes = {
  idea: PropTypes.shape({
    idea_type: PropTypes.oneOf(Object.values(IDEA_TYPES)).isRequired,
    reason: PropTypes.string.isRequired,
    potential_solution: PropTypes.string.isRequired,
    business_benefits: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    biz_category: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  className: PropTypes.string,
};

IdeaData.defaultProps = {
  className: '',
};

export default IdeaData;
