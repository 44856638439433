import PropTypes from 'prop-types';

import { FILTER_TYPES } from '../../Filters/constants';
import CheckboxGroupField from '../../Form/connected/CheckboxGroupField';

export const FILTER_COMPONENT_BY_TYPE = {
  [FILTER_TYPES.CHECKBOX]: CheckboxGroupField,
};

export const FILTER_DEFAULT_VALUES_BY_TYPE = {
  [FILTER_TYPES.CHECKBOX]: [],
};

export const FILTER_PROPS_BY_TYPE = {
  [FILTER_TYPES.CHECKBOX]: {
    type: PropTypes.oneOf([FILTER_TYPES.CHECKBOX]).isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })),
  },
};

export const FILTER_DEFAULT_PROPS_BY_TYPE = {
  [FILTER_TYPES.CHECKBOX]: {
    visibleItemsNumber: 3,
  },
};
