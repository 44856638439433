import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, {
  FORM_PROPS,
  FORM_TITLE_SIZES,
} from '../../Form';
import LinkField from '../../Form/connected/LinkField';
import FormDescription from '../../Form/FormDescription';
import {
  FORM_FIELD_NAMES,
  MAX_LINKS_COUNT,
} from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';

const AttachLinksForm = ({
  defaultValues,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const resultDefaultValues = useMemo(() => (defaultValues || {
    [FORM_FIELD_NAMES.links]: [],
  }), [defaultValues]);
  const validationSchema = useValidationSchema();

  return (
    <Form
      data-testid="form"
      defaultValues={resultDefaultValues}
      validationSchema={validationSchema}
      title={formatMessage({ id: 'attachLinksForm.title' })}
      titleSize={FORM_TITLE_SIZES.SM}
      hideButtons
      {...props}
    >
      <FormDescription
        data-testid="formDescription"
      >
        {formatMessage({ id: 'attachLinksForm.description' })}
      </FormDescription>
      <LinkField
        data-testid="linkField"
        name={FORM_FIELD_NAMES.links}
        maxItemsCount={MAX_LINKS_COUNT}
      />
    </Form>
  );
};

AttachLinksForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
  defaultValues: PropTypes.object,
};

AttachLinksForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  getFormApi: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  values: null,
  defaultValues: null,
};

export default AttachLinksForm;
