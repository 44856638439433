import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import LikeButton from '../LikeButton';
import CommentButton from '../CommentButton';
import { userIdSelector } from '../../redux/slices/access';
import { useIsAbleToCommentIdea, useIsAbleToLikeIdea } from '../../hooks';

import styles from './LikesAndCommentsBar.module.scss';

const LikesAndCommentsBar = ({
  idea,
  onLike,
  onComment,
  className,
}) => {
  const currentUserId = useSelector(userIdSelector);
  const isAbleToLikeIdea = useIsAbleToLikeIdea(idea);
  const isAbleToCommentIdea = useIsAbleToCommentIdea(idea);
  const {
    likes,
    human_readable_id: ideaId,
    total_comments_with_replies: totalComments,
  } = idea;
  const isLiked = useMemo(
    () => likes.includes(currentUserId),
    [currentUserId, likes],
  );

  return (
    <div className={clsx(styles.wrapper, className)}>
      <LikeButton
        data-testid="likeButton"
        likes={likes.length}
        onLike={onLike}
        isLiked={isLiked}
        isDisabled={!isAbleToLikeIdea}
        ideaId={ideaId}
      />
      <CommentButton
        data-testid="commentButton"
        ideaId={ideaId}
        onComment={onComment}
        isDisabled={!isAbleToCommentIdea}
        comments={totalComments}
      />
    </div>
  );
};

LikesAndCommentsBar.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    likes: PropTypes.arrayOf(PropTypes.number).isRequired,
    total_comments_with_replies: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    human_readable_id: PropTypes.string.isRequired,
  }).isRequired,
  onLike: PropTypes.func,
  onComment: PropTypes.func,
  className: PropTypes.string,
};

LikesAndCommentsBar.defaultProps = {
  className: '',
  onLike: null,
  onComment: null,
};

export default LikesAndCommentsBar;
