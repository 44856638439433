export const IDEAS_BOARD_TAB_IDES = {
  IDEAS_TOTAL: 'ideasTotal',
  IDEAS_DOWN_SELECTION: 'ideasDownSelection',
  ARCHIVED_IDEAS: 'archivedIdeas',
  VOTING_IDEAS: 'votingIdeas',
  IDEAS_WINNER_SELECTION: 'ideasWinnerSelection',
};

export const IDEAS_TAB_PARAM_NAME = 'ideasTab';

export const SORTING_TYPES = {
  likesAndComments: '-collaborations',
  newestIdeas: '-created_at',
  oldestIdeas: 'created_at',
};

export const FILTERING_TYPES = {
  BIZ_OBJECTIVE: 'bizCategoryIds',
};
