import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../Tooltip';

import styles from './TextCell.module.scss';

const TextCell = ({
  value,
}) => (
  <div className={styles.wrapper}>
    <Tooltip
      content={value}
      delay={1000}
    >
      <span className={styles.content}>
        {value}
      </span>
    </Tooltip>
  </div>
);

TextCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TextCell;
