import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CategoryItem from './CategoryItem';

import styles from './CategoriesList.module.scss';

const CategoriesList = ({
  categories,
  onToggleAvailability,
  onUpdate,
  className,
  updateButtonLabel,
  deactivateButtonLabel,
  activateButtonLabel,
}) => (
  <div className={clsx(styles.wrapper, className)}>
    <ul className={styles.list}>
      {
        categories.map((category) => (
          <li
            key={category.id}
            className={styles.item}
          >
            <CategoryItem
              data-testid={category.id}
              className={styles.listItem}
              category={category}
              onToggleAvailability={onToggleAvailability}
              onUpdate={onUpdate}
              updateButtonLabel={updateButtonLabel}
              deactivateButtonLabel={deactivateButtonLabel}
              activateButtonLabel={activateButtonLabel}
            />
          </li>
        ))
      }
    </ul>
  </div>
);

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      is_active: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onToggleAvailability: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  className: PropTypes.string,
  updateButtonLabel: PropTypes.string.isRequired,
  deactivateButtonLabel: PropTypes.string.isRequired,
  activateButtonLabel: PropTypes.string.isRequired,
};

CategoriesList.defaultProps = {
  className: '',
};

export default CategoriesList;
