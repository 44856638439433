import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../../Icon';

const TrashIcon = () => (
  <Icon
    type={ICON_TYPES.trashCan}
    size={ICON_SIZE.xxs}
    color={ICON_COLOR.quaternary}
    data-testid="trashIcon"
  />
);

export default TrashIcon;
