import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as SpinnerBase } from '@epam/promo';
import clsx from 'clsx';

import styles from './Spinner.module.scss';

export const SPINNER_SIZE = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
};

const SPINNER_CLASS_NAME_BY_SIZE = {
  [SPINNER_SIZE.xs]: styles.extraSmall,
  [SPINNER_SIZE.sm]: styles.small,
  [SPINNER_SIZE.md]: '',
};

const Spinner = ({ size, className }) => (
  <SpinnerBase cx={clsx(SPINNER_CLASS_NAME_BY_SIZE[size], className)} />
);

Spinner.propTypes = {
  size: PropTypes.oneOf(Object.values(SPINNER_SIZE)),
  className: PropTypes.string,
};

Spinner.defaultProps = {
  size: SPINNER_SIZE.md,
  className: '',
};

export default Spinner;
