import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { useUuiContext, UuiError } from '@epam/uui';

import {
  MultiColumnContent,
  OneColumnContent,
  useStep,
  useStepperApi,
} from '../../../Stepper';
import ProposeIdeaSolutionForm from '../../../forms/ProposeIdeaSolutionForm';
import Spinner from '../../../Spinner';
import { useGetIdeaByIdQuery } from '../../../../redux/api/slices/ideaApi';
import { useGetActiveTechnicalCategoriesQuery } from '../../../../redux/api/slices/technicalCategoriesApi';
import { convertTechnicalCategories } from './utils';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import IdeaInfo from '../../../IdeaInfo';
import { IDEA_STATUSES } from '../../../../constants/idea';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';

import styles from './ProposeIdeaSolutionStep.module.scss';

const ProposeIdeaSolutionStep = () => {
  const { ideaId } = useParams();
  const {
    goNext,
    stepDefaultValues,
    stepValues,
  } = useStepperApi();
  const [{
    isSubmitting,
    submitForm,
    isDirty,
  }, setFormApi] = useState({});
  const { uuiErrors } = useUuiContext();
  const handleSubmit = useCallback((formValues) => goNext(formValues), [goNext]);
  const [verifiedIdeaData, setVerifiedIdeaData] = useState();
  const [canVerifyIdea, setCanVerifyIdea] = useState(false);

  const {
    data: ideaData,
    isLoading: isIdeaLoading,
    isFetching: isIdeaFetching,
    isError: isIdeaError,
    error: ideaError,
  } = useGetIdeaByIdQuery({
    ideaId,
    eventHrid: ACTIVE_EVENT_HRID,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: techCetegories,
    isLoading: isTechCetegoriesLoading,
    isFetching: isTechCetegoriesFetching,
    isError: isTechCetegoriesError,
    error: techCetegoriesError,
  } = useGetActiveTechnicalCategoriesQuery();

  const resultTechCategories = useMemo(() => {
    if (isTechCetegoriesLoading || isTechCetegoriesError) {
      return [];
    }

    return techCetegories.map(convertTechnicalCategories);
  }, [
    isTechCetegoriesLoading,
    isTechCetegoriesError,
    techCetegories,
  ]);

  const isLoading = isTechCetegoriesLoading || isIdeaLoading;

  const isFetching = isTechCetegoriesFetching || isIdeaFetching;

  const isError = isIdeaError || isTechCetegoriesError;

  const showSpinner = isLoading || (isFetching && !verifiedIdeaData);

  useStep({
    isLoading: showSpinner,
    isFetching: !isLoading && isFetching && !!verifiedIdeaData,
    isInProgress: isSubmitting,
    onGoNext: submitForm,
    isDirty,
  });

  useEffect(() => {
    isAxiosError(ideaError) && ideaError.handleGlobally();
  }, [ideaError]);

  useEffect(() => {
    isAxiosError(techCetegoriesError) && techCetegoriesError.handleGlobally();
  }, [techCetegoriesError]);

  useLayoutEffect(() => {
    /*
      we use refetchOnMountOrArgChange: true to refetch idea data on mount
      but isIdeaFetching is false on first render. I believe it is rtk-query bug
      https://github.com/reduxjs/redux-toolkit/issues/2349#issuecomment-1375335169

      so we use canVerifyIdea flag to skip logic in this useLayoutEffect on first render
    */
    if (!canVerifyIdea) {
      setCanVerifyIdea(true);
      return;
    }

    if (isIdeaFetching || isIdeaError) {
      return;
    }

    const { status, archive } = ideaData;

    if (archive || status === IDEA_STATUSES.DRAFT) {
      uuiErrors.reportError(new UuiError({ status: 404 }));
      return;
    }

    setVerifiedIdeaData(ideaData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdeaFetching, ideaData]);

  if (showSpinner) {
    return (
      <OneColumnContent>
        <Spinner />
      </OneColumnContent>
    );
  }

  if (isError || !verifiedIdeaData) {
    return null;
  }

  return (
    <MultiColumnContent
      columns={[{
        content: (
          <IdeaInfo
            idea={verifiedIdeaData}
          />
        ),
        className: styles.column,
      }, {
        content: (
          <ProposeIdeaSolutionForm
            onSubmit={handleSubmit}
            getFormApi={setFormApi}
            defaultValues={stepDefaultValues}
            values={stepValues}
            technicalCategories={resultTechCategories}
          />
        ),
        className: styles.column,
      }]}
    />
  );
};

export default ProposeIdeaSolutionStep;
