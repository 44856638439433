import { EVENT_STAGES } from '../constants/event';
import { USER_ROLES } from '../constants/roles';

export const ideaAuthorAccessConditions = ({
  userRole,
  activeStageType,
}) => {
  if (userRole === USER_ROLES.ADMIN) {
    return true;
  }

  return ![EVENT_STAGES.VOTING, EVENT_STAGES.ANNOUNCE_WINNER].includes(activeStageType);
};
