import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  Doughnut,
  Line,
  Pie,
} from 'react-chartjs-2';
import { omit } from 'lodash';

import { uuid } from '../../utils';
import { htmlLegendPlugin } from './plugins/htmlLegend';
import {
  CHART_TYPES,
  LEGEND_POSITIONS,
} from './constants';

export const chartComponentsByType = {
  [CHART_TYPES.bar]: Bar,
  [CHART_TYPES.doughnut]: Doughnut,
  [CHART_TYPES.line]: Line,
  [CHART_TYPES.pie]: Pie,
};

const Chart = ({
  type,
  data,
}) => {
  const chartId = useMemo(() => uuid(), []);
  const rootContainerId = `${chartId}-chart-root`;
  const legendContainerId = `${chartId}-chart-legend`;
  const chartContainerId = `${chartId}-chart-chart`;

  const options = useMemo(() => {
    let chartOptions = {};
    let legendOptions = {};

    switch (type) {
      case CHART_TYPES.line:
      case CHART_TYPES.bar:
        chartOptions = {
          scales: {
            y: {
              ticks: {
                precision: 0,
              },
              beginAtZero: true,
            },
          },
          aspectRatio: 4,
        };
        legendOptions = {
          position: LEGEND_POSITIONS.top,
        };
        break;
      case CHART_TYPES.doughnut:
      case CHART_TYPES.pie:
        legendOptions = {
          position: LEGEND_POSITIONS.right,
        };
        break;
      default:
        break;
    }

    return {
      plugins: {
        htmlLegend: {
          rootContainerId,
          legendContainerId,
          chartContainerId,
          ...legendOptions,
        },
        legend: {
          display: false,
        },
      },
      ...chartOptions,
    };
  }, [
    type,
    rootContainerId,
    legendContainerId,
    chartContainerId,
  ]);

  const resultData = useMemo(() => ({
    ...data,
    datasets: data.datasets.map((dataSet) => ({
      ...omit(dataSet, 'color'),
      borderColor: dataSet.color,
      backgroundColor: dataSet.color,
    })),
  }), [data]);

  const plugins = [htmlLegendPlugin];

  const ChartComponent = chartComponentsByType[type];

  return (
    <div id={rootContainerId}>
      <div id={legendContainerId} />
      <div id={chartContainerId}>
        <ChartComponent
          data-testid="chart"
          data={resultData}
          options={options}
          plugins={plugins}
        />
      </div>
    </div>
  );
};

Chart.propTypes = {
  type: PropTypes.oneOf(Object.values(CHART_TYPES)),
  data: PropTypes.shape({
    labels: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    datasets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ])).isRequired,
      backgroundColor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      borderColor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      borderWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })).isRequired,
  }).isRequired,
};

Chart.defaultProps = {
  type: CHART_TYPES.pie,
};

export default Chart;
