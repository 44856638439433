import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { flexRender } from '@tanstack/react-table';

import {
  COLUMN_DEFAULT_WIDTH,
  EXPAND_CELL_ACCESSOR,
} from '../constants';
import { FIXED_COLUMNS_WIDTH } from './constants';

import styles from './TableHeader.module.scss';

const TableHeader = ({
  table,
}) => (
  <thead className={styles.tableHeader}>
    {table.getHeaderGroups().map((headerGroup) => (
      <tr
        key={headerGroup.id}
        className={styles.tableHeaderRow}
      >
        {headerGroup.headers.map((header) => {
          const isExpanderColumn = header.column.id === EXPAND_CELL_ACCESSOR;

          const columnSize = (() => {
            const size = header.getSize();

            return FIXED_COLUMNS_WIDTH[header.column.id] || (
              size === COLUMN_DEFAULT_WIDTH
                ? 'auto'
                : `${size}%`
            );
          })();

          return (
            <th
              key={header.id}
              colSpan={header.colSpan}
              className={clsx(styles.tableHeaderCell, {
                [styles.tableHeaderCell__expander]: isExpanderColumn,
              })}
              style={{ width: columnSize }}
            >
              {header.isPlaceholder ? null : (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  className={clsx(
                    styles.tableHeaderCellCaption,
                    { [styles.tableHeaderCellCaption__sortable]: header.column.getCanSort() },
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </div>
              )}
            </th>
          );
        })}
      </tr>
    ))}
  </thead>
);

TableHeader.propTypes = {
  table: PropTypes.object.isRequired,
};

export default TableHeader;
