import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import CheckboxGroupFieldBase from '../../CheckboxGroupField';

const CheckboxGroupField = ({
  name,
  deps,
  ...props
}) => {
  const {
    field: {
      onChange,
      value,
    },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      deps,
    },
  });

  return (
    <CheckboxGroupFieldBase
      {...props}
      data-testid="checkboxGroupFieldBase"
      value={value}
      onValueChange={onChange}
      errorMessage={error?.message}
    />
  );
};

CheckboxGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  deps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

CheckboxGroupField.defaultProps = {
  deps: undefined,
};

export default CheckboxGroupField;
