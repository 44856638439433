import { useSelector } from 'react-redux';

import { USER_ROLES } from '../constants/roles';
import { userSelector } from '../redux/slices/access';
import {
  activeEventActiveStageTypeSelector,
  activeEventStatusSelector,
} from '../redux/slices/events';
import { EVENT_STAGES, EVENT_STATUSES } from '../constants/event';

export const useIsAbleToArchiveIdea = (idea) => {
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const activeEventStatus = useSelector(activeEventStatusSelector);
  const {
    role: userRole,
    id: userId,
  } = useSelector(userSelector);

  if (idea.archive) {
    return false;
  }

  if (
    userRole === USER_ROLES.ADMIN
    && activeEventStatus === EVENT_STATUSES.IN_PROGRESS
  ) {
    return true;
  }

  return (
    userId === idea.author.id
    && activeStageType === EVENT_STAGES.IDEA_SUBMISSION
  );
};
