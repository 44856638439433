export const FILTERS_URL_PARAM_NAME = 'ideasWinnerSelectionFilters';
export const TABLE_ID = 'ideasWinnerSelectionTable';
export const SORTING_URL_PARAM_NAME = 'ideasWinnerSelectionSorting';
export const PAGE_URL_PARAM_NAME = 'ideasWinnerSelectionPage';

export const ACCESSOR_KEYS = {
  IDEA_TITLE: 'title',
  VOTES: 'votes',
  ACTIONS: 'ideasWinnerSelectionActions',
};
