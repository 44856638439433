import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Section from '../Section';
import {
  INACTIVATE_REASON_TYPE_TRANSLATION_KEY,
  IDEA_INACTIVATE_REASON_TYPES,
} from '../../constants/idea';
import { getUserFullName, formatDate } from '../../utils';

const ArchivedIdeaDescription = ({
  archive,
  className,
}) => {
  const {
    author,
    reason,
    reason_type: reasonType,
    created_at: createdAt,
  } = archive;
  const { formatMessage } = useIntl();

  const sections = useMemo(() => ([
    {
      id: 'archiveReason',
      label: formatMessage({ id: 'archivedIdeaDescription.archiveReason.label' }),
      value: formatMessage({ id: INACTIVATE_REASON_TYPE_TRANSLATION_KEY[reasonType] }),
    },
    {
      id: 'additionalContext',
      label: formatMessage({ id: 'archivedIdeaDescription.additionalContext.label' }),
      value: reason,
    },
    {
      id: 'archivedBy',
      label: formatMessage({ id: 'archivedIdeaDescription.archivedBy.label' }),
      value: `${getUserFullName(author)}, ${formatDate(createdAt)}`,
    },
  ]), [
    formatMessage,
    author,
    reason,
    reasonType,
    createdAt,
  ]);

  return (
    <div className={className}>
      {sections.map(({ id, label, value }) => (!!value && (
        <Section
          key={id}
          heading={label}
          description={value}
          data-testid={id}
        />
      )))}
    </div>
  );
};

ArchivedIdeaDescription.propTypes = {
  archive: PropTypes.shape({
    author: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    reason: PropTypes.string.isRequired,
    reason_type: PropTypes.oneOf(
      Object.values(IDEA_INACTIVATE_REASON_TYPES),
    ).isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

ArchivedIdeaDescription.defaultProps = {
  className: '',
};

export default ArchivedIdeaDescription;
