import React from 'react';

import Icon, {
  ICON_TYPES,
  ICON_SIZE,
  ICON_COLOR,
} from '../../../../../Icon';

const CopyLinkIcon = () => (
  <Icon
    data-testid="copyLinkIcon"
    type={ICON_TYPES.link}
    size={ICON_SIZE.xxs}
    color={ICON_COLOR.primary}
  />
);

export default CopyLinkIcon;
