import {
  useLayoutEffect,
  useCallback,
  useContext,
} from 'react';
import { useIntl } from 'react-intl';
import { useUuiContext, UuiError } from '@epam/uui';

import { useListener, useShowNotification } from '../../hooks';
import { setHandleGlobally } from '../../redux/api/fetchClient';
import { tokenExpiredListener, tokenExpiredActionCreator } from './listeners/tokenExpiredListener';
import { NOTIFICATION_TYPES } from '../Notification/Notification';
import { emitErrorToSentry } from '../../utils';
import { LeavePromptContext } from '../LeavePromptProvider';

const ApiErrorsHandler = ({ children }) => {
  const { formatMessage } = useIntl();
  const { uuiErrors } = useUuiContext();
  const {
    disableLeavePrompt,
    enableLeavePrompt,
  } = useContext(LeavePromptContext);

  const [handleTokenExpired] = useListener({
    listener: tokenExpiredListener,
    actionCreator: tokenExpiredActionCreator,
  });

  const handleTokenExpiredError = useCallback(async () => {
    disableLeavePrompt();

    try {
      await handleTokenExpired();
    } finally {
      enableLeavePrompt();
    }
  }, [
    disableLeavePrompt,
    enableLeavePrompt,
    handleTokenExpired,
  ]);

  const showToast = useShowNotification();

  const showErrorToast = useCallback((messageId) => {
    showToast({
      type: NOTIFICATION_TYPES.ERROR,
      message: formatMessage({ id: messageId }),
    });
  }, [showToast, formatMessage]);

  const handleGlobally = useCallback((error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          handleTokenExpiredError();
          break;
        case 404:
          uuiErrors.reportError(new UuiError({ status: 404 }));
          break;
        default:
          showErrorToast('common.error.somethingWentWrong');
          emitErrorToSentry(error);
          break;
      }
    } else {
      showErrorToast('common.error.somethingWentWrong');
      emitErrorToSentry(error);
    }
  }, [
    showErrorToast,
    handleTokenExpiredError,
    uuiErrors,
  ]);

  useLayoutEffect(() => {
    setHandleGlobally(handleGlobally);
  }, [handleGlobally]);

  return children;
};

export default ApiErrorsHandler;
