import { userApi } from '../redux/api/slices/userApi';

export const updateUserDataFork = (dispatch, userData) => async (forkApi) => {
  const { pause } = forkApi;

  const updateUserData = dispatch(
    userApi.endpoints.updateUserData.initiate(userData),
  );

  try {
    return await pause(updateUserData.unwrap());
    // eslint-disable-next-line no-useless-catch
  } catch (error) {
    throw error;
  } finally {
    updateUserData.unsubscribe();
  }
};
