import PropTypes from 'prop-types';
import { useBeforeunload } from 'react-beforeunload';

const BrowserPrompt = ({ isLeaveBlocked }) => {
  useBeforeunload((event) => {
    if (isLeaveBlocked) {
      event.preventDefault();
    }
  });

  return null;
};

BrowserPrompt.propTypes = {
  isLeaveBlocked: PropTypes.bool.isRequired,
};

export default BrowserPrompt;
