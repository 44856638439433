import React from 'react';
import { useIntl } from 'react-intl';

import Login from '../../Login';
import PageInfo from '../../PageInfo';

const LoginPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'loginPage.pageTitle' })}
      />
      <Login data-testid="login" />
    </>
  );
};

export default LoginPage;
