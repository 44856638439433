import React from 'react';
import PropTypes from 'prop-types';

import AttachedLink from './AttachedLink';
import NoAttachedLinks from './NoAttachedLinks';

import styles from './AttachedLinks.module.scss';

const AttachedLinks = ({ links, author }) => (
  <div className={styles.wrapper}>
    {
      links.length ? (
        <ul className={styles.list}>
          {links.map(({ id, title, link }) => (
            <li key={id}>
              <AttachedLink
                author={author}
                link={link}
                title={title}
                data-testid={id}
              />
            </li>
          ))}
        </ul>
      ) : <NoAttachedLinks data-testid="noAttachedLinks" />
    }
  </div>
);

AttachedLinks.propTypes = {
  author: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AttachedLinks;
