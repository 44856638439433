import React, {
  useState,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { rangeDatePickerPresets } from '@epam/uui-components';
import { pick } from 'lodash';
import dayjs from 'dayjs';

import RangeDatePicker from '../../Form/RangeDatePicker';
import SummaryInfo from './SummaryInfo';
import {
  subtractDaysFromToday,
  formatDate,
  formatDateWithTimezone,
} from '../../../utils';
import {
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_DATE_VIEW_FORMAT,
} from '../../../constants/date';
import { useGetYesterdayPreset } from '../../Form/RangeDatePicker/hook/useGetYesterdayPreset';
import IdeasOverview from './IdeasOverview';
import BusinessObjectivesOverview from './BusinessObjectivesOverview';
// import TechnologyCategoriesOverview from './TechnologyCategoriesOverview';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const { formatMessage } = useIntl();
  const defaultRange = useMemo(() => ({
    from: formatDate(subtractDaysFromToday(6), DATE_PICKER_DATE_FORMAT),
    to: formatDate(dayjs(), DATE_PICKER_DATE_FORMAT),
  }), []);
  const [period, setPeriod] = useState(defaultRange);
  const lastValidBackendPeriod = useRef(null);

  const backendPeriod = useMemo(() => {
    if (period.from && period.to) {
      lastValidBackendPeriod.current = {
        from: formatDateWithTimezone(dayjs(period.from).startOf('day')),
        to: formatDateWithTimezone(dayjs(period.to).endOf('day')),
      };
    }

    return lastValidBackendPeriod.current;
  }, [period]);
  const yesterdayPreset = useGetYesterdayPreset();

  const presets = useMemo(() => ({
    yesterdayPreset,
    ...pick(rangeDatePickerPresets, [
      'thisWeek',
      'lastWeek',
      'thisMonth',
      'lastMonth',
      'last3Month',
      'thisYear',
      'lastYear',
    ]),
  }), [yesterdayPreset]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>
            {formatMessage({ id: 'adminPanel.dashboard.title' })}
          </h2>
        </div>
        <div className={styles.rangeDatePicker}>
          <RangeDatePicker
            value={period}
            onValueChange={setPeriod}
            format={DATE_PICKER_DATE_VIEW_FORMAT}
            presets={presets}
          />
        </div>
      </div>
      <div className={styles.content}>
        <SummaryInfo
          period={backendPeriod}
          className={styles.summaryInfo}
        />
        <IdeasOverview
          period={backendPeriod}
          className={styles.ideasOverview}
        />
        <BusinessObjectivesOverview
          period={backendPeriod}
          className={styles.businessObjectivesOverview}
        />
        {/* <TechnologyCategoriesOverview
          period={backendPeriod}
          className={styles.technologyCategoriesOverview}
        /> */}
      </div>
    </div>
  );
};

export default Dashboard;
