export const ACTION_TYPES = {
  common: {
    reset: 'common/RESET',
  },
  app: {
    handleActiveEvent: 'app/HANDLE_ACTIVE_EVENT_TRANSITION',
    getActiveEvent: 'app/GET_ACTIVE_EVENT',
  },
  user: {
    login: 'user/LOGIN',
    signUp: 'user/SIGNUP',
    getResetLink: 'user/GET_RESET_LINK',
    setNewPassword: 'user/SET_NEW_PASSWORD',
    ssoAuth: 'user/SSO_AUTH',
    contactUs: 'user/CONTACT_US',
  },
  onboarding: {
    updateUserData: 'onboarding/UPDATE_USER_DATA',
  },
  idea: {
    createIdea: 'idea/CREATE_IDEA',
    createIdeaFromDraftIdea: 'idea/CREATE_IDEA_FROM_DRAFT_IDEA',
    updateIdea: 'idea/UPDATE_IDEA',
    deleteIdea: 'idea/DELETE_IDEA',
    toggleArchiveIdea: 'idea/TOGGLE_ARCHIVE_IDEA',

    createDraftIdea: 'idea/CREATE_DRAFT_IDEA',
    updateDraftIdea: 'idea/UPDATE_DRAFT_IDEA',

    createSolution: 'idea/CREATE_SOLUTION',
    updateSolution: 'idea/UPDATE_SOLUTION',
    deleteSolution: 'idea/DELETE_SOLUTION',

    getReport: 'idea/GET_REPORT',
    toggleIdeaForVote: 'idea/TOGGLE_FOR_VOTE',
    selectIdeaAsWinner: 'idea/SELECT_IDEA_AS_WINNER',
    deselectIdeaAsWinner: 'idea/DESELECT_IDEA_AS_WINNER',

    vote: 'idea/VOTE',
    unvote: 'idea/UNVOTE',
  },
  ideaComments: {
    createComment: 'ideaComments/CREATE_COMMENT',
    getComments: 'ideaComments/GET_COMMENTS',
    deleteComment: 'ideaComments/DELETE_COMMENT',
    updateComment: 'ideaComments/UPDATE_COMMENT',
  },
  solutionComments: {
    createComment: 'solutionComments/CREATE_COMMENT',
    getComments: 'solutionComments/GET_COMMENTS',
    deleteComment: 'solutionComments/DELETE_COMMENT',
    updateComment: 'solutionComments/UPDATE_COMMENT',
  },
  apiErrorsHandler: {
    tokenExpired: 'apiErrorsHandler/TOKEN_EXPIRED',
  },
  bizCategory: {
    createNewBizCategory: 'bizCategory/CREATE_NEW_BIZ_CATEGORY',
    toggleBizCategoryAvailability: 'bizCategory/TOGGLE_BIZ_CATEGORY_AVAILABILITY',
    updateBizCategory: 'bizCategory/UPDATE_BIZ_CATEGORY',
  },
  techCategory: {
    createNewTechCategory: 'techCategory/CREATE_NEW_TECH_CATEGORY',
    updateTechCategory: 'techCategory/UPDATE_TECH_CATEGORY',
    toggleTechCategoryAvailability: 'techCategory/TOGGLE_TECH_CATEGORY_AVAILABILITY',
  },
  admin: {
    updateUserData: 'admin/UPDATE_USER_DATA',
  },
};
