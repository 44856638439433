import React from 'react';
import PropTypes from 'prop-types';

import Label from '../Label';
import TextArea from '../TextArea';

const TextAreaField = ({
  label,
  tooltip,
  errorMessage,
  onChange,
  isRequired,
  footer,
  ...props
}) => (
  <Label
    data-testid="label"
    label={label}
    isInvalid={!!errorMessage}
    validationMessage={errorMessage}
    info={tooltip}
    isRequired={isRequired}
    footer={footer}
  >
    <TextArea
      data-testid="textAreaBase"
      isInvalid={!!errorMessage}
      onValueChange={onChange}
      size="42"
      validationMessage={errorMessage}
      {...props}
    />
  </Label>
);

TextAreaField.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  footer: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

TextAreaField.defaultProps = {
  label: null,
  tooltip: null,
  placeholder: '',
  errorMessage: '',
  isRequired: false,
  footer: null,
  value: null,
};

export default TextAreaField;
