import React from 'react';

import Icon, {
  ICON_TYPES,
  ICON_SIZE,
  ICON_COLOR,
} from '../../../../Icon';

const CopyLinkIcon = () => (
  <Icon
    type={ICON_TYPES.copy}
    size={ICON_SIZE.msm}
    color={ICON_COLOR.primary}
    data-testid="copyLinkIcon"
  />
);

export default CopyLinkIcon;
