import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { ideaApi } from '../../../redux/api/slices/ideaApi';

export const unvoteIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      ideaId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const unvoteIdea = dispatch(ideaApi.endpoints.unvoteIdea.initiate({
    eventHrid,
    ideaId,
  }));

  try {
    await pause(unvoteIdea.unwrap());

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    unvoteIdea.unsubscribe();
  }
};

export const unvoteIdeaActionCreator = createAction(ACTION_TYPES.idea.unvote);
