import React from 'react';

import EventJourney from '../EventJourney';
import Banner from './Banner';

const InactiveWelcome = () => (
  <>
    <Banner data-testid="banner" />
    <EventJourney data-testid="eventJourney" />
  </>
);

export default InactiveWelcome;
