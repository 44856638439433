import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../../constants/actionTypes';
import { isAxiosError } from '../../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../../redux/api/slices/ideaApi';
import { NOTIFICATION_TYPES } from '../../../../Notification';

export const selectIdeaAsWinnerListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      ideaId,
      eventHrid,
      winnersMaxNumber,
      showToast,
      formatMessage,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const selectIdeaAsWinner = dispatch(
    ideaApi.endpoints.selectIdeaAsWinner.initiate({
      eventHrid,
      ideaId,
    }),
  );

  try {
    await pause(selectIdeaAsWinner.unwrap());

    resolve();
  } catch (error) {
    if (isAxiosError(error)) {
      const { status } = error.response || {};

      switch (status) {
        case 403:
          showToast({
            type: NOTIFICATION_TYPES.WARNING,
            message: formatMessage(
              { id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.warning.winnersMaxNumber' },
              { winnersMaxNumber },
            ),
          });
          break;

        default:
          error.handleGlobally();
          break;
      }
    }

    reject();
  } finally {
    selectIdeaAsWinner.unsubscribe();
  }
};

export const selectIdeaAsWinnerActionCreator = createAction(
  ACTION_TYPES.idea.selectIdeaAsWinner,
);
