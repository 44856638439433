import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useWithDispatch = (actionCreator) => {
  const dispatch = useDispatch();

  return useCallback(
    (...params) => (
      dispatch(actionCreator(...params))
    ),
    [dispatch, actionCreator],
  );
};
