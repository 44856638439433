import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../IconButton';
import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../Icon';

import styles from './ExpanderCell.module.scss';

const ExpanderCell = ({
  isExpanded,
  onClick,
}) => {
  const getArrowIcon = useCallback(() => (
    <Icon
      size={ICON_SIZE.xxxs}
      color={isExpanded ? ICON_COLOR.primary : ICON_COLOR.quaternary}
      type={isExpanded ? ICON_TYPES.arrowUp : ICON_TYPES.arrowDown}
    />
  ), [isExpanded]);

  return (
    <div className={styles.wrapper}>
      <IconButton
        icon={getArrowIcon}
        onClick={onClick}
      />
    </div>
  );
};

ExpanderCell.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExpanderCell;
