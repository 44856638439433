import { ArrayDataSource } from '@epam/uui';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FILTER_PANEL_FILTER_TYPES } from '../../../FiltersPanel';
import { FILTERING_TYPES } from '../constants';

export const useGetFilters = ({ bizObjectives }) => {
  const { formatMessage } = useIntl();

  const filters = useMemo(() => ([{
    field: FILTERING_TYPES.BIZ_OBJECTIVE,
    type: FILTER_PANEL_FILTER_TYPES.MULTI_PICKER,
    isAlwaysVisible: true,
    title: formatMessage({ id: 'ideas.ideasList.filters.bizObjective.title' }),
    dataSource: new ArrayDataSource({
      items: (bizObjectives || []).map(({ name, id }) => ({
        name,
        id,
      })),
    }),
  }]), [
    formatMessage,
    bizObjectives,
  ]);

  return filters;
};
