import { useSelector } from 'react-redux';

import { IDEA_STATUSES } from '../constants/idea';
import { USER_ROLES } from '../constants/roles';
import { userRoleSelector } from '../redux/slices/access';
import {
  activeEventStatusSelector,
} from '../redux/slices/events';
import { EVENT_STATUSES } from '../constants/event';

export const useIsAbleToDeleteIdea = (idea) => {
  const activeEventStatus = useSelector(activeEventStatusSelector);
  const currentUserRole = useSelector(userRoleSelector);
  const {
    archive,
    status,
  } = idea;

  if (archive) {
    return (
      currentUserRole === USER_ROLES.ADMIN
      && activeEventStatus === EVENT_STATUSES.IN_PROGRESS
    );
  }

  return status === IDEA_STATUSES.DRAFT;
};
