import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  ICON_TYPES,
} from '../../Icon';
import EmptyContent from '../../EmptyContent';
import { EVENT_STAGES } from '../../../constants/event';

const NoComments = ({ activeStageType }) => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.communication,
      }}
      title={formatMessage({ id: 'comments.noComments.title' })}
      description={formatMessage({
        id: (activeStageType === EVENT_STAGES.IDEA_SUBMISSION)
          ? 'comments.noComments.ideaSubmissionStage.description'
          : 'comments.noComments.description',
      })}
    />
  );
};

NoComments.propTypes = {
  activeStageType: PropTypes.oneOf(Object.values(EVENT_STAGES)),
};

NoComments.defaultProps = {
  activeStageType: null,
};

export default NoComments;
