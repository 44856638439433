import React from 'react';

import Banner from './Banner';
import EventJourney from '../EventJourney';
import EventStats from '../EventStats';

const WinnerAnnouncementWelcome = () => (
  <>
    <Banner data-testid="banner" />
    <EventJourney data-testid="eventJourney" />
    <EventStats data-testid="eventStats" />
  </>
);

export default WinnerAnnouncementWelcome;
