import React from 'react';
import { useIntl } from 'react-intl';

import {
  ICON_TYPES,
} from '../../../Icon';
import EmptyContent from '../../../EmptyContent';

const NoAttachedLinks = () => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.paperClip,
      }}
      title={formatMessage({ id: 'idea.ideaTabs.linksTab.noAttachments.heading' })}
      description={formatMessage({ id: 'idea.ideaTabs.linksTab.noAttachments.description' })}
      data-testid="attachedLinksEmptyContent"
    />
  );
};

export default NoAttachedLinks;
