import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../Button';
import { ROUTES } from '../../constants/routes';
import { useIsAbleToSubmitIdea } from '../../hooks';

const CreateNewIdeaButton = (props) => {
  const { formatMessage } = useIntl();
  const isAbleToCreateNewIdea = useIsAbleToSubmitIdea();

  if (!isAbleToCreateNewIdea) {
    return null;
  }

  return (
    <Button
      size="42"
      caption={formatMessage({ id: 'common.newIdea.label' })}
      link={{ pathname: ROUTES.createIdea }}
      data-testid="ideaCreationButton"
      {...props}
    />
  );
};

export default CreateNewIdeaButton;
