import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isBoolean } from 'lodash';

import { useIsLoggedIn } from '../../hooks';
import {
  activeEventActiveStageTypeSelector,
  activeEventStatusSelector,
} from '../../redux/slices/events';
import { userRoleSelector } from '../../redux/slices/access';

const HasAccess = ({
  conditions,
  fallback,
  children,
}) => {
  const isLoggedIn = useIsLoggedIn();
  const userRole = useSelector(userRoleSelector);
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const activeEventStatus = useSelector(activeEventStatusSelector);

  const accessResult = useMemo(() => conditions({
    isLoggedIn,
    userRole,
    activeStageType,
    activeEventStatus,
  }), [
    conditions,
    isLoggedIn,
    userRole,
    activeStageType,
    activeEventStatus,
  ]);

  if (isBoolean(accessResult)) {
    return accessResult
      ? children
      : fallback;
  }

  return accessResult;
};

HasAccess.propTypes = {
  conditions: PropTypes.func,
  children: PropTypes.node,
  fallback: PropTypes.node,
};

HasAccess.defaultProps = {
  conditions: ({ isLoggedIn }) => isLoggedIn,
  children: null,
  fallback: null,
};

export default HasAccess;
