import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../Icon';

const CrossIcon = () => (
  <Icon
    data-testid="icon"
    type={ICON_TYPES.cross}
    size={ICON_SIZE.mxxxs}
    color={ICON_COLOR.septenary}
  />
);

export default CrossIcon;
