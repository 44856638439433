import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, {
  FORM_PROPS,
  FORM_TITLE_SIZES,
} from '../../Form';
import TextField from '../../Form/connected/TextField';
import RadioGroupField from '../../Form/connected/RadioGroupField';
import TextAreaField from '../../Form/connected/TextAreaField';
import {
  FORM_FIELD_NAMES,
  REASON_FIELD_TRANSLATION_IDS_BY_IDEA_TYPE,
} from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';
import WatchFormValues from '../../Form/WatchFormValues';
import {
  IDEA_TYPES,
  IDEA_TYPE_LABEL_TRANSLATION_KEYS,
} from '../../../constants/idea';

const GeneralOverviewForm = ({
  defaultValues,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const resultDefaultValues = useMemo(() => (defaultValues || {
    [FORM_FIELD_NAMES.IDEA_TITLE]: '',
    [FORM_FIELD_NAMES.IDEA_TYPE]: null,
    [FORM_FIELD_NAMES.REASON]: '',
    [FORM_FIELD_NAMES.POTENTIAL_SOLUTION]: '',
    [FORM_FIELD_NAMES.BUSINESS_BENEFITS]: '',
  }), [defaultValues]);
  const ideaTypes = useMemo(() => ([{
    id: IDEA_TYPES.UNTAPPED_OPPORTUNITY,
    name: formatMessage({ id: IDEA_TYPE_LABEL_TRANSLATION_KEYS[IDEA_TYPES.UNTAPPED_OPPORTUNITY] }),
  }, {
    id: IDEA_TYPES.UNSOLVED_CHALLENGE,
    name: formatMessage({ id: IDEA_TYPE_LABEL_TRANSLATION_KEYS[IDEA_TYPES.UNSOLVED_CHALLENGE] }),
  }]), [formatMessage]);
  const validationSchema = useValidationSchema();

  return (
    <Form
      data-testid="form"
      defaultValues={resultDefaultValues}
      validationSchema={validationSchema}
      title={formatMessage({ id: 'generalOverviewForm.title' })}
      titleSize={FORM_TITLE_SIZES.SM}
      hideButtons
      {...props}
    >
      <TextField
        data-testid={FORM_FIELD_NAMES.IDEA_TITLE}
        name={FORM_FIELD_NAMES.IDEA_TITLE}
        label={formatMessage({ id: 'generalOverviewForm.ideaTitleField.label' })}
        placeholder={formatMessage({ id: 'generalOverviewForm.ideaTitleField.placeholder' })}
        isRequired
      />
      <RadioGroupField
        data-testid={FORM_FIELD_NAMES.IDEA_TYPE}
        name={FORM_FIELD_NAMES.IDEA_TYPE}
        label={formatMessage({ id: 'generalOverviewForm.ideaType.label' })}
        tooltip={formatMessage({ id: 'generalOverviewForm.ideaType.tooltip' })}
        items={ideaTypes}
        deps={FORM_FIELD_NAMES.REASON}
        isRequired
      />
      <WatchFormValues
        data-testid={`${FORM_FIELD_NAMES.IDEA_TYPE}WatchFormValues`}
        name={FORM_FIELD_NAMES.IDEA_TYPE}
      >
        {(value) => (
          value && (
            <TextAreaField
              data-testid={FORM_FIELD_NAMES.REASON}
              name={FORM_FIELD_NAMES.REASON}
              label={formatMessage({ id: REASON_FIELD_TRANSLATION_IDS_BY_IDEA_TYPE[value].label })}
              placeholder={formatMessage({
                id: REASON_FIELD_TRANSLATION_IDS_BY_IDEA_TYPE[value].placeholder,
              })}
              isRequired
            />
          )
        )}
      </WatchFormValues>
      <TextAreaField
        data-testid={FORM_FIELD_NAMES.POTENTIAL_SOLUTION}
        name={FORM_FIELD_NAMES.POTENTIAL_SOLUTION}
        label={formatMessage({ id: 'generalOverviewForm.potentialSolution.label' })}
        placeholder={formatMessage({ id: 'generalOverviewForm.potentialSolution.placeholder' })}
        isRequired
      />
      <TextAreaField
        data-testid={FORM_FIELD_NAMES.BUSINESS_BENEFITS}
        name={FORM_FIELD_NAMES.BUSINESS_BENEFITS}
        label={formatMessage({ id: 'generalOverviewForm.businessBenefits.label' })}
        placeholder={formatMessage({ id: 'generalOverviewForm.businessBenefits.placeholder' })}
        isRequired
      />
    </Form>
  );
};

GeneralOverviewForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
  defaultValues: PropTypes.object,
};

GeneralOverviewForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  getFormApi: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  values: null,
  defaultValues: null,
};

export default GeneralOverviewForm;
