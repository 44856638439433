import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import yup from '../../../../yup';
import { FORM_FIELD_NAMES } from '../constants';

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => (yup.object().shape({
    [FORM_FIELD_NAMES.NAME]: yup.string()
      .required(formatMessage({ id: 'bizCategoryForm.error.nameRequired' }))
      .max(250, formatMessage({ id: 'bizCategoryForm.error.nameMaxLength' })),
  })), [formatMessage]);
};
