import React, {
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../../Spinner';
import Cards from '../../Cards';
import {
  useGetIdeas,
  useGetTabs,
  useGetSorting,
  useGetTitleRenderer,
  useGetFilters,
  useGetCardRenderer,
} from './hooks';
import {
  CARDS_ID,
  TAB_TYPE_URL_PARAM_NAME,
  SORTING_TYPE_URL_PARAM_NAME,
  PAGE_URL_PARAM_NAME,
  FILTERS_URL_PARAM_NAME,
} from './constants';
import { useGetActiveBusinessObjectivesQuery } from '../../../redux/api/slices/businessObjectivesApi';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { ACTIVE_EVENT_HRID, EVENT_STAGES } from '../../../constants/event';
import { renderEmptyState } from './utils/renderEmptyState';
import { activeEventActiveStageTypeSelector } from '../../../redux/slices/events';
import { useGetUserVotesMetaQuery } from '../../../redux/api/slices/userApi';

import styles from './IdeasList.module.scss';

const IdeasList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const {
    data: bizObjectivesData,
    isLoading: areBizObjectivesLoading,
    error: bizObjectivesError,
  } = useGetActiveBusinessObjectivesQuery();
  const {
    data: votesMetaData,
    isLoading: isVotesMetaDataLoading,
    isFetching: isVotesMetaDataFetching,
    isError: isVotesMetaDataError,
    error: votesMetaDataError,
  } = useGetUserVotesMetaQuery(
    { eventHrid: ACTIVE_EVENT_HRID },
    {
      refetchOnMountOrArgChange: true,
      skip: activeStageType !== EVENT_STAGES.VOTING,
    },
  );
  const isVotesLimitReached = !!(votesMetaData
    && votesMetaData.voting.max_number_of_votes === votesMetaData.voting.used_number_of_votes);

  const renderCard = useGetCardRenderer({
    toogleIsProcessing: setIsProcessing,
    eventHrid: ACTIVE_EVENT_HRID,
    isVotesLimitReached,
  });
  const renderTitle = useGetTitleRenderer();
  const tabs = useGetTabs();
  const sorting = useGetSorting();
  const filters = useGetFilters({ bizObjectives: bizObjectivesData });

  useEffect(() => {
    isAxiosError(bizObjectivesError) && bizObjectivesError.handleGlobally();
  }, [bizObjectivesError]);

  useEffect(() => {
    isAxiosError(votesMetaDataError) && votesMetaDataError.handleGlobally();
  }, [votesMetaDataError]);

  if (isVotesMetaDataLoading || areBizObjectivesLoading) {
    return <Spinner cx={styles.spinner} />;
  }

  if (isVotesMetaDataError) {
    return null;
  }

  return (
    <Cards
      title={renderTitle}
      renderCard={renderCard}
      emptyState={renderEmptyState}
      pageSize={20}
      useData={useGetIdeas}
      tabUrlParamName={TAB_TYPE_URL_PARAM_NAME}
      pageUrlParamName={PAGE_URL_PARAM_NAME}
      tabs={tabs}
      id={CARDS_ID}
      filtersProps={{
        filters,
        urlParamName: FILTERS_URL_PARAM_NAME,
      }}
      sortingProps={{
        sorting,
        urlParamName: SORTING_TYPE_URL_PARAM_NAME,
      }}
      getDataAdditionalParams={{ eventHrid: ACTIVE_EVENT_HRID }}
      isInProgress={isProcessing || isVotesMetaDataFetching}
    />
  );
};

export default IdeasList;
