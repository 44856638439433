import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';

import Spinner from '../Spinner';
import Button from '../Button';
import Blocker from '../Blocker';
import CategoriesMenu from '../CategoriesMenu';
import CategoriesList from '../CategoriesList';
import NoObjectives from './NoObjectives';
import BusinessCategoryModal from '../modals/BusinessCategoryModal';
import { useGetAllBusinessObjectivesQuery } from '../../redux/api/slices/businessObjectivesApi';
import { isAxiosError } from '../../redux/api/fetchClient';
import {
  useListener,
  useModal,
} from '../../hooks';
import {
  toggleBizCategoryAvailabilityListener,
  toggleBizCategoryAvailabilityActionCreator,
} from './listeners/toggleBizCategoryAvailabilityListener';
import DeactivateBusinessCategoryConfirmationModal from '../modals/DeactivateBusinessCategoryConfirmationModal';

import styles from './BusinessCategories.module.scss';

const BusinessCategories = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();

  const [isInactive, setIsInactive] = useState(false);
  const [isActivatingBizCategory, setIsActivatingBizCategory] = useState(false);
  const {
    data: businessObjectives,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAllBusinessObjectivesQuery();

  const resultBusinessObjectives = useMemo(() => {
    if (isLoading || isError) {
      return [];
    }

    return businessObjectives?.filter((category) => isInactive || category.is_active);
  }, [
    businessObjectives,
    isLoading,
    isInactive,
    isError,
  ]);

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  const [toggleBizCategoryAvailability] = useListener({
    listener: toggleBizCategoryAvailabilityListener,
    actionCreator: toggleBizCategoryAvailabilityActionCreator,
  });

  const activateBizCategory = useCallback(async (id) => {
    setIsActivatingBizCategory(true);

    try {
      await toggleBizCategoryAvailability({
        bizCategoryId: id,
        isActive: true,
      });
    } finally {
      setIsActivatingBizCategory(false);
    }
  }, [
    toggleBizCategoryAvailability,
  ]);

  const handleDeactivateBizCateogry = useCallback((id) => toggleBizCategoryAvailability({
    bizCategoryId: id,
    isActive: false,
  }), [
    toggleBizCategoryAvailability,
  ]);

  const showBusinessCategoryModal = useCallback(({
    category,
    isEdited,
  } = {}) => (
    showModal((props) => (
      <BusinessCategoryModal
        data-testid="businessCategoryModal"
        currentBizCategory={category}
        isEdited={isEdited}
        {...props}
      />
    ))
  ), [showModal]);

  const showAddBizCategoryModal = useCallback(
    () => showBusinessCategoryModal(),
    [showBusinessCategoryModal],
  );

  const showUpdateBizCategoryModal = useCallback(
    (category) => showBusinessCategoryModal({
      category,
      isEdited: true,
    }),
    [showBusinessCategoryModal],
  );

  const handleToggleAvailability = useCallback((bizCategory) => (bizCategory.is_active
    ? showModal((props) => (
      <DeactivateBusinessCategoryConfirmationModal
        data-testid="deactivateBusinessCategoryConfirmationModal"
        bizCategory={bizCategory}
        onDeactivate={handleDeactivateBizCateogry}
        {...props}
      />
    ))
    : activateBizCategory(bizCategory.id)
  ), [
    handleDeactivateBizCateogry,
    activateBizCategory,
    showModal,
  ]);

  const content = (() => {
    if (isLoading) {
      return (
        <div className={styles.contentData}>
          <Spinner
            data-testid="spinner"
            cx={styles.spinner}
          />
        </div>
      );
    }

    if (isError) {
      return null;
    }

    if (!businessObjectives.length) {
      return (
        <div className={styles.contentData}>
          <NoObjectives
            data-testid="emptyState"
          />
        </div>
      );
    }

    return (
      <>
        <CategoriesMenu
          data-testid="categoriesMenu"
          isInactive={isInactive}
          handleIsInactiveChange={setIsInactive}
          label={formatMessage(
            { id: 'businessCategories.categoriesMenu.label' },
            {
              total: resultBusinessObjectives.length,
              isInactive,
            },
          )}
        />
        <div className={styles.contentData}>
          <CategoriesList
            data-testid="categoriesList"
            categories={resultBusinessObjectives}
            onToggleAvailability={handleToggleAvailability}
            onUpdate={showUpdateBizCategoryModal}
            updateButtonLabel={formatMessage({ id: 'businessCategories.editButtonLabel' })}
            deactivateButtonLabel={formatMessage({ id: 'businessCategories.deactivateButtonLabel' })}
            activateButtonLabel={formatMessage({ id: 'businessCategories.activateButtonLabel' })}
          />
        </div>
      </>
    );
  })();

  return (
    <div className={styles.wrapper}>
      {((!isLoading && isFetching) || isActivatingBizCategory) && (
        <Blocker data-testid="blocker" />
      )}
      <header className={styles.header}>
        <h3 className={styles.title}>
          {formatMessage({ id: 'businessCategories.title' })}
        </h3>
      </header>
      <div className={styles.content}>
        {content}
      </div>
      <footer className={styles.footer}>
        <Button
          data-testid="addCategoryBtn"
          caption={formatMessage({ id: 'businessCategories.addCategoryButton.label' })}
          onClick={showAddBizCategoryModal}
          size="42"
        />
      </footer>
    </div>
  );
};

export default BusinessCategories;
