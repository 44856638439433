import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import TextFieldBase from '../../TextField';

const TextField = ({
  name,
  deps,
  ...props
}) => {
  const {
    field: {
      onChange,
      onBlur,
      value,
    },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      deps,
    },
  });

  return (
    <TextFieldBase
      data-testid="textFieldBase"
      name={name}
      value={value}
      errorMessage={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'number']),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  deps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

TextField.defaultProps = {
  placeholder: '',
  type: 'text',
  deps: undefined,
};

export default TextField;
