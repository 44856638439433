import { useRef } from 'react';
import { isEqual, isNumber } from 'lodash';

import { useQueryParams } from '../../../hooks';
import { FILTER_TYPES } from '../constants';

export const useGetFilterValuesFromUrl = (filters, urlParamName) => {
  const { [urlParamName]: filtersFromUrl } = useQueryParams();
  const lastFiltersValue = useRef(null);

  const newFilters = (() => {
    const resultFilters = {};

    if (!filtersFromUrl || !filters) {
      return resultFilters;
    }

    Object.keys(filtersFromUrl).forEach((name) => {
      const currentFilter = filters.find((filter) => filter.name === name);

      if (!currentFilter) {
        return;
      }

      switch (currentFilter.type) {
        case (FILTER_TYPES.CHECKBOX):
          resultFilters[name] = currentFilter.items
            .filter(({ id: filterId }) => (
              filtersFromUrl[name]?.some((filterIdFromUrl) => (
                (isNumber(filterId) ? Number(filterIdFromUrl) : filterIdFromUrl) === filterId))))
            .map(({ id: filterId }) => filterId);
          break;
        default:
          break;
      }
    });

    return resultFilters;
  })();

  if (!isEqual(lastFiltersValue.current, newFilters)) {
    lastFiltersValue.current = newFilters;
  }

  return lastFiltersValue.current;
};
