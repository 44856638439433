import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { EVENT_STAGES } from '../../constants/event';
import Stage, { STAGE_TYPES } from './Stage';
import { activeEventActiveStageSelector } from '../../redux/slices/events';
import { getDiffInDays } from '../../utils/dateUtils';
import { ICON_TYPES } from '../Icon';

import styles from './EventJourney.module.scss';

const EventJourney = () => {
  const { formatMessage } = useIntl();
  const activeEventActiveStage = useSelector(activeEventActiveStageSelector);

  const STAGES = useMemo(() => ([
    {
      id: EVENT_STAGES.IDEA_SUBMISSION,
      title: formatMessage({ id: 'eventJourney.stages.ideaSubmission.title' }),
      description: formatMessage({ id: 'eventJourney.stages.ideaSubmission.description' }),
      iconType: ICON_TYPES.persons,
      type: STAGE_TYPES.primary,
    },
    {
      id: EVENT_STAGES.DOWN_SELECTION,
      title: formatMessage({ id: 'eventJourney.stages.downSelection.title' }),
      description: formatMessage({ id: 'eventJourney.stages.downSelection.description' }),
      iconType: ICON_TYPES.search,
      type: STAGE_TYPES.secondary,
    },
    {
      id: EVENT_STAGES.VOTING,
      title: formatMessage({ id: 'eventJourney.stages.voting.title' }),
      description: formatMessage({ id: 'eventJourney.stages.voting.description' }),
      iconType: ICON_TYPES.like,
      type: STAGE_TYPES.tertiary,
    },
    {
      id: EVENT_STAGES.ANNOUNCE_WINNER,
      title: formatMessage({ id: 'eventJourney.stages.announceWinner.title' }),
      description: formatMessage({ id: 'eventJourney.stages.announceWinner.description' }),
      iconType: ICON_TYPES.winner,
      type: STAGE_TYPES.quaternary,
    },
  ]), [formatMessage]);

  const activeStageIndex = useMemo(
    () => STAGES.findIndex(({ id }) => id === activeEventActiveStage?.type),
    [STAGES, activeEventActiveStage],
  );

  return (
    <div className={styles.eventJourney}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          {formatMessage({ id: 'eventJourney.title' })}
        </h2>
        <p className={styles.description}>
          {formatMessage({ id: 'eventJourney.description' })}
        </p>
        <ul className={styles.list}>
          {STAGES.map(({
            id,
            title,
            description,
            iconType,
            type,
          }, index) => (
            <li
              key={id}
              className={styles.item}
            >
              <Stage
                data-testid={id}
                stageId={id}
                {...(
                  index === activeStageIndex
                    ? {
                      isActive: true,
                      timeLeft: formatMessage({ id: 'eventJourney.timeLeft' }, {
                        daysAmount: getDiffInDays(activeEventActiveStage.end_at),
                      }),
                    }
                    : {}
                )}
                isCompleted={index < activeStageIndex}
                title={title}
                description={description}
                iconType={iconType}
                type={type}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventJourney;
