export const EVENT_STAGES = {
  IDEA_SUBMISSION: 'IDEA_SUBMISSION',
  DOWN_SELECTION: 'DOWN_SELECTION',
  VOTING: 'VOTING',
  ANNOUNCE_WINNER: 'ANNOUNCE_WINNER',
};

export const ALL_EVENT_STAGES_COLLECTION = Object.values(EVENT_STAGES);

export const EVENT_STATUSES = {
  FUTURE: 'FUTURE',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
};

export const EVENT_STAGES_ORDER = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 1,
  [EVENT_STAGES.DOWN_SELECTION]: 2,
  [EVENT_STAGES.VOTING]: 3,
  [EVENT_STAGES.ANNOUNCE_WINNER]: 4,
};

export const ALL_EVENT_STATUSES_COLLECTION = Object.values(EVENT_STATUSES);

export const ACTIVE_EVENT_HRID = 'event_2';

export const EVENT_STAGE_LABEL_TRANSLATION_KEYS = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'event.stages.ideaSubmissionStage.title',
  [EVENT_STAGES.DOWN_SELECTION]: 'event.stages.downSelectionStage.title',
  [EVENT_STAGES.VOTING]: 'event.stages.votingStage.title',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'event.stages.winnerAnnouncementStage.title',
};
