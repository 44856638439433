import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import RestoreIcon from './RestoreIcon';
import TrashIcon from './TrashIcon';
import Menu from '../../../Menu';
import { SUPER_ADMIN_ROLE, USER_ROLES } from '../../../../constants/roles';
import {
  useIsAbleToRestoreIdea,
  useIsAbleToDeleteIdea,
} from '../../../../hooks';
import { IDEA_STATUSES } from '../../../../constants/idea';

import styles from './ArchivedIdeaCardMenu.module.scss';

const ArchivedIdeaCardMenu = ({
  onRestore,
  onDelete,
  idea,
  className,
}) => {
  const { formatMessage } = useIntl();
  const isAbleToRestoreIdea = useIsAbleToRestoreIdea(idea);
  const isAbleToDeleteIdea = useIsAbleToDeleteIdea(idea);

  const groups = useMemo(
    () => ([{
      id: 'actions',
      items: [
        ...(
          isAbleToRestoreIdea
            ? [{
              id: 'restore',
              label: formatMessage({ id: 'ideas.archivedIdeaCard.archivedIdeaCardMenu.restoreButtonLabel' }),
              onClick: onRestore,
              icon: RestoreIcon,
            }]
            : []
        ),
        ...(
          isAbleToDeleteIdea
            ? [{
              id: 'delete',
              label: formatMessage({ id: 'ideas.archivedIdeaCard.archivedIdeaCardMenu.deleteButtonLabel' }),
              onClick: onDelete,
              icon: TrashIcon,
            }]
            : []
        ),
      ],
    }]),
    [
      formatMessage,
      onRestore,
      onDelete,
      isAbleToRestoreIdea,
      isAbleToDeleteIdea,
    ],
  );

  if (!groups[0].items.length) {
    return;
  }

  return (
    <Menu
      dropdownMenuProps={{
        groups,
        bodyClassName: styles.dropdownBody,
      }}
      className={className}
      data-testid="menu"
    />
  );
};

ArchivedIdeaCardMenu.propTypes = {
  onRestore: PropTypes.func,
  onDelete: PropTypes.func,
  idea: PropTypes.shape({
    archive: PropTypes.shape({
      author: PropTypes.shape({
        role: PropTypes.oneOf([
          ...Object.values(USER_ROLES),
          SUPER_ADMIN_ROLE,
        ]).isRequired,
        id: PropTypes.number.isRequired,
      }).isRequired,
    }),
    status: PropTypes.oneOf(Object.values(IDEA_STATUSES)).isRequired,
    biz_category: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  className: PropTypes.string,
};

ArchivedIdeaCardMenu.defaultProps = {
  onDelete: null,
  onRestore: null,
  className: null,
};

export default ArchivedIdeaCardMenu;
