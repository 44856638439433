import React from 'react';
import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { BACKEND_ERRORS_TRANSLATION_KEYS } from '../../../constants/backendErrors';
import { ROUTES } from '../../../constants/routes';
import TextLink from '../../TextLink';
import {
  signUpFork,
  getTokenFork,
  initializeAppFork,
} from '../../../listenerForks';
import { trackMatomoEvent } from '../../../utils';
import { isAxiosError } from '../../../redux/api/fetchClient';
import {
  MATOMO_EVENT_CATEGORIES,
  MATOMO_EVENT_ACTIONS,
} from '../../../constants/matomo';

export const signUpListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formatMessage,
      formData,
    },
  } = action;

  const { dispatch, fork } = listenerApi;

  try {
    const signUpForkResult = await fork(signUpFork({
      dispatch,
      formData,
      handleErrors: false,
    })).result;

    if (signUpForkResult.status !== 'ok') {
      const errorIds = [];

      if (isAxiosError(signUpForkResult.error)) {
        const { status, data } = signUpForkResult.error.response || {};

        switch (status) {
          case 400:
            Object.keys(data)
              .forEach((key) => {
                data[key].forEach(({ code }) => {
                  const translationId = BACKEND_ERRORS_TRANSLATION_KEYS.SIGNUP[status][key]?.[code];

                  translationId
                    ? errorIds.push(translationId)
                    : signUpForkResult.error.handleGlobally();
                });
              });
            break;
          default:
            signUpForkResult.error.handleGlobally();
        }
      }

      const errors = errorIds.map((id) => (
        formatMessage({ id }, {
          loginLink: (chunk) => (
            <TextLink
              inheritSize
              label={chunk[0]}
              path={ROUTES.login}
            />
          ),
        })
      ));

      reject({
        error: errors,
      });
      return;
    }

    trackMatomoEvent(
      MATOMO_EVENT_CATEGORIES.app,
      MATOMO_EVENT_ACTIONS.signUp,
    );

    const tokenForkResult = await fork(
      getTokenFork({
        dispatch,
        formData: { email: formData.email, password: formData.password },
      }),
    ).result;

    if (tokenForkResult.status !== 'ok') {
      reject();
      return;
    }

    const initializeAppForkResult = await fork(initializeAppFork({
      dispatch,
      fork,
      token: tokenForkResult.value,
      redirectPath: ROUTES.onboarding,
    })).result;

    if (initializeAppForkResult.status !== 'ok') {
      reject();
      return;
    }

    resolve();
  } catch (error) {
    reject();
  }
};

export const signUpListenerActionCreator = createAction(ACTION_TYPES.user.signUp);
