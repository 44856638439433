import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import IdeaActions from './IdeaActions';
import { formatDate } from '../../../utils';
import {
  SUPER_ADMIN_ROLE,
  USER_ROLES,
} from '../../../constants/roles';

import styles from './IdeaHeader.module.scss';

const IdeaHeader = ({
  className,
  idea,
  onComment,
  onArchive,
  onRestore,
  onDelete,
  onVote,
  onUnvote,
  isVoteLimitReached,
}) => {
  const { formatMessage } = useIntl();
  const {
    archive,
    title,
    human_readable_id: name,
    created_at: date,
  } = idea;

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.ideaInfo}>
        <p className={styles.primaryInfo}>
          {formatMessage(
            { id: 'common.ideaTitle' },
            {
              isArchived: !!archive,
              name,
            },
          )}
          <span className={styles.delimiter}>|</span>
          {formatDate(date)}
        </p>
        <p className={styles.secondaryInfo}>{title}</p>
      </div>
      <div className={styles.ideaActions}>
        <IdeaActions
          idea={idea}
          onComment={onComment}
          onArchive={onArchive}
          onRestore={onRestore}
          onDelete={onDelete}
          onVote={onVote}
          onUnvote={onUnvote}
          isVoteLimitReached={isVoteLimitReached}
        />
      </div>
    </div>
  );
};

IdeaHeader.propTypes = {
  idea: PropTypes.shape({
    archive: PropTypes.shape({
      author: PropTypes.shape({
        role: PropTypes.oneOf([
          ...Object.values(USER_ROLES),
          SUPER_ADMIN_ROLE,
        ]).isRequired,
        id: PropTypes.number.isRequired,
      }).isRequired,
    }),
    title: PropTypes.string.isRequired,
    human_readable_id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  onComment: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onVote: PropTypes.func.isRequired,
  onUnvote: PropTypes.func.isRequired,
  isVoteLimitReached: PropTypes.bool.isRequired,
};

IdeaHeader.defaultProps = {
  className: '',
};

export default IdeaHeader;
