import { ACTIONS_MENU_CELL_ACCESSOR } from '../../../Table/constants';

export const FILTERS_URL_PARAM_NAME = 'totalIdeasFilters';
export const PAGE_URL_PARAM_NAME = 'totalIdeasPage';
export const SORTING_URL_PARAM_NAME = 'totalIdeasSorting';
export const TABLE_ID = 'totalIdeasTable';

export const ACCESSOR_KEYS = {
  IDEA_TITLE: 'title',
  COLLABORATION: 'collaborations',
  BUSINESS_OBJECTIVE: 'biz_category',
  ACTIONS: ACTIONS_MENU_CELL_ACCESSOR,
};
