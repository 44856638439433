import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { getCurrentYear } from '../../../utils';
import ContactUs from '../../ContactUs';

import styles from './Footer.module.scss';

const Footer = ({ className }) => {
  const { formatMessage } = useIntl();

  return (
    <footer className={clsx([className, styles.footer])}>
      <div className={styles.container}>
        <div>
          <p>{`© ${getCurrentYear()}. EPAM Systems.`}</p>
          <p>{formatMessage({ id: 'footer.copyright.description' })}</p>
        </div>
        <ContactUs data-testid="contactUs" />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
