import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { ideaApi } from '../../../redux/api/slices/ideaApi';

export const toggleArchiveIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      showToast,
      formatMessage,
      ideaId,
      eventHrid,
      data,
      isArchive = true,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const toggleArchiveIdea = dispatch(
    isArchive
      ? ideaApi.endpoints.archiveIdea.initiate({
        ideaId,
        eventHrid,
        data,
      })
      : ideaApi.endpoints.restoreIdea.initiate({
        ideaId,
        eventHrid,
      }),
  );

  try {
    await pause(toggleArchiveIdea.unwrap());

    showToast({
      message: formatMessage(
        { id: 'archiveIdeaConfirmationModal.toogleArchiveToastMessage' },
        { isArchive },
      ),
    });

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    toggleArchiveIdea.unsubscribe();
  }
};

export const toggleArchiveIdeaActionCreator = createAction(
  ACTION_TYPES.idea.toggleArchiveIdea,
);
