import { EVENT_STAGES } from '../../../constants/event';

export const PAGE_INFO_TRANSLATION_KEY_BY_ACTIVE_STAGE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'ideasPage.ideaSubmission.pageTitle',
  [EVENT_STAGES.DOWN_SELECTION]: 'ideasPage.downSelection.pageTitle',
  [EVENT_STAGES.VOTING]: 'ideasPage.vote.pageTitle',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'ideasPage.winnerAnnouncement.pageTitle',
};

export const PAGE_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'ideasPage.ideaSubmission.title',
  [EVENT_STAGES.DOWN_SELECTION]: 'ideasPage.downSelection.title',
  [EVENT_STAGES.VOTING]: 'ideasPage.vote.title',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'ideasPage.winnerAnnouncement.title',
};

export const PAGE_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'ideasPage.ideaSubmission.description',
  [EVENT_STAGES.DOWN_SELECTION]: 'ideasPage.downSelection.description',
  [EVENT_STAGES.VOTING]: 'ideasPage.vote.description',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'ideasPage.winnerAnnouncement.description',
};
