import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import yup from '../../../../yup';
import { FORM_FIELD_NAMES } from '../../InactivateIdeaFormPattern';

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => (yup.object().shape({
    [FORM_FIELD_NAMES.REASON_TYPE]: yup.string()
      .required(formatMessage({ id: 'archiveIdeaForm.error.resonTypeRequired' })),
    [FORM_FIELD_NAMES.REASON_DESCRIPTION]: yup.string()
      .trim()
      .when(FORM_FIELD_NAMES.REASON_TYPE, {
        is: (value) => value,
        then: (schema) => schema
          .required(formatMessage({ id: 'archiveIdeaForm.error.resonDescriptionRequired' }))
          .min(3, formatMessage({ id: 'archiveIdeaForm.error.resonDescriptionMin' }))
          .max(250, formatMessage({ id: 'archiveIdeaForm.error.resonDescriptionMax' })),
      }),
  })), [formatMessage]);
};
