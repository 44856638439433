import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import EmptyContent from '../../../../EmptyContent';
import CreateNewIdeaButton from '../../../../CreateNewIdeaButton';
import { activeEventActiveStageTypeSelector } from '../../../../../redux/slices/events';
import {
  NO_IDEAS_ICON_TYPE_BY_ACTIVE_STAGE_TYPE,
  NO_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE,
} from './constants';

const NoIdeas = () => {
  const { formatMessage } = useIntl();
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);

  return (
    <EmptyContent
      iconProps={{
        type: NO_IDEAS_ICON_TYPE_BY_ACTIVE_STAGE_TYPE[activeStageType],
      }}
      title={formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.noIdeas.title' })}
      description={formatMessage(
        { id: NO_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE[activeStageType] },
      )}
      customButton={(
        <CreateNewIdeaButton
          caption={formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.noIdeas.button.label' })}
        />
      )}
    />
  );
};

export default NoIdeas;
