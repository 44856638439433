import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import EntityMainAttributes from '../../EntityMainAttributes';
import { formatDate, getUserFullName } from '../../../utils';
import { userIdSelector } from '../../../redux/slices/access';
import {
  IDEA_INACTIVATE_REASON_TYPES,
  INACTIVATE_REASON_TYPE_TRANSLATION_KEY,
} from '../../../constants/idea';
import Section from '../../Section';

import styles from './ArchivedIdeaInfo.module.scss';

const ArchivedIdeaInfo = ({ archive }) => {
  const {
    reason,
    reason_type: reasonType,
    author,
    created_at: date,
  } = archive;
  const { formatMessage } = useIntl();
  const currentUserId = useSelector(userIdSelector);

  const sections = useMemo(() => ([{
    id: 'reasonTypes',
    label: formatMessage({ id: 'idea.archivedIdeaInfo.reasonType.title' }),
    value: formatMessage({ id: INACTIVATE_REASON_TYPE_TRANSLATION_KEY[reasonType] }),
  }, {
    id: 'reason',
    label: formatMessage({ id: 'idea.archivedIdeaInfo.reason.title' }),
    value: reason,
  }]), [
    formatMessage,
    reasonType,
    reason,
  ]);

  return (
    <div className={styles.wrapper}>
      {sections.map(({ id, label, value }) => (
        <Section
          key={id}
          heading={label}
          description={value}
          data-testid={id}
        />
      ))}
      <EntityMainAttributes
        author={formatMessage(
          { id: 'idea.archivedIdeaInfo.archivedByCurrentUse.label' },
          {
            isCurrentUser: author.id === currentUserId,
            name: getUserFullName(author),
          },
        )}
        date={formatDate(date)}
        data-testid="entityMainAttributes"
      />
    </div>
  );
};

ArchivedIdeaInfo.propTypes = {
  archive: PropTypes.shape({
    reason: PropTypes.string,
    reason_type: PropTypes.oneOf(Object.values(IDEA_INACTIVATE_REASON_TYPES)).isRequired,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArchivedIdeaInfo;
