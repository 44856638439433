import React from 'react';
import { useIntl } from 'react-intl';
import { ROUTES } from '../../../../constants/routes';

import Button from '../../../Button';

import styles from './SignUpSuggestion.module.scss';

const SignUpSuggestion = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <p className={styles.signUpLabel}>
        {formatMessage({ id: 'signUpSuggestion.signUpLabel' })}
      </p>
      <Button
        data-testid="signUpButton"
        cx={styles.signUpButton}
        caption={formatMessage({ id: 'common.signUp.label' })}
        fill="white"
        size="36"
        link={{ pathname: ROUTES.signUp }}
      />
    </div>
  );
};

export default SignUpSuggestion;
