import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { useGetActiveBusinessObjectivesQuery } from '../../../../redux/api/slices/businessObjectivesApi';
import { getFullUrl } from '../../../../utils';
import { ROUTES } from '../../../../constants/routes';
import Button from '../../../Button';
import IdeaDetails from '../../../Table/expandedRowRenders/IdeaDetails';
import NoFilterResults from '../../../Filters/NoFilterResults';
import NoIdeas from './NoIdeas';
import {
  ACCESSOR_KEYS,
  TABLE_ID,
  SORTING_URL_PARAM_NAME,
  PAGE_URL_PARAM_NAME,
  FILTERS_URL_PARAM_NAME,
} from './constants';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { useGetIdeas } from './hooks/useGetIdeas';
import ExportToExcelButton from '../ExportToExcelButton';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';
import Spinner from '../../../Spinner';
import Table from '../../../Table';
import { EntityCell, TextCell } from '../../../Table/cellsRenders';
import ArchiveIdeaConfirmationModal from '../../../modals/ArchiveIdeaConfirmationModal';
import { useModal, useListener } from '../../../../hooks';
import { IDEA_STATUSES } from '../../../../constants/idea';
import {
  selectIdeaAsWinnerListener,
  selectIdeaAsWinnerActionCreator,
} from './listeners/selectIdeaAsWinnerListener';
import {
  deselectIdeaAsWinnerListener,
  deselectIdeaAsWinnerActionCreator,
} from './listeners/deselectIdeaAsWinnerListener';
import { useGetAnnounceWinnerMetadataQuery } from '../../../../redux/api/slices/metadataApi';
import {
  useGetFilters,
  useGetSorting,
} from '../hooks';

import styles from './IdeasWinnerSelectionTab.module.scss';

const IdeasWinnerSelectionTab = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();

  const [isTogglingForWinner, setIsTogglingForWinner] = useState(false);

  const {
    data: bizObjectivesData,
    isLoading: areBizObjectivesLoading,
    error: bizObjectivesError,
  } = useGetActiveBusinessObjectivesQuery();

  const {
    data: announceWinnerMetaData,
    isLoading: isAnnounceWinnerMetaDataLoading,
    isFetching: isAnnounceWinnerMetaDataFetching,
    error: announceWinnerMetaDataError,
  } = useGetAnnounceWinnerMetadataQuery({
    eventHrid: ACTIVE_EVENT_HRID,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const {
    max_number_of_winners: winnersMaxNumber,
    used_number_of_winners: winnersUsedNumber,
  } = announceWinnerMetaData?.announce_winner || {};

  const isLimitReached = winnersMaxNumber === winnersUsedNumber;

  const [selectAsWinner] = useListener({
    listener: selectIdeaAsWinnerListener,
    actionCreator: selectIdeaAsWinnerActionCreator,
  });

  const [deselectAsWinner] = useListener({
    listener: deselectIdeaAsWinnerListener,
    actionCreator: deselectIdeaAsWinnerActionCreator,
  });

  const renderTitleCell = useCallback(({ row }) => (
    <EntityCell
      humanReadableId={
        formatMessage(
          { id: 'common.ideaTitle' },
          {
            isArchived: false,
            name: row.original.human_readable_id,
          },
        )
      }
      title={row.original.title}
      url={getFullUrl(generatePath(ROUTES.idea, { ideaId: row.original.human_readable_id }))}
    />
  ), [formatMessage]);

  const renderVotesCell = useCallback(({ row }) => (
    <TextCell value={row.original.total_votes} />
  ), []);

  const showArchiveIdeaConfirmatonModal = useCallback((ideaId) => showModal((props) => (
    <ArchiveIdeaConfirmationModal
      {...props}
      ideaId={ideaId}
    />
  )), [showModal]);

  const handleToggleForWinner = useCallback(
    async (ideaId, isWinner) => {
      setIsTogglingForWinner(true);

      try {
        if (isWinner) {
          await deselectAsWinner({
            ideaId,
            eventHrid: ACTIVE_EVENT_HRID,
          });
        } else {
          await selectAsWinner({
            ideaId,
            eventHrid: ACTIVE_EVENT_HRID,
            winnersMaxNumber,
          });
        }
      } finally {
        setIsTogglingForWinner(false);
      }
    },
    [
      selectAsWinner,
      winnersMaxNumber,
      deselectAsWinner,
    ],
  );

  const renderActionsCell = useCallback(({ row }) => {
    const isWinner = row.original.status === IDEA_STATUSES.WINNER;

    return (
      <div className={styles.actionsWrapper}>
        <Button
          cx={styles.toggleWinnerButton}
          caption={formatMessage(
            { id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.columns.actions.toggleAsWinner' },
            { isWinner },
          )}
          size="42"
          onClick={() => handleToggleForWinner(
            row.original.human_readable_id,
            isWinner,
          )}
          fill={isWinner ? 'solid' : 'white'}
          isDisabled={!isWinner && isLimitReached}
        />
        <Button
          caption={formatMessage(
            { id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.columns.actions.archive' },
          )}
          size="42"
          fill="white"
          onClick={() => showArchiveIdeaConfirmatonModal(row.original.human_readable_id)}
        />
      </div>
    );
  }, [
    formatMessage,
    showArchiveIdeaConfirmatonModal,
    handleToggleForWinner,
    isLimitReached,
  ]);

  const renderDetailPanel = useCallback(({ row }) => (
    <IdeaDetails
      idea={row.original}
    />
  ), []);

  const renderEmptyState = useCallback(({
    hasActiveFilters,
    clearFilters,
  }) => {
    if (hasActiveFilters) {
      return <NoFilterResults onClick={clearFilters} />;
    }

    return <NoIdeas />;
  }, []);

  const columns = useMemo(() => ([
    {
      accessorKey: ACCESSOR_KEYS.IDEA_TITLE,
      header: formatMessage(
        { id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.columns.title' },
      ),
      cell: renderTitleCell,
    },
    {
      accessorKey: ACCESSOR_KEYS.VOTES,
      header: formatMessage(
        { id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.columns.votes' },
      ),
      cell: renderVotesCell,
      size: 20,
    },
    {
      accessorKey: ACCESSOR_KEYS.ACTIONS,
      header: formatMessage(
        { id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.columns.actions' },
      ),
      cell: renderActionsCell,
      size: 48,
    },
  ]), [
    renderTitleCell,
    renderVotesCell,
    renderActionsCell,
    formatMessage,
  ]);

  const filters = useGetFilters({ bizObjectives: bizObjectivesData });
  const sortingItems = useGetSorting();

  useEffect(() => {
    isAxiosError(bizObjectivesError) && bizObjectivesError.handleGlobally();
  }, [bizObjectivesError]);

  useEffect(() => {
    isAxiosError(announceWinnerMetaDataError) && announceWinnerMetaDataError.handleGlobally();
  }, [announceWinnerMetaDataError]);

  if (areBizObjectivesLoading || isAnnounceWinnerMetaDataLoading) {
    return <Spinner cx={styles.spinner} />;
  }

  return (
    <Table
      emptyState={renderEmptyState}
      isInProgress={isTogglingForWinner || isAnnounceWinnerMetaDataFetching}
      tableId={TABLE_ID}
      columns={columns}
      useGetData={useGetIdeas}
      renderDetailPanel={renderDetailPanel}
      pageUrlParamName={PAGE_URL_PARAM_NAME}
      actions={<ExportToExcelButton />}
      description={formatMessage({ id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.description' })}
      filtersProps={{
        urlParamName: FILTERS_URL_PARAM_NAME,
        title: formatMessage({ id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.filters.title' }),
        filters,
        reportLabelId: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.filters.reportLabel',
      }}
      sortingProps={{
        urlParamName: SORTING_URL_PARAM_NAME,
        items: sortingItems,
      }}
      getDataAdditionalParams={{ eventHrid: ACTIVE_EVENT_HRID }}
      title={formatMessage(
        { id: 'adminPanel.ideasBoard.ideasWinnerSelectionTable.tableTitle' },
        { winnersUsedNumber, winnersMaxNumber },
      )}
    />
  );
};

export default IdeasWinnerSelectionTab;
