import { EVENT_STAGES } from '../../../../constants/event';

export const NO_MY_IDEAS_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'ideas.noMyIdeas.ideaSubmitionStage.title',
  [EVENT_STAGES.DOWN_SELECTION]: 'ideas.noMyIdeas.title',
  [EVENT_STAGES.VOTING]: 'ideas.noMyIdeas.title',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'ideas.noMyIdeas.title',
};

export const NO_MY_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'ideas.noMyIdeas.ideaSubmitionStage.description',
  [EVENT_STAGES.DOWN_SELECTION]: 'ideas.noMyIdeas.description',
  [EVENT_STAGES.VOTING]: 'ideas.noMyIdeas.description',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'ideas.noMyIdeas.description',
};
