import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import DynamicFields from '../DynamicFields';
import TextField from '../TextField';
import { FIELD_NAMES } from './constants';

const LinkField = ({
  name,
  maxItemsCount,
}) => {
  const { formatMessage } = useIntl();

  const newItem = useMemo(() => ({
    [FIELD_NAMES.DESCRIPTION]: '',
    [FIELD_NAMES.URL]: '',
  }), []);

  return (
    <DynamicFields
      data-testid="dynamicFields"
      name={name}
      label={formatMessage({ id: 'linkField.label' })}
      deleteButtonCaption={formatMessage({ id: 'linkField.deleteButton.label' })}
      addButtonCaption={formatMessage({ id: 'linkField.addButton.label' })}
      maxItemsCount={maxItemsCount}
      hint={formatMessage({ id: 'linkField.hint' })}
      renderItem={(namePath) => (
        <>
          <TextField
            data-testid="descriptionField"
            name={`${namePath}.${FIELD_NAMES.DESCRIPTION}`}
            label={formatMessage({ id: 'linkField.descriptionField.label' })}
            placeholder={formatMessage({ id: 'linkField.descriptionField.placeholder' })}
            isRequired
          />
          <TextField
            data-testid="urlField"
            name={`${namePath}.${FIELD_NAMES.URL}`}
            label={formatMessage({ id: 'linkField.urlField.label' })}
            placeholder={formatMessage({ id: 'linkField.urlField.placeholder' })}
            isRequired
          />
        </>
      )}
      newItem={newItem}
    />
  );
};

LinkField.propTypes = {
  name: PropTypes.string.isRequired,
  maxItemsCount: PropTypes.number,
};

LinkField.defaultProps = {
  maxItemsCount: null,
};

export default LinkField;
