import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { ROUTES } from '../../../../constants/routes';
import { initializeAppFork } from '../../../../listenerForks';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { userApi } from '../../../../redux/api/slices/userApi';

export const ssoAuthListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      data,
    },
  } = action;
  const {
    dispatch,
    pause,
    fork,
  } = listenerApi;

  const ssoAuth = dispatch(userApi.endpoints.ssoAuth.initiate(data));

  try {
    const {
      access_token: accessToken,
      user_created: isUserCreated,
    } = await pause(ssoAuth.unwrap());

    const initializeAppForkResult = await fork(initializeAppFork({
      dispatch,
      fork,
      token: accessToken,
      redirectPath: isUserCreated ? ROUTES.onboarding : ROUTES.base,
    })).result;

    if (initializeAppForkResult.status !== 'ok') {
      reject();
      return;
    }

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    ssoAuth.unsubscribe();
  }
};

export const ssoAuthActionCreator = createAction(ACTION_TYPES.user.ssoAuth);
