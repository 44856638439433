import { isPlainObject, isUndefined } from 'lodash';
import qs from 'qs';

import { fetchClient } from './fetchClient';

const stripUndefined = (obj) => {
  if (!isPlainObject(obj)) {
    return obj;
  }

  const copy = { ...obj };

  Object.keys(copy).forEach((key) => {
    if (isUndefined(copy[key])) {
      delete copy[key];
    }
  });

  return copy;
};

export const baseQuery = ({
  baseUrl = '',
  prepareHeaders = (x) => x,
}) => (
  async (arg, api) => {
    const {
      url,
      method,
      data,
      params,
      headers = {},
      responseType,
    } = arg;
    const {
      getState,
      extra,
      endpoint,
      forced,
      type,
      signal,
    } = api;
    const resultHeaders = await prepareHeaders(
      stripUndefined(headers),
      {
        getState,
        extra,
        endpoint,
        forced,
        type,
      },
    );

    try {
      const result = await fetchClient({
        url: baseUrl + url,
        method,
        data,
        paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: 'repeat' }),
        params,
        headers: resultHeaders,
        signal,
        responseType,
      });

      return { data: result.data };
    } catch (error) {
      return { error };
    }
  }
);
