import React from 'react';
import { IntlProvider as IntlProviderBase } from 'react-intl';
import PropTypes from 'prop-types';

import enUSMessages from '../../translations/en-US.json';
import { LOCALES, DEFAULT_LOCALE } from '../../constants/i18n';

const MESSAGES_BY_LOCALE = {
  [LOCALES.enUS]: enUSMessages,
};

const defaultRichTextElements = {
  b: (chunks) => <b>{chunks}</b>,
  br: () => <br />,
  i: (chunks) => <i>{chunks}</i>,
  em: (chunks) => <em>{chunks}</em>,
  strong: (chunks) => <strong>{chunks}</strong>,
  span: (chunks) => <span>{chunks}</span>,
  div: (chunks) => <div>{chunks}</div>,
  p: (chunks) => <p>{chunks}</p>,
  ul: (chunks) => <ul>{chunks}</ul>,
  ol: (chunks) => <ol>{chunks}</ol>,
  li: (chunks) => <li>{chunks}</li>,
  code: (chunks) => <code>{chunks}</code>,
};

const IntlProvider = ({ children }) => (
  <IntlProviderBase
    messages={MESSAGES_BY_LOCALE[DEFAULT_LOCALE]}
    locale={DEFAULT_LOCALE}
    defaultLocale={DEFAULT_LOCALE}
    defaultRichTextElements={defaultRichTextElements}
  >
    {children}
  </IntlProviderBase>
);

IntlProvider.propTypes = {
  children: PropTypes.element,
};

IntlProvider.defaultProps = {
  children: null,
};

export default IntlProvider;
