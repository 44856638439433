export const IDEA_TYPES = {
  UNSOLVED_CHALLENGE: 'UNSOLVED_CHALLENGE',
  UNTAPPED_OPPORTUNITY: 'UNTAPPED_OPPORTUNITY',
};

export const IDEA_TYPE_LABEL_TRANSLATION_KEYS = {
  [IDEA_TYPES.UNSOLVED_CHALLENGE]: 'ideaType.unsolvedChallenge.label',
  [IDEA_TYPES.UNTAPPED_OPPORTUNITY]: 'ideaType.untappedOpportunity.label',
};

export const IDEA_STATUSES = {
  DRAFT: 'DRAFT',
  COLLABORATORS_REVIEW: 'COLLABORATORS_REVIEW',
  FOR_VOTING: 'FOR_VOTING',
  WINNER: 'WINNER',
  NOT_WINNER: 'NOT_WINNER',
};

export const IDEA_STATUS_LABEL_TRANSLATION_KEYS = {
  [IDEA_STATUSES.DRAFT]: 'idea.statuses.draft.title',
  [IDEA_STATUSES.COLLABORATORS_REVIEW]: 'idea.statuses.collaborationReview.title',
  [IDEA_STATUSES.WINNER]: 'idea.statuses.winner.title',
  [IDEA_STATUSES.NOT_WINNER]: 'idea.statuses.notWinner.title',
  [IDEA_STATUSES.FOR_VOTING]: 'idea.statuses.forVoting.title',
};

export const IDEA_INACTIVATE_REASON_TYPES = {
  SOLUTION_EXIST: 'SOLUTION_EXIST',
  SOLUTION_IN_PROGRESS: 'SOLUTION_IN_PROGRESS',
  NOT_AN_IMMEDIATE_PRIORITY: 'NOT_AN_IMMEDIATE_PRIORITY',
  OTHER: 'OTHER',
};

export const INACTIVATE_REASON_TYPE_TRANSLATION_KEY = {
  [IDEA_INACTIVATE_REASON_TYPES.SOLUTION_EXIST]: 'inactivateIdeaFormPattern.reasonTypes.solutionExist',
  [IDEA_INACTIVATE_REASON_TYPES.SOLUTION_IN_PROGRESS]: 'inactivateIdeaFormPattern.reasonTypes.solutionInProgress',
  [IDEA_INACTIVATE_REASON_TYPES.NOT_AN_IMMEDIATE_PRIORITY]: 'inactivateIdeaFormPattern.reasonTypes.notAnImmediatePriority',
  [IDEA_INACTIVATE_REASON_TYPES.OTHER]: 'inactivateIdeaFormPattern.reasonTypes.other',
};
