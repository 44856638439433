import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ReactComponent as DraftIcon } from '../../assets/images/icons/draft.svg';
import { ReactComponent as InnovateIcon } from '../../assets/images/icons/innovate.svg';
import { ReactComponent as EngageIcon } from '../../assets/images/icons/engage.svg';
import { ReactComponent as CreateIcon } from '../../assets/images/icons/create.svg';
import { ReactComponent as GrowIcon } from '../../assets/images/icons/grow.svg';
import { ReactComponent as HiddenSecretIcon } from '../../assets/images/icons/hidden-secret.svg';
import { ReactComponent as VisibleSecretIcon } from '../../assets/images/icons/visible-secret.svg';
import { ReactComponent as BellIcon } from '../../assets/images/icons/bell.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/icons/logout.svg';
import { ReactComponent as HeartIcon } from '../../assets/images/icons/heart.svg';
import { ReactComponent as HeartFilledIcon } from '../../assets/images/icons/heart-filled.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/icons/info.svg';
import { ReactComponent as BorderedInfoIcon } from '../../assets/images/icons/bordered-info.svg';
import { ReactComponent as AddIcon } from '../../assets/images/icons/add.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg';
import { ReactComponent as CheckMarkIcon } from '../../assets/images/icons/check-mark.svg';
import { ReactComponent as LikeIcon } from '../../assets/images/icons/like.svg';
import { ReactComponent as CommentIcon } from '../../assets/images/icons/comment.svg';
import { ReactComponent as Link } from '../../assets/images/icons/link.svg';
import { ReactComponent as Copy } from '../../assets/images/icons/copy.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/images/icons/arrow-up.svg';
import { ReactComponent as Avatar } from '../../assets/images/icons/avatar.svg';
import { ReactComponent as Calendar } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as Folder } from '../../assets/images/icons/folder.svg';
import { ReactComponent as ExternalLink } from '../../assets/images/icons/external-link.svg';
import { ReactComponent as EmptyFile } from '../../assets/images/icons/empty-file.svg';
import { ReactComponent as TrashCan } from '../../assets/images/icons/trash-can.svg';
import { ReactComponent as Pencil } from '../../assets/images/icons/pencil.svg';
import { ReactComponent as Reply } from '../../assets/images/icons/reply.svg';
import { ReactComponent as Communication } from '../../assets/images/icons/communication.svg';
import { ReactComponent as PaperClip } from '../../assets/images/icons/paper-clip.svg';
import { ReactComponent as Hint } from '../../assets/images/icons/hint.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icons/warning.svg';
import { ReactComponent as EditorListIcon } from '../../assets/images/icons/editor-list.svg';
import { ReactComponent as PersonIcon } from '../../assets/images/icons/person.svg';
import { ReactComponent as DocumentIcon } from '../../assets/images/icons/document.svg';
import { ReactComponent as Sort } from '../../assets/images/icons/sort.svg';
import { ReactComponent as Lamp } from '../../assets/images/icons/lamp.svg';
import { ReactComponent as Star } from '../../assets/images/icons/star.svg';
import { ReactComponent as MenuDots } from '../../assets/images/icons/menu-dots.svg';
import { ReactComponent as CircledPlus } from '../../assets/images/icons/circled-plus.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/images/icons/archive.svg';
import { ReactComponent as RestoreIcon } from '../../assets/images/icons/restore.svg';
import { ReactComponent as EverestLogo } from '../../assets/images/icons/everest-logo.svg';
import { ReactComponent as Dashboard } from '../../assets/images/icons/dashboard.svg';
import { ReactComponent as People } from '../../assets/images/icons/people.svg';
import { ReactComponent as Configuration } from '../../assets/images/icons/configuration.svg';
import { ReactComponent as Search } from '../../assets/images/icons/search.svg';
import { ReactComponent as File } from '../../assets/images/icons/file.svg';
import { ReactComponent as FileWithStats } from '../../assets/images/icons/file-with-stats.svg';
import { ReactComponent as BoardWithPockets } from '../../assets/images/icons/board-with-pockets.svg';
import { ReactComponent as StarHalfPainted } from '../../assets/images/icons/star-half-painted.svg';
import { ReactComponent as DeactivateIcon } from '../../assets/images/icons/deactivate.svg';
import { ReactComponent as EyeCrossedOut } from '../../assets/images/icons/eye-crossed-out.svg';
import { ReactComponent as Cross } from '../../assets/images/icons/cross.svg';
import { ReactComponent as SemicirclesCrossed } from '../../assets/images/icons/semicircles-crossed.svg';
import { ReactComponent as TextSnippet } from '../../assets/images/icons/text-snippet.svg';
import { ReactComponent as ExcelFile } from '../../assets/images/icons/excel-file.svg';
import { ReactComponent as Clocks } from '../../assets/images/icons/clocks.svg';
import { ReactComponent as Medal } from '../../assets/images/icons/medal.svg';
import { ReactComponent as Cup } from '../../assets/images/icons/cup.svg';
import { ReactComponent as Persons } from '../../assets/images/icons/persons.svg';
import { ReactComponent as Winner } from '../../assets/images/icons/winner.svg';

import styles from './Icon.module.scss';

export const ICON_TYPES = {
  draft: 'draft',
  innovate: 'innovate',
  engage: 'engage',
  create: 'create',
  grow: 'grow',
  hiddenSecret: 'hiddenSecret',
  visibleSecret: 'visibleSecret',
  bell: 'bell',
  logout: 'logout',
  heart: 'heart',
  heartFilled: 'heartFilled',
  info: 'info',
  borderedInfo: 'borderedInfo',
  add: 'add',
  delete: 'delete',
  checkMark: 'checkMark',
  like: 'like',
  comment: 'comment',
  link: 'link',
  copy: 'copy',
  arrowRight: 'arrowRight',
  arrowDown: 'arrowDown',
  arrowUp: 'arrowUp',
  avatar: 'avatar',
  calendar: 'calendar',
  folder: 'folder',
  externalLink: 'externalLink',
  emptyFile: 'emptyFile',
  trashCan: 'trashCan',
  pencil: 'pencil',
  reply: 'reply',
  communication: 'communication',
  paperClip: 'paperClip',
  hint: 'hint',
  warning: 'warning',
  editorList: 'editorList',
  person: 'person',
  document: 'document',
  sort: 'sort',
  lamp: 'lamp',
  star: 'star',
  menuDots: 'menuDots',
  circledPlus: 'circledPlus',
  archive: 'archive',
  restore: 'restore',
  everestLogo: 'everestLogo',
  dashboard: 'dashboard',
  people: 'people',
  configuration: 'configuration',
  search: 'search',
  file: 'file',
  fileWithStats: 'fileWithStats',
  boardWithPockets: 'boardWithPockets',
  starHalfPainted: 'starHalfPainted',
  deactivate: 'deactivate',
  eyeCrossedOut: 'eyeCrossedOut',
  cross: 'cross',
  semicirclesCrossed: 'semicirclesCrossed',
  textSnippet: 'textSnippet',
  excelFile: 'excelFile',
  clocks: 'clocks',
  medal: 'medal',
  cup: 'cup',
  persons: 'persons',
  winner: 'winner',
};

export const ICON_SIZE = {
  xxxxs: 'xxxxs',
  xxxs: 'xxxs',
  mxxxs: 'mxxxs',
  xxs: 'xxs',
  mxxs: 'mxxs',
  xs: 'xs',
  msm: 'msm',
  ssm: 'ssm',
  sm: 'sm',
  ssmd: 'ssmd',
  smmd: 'smmd',
  smd: 'smd',
  md: 'md',
};

export const ICON_COLOR = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  quaternary: 'quaternary',
  quinary: 'quinary',
  senary: 'senary',
  septenary: 'septenary',
  inherit: 'inherit',
};

export const ICONS_BY_TYPE = {
  [ICON_TYPES.innovate]: InnovateIcon,
  [ICON_TYPES.draft]: DraftIcon,
  [ICON_TYPES.engage]: EngageIcon,
  [ICON_TYPES.create]: CreateIcon,
  [ICON_TYPES.grow]: GrowIcon,
  [ICON_TYPES.hiddenSecret]: HiddenSecretIcon,
  [ICON_TYPES.visibleSecret]: VisibleSecretIcon,
  [ICON_TYPES.bell]: BellIcon,
  [ICON_TYPES.logout]: LogoutIcon,
  [ICON_TYPES.heart]: HeartIcon,
  [ICON_TYPES.heartFilled]: HeartFilledIcon,
  [ICON_TYPES.info]: InfoIcon,
  [ICON_TYPES.borderedInfo]: BorderedInfoIcon,
  [ICON_TYPES.add]: AddIcon,
  [ICON_TYPES.delete]: DeleteIcon,
  [ICON_TYPES.checkMark]: CheckMarkIcon,
  [ICON_TYPES.like]: LikeIcon,
  [ICON_TYPES.comment]: CommentIcon,
  [ICON_TYPES.link]: Link,
  [ICON_TYPES.copy]: Copy,
  [ICON_TYPES.arrowRight]: ArrowRight,
  [ICON_TYPES.arrowDown]: ArrowDown,
  [ICON_TYPES.arrowUp]: ArrowUp,
  [ICON_TYPES.avatar]: Avatar,
  [ICON_TYPES.calendar]: Calendar,
  [ICON_TYPES.folder]: Folder,
  [ICON_TYPES.externalLink]: ExternalLink,
  [ICON_TYPES.emptyFile]: EmptyFile,
  [ICON_TYPES.trashCan]: TrashCan,
  [ICON_TYPES.pencil]: Pencil,
  [ICON_TYPES.reply]: Reply,
  [ICON_TYPES.communication]: Communication,
  [ICON_TYPES.paperClip]: PaperClip,
  [ICON_TYPES.hint]: Hint,
  [ICON_TYPES.warning]: WarningIcon,
  [ICON_TYPES.editorList]: EditorListIcon,
  [ICON_TYPES.person]: PersonIcon,
  [ICON_TYPES.document]: DocumentIcon,
  [ICON_TYPES.sort]: Sort,
  [ICON_TYPES.lamp]: Lamp,
  [ICON_TYPES.star]: Star,
  [ICON_TYPES.menuDots]: MenuDots,
  [ICON_TYPES.circledPlus]: CircledPlus,
  [ICON_TYPES.archive]: ArchiveIcon,
  [ICON_TYPES.restore]: RestoreIcon,
  [ICON_TYPES.everestLogo]: EverestLogo,
  [ICON_TYPES.dashboard]: Dashboard,
  [ICON_TYPES.people]: People,
  [ICON_TYPES.configuration]: Configuration,
  [ICON_TYPES.search]: Search,
  [ICON_TYPES.file]: File,
  [ICON_TYPES.fileWithStats]: FileWithStats,
  [ICON_TYPES.boardWithPockets]: BoardWithPockets,
  [ICON_TYPES.starHalfPainted]: StarHalfPainted,
  [ICON_TYPES.deactivate]: DeactivateIcon,
  [ICON_TYPES.eyeCrossedOut]: EyeCrossedOut,
  [ICON_TYPES.cross]: Cross,
  [ICON_TYPES.semicirclesCrossed]: SemicirclesCrossed,
  [ICON_TYPES.textSnippet]: TextSnippet,
  [ICON_TYPES.excelFile]: ExcelFile,
  [ICON_TYPES.clocks]: Clocks,
  [ICON_TYPES.medal]: Medal,
  [ICON_TYPES.cup]: Cup,
  [ICON_TYPES.persons]: Persons,
  [ICON_TYPES.winner]: Winner,
};

const Icon = forwardRef(({
  type,
  size,
  color,
  className,
}, ref) => {
  const Component = ICONS_BY_TYPE[type];

  return (
    <Component
      ref={ref}
      data-testid={type}
      className={clsx(
        styles.icon,
        styles[`icon__size_${size}`],
        styles[`icon__color_${color}`],
        className,
      )}
    />
  );
});

Icon.propTypes = {
  type: PropTypes.oneOf(Object.values(ICON_TYPES)).isRequired,
  size: PropTypes.oneOf(Object.values(ICON_SIZE)),
  color: PropTypes.oneOf(Object.values(ICON_COLOR)),
  className: PropTypes.string,
};

Icon.defaultProps = {
  size: ICON_SIZE.md,
  color: ICON_COLOR.primary,
  className: '',
};

export default Icon;
