import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Switch, { SWITCH_LABEL_POSITIONS } from '../Form/Switch';

import styles from './CategoriesMenu.module.scss';

const CategoriesMenu = ({
  isInactive,
  handleIsInactiveChange,
  label,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <div className={styles.totalCategories}>
        {label}
      </div>
      <Switch
        label={formatMessage({ id: 'categoriesMenu.inactiveSwitch.label' })}
        value={isInactive}
        onValueChange={handleIsInactiveChange}
        labelPosition={SWITCH_LABEL_POSITIONS.start}
        data-testid="categoriesSwitch"
      />
    </div>
  );
};

CategoriesMenu.propTypes = {
  isInactive: PropTypes.bool.isRequired,
  handleIsInactiveChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CategoriesMenu;
