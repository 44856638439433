import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
  trackMatomoPageView,
  setMatomoCustomUrl,
  setMatomoDocumentTitle,
  getFullUrl,
} from '../../utils';

const PageInfo = ({ title }) => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setMatomoDocumentTitle(document.title);
      setMatomoCustomUrl(getFullUrl(pathname));
      trackMatomoPageView();
    }, 50); // 50 ms to wait for title is applied by Helmet component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Helmet data-testid="helmet">
      <title data-testid="title">
        {
          formatMessage(
            { id: 'common.pageTitle' },
            { title },
          )
        }
      </title>
    </Helmet>
  );
};

PageInfo.propTypes = {
  title: PropTypes.string,
};

PageInfo.defaultProps = {
  title: null,
};

export default PageInfo;
