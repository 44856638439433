import React from 'react';
import { useIntl } from 'react-intl';

import EmptyContent from '../../EmptyContent';

import styles from './NoObjectives.module.scss';

const NoObjectives = () => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      data-testid="emptyContent"
      className={styles.wrapper}
      title={formatMessage({ id: 'businessCategories.noObjectives.title' })}
      description={formatMessage({ id: 'businessCategories.noObjectives.description' })}
    />
  );
};

export default NoObjectives;
