import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Chart from '../Chart';
import Spinner from '../Spinner';
import Blocker from '../Blocker';
import { CHART_TYPES } from '../Chart/constants';
import { isAxiosError } from '../../redux/api/fetchClient';

import styles from './ChartPattern.module.scss';

const ChartPattern = ({
  title,
  actions,
  chartType,
  useGetData,
  requestProps,
  className,
}) => {
  const {
    data,
    isLoading,
    isFetching,
    error,
    isError,
  } = useGetData(requestProps, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  const content = (() => {
    if (isLoading) {
      return (
        <Spinner
          data-testid="spinner"
          cx={styles.spinner}
        />
      );
    }

    return isError
      ? null
      : (
        <Chart
          data-testid="chart"
          type={chartType}
          data={data}
        />
      );
  })();

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.header}>
        <h3 className={styles.title}>
          {title}
        </h3>
        {actions}
      </div>
      <div
        data-testid="content"
        className={styles.content}
      >
        {content}
        {isFetching && !isLoading && (
          <Blocker
            data-testid="blocker"
          />
        )}
      </div>
    </div>
  );
};

ChartPattern.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.node,
  chartType: PropTypes.oneOf(Object.values(CHART_TYPES)).isRequired,
  useGetData: PropTypes.func.isRequired,
  requestProps: PropTypes.object,
  className: PropTypes.string,
};

ChartPattern.defaultProps = {
  actions: null,
  requestProps: undefined,
  className: '',
};

export default ChartPattern;
