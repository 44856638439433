import React from 'react';

import { ICON_TYPES } from '../../../../Icon';
import BaseIcon from '../BaseIcon';

export const HiddenSecretIcon = () => (
  <BaseIcon type={ICON_TYPES.hiddenSecret} />
);

export default HiddenSecretIcon;
