import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import LikeableCheckboxGroupFieldBase from '../../LikeableCheckboxGroupField';

const LikeableCheckboxGroupField = ({ name, ...props }) => {
  const {
    field: {
      onChange,
      onBlur,
      value,
    },
    fieldState: { error },
  } = useController({ name });

  return (
    <LikeableCheckboxGroupFieldBase
      data-testid="likeableCheckboxGroupFieldBase"
      errorMessage={error?.message}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  );
};

LikeableCheckboxGroupField.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.node.isRequired,
    }),
  ),
  name: PropTypes.string.isRequired,
};

LikeableCheckboxGroupField.defaultProps = {
  items: [],
  label: null,
};

export default LikeableCheckboxGroupField;
