import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import IconButton from '../../../IconButton';
import CopyToClipboard from '../../../CopyToClipboard';
import CopyLinkIcon from './CopyLinkIcon';
import LinkIcon from './LinkIcon';

import styles from './AttachedLink.module.scss';

const AttachedLink = ({ title, link, author }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <div className={styles.linkIcon}>
        <LinkIcon data-testid="linkIcon" />
      </div>
      <div className={styles.info}>
        <a
          className={styles.link}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {title}
        </a>
        <p className={styles.author}>
          {formatMessage({ id: 'idea.ideaTabs.linksTab.link.attachedBy' }, { author })}
        </p>
      </div>
      <div className={styles.copyLinkItem}>
        <CopyToClipboard
          dataToCopy={link}
          data-testid="copyToClipboard"
        >
          <IconButton
            icon={CopyLinkIcon}
            onClick={() => {}}
            data-testid="iconButton"
          />
        </CopyToClipboard>
      </div>
    </div>
  );
};

AttachedLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default AttachedLink;
