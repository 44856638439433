import React from 'react';

import NoFilterResults from '../../../Filters/NoFilterResults';
import NoArchivedIdeas from '../NoArchivedIdeas';
import NoDraftIdeas from '../NoDraftIdeas';
import NoIdeas from '../NoIdeas';
import NoLikedIdeas from '../NoLikedIdeas';
import NoMyIdeas from '../NoMyIdeas';
import { TAB_TYPES } from '../constants';

export const renderEmptyState = ({
  hasActiveFilters,
  clearFilters,
  activeTabId,
}) => {
  if (hasActiveFilters) {
    return <NoFilterResults onClick={clearFilters} />;
  }

  switch (activeTabId) {
    case TAB_TYPES.allIdeas:
      return <NoIdeas />;
    case TAB_TYPES.myIdeas:
      return <NoMyIdeas />;
    case TAB_TYPES.likedIdeas:
      return <NoLikedIdeas />;
    case TAB_TYPES.draftIdeas:
      return <NoDraftIdeas />;
    case TAB_TYPES.archivedIdeas:
      return <NoArchivedIdeas />;
    default:
  }
};
