import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { EVENT_STAGES } from '../../../../constants/event';
import { useListener, useModal } from '../../../../hooks';
import { activeEventActiveStageTypeSelector } from '../../../../redux/slices/events';
import {
  toggleArchiveIdeaListener,
  toggleArchiveIdeaActionCreator,
} from '../../../Idea/listeners/toggleArchiveIdeaListener';
import {
  voteIdeaActionCreator,
  voteIdeaListener,
} from '../../../Idea/listeners/voteIdeaListener';
import {
  unvoteIdeaListener,
  unvoteIdeaActionCreator,
} from '../../../Idea/listeners/unvoteIdeaListener';
import ArchiveIdeaConfirmationModal from '../../../modals/ArchiveIdeaConfirmationModal';
import DeleteIdeaConfirmationModal from '../../../modals/DeleteIdeaConfirmationModal';
import ArchivedIdeaCard from '../../ArchivedIdeaCard';
import DraftIdeaCard from '../../DraftIdeaCard';
import IdeaCard from '../../IdeaCard';
import VoteIdeaCard from '../../VoteIdeaCard';
import { TAB_TYPES } from '../constants';

export const useGetCardRenderer = ({
  toogleIsProcessing,
  eventHrid,
  isVotesLimitReached,
}) => {
  const activeEventType = useSelector(activeEventActiveStageTypeSelector);
  const { showModal } = useModal();
  const [toggleArchiveIdea] = useListener({
    listener: toggleArchiveIdeaListener,
    actionCreator: toggleArchiveIdeaActionCreator,
  });
  const [voteIdea] = useListener({
    listener: voteIdeaListener,
    actionCreator: voteIdeaActionCreator,
  });
  const [unvoteIdea] = useListener({
    listener: unvoteIdeaListener,
    actionCreator: unvoteIdeaActionCreator,
  });

  const handleRestoreIdea = useCallback(
    async (ideaId) => {
      toogleIsProcessing(true);

      try {
        await toggleArchiveIdea({
          ideaId,
          eventHrid,
          isArchive: false,
        });
      } finally {
        toogleIsProcessing(false);
      }
    },
    [
      toggleArchiveIdea,
      toogleIsProcessing,
      eventHrid,
    ],
  );

  const handleVoteIdea = useCallback(async (ideaId) => {
    try {
      toogleIsProcessing(true);

      await voteIdea({
        ideaId,
        eventHrid,
      });
    } finally {
      toogleIsProcessing(false);
    }
  }, [
    voteIdea,
    toogleIsProcessing,
    eventHrid,
  ]);

  const handleUnvoteIdea = useCallback(async (ideaId) => {
    try {
      toogleIsProcessing(true);

      await unvoteIdea({
        ideaId,
        eventHrid,
      });
    } finally {
      toogleIsProcessing(false);
    }
  }, [
    unvoteIdea,
    toogleIsProcessing,
    eventHrid,
  ]);

  const showDeleteIdeaConfirmationModal = useCallback((ideaId, isDraft) => (
    showModal((props) => (
      <DeleteIdeaConfirmationModal
        ideaId={ideaId}
        isDraft={isDraft}
        {...props}
      />
    ))
  ), [showModal]);

  const showArchiveIdeaConfirmatonModal = useCallback((ideaId) => (
    showModal((props) => (
      <ArchiveIdeaConfirmationModal
        {...props}
        ideaId={ideaId}
      />
    ))
  ), [showModal]);

  const renderCard = useCallback(({ cardData, activeTabId }) => {
    switch (activeTabId) {
      case (TAB_TYPES.archivedIdeas):
        return (
          <ArchivedIdeaCard
            idea={cardData}
            onDelete={() => showDeleteIdeaConfirmationModal(cardData.human_readable_id)}
            onRestore={() => handleRestoreIdea(cardData.human_readable_id)}
          />
        );
      case (TAB_TYPES.draftIdeas):
        return (
          <DraftIdeaCard
            idea={cardData}
            onDelete={() => showDeleteIdeaConfirmationModal(cardData.human_readable_id, true)}
          />
        );
      default:
        switch (activeEventType) {
          case EVENT_STAGES.VOTING:
            return (
              <VoteIdeaCard
                idea={cardData}
                onArchive={() => showArchiveIdeaConfirmatonModal(cardData.human_readable_id)}
                onVote={() => handleVoteIdea(cardData.human_readable_id)}
                onUnvote={() => handleUnvoteIdea(cardData.human_readable_id)}
                isLimitReached={isVotesLimitReached}
              />
            );
          default:
            return (
              <IdeaCard
                idea={cardData}
                onArchive={() => showArchiveIdeaConfirmatonModal(cardData.human_readable_id)}
              />
            );
        }
    }
  }, [
    showDeleteIdeaConfirmationModal,
    showArchiveIdeaConfirmatonModal,
    handleRestoreIdea,
    activeEventType,
    handleVoteIdea,
    handleUnvoteIdea,
    isVotesLimitReached,
  ]);

  return renderCard;
};
