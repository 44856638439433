import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from '../../Modal';
import { useListener } from '../../../hooks';
import {
  deleteIdeaActionCreator,
  deleteIdeaListener,
} from './listeners/deleteIdeaListener';
import { ACTIVE_EVENT_HRID } from '../../../constants/event';

const DeleteIdeaConfirmationModal = ({
  ideaId,
  success,
  abort,
  redirectPath,
  isDraft,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [deleteIdea] = useListener({
    listener: deleteIdeaListener,
    actionCreator: deleteIdeaActionCreator,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteConfirm = useCallback(async () => {
    setIsLoading(true);

    try {
      await deleteIdea({
        ideaId,
        eventHrid: ACTIVE_EVENT_HRID,
        redirectPath,
        isDraft,
      });

      success();
    } finally {
      setIsLoading(false);
    }
  }, [
    success,
    deleteIdea,
    ideaId,
    setIsLoading,
    redirectPath,
    isDraft,
  ]);

  return (
    <Modal
      title={formatMessage({
        id: isDraft
          ? 'deleteIdeaConfirmationModal.draftIdea.title'
          : 'deleteIdeaConfirmationModal.archivedIdea.title',
      })}
      content={formatMessage({
        id: isDraft
          ? 'deleteIdeaConfirmationModal.draftIdea.description'
          : 'deleteIdeaConfirmationModal.archivedIdea.description',
      })}
      onClose={abort}
      onReject={abort}
      onConfirm={handleDeleteConfirm}
      confirmButtonCaption={formatMessage({ id: 'common.delete' })}
      isLoading={isLoading}
      {...props}
    />
  );
};

DeleteIdeaConfirmationModal.propTypes = {
  ideaId: PropTypes.string.isRequired,
  success: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
  redirectPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }),
  ]),
  isDraft: PropTypes.bool,
};

DeleteIdeaConfirmationModal.defaultProps = {
  redirectPath: null,
  isDraft: false,
};

export default DeleteIdeaConfirmationModal;
