import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { useGetActiveBusinessObjectivesQuery } from '../../../../redux/api/slices/businessObjectivesApi';
import {
  useStep,
  useStepperApi,
  OneColumnContent,
} from '../../../Stepper';
import Spinner from '../../../Spinner';
import BusinessObjectiveForm from '../../../forms/BusinessObjectiveForm';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { convertBusinessObjectives } from '../utils';

const SelectBusinessObjectiveStep = () => {
  const {
    data: businessObjectives,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetActiveBusinessObjectivesQuery();
  const {
    goNext,
    stepDefaultValues,
    stepValues,
    saveAsDraft,
  } = useStepperApi();
  const [{
    getFormValues,
    isSubmitting,
    submitForm,
    isDirty,
  }, setFormApi] = useState({});

  const handleSubmit = useCallback((formValues) => goNext(formValues), [goNext]);
  const handleSaveAsDraft = useCallback(
    () => saveAsDraft(getFormValues()),
    [saveAsDraft, getFormValues],
  );
  const resultBusinessObjectives = useMemo(() => {
    if (isLoading || isError) {
      return [];
    }

    return businessObjectives.map(convertBusinessObjectives);
  }, [
    businessObjectives,
    isLoading,
    isError,
  ]);

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  useStep({
    isLoading,
    isFetching: !isLoading && isFetching,
    isInProgress: isSubmitting,
    onGoNext: submitForm,
    onSaveAsDraft: handleSaveAsDraft,
    isDirty,
  });

  return (
    <OneColumnContent>
      {
        isLoading
          ? (
            <Spinner data-testid="spinner" />
          )
          : (
            <BusinessObjectiveForm
              data-testid="businessObjectiveForm"
              onSubmit={handleSubmit}
              getFormApi={setFormApi}
              defaultValues={stepDefaultValues}
              values={stepValues}
              businessObjectives={resultBusinessObjectives}
            />
          )
      }
    </OneColumnContent>
  );
};

export default SelectBusinessObjectiveStep;
