import React from 'react';
import PropTypes from 'prop-types';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../Icon';

const ExternalLinkIcon = ({ className }) => (
  <Icon
    data-testid="externalLinkIcon"
    type={ICON_TYPES.externalLink}
    color={ICON_COLOR.primary}
    size={ICON_SIZE.xxxs}
    className={className}
  />
);

ExternalLinkIcon.propTypes = {
  className: PropTypes.string,
};

ExternalLinkIcon.defaultProps = {
  className: null,
};

export default ExternalLinkIcon;
