import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

const WatchFormValues = ({
  name,
  children,
}) => {
  const values = useWatch({ name });

  return children(values);
};

WatchFormValues.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.func.isRequired,
};

WatchFormValues.defaultProps = {
  name: undefined,
};

export default WatchFormValues;
