import { useGetIdeasQuery } from '../../../../../redux/api/slices/ideaApi';

export const useGetIdeas = ({
  page,
  pageSize,
  sort,
  filters = {},
  eventHrid,
}) => useGetIdeasQuery({
  page,
  pageSize,
  isArchive: true,
  eventHrid,
  sort,
  ...filters,
}, { refetchOnMountOrArgChange: true });
