import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { STAGE_TYPES } from './constants';
import StageIcon from './StageIcon';
import { ICON_TYPES } from '../../Icon';
import InfoBadge from '../../InfoBadge';
import StageBadge from '../../StageBadge';
import { EVENT_STAGES_ORDER } from '../../../constants/event';

import styles from './Stage.module.scss';

const Stage = ({
  title,
  description,
  isActive,
  timeLeft,
  className,
  isCompleted,
  iconType,
  type,
  stageId,
}) => {
  const { formatMessage } = useIntl();
  const isStageCompleted = isCompleted && !isActive;

  return (
    <div
      data-testid="stage"
      className={clsx(
        styles.stage,
        className,
        styles[`stage__type_${type}`],
        {
          [styles.stage__active]: isActive,
        },
      )}
    >
      <div className={styles.iconWrapper}>
        <StageIcon type={iconType} />
      </div>
      <h2 className={styles.title}>
        {title}
      </h2>
      <p className={styles.description}>
        {description}
      </p>
      <div className={styles.footer}>
        <StageBadge
          data-testid="stageBadge"
          isActive={isActive}
          stageNumber={EVENT_STAGES_ORDER[stageId]}
        />
        <InfoBadge
          data-testid="infoBadge"
          isActive={isActive}
        >
          {
            isActive
              ? timeLeft
              : formatMessage(
                { id: 'eventJourney.stage.completionStatus' },
                { isCompleted: isStageCompleted },
              )
          }
        </InfoBadge>
      </div>
    </div>
  );
};

Stage.propTypes = {
  title: PropTypes.string.isRequired,
  stageId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  timeLeft: PropTypes.string,
  className: PropTypes.string,
  isCompleted: PropTypes.bool.isRequired,
  iconType: PropTypes.oneOf(
    Object.values(ICON_TYPES),
  ).isRequired,
  type: PropTypes.oneOf(
    Object.values(STAGE_TYPES),
  ).isRequired,
};

Stage.defaultProps = {
  isActive: false,
  timeLeft: null,
  className: '',
};

export default Stage;
