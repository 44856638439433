export const i18n = {
    dataPickerBody: {
        searchPlaceholder: 'Search',
        noRecordsMessage: 'No records found',
        noRecordsSubTitle: 'Check your spelling, or search for a different keyword',
    },
    fileCard: {
        fileSizeProgress: ' of ',
    },
    pickerModal: {
        headerTitle: 'Please select',
        searchPlaceholder: 'Type text for quick search',
        cancelButton: 'Cancel',
        selectButton: 'Select',
        clearAllButton: 'CLEAR ALL',
        selectAllButton: 'SELECT ALL',
    },
    pickerInput: {
        showOnlySelectedLabel: 'Show only selected',
        clearSelectionButton: 'CLEAR ALL',
        clearSelectionButtonSingle: 'CLEAR',
        selectAllButton: 'SELECT ALL',
    },
    notificationCard: {
        closeAllNotificationsButton: 'CLOSE ALL NOTIFICATIONS',
    },
    form: {
        notifications: {
            actionButtonCaption: 'Restore',
            unsavedChangesMessage: 'You have unsaved changes. Click Restore button if you would like to recover the data',
        },
        modals: {
            beforeLeaveMessage: 'Your data may be lost. Do you want to save data?',
            discardButton: 'Discard',
            saveButton: 'Save',
        },
    },
    rangeDatePicker: {
        pickerPlaceholderFrom: 'From:',
        pickerPlaceholderTo: 'To:',
    },
    tables: {
        columnsConfigurationModal: {
            applyButton: 'Apply',
            cancelButton: 'Cancel',
            selectAllButton: 'Select all',
            clearAllButton: 'Clear all',
            checkAllButton: 'Check All',
            uncheckAllButton: 'Uncheck All',
            configureColumnsTitle: 'Configure columns',
            resetToDefaultButton: 'Reset to Default',
            displayedSectionTitle: 'Displayed in table',
            hiddenSectionTitle: 'Hidden in table',
            searchPlaceholder: 'Search by column name',
            noResultsFound: {
                text: 'No results found',
                subText: 'We can’t find any item matching your request',
            },
            pinColumnButton: "Pin column",
            unPinColumnButton: "Unpin column",
            lockedColumnPinButton: "You cannot unpin this column",
        },
    },
    pickerFilterHeader: {
        sortAscending: 'Sort Ascending',
        sortDescending: 'Sort Descending',
    },
    fileUpload: {
        labelStart: "Drop files to attach or",
        browse: "browse",
        labelEnd: "your local files",
    },
    errorHandler: {
        errorPageConfig: {
            notFound: {
                title: "Oooops! We couldn’t find this page",
                subtitle: "Sorry for the inconvenience.",
            },
            permissionDenied: {
                title: "You have no permissions!",
                subtitle: "Sorry for the inconvenience.",
            },
            serverError: {
                title: "500 Error! Something went wrong",
                subtitle: "Sorry for the inconvenience, we’ll get it fixed.",
            },
            serviceUnavailable: {
                title: "The page request was canceled, because it took too long to complete",
                subtitle: "Sorry for the inconvenience, we’ll get it fixed.",
            },
            default: {
                title: "Something went wrong",
                subtitle: "Sorry for the inconvenience, we’ll get it fixed.",
            },
        },
        recoveryMessageConfig: {
            'auth-lost': {
                title: 'Your session has expired.',
                subtitle: 'Attempting to log you in.',
            },
            'connection-lost': {
                title: 'Network connection down',
                subtitle: 'Please check your network connection.',
            },
            'maintenance': {
                title: 'Server maintenance',
                subtitle: 'We apologize for the inconvenience. Our site is currently under maintenance. Will come back as soon as possible.',
            },
            'server-overload': {
                title: 'Server overloaded',
                subtitle: 'We are trying to recover. Please wait.',
            },
        },
    },
    dataTable: {
        title: 'No results found',
        message: 'We can’t find any item matching your request',
    },
    filterToolbar: {
        addCaption: 'Add filter',
        datePicker: {
            placeholder: 'Select date',
            removeCaption: 'REMOVE FILTER',
            clearCaption: 'CLEAR',
        },
        rangeDatePicker: {
            emptyPickerPlaceholder: 'Select period',
            emptyPlaceholderFrom: 'Select From',
            emptyPlaceholderTo: 'Select To',
        },
        pickerInput: {
            itemsPlaceholder: 'items',
            emptyValueCaption: 'ALL',
        },
    },
    presetPanel: {
        addCaption: 'Add Preset',
    },
};
