import React from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';

import SharableLinkIcon from './SharableLinkIcon';
import IconButton from '../IconButton';
import CopyToClipboard from '../CopyToClipboard';
import { getFullUrl } from '../../utils';
import { ROUTES } from '../../constants/routes';

const IdeaSharableLink = ({ id }) => (
  <CopyToClipboard
    data-testid="copyToClipboard"
    dataToCopy={getFullUrl(
      generatePath(ROUTES.idea, { ideaId: id }),
    )}
  >
    <IconButton
      data-testid="iconButton"
      icon={SharableLinkIcon}
      onClick={() => { }}
    />
  </CopyToClipboard>
);

IdeaSharableLink.propTypes = {
  id: PropTypes.string.isRequired,
};

export default IdeaSharableLink;
