import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ExternalLinkIcon from './ExternalLinkIcon';

import styles from './TextLink.module.scss';

const TextLink = ({
  path,
  label,
  inheritSize,
  onClick,
  isExternal,
  className,
}) => (
  isExternal
    ? (
      <a
        href={path}
        data-testid="link"
        className={clsx(
          styles.link,
          { [styles.link__inheritSize]: inheritSize },
          className,
        )}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        {label}
        <ExternalLinkIcon
          data-testid="externalLinkIcon"
          className={styles.icon}
        />
      </a>
    ) : (
      <Link
        data-testid="link"
        className={clsx(
          styles.link,
          { [styles.link__inheritSize]: inheritSize },
          className,
        )}
        to={path}
        onClick={onClick}
      >
        {label}
      </Link>
    )
);

TextLink.propTypes = {
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  ]).isRequired,
  label: PropTypes.node.isRequired,
  inheritSize: PropTypes.bool,
  onClick: PropTypes.func,
  isExternal: PropTypes.bool,
  className: PropTypes.string,
};

TextLink.defaultProps = {
  inheritSize: false,
  onClick: null,
  isExternal: false,
  className: '',
};

export default TextLink;
