import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { ROUTES } from '../../../../constants/routes';
import { getFullUrl } from '../../../../utils';
import LikesAndCommentsBar from '../../../LikesAndCommentsBar';
import Table from '../../../Table';
import {
  EntityCell,
  TextCell,
} from '../../../Table/cellsRenders';
import IdeaDetails from '../../../Table/expandedRowRenders/IdeaDetails';
import {
  ACCESSOR_KEYS,
  FILTERS_URL_PARAM_NAME,
  PAGE_URL_PARAM_NAME,
  SORTING_URL_PARAM_NAME,
  TABLE_ID,
} from './constants';
import NoIdeas from './NoIdeas';
import Spinner from '../../../Spinner';
import NoFilterResults from '../../../Filters/NoFilterResults';
import ExportToExcelButton from '../ExportToExcelButton';
import { useGetIdeas } from './hooks/useGetIdeas';
import { useGetActiveBusinessObjectivesQuery } from '../../../../redux/api/slices/businessObjectivesApi';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import IdeasMenu from './IdeasMenu';
import {
  toggleArchiveIdeaListener,
  toggleArchiveIdeaActionCreator,
} from '../../../Idea/listeners/toggleArchiveIdeaListener';
import { useListener, useModal } from '../../../../hooks';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';
import DeleteIdeaConfirmationModal from '../../../modals/DeleteIdeaConfirmationModal';
import ArchiveIdeaConfirmationModal from '../../../modals/ArchiveIdeaConfirmationModal';
import {
  useGetSorting,
  useGetFilters,
} from '../hooks';

import styles from './IdeasTotalTab.module.scss';

const IdeasTotalTab = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();
  const [isRestoring, setIsRestoring] = useState(false);

  const {
    data: bizObjectivesData,
    isLoading: areBizObjectivesLoading,
    error: bizObjectivesError,
  } = useGetActiveBusinessObjectivesQuery();

  const [toggleArchive] = useListener({
    listener: toggleArchiveIdeaListener,
    actionCreator: toggleArchiveIdeaActionCreator,
  });

  const showDeleteIdeaConfirmationModal = useCallback((ideaId) => showModal((props) => (
    <DeleteIdeaConfirmationModal
      {...props}
      ideaId={ideaId}
    />
  )), [showModal]);

  const handleRestore = useCallback(
    async (ideaId) => {
      setIsRestoring(true);

      try {
        await toggleArchive({
          ideaId,
          eventHrid: ACTIVE_EVENT_HRID,
          isArchive: false,
        });
      } finally {
        setIsRestoring(false);
      }
    },
    [toggleArchive],
  );

  const showArchiveIdeaConfirmatonModal = useCallback((ideaId) => showModal((props) => (
    <ArchiveIdeaConfirmationModal
      {...props}
      ideaId={ideaId}
    />
  )), [
    showModal,
  ]);

  const renderTitleCell = useCallback(({ row }) => (
    <EntityCell
      humanReadableId={
        formatMessage(
          { id: 'common.ideaTitle' },
          {
            isArchived: !!row.original.archive,
            name: row.original.human_readable_id,
          },
        )
      }
      title={row.original.title}
      url={getFullUrl(generatePath(ROUTES.idea, { ideaId: row.original.human_readable_id }))}
    />
  ), [formatMessage]);

  const renderCollaborationsCell = useCallback(({ row }) => (
    <LikesAndCommentsBar
      idea={row.original}
    />
  ), []);

  const renderBizCategoryCell = useCallback(({ row }) => (
    <TextCell
      value={row.original.biz_category.name}
    />
  ), []);

  const renderActionsCell = useCallback(({ row }) => (
    <div className={styles.actionsWrapper}>
      <IdeasMenu
        idea={row.original}
        onArchive={() => showArchiveIdeaConfirmatonModal(row.original.human_readable_id)}
        onRestore={() => handleRestore(row.original.human_readable_id)}
        onDelete={() => showDeleteIdeaConfirmationModal(row.original.human_readable_id)}
      />
    </div>
  ), [
    handleRestore,
    showDeleteIdeaConfirmationModal,
    showArchiveIdeaConfirmatonModal,
  ]);

  const renderDetailPanel = useCallback(({ row }) => (
    <IdeaDetails
      idea={row.original}
    />
  ), []);

  const renderEmptyState = useCallback(({
    hasActiveFilters,
    clearFilters,
  }) => {
    if (hasActiveFilters) {
      return <NoFilterResults onClick={clearFilters} />;
    }

    return <NoIdeas />;
  }, []);

  const columns = useMemo(() => ([
    {
      accessorKey: ACCESSOR_KEYS.IDEA_TITLE,
      header: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.title' }),
      cell: renderTitleCell,
    },
    {
      accessorKey: ACCESSOR_KEYS.COLLABORATION,
      header: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.collaboration' }),
      cell: renderCollaborationsCell,
      size: 27,
    },
    {
      accessorKey: ACCESSOR_KEYS.BUSINESS_OBJECTIVE,
      header: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.bizObjective' }),
      cell: renderBizCategoryCell,
      size: 20,
    },
    {
      accessorKey: ACCESSOR_KEYS.ACTIONS,
      header: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.actions' }),
      cell: renderActionsCell,
    },
  ]), [
    formatMessage,
    renderTitleCell,
    renderCollaborationsCell,
    renderBizCategoryCell,
    renderActionsCell,
  ]);

  const filters = useGetFilters({ bizObjectives: bizObjectivesData });
  const sortingOptions = useGetSorting();

  useEffect(() => {
    isAxiosError(bizObjectivesError) && bizObjectivesError.handleGlobally();
  }, [bizObjectivesError]);

  if (areBizObjectivesLoading) {
    return <Spinner cx={styles.spinner} />;
  }

  return (
    <Table
      emptyState={renderEmptyState}
      tableId={TABLE_ID}
      isInProgress={isRestoring}
      columns={columns}
      useGetData={useGetIdeas}
      renderDetailPanel={renderDetailPanel}
      pageUrlParamName={PAGE_URL_PARAM_NAME}
      totalResultsLabelId="adminPanel.ideasBoard.ideasTotalTable.totalResultsLabel"
      actions={<ExportToExcelButton />}
      filtersProps={{
        urlParamName: FILTERS_URL_PARAM_NAME,
        title: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.filters.title' }),
        filters,
        reportLabelId: 'adminPanel.ideasBoard.ideasTotalTable.filters.reportLabel',
      }}
      getDataAdditionalParams={{ eventHrid: ACTIVE_EVENT_HRID }}
      sortingProps={{
        urlParamName: SORTING_URL_PARAM_NAME,
        items: sortingOptions,
      }}
    />
  );
};

export default IdeasTotalTab;
