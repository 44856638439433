import { CHART_DATA_TYPES } from '../../../components/AdminPanel/Dashboard/constants';
import { baseApi } from '../baseApi';

export const analyticsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOveralSummaryInfo: build.query({
      query: ({ from, to, eventHrid }) => ({
        url: `/events/${eventHrid}/analytics/summary`,
        params: {
          from_date: from,
          to_date: to,
        },
      }),
    }),
    getPublicOveralSummaryInfo: build.query({
      query: ({ from, eventHrid }) => ({
        url: `/events/${eventHrid}/analytics/public/summary`,
        params: {
          from_date: from,
        },
      }),
    }),
    getIdeaOverviewInfo: build.query({
      query: ({ from, to, eventHrid }) => ({
        url: `/events/${eventHrid}/analytics/idea-overview`,
        params: {
          from_date: from,
          to_date: to,
        },
      }),
    }),
    getBusinessObjectiveByTypeOverviewInfo: build.query({
      query: ({
        type, from, to, eventHrid,
      }) => ({
        url: (type === CHART_DATA_TYPES.ideas)
          ? `/events/${eventHrid}/analytics/business-objectives-ideas`
          : '/events/analytics/business-objectives-users',
        params: {
          from_date: from,
          to_date: to,
        },
      }),
    }),
    // getTechnologyCategoriesByTypeOverviewInfo: build.query({
    //   query: ({ type, from, to }) => ({
    //     url: (type === CHART_DATA_TYPES.ideas)
    //       ? '/analytics/technology-category-ideas/'
    //       : '/analytics/technology-category-users/',
    //     params: {
    //       from_date: from,
    //       to_date: to,
    //     },
    //   }),
    // }),
  }),
});

export const {
  useGetOveralSummaryInfoQuery,
  useGetPublicOveralSummaryInfoQuery,
  useGetIdeaOverviewInfoQuery,
  useGetBusinessObjectiveByTypeOverviewInfoQuery,
  useGetTechnologyCategoriesByTypeOverviewInfoQuery,
} = analyticsApi;
