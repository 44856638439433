import React from 'react';
import PropTypes from 'prop-types';

import { getIsUserUnauthorized } from './utils';
import ProtectedRoute from '../ProtectedRoute';

const UnauthorizedRoute = ({
  exact,
  path,
  children,
}) => (
  <ProtectedRoute
    data-testid="protectedRoute"
    exact={exact}
    path={path}
    conditions={getIsUserUnauthorized}
  >
    {children}
  </ProtectedRoute>
);

UnauthorizedRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  children: PropTypes.node,
};

UnauthorizedRoute.defaultProps = {
  exact: false,
  children: null,
};

export default UnauthorizedRoute;
