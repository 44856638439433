import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  ScrollBars,
  Panel,
  Button,
} from '@epam/promo';

import Blocker from '../Blocker';

import styles from './Modal.module.scss';

const Modal = ({
  title,
  content,
  onClose,
  onReject,
  onConfirm,
  rejectButtonCaption,
  confirmButtonCaption,
  isLoading,
  withFooterBorderTop,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <ModalBlocker
      data-testid="modalBlocker"
      blockerShadow="dark"
      abort={onClose}
      {...props}
    >
      <ModalWindow
        data-testid="modalWindow"
        cx={styles.modalWindow}
      >
        <Panel
          background="white"
          data-testid="panel"
        >
          <ModalHeader
            data-testid="modalHeader"
            title={title}
            onClose={onClose}
            borderBottom
          />
          <ScrollBars
            data-testid="scrollBars"
          >
            <div className={styles.content}>
              {content}
            </div>
          </ScrollBars>
          <ModalFooter
            data-testid="modalFooter"
            cx={styles.footer}
            borderTop={withFooterBorderTop}
          >
            {onReject && (
              <Button
                data-testid="rejectButton"
                color="gray50"
                fill="white"
                caption={rejectButtonCaption || formatMessage({ id: 'common.cancel' })}
                onClick={onReject}
                isDisabled={isLoading}
              />
            )}
            {onConfirm && (
              <Button
                data-testid="confirmButton"
                color="green"
                caption={confirmButtonCaption || formatMessage({ id: 'common.ok' })}
                onClick={onConfirm}
                isDisabled={isLoading}
              />
            )}
          </ModalFooter>
        </Panel>
        {isLoading && <Blocker />}
      </ModalWindow>
    </ModalBlocker>
  );
};

Modal.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  onClose: PropTypes.func,
  rejectButtonCaption: PropTypes.string,
  confirmButtonCaption: PropTypes.string,
  disallowClickOutside: PropTypes.bool,
  isLoading: PropTypes.bool,
  withFooterBorderTop: PropTypes.bool,
};

Modal.defaultProps = {
  onConfirm: null,
  onReject: null,
  onClose: null,
  rejectButtonCaption: null,
  confirmButtonCaption: null,
  disallowClickOutside: true,
  isLoading: false,
  withFooterBorderTop: false,
};

export default Modal;
