import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { updateUserDataFork } from '../../../listenerForks';

export const updateUserDataListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      data,
    },
  } = action;
  const { dispatch, fork } = listenerApi;
  try {
    const updateUserDataForkResult = await fork(
      updateUserDataFork(dispatch, data),
    ).result;
    if (updateUserDataForkResult.status !== 'ok') {
      isAxiosError(updateUserDataForkResult.error)
        && updateUserDataForkResult.error.handleGlobally();

      reject();
    }

    resolve();
  } catch (error) {
    reject();
  }
};

export const updateUserDataActionCreator = createAction(ACTION_TYPES.admin.updateUserData);
