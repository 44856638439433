import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// import ProposedSolutions from '../ProposedSolutions';
import AttachedLinks from '../AttachedLinks';
import TabsContent from '../../Tabs/TabsContent';
import {
  IDEA_TAB_IDS,
  COMMENTS_ID,
  COMMENTS_DATA_ID,
} from '../constants';
import IdeaComments from '../IdeaComments';

import styles from './IdeaTabsContent.module.scss';

const IdeaTabsContent = ({
  activeTabId,
  links,
  author,
  // activeSolutionId,
  // generateSolutionId,
  // solutionsId,
  isAbleToCommentIdea,
}) => {
  const { ideaId } = useParams();
  const { comments: commentsClassName } = styles;

  const items = useMemo(() => (
    [
      {
        id: IDEA_TAB_IDS.comments,
        Component: IdeaComments,
        componentProps: {
          entityId: ideaId,
          className: commentsClassName,
          id: COMMENTS_ID,
          dataId: COMMENTS_DATA_ID,
          isReadOnly: !isAbleToCommentIdea,
        },
      },
      {
        id: IDEA_TAB_IDS.links,
        Component: AttachedLinks,
        componentProps: { links, author },
      },
      // {
      //   id: IDEA_TAB_IDS.solutions,
      //   Component: ProposedSolutions,
      //   componentProps: {
      //     activeSolutionId,
      //     generateSolutionId,
      //     id: solutionsId,
      //   },
      // },
    ]
  ), [
    ideaId,
    links,
    author,
    commentsClassName,
    // activeSolutionId,
    // generateSolutionId,
    // solutionsId,
    isAbleToCommentIdea,
  ]);

  return (
    <TabsContent
      items={items}
      activeTabId={activeTabId}
      data-testid="tabsContent"
    />
  );
};

IdeaTabsContent.propTypes = {
  activeTabId: PropTypes.oneOf(
    Object.values(IDEA_TAB_IDS),
  ).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  author: PropTypes.string.isRequired,
  // activeSolutionId: PropTypes.number,
  // generateSolutionId: PropTypes.func.isRequired,
  // solutionsId: PropTypes.string.isRequired,
  isAbleToCommentIdea: PropTypes.bool.isRequired,
};

// IdeaTabsContent.defaultProps = {
//   activeSolutionId: null,
// };

export default IdeaTabsContent;
