import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import yup from '../../../../yup';
import { FORM_FIELD_NAMES } from '../constants';
import { FIELD_NAMES as PRODUCT_FIELD_NAMES } from '../../../Form/connected/ProductField/constants';
import { FIELD_NAMES as LINK_FIELD_NAMES } from '../../../Form/connected/LinkField';

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => (yup.object().shape({
    [FORM_FIELD_NAMES.TITLE]: yup.string()
      .trim()
      .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.titleRequired' }))
      .min(3, formatMessage({ id: 'proposeIdeaSolutionForm.error.titleMin' }))
      .max(150, formatMessage({ id: 'proposeIdeaSolutionForm.error.titleMax' })),
    [FORM_FIELD_NAMES.TECH_CATEGORIES]: yup.array()
      .min(1, formatMessage({ id: 'proposeIdeaSolutionForm.error.techCategoriesRequired' })),
    [FORM_FIELD_NAMES.TECH_REQUIREMENTS]: yup.string()
      .trim()
      .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.techRequirementsRequired' }))
      .min(120, formatMessage({ id: 'proposeIdeaSolutionForm.error.techRequirementsMin' })),
    [FORM_FIELD_NAMES.RISKS]: yup.string()
      .trim()
      .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.risksRequired' }))
      .min(10, formatMessage({ id: 'proposeIdeaSolutionForm.error.risksMin' })),
    [FORM_FIELD_NAMES.ASSUMPTIONS]: yup.string()
      .trim()
      .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.assumptionsRequired' }))
      .min(10, formatMessage({ id: 'proposeIdeaSolutionForm.error.assumptionsMin' })),
    [FORM_FIELD_NAMES.DEPENDENCIES]: yup.string()
      .trim()
      .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.dependenciesRequired' }))
      .min(10, formatMessage({ id: 'proposeIdeaSolutionForm.error.dependenciesMin' })),
    [FORM_FIELD_NAMES.DURATION]: yup.number()
      .nullable()
      .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.durationRequired' }))
      .moreThan(0, formatMessage({ id: 'proposeIdeaSolutionForm.error.durationMoreThan' })),
    [FORM_FIELD_NAMES.SIMILAR_PRODUCTS]: yup.array()
      .of(
        yup.object({
          [PRODUCT_FIELD_NAMES.TITLE]: yup.string()
            .trim()
            .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.product.titleRequired' }))
            .min(3, formatMessage({ id: 'proposeIdeaSolutionForm.error.product.titleMin' }))
            .max(150, formatMessage({ id: 'proposeIdeaSolutionForm.error.product.titleMax' })),
          [PRODUCT_FIELD_NAMES.DESCRIPTION]: yup.string()
            .trim()
            .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.product.descriptionRequired' }))
            .min(10, formatMessage({ id: 'proposeIdeaSolutionForm.error.product.descriptionMin' })),
          [PRODUCT_FIELD_NAMES.URL]: yup.string()
            .trim()
            .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.product.urlRequired' }))
            .url(formatMessage({ id: 'proposeIdeaSolutionForm.error.product.urlFormat' })),
        }),
      ),
    [FORM_FIELD_NAMES.LINKS]: yup.array()
      .of(
        yup.object({
          [LINK_FIELD_NAMES.DESCRIPTION]: yup.string()
            .trim()
            .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.links.descriptionRequired' }))
            .min(3, formatMessage({ id: 'proposeIdeaSolutionForm.error.links.descriptionMin' }))
            .max(150, formatMessage({ id: 'proposeIdeaSolutionForm.error.links.descriptionMax' })),
          [LINK_FIELD_NAMES.URL]: yup.string()
            .trim()
            .required(formatMessage({ id: 'proposeIdeaSolutionForm.error.links.urlRequired' }))
            .url(formatMessage({ id: 'proposeIdeaSolutionForm.error.links.urlFormat' })),
        }),
      ),
  })), [formatMessage]);
};
