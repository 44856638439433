import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import IconButton from '../../IconButton';
import CrossIcon from './CrossIcon';

import styles from './FiltersReport.module.scss';

const FiltersReport = ({
  label,
  hasActiveFilters,
  onFiltersClear,
  className,
}) => (
  <div className={clsx(styles.wrapper, className)}>
    <div className={styles.label}>
      {label}
    </div>
    <IconButton
      data-testid="clearButton"
      icon={CrossIcon}
      onClick={onFiltersClear}
      cx={clsx(styles.clearFiltersButton, {
        [styles.clearFiltersButton__visible]: hasActiveFilters,
      })}
    />
  </div>
);

FiltersReport.propTypes = {
  label: PropTypes.string.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onFiltersClear: PropTypes.func.isRequired,
};

FiltersReport.defaultProps = {
  className: '',
};

export default FiltersReport;
