import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_PROPS } from '../../Form';
import TextField from '../../Form/connected/TextField';
import { FORM_FIELD_NAMES } from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';

import styles from './ForgotPasswordForm.module.scss';

const ForgotPasswordForm = (props) => {
  const { formatMessage } = useIntl();
  const defaultValues = useMemo(() => ({
    [FORM_FIELD_NAMES.email]: '',
  }), []);
  const validationSchema = useValidationSchema();

  return (
    <Form
      data-testid="form"
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      blockPageOnSubmit
      title={formatMessage({ id: 'forgotPasswordForm.title' })}
      submitButtonCaption={formatMessage({ id: 'forgotPasswordForm.submitButton.caption' })}
      className={styles.form}
      {...props}
    >
      <TextField
        data-testid="email"
        name={FORM_FIELD_NAMES.email}
        label={formatMessage({ id: 'forgotPasswordForm.emailField.label' })}
        placeholder={formatMessage({ id: 'forgotPasswordForm.emailField.placeholder' })}
        isRequired
      />
    </Form>
  );
};

ForgotPasswordForm.propTypes = pick(FORM_PROPS, [
  'onSubmit',
  'onSubmitFail',
  'onSubmitSuccess',
  'getFormApi',
]);

ForgotPasswordForm.defaultProps = {
  onSubmitFail: null,
  onSubmitSuccess: null,
  getFormApi: null,
};

export default ForgotPasswordForm;
