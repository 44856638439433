import React from 'react';
import PropTypes from 'prop-types';

import WinnerCard from './WinnerCard';

import styles from './Winners.module.scss';

const Winners = ({
  ideas,
}) => (
  <ul className={styles.list}>
    {ideas.map((idea) => (
      <li
        key={idea.id}
        className={styles.listItem}
      >
        <WinnerCard
          data-testid={`idea:${idea.id}`}
          idea={idea}
        />
      </li>
    ))}
  </ul>
);

Winners.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Winners;
