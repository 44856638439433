import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SuccessNotification,
  WarningNotification,
  ErrorNotification,
} from '@epam/promo';
import clsx from 'clsx';

import HintNotification from './HintNotification';

import styles from './Notification.module.scss';

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  HINT: 'hint',
};

export const NOTIFICATION_BY_TYPE = {
  [NOTIFICATION_TYPES.SUCCESS]: SuccessNotification,
  [NOTIFICATION_TYPES.WARNING]: WarningNotification,
  [NOTIFICATION_TYPES.ERROR]: ErrorNotification,
  [NOTIFICATION_TYPES.HINT]: HintNotification,
};

const Notification = ({
  type,
  message,
  isClosable,
  fullWidth,
  cx,
  ...props
}) => {
  const ResultNotification = NOTIFICATION_BY_TYPE[type];
  const [isOpen, setIsOpen] = useState(true);

  if (isClosable && !isOpen) {
    return null;
  }

  return (
    <ResultNotification
      data-testid={type}
      cx={clsx(cx, styles.notification, {
        [styles.notification__fullWidth]: fullWidth,
      })}
      {...props}
      {...(
        isClosable
          ? { onClose: () => setIsOpen(false) }
          : {}
        )}
    >
      <p className={styles.content}>
        {message}
      </p>
    </ResultNotification>
  );
};

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  })),
  onClose: PropTypes.func,
  isClosable: PropTypes.bool,
  cx: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Notification.defaultProps = {
  type: NOTIFICATION_TYPES.SUCCESS,
  actions: [],
  onClose: null,
  isClosable: false,
  cx: '',
  fullWidth: false,
};

export default Notification;
