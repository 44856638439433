import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../Icon';

const PeopleIcon = () => (
  <Icon
    type={ICON_TYPES.people}
    size={ICON_SIZE.mxxs}
    color={ICON_COLOR.inherit}
  />
);

export default PeopleIcon;
