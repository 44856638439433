import { useIntl } from 'react-intl';

import { subtractDaysFromToday } from '../../../../utils';

export const useGetYesterdayPreset = () => {
  const { formatMessage } = useIntl();

  return ({
    name: formatMessage({ id: 'rangeDatePicker.presets.yesterday.label' }),
    getRange: () => ({
      from: subtractDaysFromToday(1).toISOString(),
      to: subtractDaysFromToday(1).toISOString(),
    }),
  });
};
