import { useContext, useEffect } from 'react';

import { LeavePromptContext } from '../LeavePromptProvider';

const LeavePrompt = () => {
  const {
    addSubscription,
    removeSubscription,
  } = useContext(LeavePromptContext);

  useEffect(() => {
    addSubscription();

    return () => removeSubscription();
  }, [
    addSubscription,
    removeSubscription,
  ]);

  return null;
};

export default LeavePrompt;
