import React from 'react';
import PropTypes from 'prop-types';

import EntityMainAttributes from '../EntityMainAttributes';
import {
  formatDate,
  getUserFullName,
} from '../../utils';
import {
  IDEA_TYPES,
  IDEA_STATUSES,
} from '../../constants/idea';
import IdeaDescription from '../IdeaDescription';

import styles from './IdeaInfo.module.scss';

const IdeaInfo = ({
  idea,
}) => {
  const {
    human_readable_id: humanReadableId,
    author,
    created_at: date,
    title,
    ...ideaDetails
  } = idea;

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>
      <EntityMainAttributes
        id={humanReadableId}
        author={getUserFullName(author)}
        date={formatDate(date)}
        className={styles.widgets}
        data-testid="entityMainAtributes"
      />
      <IdeaDescription
        ideaDetails={ideaDetails}
        data-testid="ideaDescription"
      />
    </div>
  );
};

IdeaInfo.propTypes = {
  idea: PropTypes.shape({
    human_readable_id: PropTypes.string.isRequired,
    author: PropTypes.object.isRequired,
    created_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    biz_category: PropTypes.object.isRequired,
    idea_type: PropTypes.oneOf(Object.values(IDEA_TYPES)).isRequired,
    reason: PropTypes.string.isRequired,
    status: PropTypes.oneOf(
      Object.values(IDEA_STATUSES),
    ).isRequired,
  }).isRequired,
};

export default IdeaInfo;
