import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '../../Button';
import { ROUTES } from '../../../constants/routes';
import { getUserFullName } from '../../../utils';
import VoteIdeaCardMenu from './VoteIdeaCardMenu';
import { userIdSelector } from '../../../redux/slices/access';
import HasAccess from '../../HasAccess';
import { ideaAuthorAccessConditions } from '../../../utils/accessConditions';

import styles from './VoteIdeaCard.module.scss';

const VoteIdeaCard = ({
  idea,
  onArchive,
  onVote,
  onUnvote,
  isLimitReached,
}) => {
  const { formatMessage } = useIntl();
  const {
    author,
    title,
    reason,
    votes,
    human_readable_id: ideaHrid,
  } = idea;
  const currentUserId = useSelector(userIdSelector);
  const isVoted = votes.includes(currentUserId);

  const ideaTitle = useMemo(() => (
    <Link
      to={generatePath(ROUTES.idea, { ideaId: ideaHrid })}
      className={clsx(styles.ideaLink, styles.cut)}
      data-testid="ideaTitle"
    >
      {title}
    </Link>
  ), [
    title,
    ideaHrid,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.ideaHrid}>
          {ideaHrid}
        </div>
        <VoteIdeaCardMenu
          idea={idea}
          onArchive={onArchive}
          data-testid="voteIdeaCardMenu"
        />
      </div>
      <h4 className={styles.title}>
        {ideaTitle}
      </h4>
      <p className={styles.reason}>
        {reason}
      </p>
      <HasAccess
        data-testid="authorNameHasAccess"
        conditions={ideaAuthorAccessConditions}
      >
        <div className={styles.author}>
          {getUserFullName(author)}
        </div>
      </HasAccess>
      <Button
        caption={formatMessage(
          { id: 'ideas.voteIdeaCard.toggleVoteButton.caption' },
          { isVoted },
        )}
        onClick={isVoted ? onUnvote : onVote}
        fill={isVoted ? 'solid' : 'white'}
        cx={styles.toggleVoteButton}
        isDisabled={!isVoted && isLimitReached}
        data-testid="button"
      />
    </div>
  );
};

VoteIdeaCard.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    author: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    votes: PropTypes.arrayOf(PropTypes.number).isRequired,
    human_readable_id: PropTypes.string.isRequired,
  }).isRequired,
  onArchive: PropTypes.func.isRequired,
  onVote: PropTypes.func.isRequired,
  onUnvote: PropTypes.func.isRequired,
  isLimitReached: PropTypes.bool.isRequired,
};

export default VoteIdeaCard;
