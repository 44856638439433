import React, {
  Fragment,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isPlainObject, isArray } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

import PageBlocker from '../PageBlockerProvider/PageBlocker';
import Blocker from '../Blocker';
import Alert from '../Alert';
import Button from '../Button';
import LeavePrompt from '../LeavePromptProvider/LeavePrompt';

import styles from './Form.module.scss';

export const FORM_TITLE_SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
};

export const FORM_PROPS = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  getFormApi: PropTypes.func,
  validationSchema: PropTypes.object,
  defaultValues: PropTypes.object.isRequired,
  values: PropTypes.object,
  blockPageOnSubmit: PropTypes.bool,
  blockFormOnSubmit: PropTypes.bool,
  title: PropTypes.string,
  submitButtonCaption: PropTypes.string,
  submitButtonProps: PropTypes.object,
  hideButtons: PropTypes.bool,
  onlyForm: PropTypes.bool,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  titleSize: PropTypes.oneOf(Object.values(FORM_TITLE_SIZES)),
  primaryActions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  resetFormOnSuccessSubmit: PropTypes.bool,
  preventLeavingWhenDirty: PropTypes.bool,
  onWatch: PropTypes.func,
};

const NOTIFICATION_TYPES = {
  error: 'error',
  hint: 'hint',
  success: 'success',
  warning: 'warning',
};

const NOTIFICATION_TYPES_BY_ORDER = [
  NOTIFICATION_TYPES.success,
  NOTIFICATION_TYPES.error,
  NOTIFICATION_TYPES.warning,
  NOTIFICATION_TYPES.hint,
];

const Form = ({
  children,
  onSubmit,
  onSubmitFail,
  onSubmitSuccess,
  getFormApi,
  validationSchema,
  defaultValues,
  values,
  blockPageOnSubmit,
  blockFormOnSubmit,
  title,
  submitButtonCaption,
  hideButtons,
  onlyForm,
  className,
  titleClassName,
  titleSize,
  primaryActions,
  resetFormOnSuccessSubmit,
  submitButtonProps,
  preventLeavingWhenDirty,
  onWatch,
}) => {
  const { formatMessage } = useIntl();
  const activeDefaultValues = useRef(defaultValues);
  const activeValues = useRef(values);

  const [notifications, setNotifications] = useState({});

  const removeNotifications = useCallback((type, index) => {
    let newNotifications;

    if (!type) {
      newNotifications = {};
    } else {
      const newTypeNonifications = [...notifications[type]];
      newTypeNonifications.splice(index, 1);

      newNotifications = {
        ...notifications,
        [type]: newTypeNonifications,
      };
    }

    setNotifications(newNotifications);
  }, [notifications, setNotifications]);

  const formRef = useRef(null);
  const formApi = useForm({
    resolver: validationSchema && yupResolver(validationSchema),
    defaultValues: values || defaultValues,
    mode: 'onSubmit',
  });
  const {
    watch,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: {
      isSubmitting,
      isDirty,
    },
  } = formApi;
  const shouldBlockPageLeave = preventLeavingWhenDirty && isDirty && !isSubmitting;

  useEffect(() => {
    if (onWatch) {
      const watchSubscription = watch(onWatch);
      return () => watchSubscription.unsubscribe();
    }
  }, [watch, onWatch]);

  const handleSuccessSubmit = useCallback(() => {
    resetFormOnSuccessSubmit && reset();
    onSubmitSuccess?.();
  }, [
    resetFormOnSuccessSubmit,
    onSubmitSuccess,
    reset,
  ]);

  const submitForm = useCallback(() => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true }),
    );
  }, []);

  const externalFormApi = useMemo(() => ({
    isSubmitting,
    isDirty,
    submitForm,
    getFormValues: getValues,
    reset,
  }), [
    isSubmitting,
    isDirty,
    submitForm,
    getValues,
    reset,
  ]);

  useLayoutEffect(() => {
    if (isSubmitting) {
      removeNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  useLayoutEffect(() => {
    getFormApi?.(externalFormApi);
  }, [externalFormApi, getFormApi]);

  useEffect(() => {
    // why so complecated - https://github.com/react-hook-form/react-hook-form/discussions/8888
    if (values) {
      reset(defaultValues);
      reset(values, { keepDefaultValues: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmitting) {
      return;
    }

    const shouldUpdateDefaultValues = activeDefaultValues.current !== defaultValues;
    const shouldUpdateValues = activeValues.current !== values;

    if (shouldUpdateDefaultValues) {
      activeDefaultValues.current = defaultValues;
      reset(defaultValues);
    }

    if (shouldUpdateValues) {
      activeValues.current = values;
      if (values) {
        reset(values, { keepDefaultValues: true });
      } else if (!shouldUpdateDefaultValues.current) {
        reset(defaultValues);
      }
    }
  }, [
    defaultValues,
    values,
    isSubmitting,
    reset,
  ]);

  const resultOnSubmit = useMemo(() => {
    const handleNotifications = (submitResult) => {
      const {
        error,
        hint,
        success,
        warning,
      } = submitResult;
      const newNotifications = {
        error,
        hint,
        success,
        warning,
      };

      Object.keys(newNotifications).length && setNotifications(newNotifications);
    };

    const runSubmitProcess = async (formValues) => {
      if (!onSubmit) {
        return;
      }

      try {
        const submitResult = await onSubmit(formValues);

        if (isPlainObject(submitResult)) {
          handleNotifications(submitResult);
        }

        await handleSuccessSubmit();
      } catch (error) {
        if (isPlainObject(error)) {
          const { fieldsErrors } = error;

          fieldsErrors && Object.keys(fieldsErrors).forEach((fieldName) => {
            setError(fieldName, { type: 'custom', message: fieldsErrors[fieldName] });
          });

          handleNotifications(error);
        }

        await onSubmitFail?.();
      }
    };

    return handleSubmit(runSubmitProcess, onSubmitFail);
  }, [
    onSubmit,
    onSubmitFail,
    handleSuccessSubmit,
    handleSubmit,
    setError,
    setNotifications,
  ]);

  const renderNotificationsByType = (type) => (
    notifications[type]?.map((message, index) => (
      <Alert
        key={message}
        type={type}
        onClose={() => removeNotifications(type, index)}
      >
        {message}
      </Alert>
    ))
  );

  const renderNotifications = () => (NOTIFICATION_TYPES_BY_ORDER.map(
    (notificationType) => (
      <Fragment key={notificationType}>
        {renderNotificationsByType(notificationType)}
      </Fragment>
    ),
  ));

  const resultPrimaryActions = isArray(primaryActions) ? primaryActions : [primaryActions];

  return (
    <>
      <FormProvider {...formApi}>
        <form
          className={clsx(styles.form, className)}
          noValidate
          onSubmit={resultOnSubmit}
          ref={formRef}
        >
          <input
            className={styles.hidenSubmitButton}
            type="submit"
          />
          {isSubmitting && blockPageOnSubmit && (
            <PageBlocker />
          )}
          {isSubmitting && blockFormOnSubmit && (
            <Blocker />
          )}
          {!onlyForm && title && (
            <h2
              className={clsx(
                styles.heading,
                titleClassName,
                styles[`heading__size_${titleSize}`],
              )}
            >
              {title}
            </h2>
          )}
          {renderNotifications()}
          {children}
          {!onlyForm && !hideButtons && (
            <footer className={styles.footer}>
              <ul className={styles.actions}>
                {resultPrimaryActions.length
                  ? resultPrimaryActions.map((primaryAction, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      {primaryAction}
                    </li>
                  ))
                  : (
                    <li>
                      <Button
                        isDisabled={isSubmitting}
                        rawProps={{
                          type: 'submit',
                        }}
                        caption={submitButtonCaption || formatMessage({ id: 'common.save' })}
                        size="42"
                        onClick={() => { }} // to have cursor pointer
                        {...submitButtonProps}
                      />
                    </li>
                  )}
              </ul>
            </footer>
          )}
        </form>
      </FormProvider>
      {shouldBlockPageLeave && (
        <LeavePrompt />
      )}
    </>
  );
};

Form.propTypes = FORM_PROPS;

Form.defaultProps = {
  onSubmit: null,
  children: null,
  validationSchema: null,
  onSubmitFail: null,
  onSubmitSuccess: null,
  getFormApi: null,
  blockPageOnSubmit: false,
  blockFormOnSubmit: false,
  values: null,
  title: null,
  titleSize: FORM_TITLE_SIZES.MD,
  submitButtonCaption: null,
  hideButtons: false,
  onlyForm: false,
  className: '',
  titleClassName: '',
  primaryActions: [],
  resetFormOnSuccessSubmit: false,
  submitButtonProps: {},
  preventLeavingWhenDirty: false,
  onWatch: null,
};

export default Form;
