import React from 'react';
import { useIntl } from 'react-intl';

import PageHeader from '../../PageHeader';
import PageInfo from '../../PageInfo';
import NewIdea from '../../Ideas/NewIdea';

const NewIdeaPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'newIdeaPage.pageTitle' })}
      />
      <PageHeader
        data-testid="pageHeader"
        title={formatMessage({ id: 'newIdeaPage.title' })}
      />
      <NewIdea
        data-testid="newIdea"
      />
    </>
  );
};

export default NewIdeaPage;
