export const SUPER_ADMIN_ROLE = 'SUPER_ADMIN';

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  PORTAL_CONTRIBUTOR: 'PORTAL_CONTRIBUTOR',
};

export const USER_ROLES_TRANSLATION_KEYS = {
  [USER_ROLES.ADMIN]: 'common.role.admin',
  [USER_ROLES.PORTAL_CONTRIBUTOR]: 'common.role.portalContributor',
};
