import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from '../../Modal';
import UpdateUserForm from '../../forms/UpdateUserForm';

const UpdateUserModal = ({
  abort,
  success,
  rowData,
  onSubmit,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [{
    isSubmitting,
    submitForm,
  }, setFormApi] = useState({});

  return (
    <Modal
      title={formatMessage({ id: 'updateUserModal.title' })}
      content={(
        <UpdateUserForm
          defaultValues={rowData}
          getFormApi={setFormApi}
          onSubmitSuccess={success}
          onSubmit={onSubmit}
        />
      )}
      onClose={abort}
      onReject={abort}
      onConfirm={submitForm}
      isLoading={isSubmitting}
      confirmButtonCaption={formatMessage({ id: 'updateUserModal.confirmButtonCaption' })}
      {...props}
    />
  );
};

UpdateUserModal.propTypes = {
  abort: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default UpdateUserModal;
