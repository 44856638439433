import React from 'react';

import Icon, {
  ICON_TYPES,
  ICON_COLOR,
} from '../../../../Icon';

const UpdateIcon = () => (
  <Icon
    type={ICON_TYPES.pencil}
    color={ICON_COLOR.quaternary}
  />
);

export default UpdateIcon;
