import React from 'react';
import { useIntl } from 'react-intl';

import styles from './ArchivedIdeaBar.module.scss';

const ArchivedIdeaBar = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>
        {formatMessage({ id: 'idea.archivedIdeaBar.title' })}
      </h3>
      <p className={styles.description}>
        {formatMessage({ id: 'idea.archivedIdeaBar.description' })}
      </p>
    </div>
  );
};

export default ArchivedIdeaBar;
