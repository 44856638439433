import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import Button from '../Button';
import EverestLogoIcon from './EverestLogoIcon';
import { SSO_AUTH_URL } from './constants';

import styles from './SsoAuthButton.module.scss';

const SsoAuthButton = ({ className }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      cx={clsx(styles.ssoButton, className)}
      captionCX={styles.ssoButtonCaption}
      caption={formatMessage({ id: 'common.ssoAuthButton.label' })}
      icon={EverestLogoIcon}
      size="42"
      href={SSO_AUTH_URL}
    />
  );
};

SsoAuthButton.propTypes = {
  className: PropTypes.string,
};

SsoAuthButton.defaultProps = {
  className: '',
};

export default SsoAuthButton;
