import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';

import { EXPAND_CELL_ACCESSOR } from '../constants';

import styles from './TableBody.module.scss';

const TableBody = ({
  table,
  renderDetailPanel,
}) => (
  <tbody className={styles.tableBody}>
    {table.getRowModel().rows.map((row) => {
      const cells = row.getVisibleCells();

      return (
        <Fragment key={row.id}>
          <tr className={clsx(styles.tableBodyRow, {
            [styles.tableBodyRow__nonExpanded]: !row.getIsExpanded(),
          })}
          >
            {cells.map((cell) => (
              <td
                key={cell.id}
                className={clsx(styles.tableBodyCell, {
                  [styles.tableBodyCell__expander]: cell.column.id === EXPAND_CELL_ACCESSOR,
                })}
              >
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext(),
                )}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr className={styles.expandedRow}>
              <td colSpan={cells.length}>
                {renderDetailPanel({ row })}
              </td>
            </tr>
          )}
        </Fragment>
      );
    })}
  </tbody>
);

TableBody.propTypes = {
  table: PropTypes.object.isRequired,
  renderDetailPanel: PropTypes.func,
};

TableBody.defaultProps = {
  renderDetailPanel: null,
};

export default TableBody;
