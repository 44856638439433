import { scroller } from 'react-scroll';
import * as Sentry from '@sentry/react';
import { isNumber } from 'lodash';

import { BASE_URL } from '../constants/routes';
import { ELEMENTS_IDS } from '../constants/elements';
import { MATOMO_INSTRUCTION_NAMES } from '../constants/matomo';

const USER_TOKEN_KEY = 'userToken';

export const getCurrentYear = () => new Date().getFullYear();

export const setTokenToLocalStorage = (token) => localStorage.setItem(USER_TOKEN_KEY, token);

export const getTokenFromLocalStorage = () => localStorage.getItem(USER_TOKEN_KEY);

export const removeTokenFromLocalStorage = () => localStorage.removeItem(USER_TOKEN_KEY);

export const getUserFullName = (user) => `${user.first_name} ${user.last_name}`;

export const createAuthorizationHeader = (token) => `Bearer ${token}`;

export const getFullUrl = (path) => `${BASE_URL}${path}`;

export const getElement = (selector) => (document.querySelector(selector));

export const waitForElementAppears = (selector, timeout) => (
  new Promise((resolve, reject) => {
    let isFinished = false;

    const resolveElement = () => {
      const element = getElement(selector);

      if (element) {
        resolve(element);
        isFinished = true;
      }
    };

    resolveElement();

    if (isFinished) {
      return;
    }

    const observer = new MutationObserver(() => {
      resolveElement();

      if (isFinished) {
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    if (timeout) {
      setTimeout(() => {
        if (!isFinished) {
          observer.disconnect();
          reject();
        }
      }, timeout);
    }
  })
);

// target is element id
export const scrollTo = async (target, options) => {
  try {
    await waitForElementAppears(`#${target}`, 20000); // wait 20 sec

    scroller.scrollTo(target, {
      smooth: true,
      ignoreCancelEvents: true,
      ...options,
    });
    // eslint-disable-next-line no-empty
  } catch { }
};

export const scrollToWithAppHeaderOffset = (target) => {
  scrollTo(target, {
    offset: -(document.getElementById(ELEMENTS_IDS.appHeader).offsetHeight + 5),
  });
};

export const isProdNodeEnv = () => process.env.NODE_ENV === 'production';

export const isProdEnv = () => process.env.REACT_APP_ENV === 'prod';

export const emitErrorToSentry = (error) => Sentry.captureException(error);

// eslint-disable-next-line no-underscore-dangle
const pushMatomoInstructions = (name, ...args) => window._paq.push([name, ...args]);

export const trackMatomoEvent = (...args) => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.trackEvent,
  ...args,
);

export const trackMatomoGoal = (...args) => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.trackGoal,
  ...args,
);

export const trackMatomoPageView = (...args) => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.trackPageView,
  ...args,
);

export const setMatomoDocumentTitle = (title) => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.setDocumentTitle,
  title,
);

export const setMatomoCustomUrl = (url) => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.setCustomUrl,
  url,
);

export const setMatomoUserId = (userId) => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.setUserId,
  userId,
);

export const enableMatomoHeartBeatTimer = () => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.enableHeartBeatTimer,
);

export const resetMatomoUserId = () => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.resetUserId,
);

export const enableMatomoLinkTracking = () => pushMatomoInstructions(
  MATOMO_INSTRUCTION_NAMES.enableLinkTracking,
);

export { v4 as uuid } from 'uuid';

export const saveFile = (data, fileName) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName;
  link.click();
};

// getRawId helps to get `id` from `hrid`, and returns `id` as is if it was passed in correct format
// 'IDEA-42' => ['IDEA', '42'] => ['42', 'IDEA'] => 42
// '42' => ['42'] => 42
// 42 => 42
export const getRawId = (id) => (
  isNumber(id)
    ? id
    : Number(id.split('-').reverse()[0])
);
