import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Blocker from '../Blocker';

export const PageBlockerContext = React.createContext();

const PageBlockerProvider = ({ children }) => {
  const [subscriptionsAmount, setSubscriptionsAmount] = useState(0);
  const hasSubscriptions = subscriptionsAmount > 0;

  const addSubscription = useCallback(() => {
    setSubscriptionsAmount((previousSubscriptionsAmount) => previousSubscriptionsAmount + 1);
  }, []);

  const removeSubscription = useCallback(() => {
    setSubscriptionsAmount((previousSubscriptionsAmount) => previousSubscriptionsAmount + -1);
  }, []);

  const pageBlockerProviderApi = useMemo(() => ({
    addSubscription,
    removeSubscription,
  }), [
    addSubscription,
    removeSubscription,
  ]);

  return (
    <PageBlockerContext.Provider value={pageBlockerProviderApi}>
      {hasSubscriptions && (
        <Blocker isFullPage />
      )}
      {children}
    </PageBlockerContext.Provider>
  );
};

PageBlockerProvider.propTypes = {
  children: PropTypes.node,
};

PageBlockerProvider.defaultProps = {
  children: null,
};

export default PageBlockerProvider;
