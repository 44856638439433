import React from 'react';
import PropTypes from 'prop-types';

import IdeaDescription from '../../../IdeaDescription';
import {
  IDEA_STATUSES,
  IDEA_TYPES,
  IDEA_INACTIVATE_REASON_TYPES,
} from '../../../../constants/idea';
import ArchivedIdeaDescription from '../../../ArchivedIdeaDescription';

import styles from './IdeaDetails.module.scss';

const IdeaDetails = ({
  idea,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.ideaInfo}>
      {idea.archive && (
        <>
          <ArchivedIdeaDescription
            archive={idea.archive}
          />
          <div className={styles.divider} />
        </>
      )}
      <IdeaDescription
        ideaDetails={idea}
      />
    </div>
  </div>
);

IdeaDetails.propTypes = {
  idea: PropTypes.shape({
    biz_category: PropTypes.object.isRequired,
    idea_type: PropTypes.oneOf(Object.values(IDEA_TYPES)).isRequired,
    reason: PropTypes.string.isRequired,
    status: PropTypes.oneOf(
      Object.values(IDEA_STATUSES),
    ).isRequired,
    archive: PropTypes.shape({
      author: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }).isRequired,
      reason: PropTypes.string.isRequired,
      reason_type: PropTypes.oneOf(
        Object.values(IDEA_INACTIVATE_REASON_TYPES),
      ).isRequired,
    }),
  }).isRequired,
};

export default IdeaDetails;
