import { baseApi } from '../baseApi';
import { API_TAG_TYPES } from './constants';

export const technicalCategoriesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getActiveTechnicalCategories: build.query({
      query: () => ({
        url: '/tech-categories',
        params: {
          is_active: true,
        },
      }),
      providesTags: (result, error) => (
        error ? [] : result.map(({ id }) => ({ type: API_TAG_TYPES.techCategory, id }))
      ),
    }),
    getTechnicalCategories: build.query({
      query: ({ isActive } = {}) => ({
        url: '/tech-categories',
        params: {
          is_active: isActive,
        },
      }),
      providesTags: (result, error) => (
        error ? [] : result.map(({ id }) => ({ type: API_TAG_TYPES.techCategory, id }))
      ),
    }),
    createTechnicalCategory: build.mutation({
      query: (data) => ({
        url: '/tech-categories',
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error) => (
        error ? [] : [API_TAG_TYPES.techCategory]
      ),
    }),
    updateTechnicalCategory: build.mutation({
      query: ({ id, data }) => ({
        url: `/tech-categories/${id}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, { id }) => (
        error ? [] : [{ type: API_TAG_TYPES.techCategory, id }]
      ),
    }),
  }),
});

export const {
  useGetActiveTechnicalCategoriesQuery,
  useGetTechnicalCategoriesQuery,
} = technicalCategoriesApi;
