import React, { memo, useMemo } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import PrimaryLayout from '../PrimaryLayout';
import SecondaryLayout from '../SecondaryLayout';
import UnauthorizedRoute from '../UnauthorizedRoute';
import {
  LoginPage,
  SignUpPage,
  // ForgotPasswordPage,
  // ResetPasswordPage,
  ProfilePage,
  NewIdeaPage,
  IdeaPage,
  AdminPanelPage,
  OnboardingPage,
  // ProposeIdeaSolutionPage,
  // UpdateIdeaSolutionPage,
  IdeasPage,
  UpdateIdeaPage,
  LandingPage,
  WelcomePage,
  SsoAuthPage,
} from '../pages';
import PermissionDenied from '../PermissionDenied';
import PageNotFound from '../PageNotFound';
import ProtectedRoute from '../ProtectedRoute';
import {
  adminPanelPageAccessConditions,
  ideaPageAccessConditions,
  ideasPageAccessConditions,
  newIdeaPageAccessConditions,
  // proposeIdeaSolutionPageAccessConditions,
  updateIdeaPageAccessConditions,
  // updateSolutionPageAccessConditions,
} from './utils';
import { isProdEnv } from '../../utils';

const App = () => {
  const { pathname } = useLocation();
  const isNonProd = useMemo(() => !isProdEnv(), []);

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route
        path={[
          ROUTES.base,
          ROUTES.profile,
          ROUTES.ideas,
          ROUTES.idea,
          ROUTES.createIdea,
          ROUTES.onboarding,
          ROUTES.adminPanel,
          // ROUTES.proposeIdeaSolution,
          ROUTES.updateIdea,
          // ROUTES.updateIdeaSolution,
        ]}
        exact
      >
        <PrimaryLayout>
          <Switch>
            <ProtectedRoute
              exact
              path={ROUTES.base}
              fallback={<LandingPage />}
            >
              <WelcomePage />
            </ProtectedRoute>
            <ProtectedRoute exact path={ROUTES.onboarding}>
              <OnboardingPage />
            </ProtectedRoute>
            <ProtectedRoute exact path={ROUTES.profile}>
              <ProfilePage />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={ROUTES.createIdea}
              conditions={newIdeaPageAccessConditions}
            >
              <NewIdeaPage />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={ROUTES.idea}
              conditions={ideaPageAccessConditions}
            >
              <IdeaPage />
            </ProtectedRoute>
            {/* <ProtectedRoute
              exact
              path={ROUTES.updateIdeaSolution}
              conditions={updateSolutionPageAccessConditions}
            >
              <UpdateIdeaSolutionPage />
            </ProtectedRoute> */}
            <ProtectedRoute
              exact
              path={ROUTES.updateIdea}
              conditions={updateIdeaPageAccessConditions}
            >
              <UpdateIdeaPage />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={ROUTES.adminPanel}
              fallback={<PermissionDenied />}
              conditions={adminPanelPageAccessConditions}
            >
              <AdminPanelPage />
            </ProtectedRoute>
            {/* <ProtectedRoute
              exact
              path={ROUTES.proposeIdeaSolution}
              conditions={proposeIdeaSolutionPageAccessConditions}
            >
              <ProposeIdeaSolutionPage />
            </ProtectedRoute> */}
            <ProtectedRoute
              exact
              path={ROUTES.ideas}
              conditions={ideasPageAccessConditions}
            >
              <IdeasPage />
            </ProtectedRoute>
          </Switch>
        </PrimaryLayout>
      </Route>
      <Route
        path={[
          ...(
            isNonProd
              ? [
                ROUTES.login,
                ROUTES.signUp,
              ]
              : []),
          // ROUTES.forgotPassword,
          // ROUTES.resetPassword,
        ]}
        exact
      >
        <SecondaryLayout>
          <Switch>
            {isNonProd && (
              <>
                <UnauthorizedRoute exact path={ROUTES.login}>
                  <LoginPage />
                </UnauthorizedRoute>
                <UnauthorizedRoute exact path={ROUTES.signUp}>
                  <SignUpPage />
                </UnauthorizedRoute>
              </>
            )}
            {/* <UnauthorizedRoute exact path={ROUTES.forgotPassword}>
              <ForgotPasswordPage />
            </UnauthorizedRoute> */}
            {/* <UnauthorizedRoute exact path={ROUTES.resetPassword}>
              <ResetPasswordPage />
            </UnauthorizedRoute> */}
          </Switch>
        </SecondaryLayout>
      </Route>
      <Route
        path={[ROUTES.ssoAuth]}
      >
        <UnauthorizedRoute exact path={ROUTES.ssoAuth}>
          <SsoAuthPage />
        </UnauthorizedRoute>
      </Route>
      <Route path="*">
        <PrimaryLayout>
          <PageNotFound />
        </PrimaryLayout>
      </Route>
    </Switch>
  );
};

export default memo(App);
