import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import styles from './Logo.module.scss';
import logo from '../../assets/images/logo.svg';

const Logo = ({ className }) => (
  <Link
    data-testid="logoLink"
    to={ROUTES.base}
    className={clsx(styles.logo, className)}
  >
    <img className={styles.img} src={logo} alt="" />
  </Link>
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
