import { flushSync } from 'react-dom';
import { isEmpty } from 'lodash';

import { getUserDataFork } from './getUserDataFork';
import { getActiveEventFork } from './getActiveEventFork';
import { updateAccess } from '../redux/slices/access';
import { setActiveEvent } from '../redux/slices/events';
import { setTokenToLocalStorage } from '../utils';
import { ACTIVE_EVENT_HRID } from '../constants/event';
import history from '../browserHistory';

export const initializeAppFork = ({
  dispatch,
  fork,
  token,
  redirectPath,
}) => async () => {
  const forksResults = await Promise.all([
    fork(getUserDataFork(dispatch, token)).result,
    fork(getActiveEventFork({ dispatch, token, activeEventHrid: ACTIVE_EVENT_HRID })).result,
  ]);

  const forksErrors = {};

  forksResults.forEach((forksResult, index) => {
    if (forksResult.status !== 'ok') {
      forksErrors[index === 0 ? 'getUserDataFork' : 'getActiveEventFork'] = forksResult.error;
    }
  });

  if (!isEmpty(forksErrors)) {
    throw forksErrors;
  }

  flushSync(() => {
    if (token) {
      setTokenToLocalStorage(token);
    }

    dispatch(updateAccess({
      user: forksResults[0].value,
      ...(token ? { token } : {}),
    }));
    dispatch(setActiveEvent(forksResults[1].value));

    if (redirectPath) {
      history.push(redirectPath);
    }
  });
};
