import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const TabsContent = ({ activeTabId, items, className }) => {
  const { Component, componentProps } = useMemo(() => (
    items.find(({ id }) => id === activeTabId)
  ), [items, activeTabId]);

  return (
    <div className={className}>
      <Component {...componentProps} />
    </div>
  );
};

TabsContent.propTypes = {
  activeTabId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      Component: PropTypes.elementType.isRequired,
      componentProps: PropTypes.object,
    }),
  ).isRequired,
  className: PropTypes.string,
};

TabsContent.defaultProps = {
  className: null,
};

export default TabsContent;
