import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ContactUsForm from '../../forms/ContactUsForm';
import Modal from '../../Modal';
import { useListener } from '../../../hooks';
import {
  contactUsListener,
  contactUsListenerCreator,
} from './listeners/contactUsListener';

const ContactUsModal = ({
  abort,
  success,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [{
    isSubmitting,
    submitForm,
  }, setFormApi] = useState({});
  const [contactUs] = useListener({
    listener: contactUsListener,
    actionCreator: contactUsListenerCreator,
  });

  const handleSubmit = useCallback(
    (data) => contactUs({ data }),
    [contactUs],
  );

  return (
    <Modal
      title={formatMessage({ id: 'contactUsModal.title' })}
      content={(
        <ContactUsForm
          getFormApi={setFormApi}
          onSubmitSuccess={success}
          onSubmit={handleSubmit}
        />
      )}
      onReject={abort}
      onClose={abort}
      onConfirm={submitForm}
      isLoading={isSubmitting}
      confirmButtonCaption={formatMessage({ id: 'contactUsModal.submitButtonLabel' })}
      {...props}
    />
  );
};

ContactUsModal.propTypes = {
  abort: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
};

export default ContactUsModal;
