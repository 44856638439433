import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import TextAreaFieldBase from '../../TextAreaField';

const TextAreaField = ({
  name,
  ...props
}) => {
  const {
    field: {
      onChange,
      onBlur,
      value,
    },
    fieldState: { error },
  } = useController({ name });

  return (
    <TextAreaFieldBase
      data-testid="textAreaFieldBase"
      name={name}
      value={value}
      errorMessage={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  );
};

TextAreaField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
};

TextAreaField.defaultProps = {
  label: null,
  placeholder: '',
};

export default TextAreaField;
