import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Badge, { BADGE_COLORS } from '../../../Badge';
import { getDiffInDays } from '../../../../utils';
import {
  IDEAS_TAB_PARAM_NAME,
  IDEAS_BOARD_TAB_IDES,
} from '../constants';
import { useQueryParams } from '../../../../hooks';

const TabTimer = ({
  tabId,
  endDate,
  className,
}) => {
  const { formatMessage } = useIntl();
  const { [IDEAS_TAB_PARAM_NAME]: currentActiveTab } = useQueryParams();

  return (
    <Badge
      caption={formatMessage(
        { id: 'adminPanel.ideasBoard.ideasTabs.tabTimer.label' },
        { daysLeft: getDiffInDays(endDate) },
      )}
      size={18}
      className={className}
      color={
        currentActiveTab === tabId
          ? BADGE_COLORS.BLUE
          : BADGE_COLORS.GRAY_LIGHT
      }
    />
  );
};

TabTimer.propTypes = {
  tabId: PropTypes.oneOf(Object.values(IDEAS_BOARD_TAB_IDES)).isRequired,
  endDate: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TabTimer.defaultProps = {
  className: '',
};

export default TabTimer;
