import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { ROUTES } from '../../../../constants/routes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../redux/api/slices/ideaApi';
import history from '../../../../browserHistory';
import {
  TAB_TYPES,
  TAB_TYPE_URL_PARAM_NAME,
} from '../../IdeasList/constants';

export const createDraftIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formData,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const saveDraftIdea = dispatch(ideaApi.endpoints.createDraftIdea.initiate(
    {
      data: formData,
      eventHrid,
    },
  ));

  try {
    await pause(saveDraftIdea.unwrap());

    history.push({
      pathname: ROUTES.ideas,
      search: qs.stringify({ [TAB_TYPE_URL_PARAM_NAME]: TAB_TYPES.draftIdeas }),
    });

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    saveDraftIdea.unsubscribe();
  }
};

export const createDraftIdeaActionCreator = createAction(ACTION_TYPES.idea.createDraftIdea);
