import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_PROPS } from '../../Form';
import TextField from '../../Form/connected/TextField';
import { FORM_FIELD_NAMES } from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';

import styles from './ResetPasswordForm.module.scss';

const ResetPasswordForm = (props) => {
  const { formatMessage } = useIntl();
  const defaultValues = useMemo(() => ({
    [FORM_FIELD_NAMES.password]: '',
    [FORM_FIELD_NAMES.confirmedPassword]: '',
  }), []);
  const validationSchema = useValidationSchema();

  return (
    <Form
      data-testid="form"
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      blockPageOnSubmit
      title={formatMessage({ id: 'resetPasswordForm.title' })}
      submitButtonCaption={formatMessage({ id: 'resetPasswordForm.submitButton.caption' })}
      className={styles.form}
      {...props}
    >
      <TextField
        data-testid="password"
        name={FORM_FIELD_NAMES.password}
        label={formatMessage({ id: 'resetPasswordForm.password.label' })}
        placeholder={formatMessage({ id: 'resetPasswordForm.password.placeholder' })}
        deps={FORM_FIELD_NAMES.confirmedPassword}
        isRequired
        type="password"
      />
      <TextField
        data-testid="confirmedPassword"
        name={FORM_FIELD_NAMES.confirmedPassword}
        label={formatMessage({ id: 'resetPasswordForm.confirmedPassword.label' })}
        placeholder={formatMessage({ id: 'resetPasswordForm.confirmedPassword.placeholder' })}
        isRequired
        type="password"
      />
    </Form>
  );
};

ResetPasswordForm.propTypes = pick(FORM_PROPS, [
  'onSubmit',
  'onSubmitFail',
  'onSubmitSuccess',
  'getFormApi',
]);

ResetPasswordForm.defaultProps = {
  onSubmitFail: null,
  onSubmitSuccess: null,
  getFormApi: null,
};

export default ResetPasswordForm;
