import {
  useContext,
  useLayoutEffect,
} from 'react';

import { PageBlockerContext } from '../PageBlockerProvider';

const PageBlocker = () => {
  const {
    addSubscription,
    removeSubscription,
  } = useContext(PageBlockerContext);

  useLayoutEffect(() => {
    addSubscription();

    return removeSubscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default PageBlocker;
