import React, {
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
  useState,
} from 'react';
import {
  useParams,
  useHistory,
  generatePath,
} from 'react-router-dom';
import qs from 'qs';
import { useIntl } from 'react-intl';

import Spinner from '../../Spinner';
import { useGetIdeaByIdQuery } from '../../../redux/api/slices/ideaApi';
import IdeaStepper from '../IdeaStepper';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { prepareIdeaStepperData } from './utils';
import { useListener } from '../../../hooks';
import {
  updateIdeaListener,
  updateIdeaActionCreator,
} from './listeners/updateIdeaListener';
import {
  updateDraftIdeaListener,
  updateDraftIdeaActionCreator,
} from './listeners/updateDraftIdeaListener';
import {
  createIdeaFromDraftIdeaActionCreator,
  createIdeaFromDraftIdeaListener,
} from './listeners/createIdeaFromDraftIdeaListener';
import { ROUTES } from '../../../constants/routes';
import { TAB_TYPES, TAB_TYPE_URL_PARAM_NAME } from '../IdeasList/constants';
import { IDEA_STATUSES } from '../../../constants/idea';
import { ACTIVE_EVENT_HRID } from '../../../constants/event';

import styles from './UpdateIdea.module.scss';

const UpdateIdea = () => {
  const { ideaId } = useParams();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [verifiedIdeaData, setVerifiedIdeaData] = useState();
  const [canVerifyIdea, setCanVerifyIdea] = useState(false);

  const [updateIdea] = useListener({
    listener: updateIdeaListener,
    actionCreator: updateIdeaActionCreator,
  });
  const [updateDraftIdea] = useListener({
    listener: updateDraftIdeaListener,
    actionCreator: updateDraftIdeaActionCreator,
  });
  const [createIdeaFromDraftIdea] = useListener({
    listener: createIdeaFromDraftIdeaListener,
    actionCreator: createIdeaFromDraftIdeaActionCreator,
  });
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetIdeaByIdQuery({
    ideaId,
    eventHrid: ACTIVE_EVENT_HRID,
  }, { refetchOnMountOrArgChange: true });

  const isDraftIdea = data?.status === IDEA_STATUSES.DRAFT;
  const defaultValues = useMemo(() => prepareIdeaStepperData(verifiedIdeaData), [verifiedIdeaData]);

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  const handleDiscard = useCallback(() => (
    isDraftIdea
      ? history.push({
        pathname: ROUTES.ideas,
        search: qs.stringify({ [TAB_TYPE_URL_PARAM_NAME]: TAB_TYPES.draftIdeas }),
      })
      : history.push(generatePath(ROUTES.idea, { ideaId }))
  ), [
    history,
    isDraftIdea,
    ideaId,
  ]);

  useLayoutEffect(() => {
    /*
      we use refetchOnMountOrArgChange: true to refetch idea data on mount
      but isIdeaFetching is false on first render. I believe it is rtk-query bug
      https://github.com/reduxjs/redux-toolkit/issues/2349#issuecomment-1375335169

      so we use canVerifyIdea flag to skip logic in this useLayoutEffect on first render
    */
    if (!canVerifyIdea) {
      setCanVerifyIdea(true);
      return;
    }

    if (isFetching || isError) {
      return;
    }

    const { archive } = data;

    if (archive) {
      history.push(ROUTES.base);
      return;
    }

    setVerifiedIdeaData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, data]);

  if (isLoading || (isFetching && !verifiedIdeaData)) {
    return <Spinner />;
  }

  if (isError) {
    return null;
  }

  return (
    <div className={styles.updateIdea}>
      <IdeaStepper
        onDone={isDraftIdea ? createIdeaFromDraftIdea : updateIdea}
        onSaveAsDraft={isDraftIdea ? updateDraftIdea : null}
        defaultValues={defaultValues}
        onDiscard={handleDiscard}
        doneButtonCaption={formatMessage({
          id: 'updateIdea.stepper.submitButtonCaption',
        }, { isDraftIdea })}
      />
    </div>
  );
};

export default UpdateIdea;
