import React, {
  useCallback,
  useState,
  useMemo,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import Input from '../Form/Input';
import SearchIcon from './SearchIcon';
import { useGenerateLink } from '../../hooks';

import styles from './Search.module.scss';

const Search = ({
  defaultValue,
  urlParamName,
  minSearchableLength,
  linkAdditionalProps,
}) => {
  const { formatMessage } = useIntl();
  const { generateLink } = useGenerateLink();
  const [search, setSearch] = useState(defaultValue);
  const history = useHistory();

  const changeUrlParam = useMemo(() => debounce((value) => {
    if (value.length > 0 && value.length < minSearchableLength) {
      return;
    }

    history.push(generateLink({
      [urlParamName]: value || undefined,
      ...linkAdditionalProps,
    }));
  }, 500), [
    history,
    generateLink,
    urlParamName,
    minSearchableLength,
    linkAdditionalProps,
  ]);

  const handleValueChange = useCallback((value) => {
    setSearch(value);
    changeUrlParam(value);
  }, [
    setSearch,
    changeUrlParam,
  ]);

  useLayoutEffect(() => {
    setSearch(defaultValue);
  }, [defaultValue]);

  return (
    <Input
      icon={SearchIcon}
      placeholder={formatMessage({ id: 'common.search.placeholder' })}
      onValueChange={handleValueChange}
      cx={styles.search}
      size="42"
      value={search}
      data-testid="input"
    />
  );
};

Search.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  urlParamName: PropTypes.string.isRequired,
  minSearchableLength: PropTypes.number,
  linkAdditionalProps: PropTypes.object,
};

Search.defaultProps = {
  minSearchableLength: 3,
  linkAdditionalProps: {},
};

export default Search;
