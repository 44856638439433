import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../../Icon';

const DeleteIcon = () => (
  <Icon
    type={ICON_TYPES.trashCan}
    color={ICON_COLOR.inherit}
    size={ICON_SIZE.xs}
  />
);

export default DeleteIcon;
