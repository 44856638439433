import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useUuiContext,
  UuiError,
} from '@epam/uui';
import qs from 'qs';

import { isAxiosError } from '../../redux/api/fetchClient';
import {
  useGenerateLink,
  useListener,
  useModal,
  useIsAbleToCommentIdea,
} from '../../hooks';
import {
  useGetIdeaByIdQuery,
  useLikeIdeaMutation,
} from '../../redux/api/slices/ideaApi';
import Spinner from '../Spinner';
import IdeaHeader from './IdeaHeader';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import IdeaMainInfo from './IdeaMainInfo';
import {
  scrollToWithAppHeaderOffset,
  getUserFullName,
  trackMatomoEvent,
  getRawId,
} from '../../utils';
// import {
//   generateSolutionId,
// } from './utils';
import {
  IDEA_TAB_URL_PARAM_NAME,
  // SOLUTION_ID_URL_PARAM_NAME,
  COMMENTS_ID,
  COMMENTS_DATA_ID,
  // SOLUTIONS_ID,
  DEFAULT_TAB_ID,
  IDEA_TAB_IDS,
} from './constants';
import { userIdSelector } from '../../redux/slices/access';
import { IDEA_STATUSES } from '../../constants/idea';
import IdeaTabsContent from './IdeaTabsContent';
import { useQueryParams } from '../../hooks/useQueryParams';
import Blocker from '../Blocker';
import {
  MATOMO_EVENT_CATEGORIES,
  MATOMO_EVENT_ACTIONS,
} from '../../constants/matomo';
import ArchivedIdeaInfo from './ArchivedIdeaInfo';
import ArchivedIdeaBar from './ArchivedIdeaBar';
import {
  toggleArchiveIdeaListener,
  toggleArchiveIdeaActionCreator,
} from './listeners/toggleArchiveIdeaListener';
import DeleteIdeaConfirmationModal from '../modals/DeleteIdeaConfirmationModal';
import {
  TAB_TYPES,
  TAB_TYPE_URL_PARAM_NAME,
} from '../Ideas/IdeasList/constants';
import { ROUTES } from '../../constants/routes';
import EventProgressBar from './EventProgressBar';
import { ACTIVE_EVENT_HRID, EVENT_STAGES } from '../../constants/event';
import ArchiveIdeaConfirmationModal from '../modals/ArchiveIdeaConfirmationModal';
import {
  voteIdeaActionCreator,
  voteIdeaListener,
} from './listeners/voteIdeaListener';
import { useGetUserVotesMetaQuery } from '../../redux/api/slices/userApi';
import { activeEventActiveStageTypeSelector } from '../../redux/slices/events';
import {
  unvoteIdeaActionCreator,
  unvoteIdeaListener,
} from './listeners/unvoteIdeaListener';

import styles from './Idea.module.scss';

const Idea = () => {
  const { showModal } = useModal();
  const history = useHistory();
  const { generateLink } = useGenerateLink();
  const queryParams = useQueryParams();
  const { [IDEA_TAB_URL_PARAM_NAME]: activeTabId } = queryParams;
  // const activeSolutionId = Number(queryParams[SOLUTION_ID_URL_PARAM_NAME]);
  const { ideaId } = useParams();
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const { uuiErrors } = useUuiContext();
  const {
    data: ideaData,
    isLoadingy: isIdeaLoading,
    isFetching: isIdeaFetching,
    isError: isIdeaError,
    error: ideaError,
  } = useGetIdeaByIdQuery({
    ideaId,
    eventHrid: ACTIVE_EVENT_HRID,
  }, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: userVotesMetaData,
    isLoading: isUserVotesMetaDataLoading,
    isFetching: isUserVotesMetaDataFetching,
    isError: isUserVotesMetaDataError,
    error: userVotesMetaDataError,
  } = useGetUserVotesMetaQuery(
    { eventHrid: ACTIVE_EVENT_HRID },
    {
      refetchOnMountOrArgChange: true,
      skip: activeStageType !== EVENT_STAGES.VOTING,
    },
  );
  const [verifiedIdeaData, setVerifiedIdeaData] = useState();
  const [canVerifyIdea, setCanVerifyIdea] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const resultActiveTabId = useMemo(() => {
    if (!Object.keys(IDEA_TAB_IDS).includes(activeTabId)) {
      return DEFAULT_TAB_ID;
    }

    return activeTabId;
  }, [activeTabId]);
  const currentUserId = useSelector(userIdSelector);
  const isArchivedIdea = !!verifiedIdeaData?.archive;
  const isAbleToCommentIdea = useIsAbleToCommentIdea(verifiedIdeaData);
  const isVoteLimitReached = !!(userVotesMetaData?.voting
    && (
      userVotesMetaData.voting.max_number_of_votes === userVotesMetaData.voting.used_number_of_votes
    ));
  const [likeIdea] = useLikeIdeaMutation();

  const [toggleArchive] = useListener({
    listener: toggleArchiveIdeaListener,
    actionCreator: toggleArchiveIdeaActionCreator,
  });

  const [voteIdea] = useListener({
    listener: voteIdeaListener,
    actionCreator: voteIdeaActionCreator,
  });

  const [unvoteIdea] = useListener({
    listener: unvoteIdeaListener,
    actionCreator: unvoteIdeaActionCreator,
  });

  const scrollToComments = useCallback(() => {
    scrollToWithAppHeaderOffset(COMMENTS_ID);
    scrollToWithAppHeaderOffset(COMMENTS_DATA_ID);
  }, []);

  // const scrollToSolution = useCallback(() => {
  //   scrollToWithAppHeaderOffset(SOLUTIONS_ID);
  //   scrollToWithAppHeaderOffset(generateSolutionId(activeSolutionId));
  // }, [activeSolutionId]);

  useEffect(() => {
    if (activeTabId === IDEA_TAB_IDS.comments) {
      scrollToComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (activeSolutionId && activeTabId === IDEA_TAB_IDS.solutions) {
  //     scrollToSolution();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleComment = useCallback(() => {
    history.push(
      generateLink({ [IDEA_TAB_URL_PARAM_NAME]: IDEA_TAB_IDS.comments }),
    );
    scrollToComments();
  }, [
    history,
    generateLink,
    scrollToComments,
  ]);

  const handleRestore = useCallback(
    async () => {
      setIsProcessing(true);

      try {
        await toggleArchive({ ideaId, eventHrid: ACTIVE_EVENT_HRID, isArchive: false });
      } finally {
        setIsProcessing(false);
      }
    },
    [toggleArchive, ideaId],
  );

  const handleLike = useCallback(async (isLiked) => {
    try {
      await likeIdea({
        ideaId,
        eventHrid: ACTIVE_EVENT_HRID,
        isLiked,
        userId: currentUserId,
      }).unwrap();

      trackMatomoEvent(
        MATOMO_EVENT_CATEGORIES.idea,
        isLiked
          ? MATOMO_EVENT_ACTIONS.likeIdea
          : MATOMO_EVENT_ACTIONS.unlikeIdea,
        getRawId(ideaId),
      );
    } catch (e) {
      isAxiosError(e) && e.handleGlobally();
    }
  }, [ideaId, currentUserId, likeIdea]);

  const handleVote = useCallback(async () => {
    try {
      setIsProcessing(true);

      await voteIdea({
        eventHrid: ACTIVE_EVENT_HRID,
        ideaId,
      });
    } finally {
      setIsProcessing(false);
    }
  }, [voteIdea, ideaId]);

  const handleUnvote = useCallback(async () => {
    try {
      setIsProcessing(true);

      await unvoteIdea({
        eventHrid: ACTIVE_EVENT_HRID,
        ideaId,
      });
    } finally {
      setIsProcessing(false);
    }
  }, [unvoteIdea, ideaId]);

  const showDeleteIdeaConfirmationModal = useCallback(() => showModal((props) => (
    <DeleteIdeaConfirmationModal
      {...props}
      ideaId={ideaId}
      redirectPath={{
        pathname: ROUTES.ideas,
        search: qs.stringify({ [TAB_TYPE_URL_PARAM_NAME]: TAB_TYPES.allIdeas }),
      }}
    />
  )), [
    showModal,
    ideaId,
  ]);

  const showArchiveIdeaConfirmatonModal = useCallback(() => showModal((props) => (
    <ArchiveIdeaConfirmationModal
      {...props}
      ideaId={ideaId}
    />
  )), [
    ideaId,
    showModal,
  ]);

  useLayoutEffect(() => {
    /*
      we use refetchOnMountOrArgChange: true to refetch idea data on mount
      but isFetching is false on first render. I believe it is rtk-query bug
      https://github.com/reduxjs/redux-toolkit/issues/2349#issuecomment-1375335169

      so we use canVerifyIdea flag to skip logic in this useLayoutEffect on first render
    */
    if (!canVerifyIdea) {
      setCanVerifyIdea(true);
      return;
    }

    if (isIdeaFetching || isIdeaError) {
      return;
    }

    const { status } = ideaData;

    if (status === IDEA_STATUSES.DRAFT) {
      uuiErrors.reportError(new UuiError({ status: 404 }));
    }

    setVerifiedIdeaData(ideaData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdeaFetching, ideaData, isIdeaError]);

  useEffect(() => {
    isAxiosError(ideaError) && ideaError.handleGlobally();
  }, [ideaError]);

  useEffect(() => {
    isAxiosError(userVotesMetaDataError) && userVotesMetaDataError.handleGlobally();
  }, [userVotesMetaDataError]);

  const showSpinner = isUserVotesMetaDataLoading
    || isIdeaLoading
    || (isIdeaFetching && !verifiedIdeaData);
  const showBlocker = (!isIdeaLoading && isIdeaFetching && !!verifiedIdeaData)
    || (!isUserVotesMetaDataLoading && isUserVotesMetaDataFetching && !!verifiedIdeaData);

  if (showSpinner) {
    return <Spinner />;
  }

  if (isUserVotesMetaDataError || isIdeaError || !verifiedIdeaData) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs
        className={styles.breadcrumbs}
      />
      <div className={styles.ideaContainer}>
        <IdeaHeader
          className={styles.ideaHeader}
          idea={verifiedIdeaData}
          onDelete={showDeleteIdeaConfirmationModal}
          onArchive={showArchiveIdeaConfirmatonModal}
          onComment={handleComment}
          onRestore={handleRestore}
          onVote={handleVote}
          onUnvote={handleUnvote}
          isVoteLimitReached={isVoteLimitReached}
        />
        <div className={styles.ideaContent}>
          {isArchivedIdea && (
            <ArchivedIdeaInfo
              archive={verifiedIdeaData.archive}
            />
          )}
          <IdeaMainInfo
            ideaDataProps={{
              idea: verifiedIdeaData,
            }}
            likesAndCommentsProps={{
              idea: verifiedIdeaData,
              onLike: handleLike,
              onComment: handleComment,
            }}
            ideaTabsProps={{
              activeTabId: resultActiveTabId,
            }}
          />
          <IdeaTabsContent
            activeTabId={resultActiveTabId}
            links={verifiedIdeaData.links}
            author={getUserFullName(verifiedIdeaData.author)}
            // activeSolutionId={activeSolutionId}
            // generateSolutionId={generateSolutionId}
            // solutionsId={SOLUTIONS_ID}
            isAbleToCommentIdea={isAbleToCommentIdea}
          />
        </div>
        <aside className={styles.ideaSidebar}>
          {
            isArchivedIdea
              ? (
                <ArchivedIdeaBar />
              )
              : (
                <EventProgressBar />
              )
          }
        </aside>
        {(showBlocker || isProcessing) && <Blocker />}
      </div>
    </div>
  );
};

export default Idea;
