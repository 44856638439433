import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ProgressBar from '../../ProgressBar';
import { stages } from './constants';
import { activeEventStatusSelector, activeEventActiveStageTypeSelector } from '../../../redux/slices/events';
import { EVENT_STATUSES } from '../../../constants/event';

import styles from './EventProgressBar.module.scss';

const EventProgressBar = () => {
  const { formatMessage } = useIntl();

  const eventStatus = useSelector(activeEventStatusSelector);
  const eventActiveStageType = useSelector(activeEventActiveStageTypeSelector);

  return (
    <ProgressBar
      className={styles.eventProgressBar}
      title={formatMessage({ id: 'event.progressBar.title' })}
      stages={stages}
      activeStageId={eventActiveStageType}
      isCompleted={eventStatus === EVENT_STATUSES.FINISHED}
      data-testid="progressBar"
    />
  );
};

export default EventProgressBar;
