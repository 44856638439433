import React from 'react';
import {
  MainMenu as BaseMainMenu,
  FlexSpacer,
  MainMenuButton,
} from '@epam/promo';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import LogoIcon from '../../assets/images/logo.svg';
import Settings from './Settings';
import { ROUTES } from '../../constants/routes';
import { ELEMENTS_IDS } from '../../constants/elements';
import HasAccess from '../HasAccess';
import EventTimer from '../EventTimer';
import {
  adminPanelCondition,
  eventTimerCondition,
  ideasCatalogCondition,
} from './utils';
import { CATALOG_LABEL_TRANSLATION_KEY_BY_ACTIVE_STAGE } from './constants';
import {
  activeEventActiveStageTypeSelector,
  activeEventStatusSelector,
} from '../../redux/slices/events';
import { EVENT_STATUSES } from '../../constants/event';

const MainMenu = () => {
  const { formatMessage } = useIntl();
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const activeEventStatus = useSelector(activeEventStatusSelector);

  return (
    <BaseMainMenu
      data-testid="baseMainMenu"
      appLogoUrl={LogoIcon}
      logoLink={{ pathname: ROUTES.base }}
      rawProps={{
        id: ELEMENTS_IDS.appHeader,
      }}
    >
      <HasAccess>
        <MainMenuButton
          link={{ pathname: ROUTES.base }}
          caption={formatMessage({ id: 'mainMenu.items.welcome' })}
        />
      </HasAccess>
      <HasAccess
        conditions={adminPanelCondition}
      >
        <MainMenuButton
          link={{ pathname: ROUTES.adminPanel }}
          caption={formatMessage({ id: 'mainMenu.items.admin' })}
        />
      </HasAccess>
      <HasAccess
        conditions={ideasCatalogCondition}
      >
        <MainMenuButton
          link={{ pathname: ROUTES.ideas }}
          caption={(
            <FormattedMessage
              id={activeEventStatus === EVENT_STATUSES.FINISHED
                ? 'mainMenu.items.winners'
                : CATALOG_LABEL_TRANSLATION_KEY_BY_ACTIVE_STAGE[activeStageType]}
            />
          )}
        />
      </HasAccess>
      <FlexSpacer
        data-testid="flexSpacer"
        priority={1000}
      />
      <HasAccess
        conditions={eventTimerCondition}
      >
        <EventTimer />
      </HasAccess>
      <Settings data-testid="settings" />
    </BaseMainMenu>
  );
};

export default MainMenu;
