import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { useGenerateLink } from '../../hooks';
import FiltersForm from '../forms/FiltersForm';
import FiltersReport from './FiltersReport';

import styles from './Filters.module.scss';

const Filters = ({
  className,
  reportLabel,
  hasActiveFilters,
  urlParamName,
  onFiltersClear,
  linkAdditionalProps,
  ...props
}) => {
  const { generateLink } = useGenerateLink();
  const history = useHistory();

  const handleFilterChange = useCallback((values) => {
    history.push(generateLink({
      [urlParamName]: values,
      ...linkAdditionalProps,
    }));
  }, [
    history,
    generateLink,
    urlParamName,
    linkAdditionalProps,
  ]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.content}>
        <FiltersForm
          data-testid="filtersForm"
          onFilterChange={handleFilterChange}
          {...props}
        />
      </div>
      <FiltersReport
        data-testid="filtersReport"
        urlParamName={urlParamName}
        label={reportLabel}
        hasActiveFilters={hasActiveFilters}
        className={styles.filtersReport}
        onFiltersClear={onFiltersClear}
      />
    </div>
  );
};

Filters.propTypes = {
  className: PropTypes.string,
  urlParamName: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  reportLabel: PropTypes.string.isRequired,
  hasActiveFilters: PropTypes.bool.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  linkAdditionalProps: PropTypes.object,
};

Filters.defaultProps = {
  className: '',
  linkAdditionalProps: {},
};

export default Filters;
