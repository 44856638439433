import React from 'react';
import { useIntl } from 'react-intl';

import PageHeader from '../../PageHeader';
import PageInfo from '../../PageInfo';
import UpdateIdea from '../../Ideas/UpdateIdea';

const UpdateIdeaPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'updateIdeaPage.pageTitle' })}
      />
      <PageHeader
        data-testid="pageHeader"
        title={formatMessage({ id: 'updateIdeaPage.title' })}
      />
      <UpdateIdea data-testid="updateIdea" />
    </>
  );
};

export default UpdateIdeaPage;
