import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ICON_TYPES } from '../../../Icon';
import EmptyContent from '../../../EmptyContent';
import { useGenerateLink } from '../../../../hooks';
import {
  TAB_TYPES,
  TAB_TYPE_URL_PARAM_NAME,
  PAGE_URL_PARAM_NAME,
} from '../constants';
import { activeEventActiveStageTypeSelector } from '../../../../redux/slices/events';
import {
  NO_LIKED_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE,
  NO_LIKED_IDEAS_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE,
} from './constants';
import { EVENT_STAGES } from '../../../../constants/event';

const NoLikedIdeas = () => {
  const { formatMessage } = useIntl();
  const { generateLink } = useGenerateLink();
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.like,
      }}
      title={formatMessage(
        { id: NO_LIKED_IDEAS_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE[activeStageType] },
      )}
      description={formatMessage(
        { id: NO_LIKED_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE[activeStageType] },
      )}
      buttonProps={
        activeStageType === EVENT_STAGES.IDEA_SUBMISSION
          ? {
            link: generateLink({
              [TAB_TYPE_URL_PARAM_NAME]: TAB_TYPES.allIdeas,
              [PAGE_URL_PARAM_NAME]: 1,
            }),
            caption: formatMessage({ id: 'ideas.noLikedIdeas.buttonLabel' }),
          }
          : null
      }
    />
  );
};

export default NoLikedIdeas;
