import React from 'react';

import PeopleList from '../../PeopleList';

import styles from './PeopleBoard.module.scss';

const PeopleBoard = () => (
  <div className={styles.wrapper}>
    <PeopleList />
  </div>
);

export default PeopleBoard;
