import React from 'react';
import { useIntl } from 'react-intl';

import PageInfo from '../../PageInfo';
import AdminPanel from '../../AdminPanel';

const AdminPanelPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'adminPanelPage.pageTitle' })}
      />
      <AdminPanel data-testid="adminPanel" />
    </>
  );
};

export default AdminPanelPage;
