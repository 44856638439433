import { useLayoutEffect } from 'react';
import { useUuiContext, UuiError } from '@epam/uui';

const PageNotFound = () => {
  const { uuiErrors } = useUuiContext();

  useLayoutEffect(() => {
    uuiErrors.reportError(new UuiError({ status: 404 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default PageNotFound;
