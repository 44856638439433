import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import HasAccess from '../HasAccess';
import { ROUTES } from '../../constants/routes';

const ProtectedRoute = ({
  fallback,
  path,
  exact,
  conditions,
  children,
}) => (
  <Route
    data-testid="route"
    exact={exact}
    path={path}
  >
    <HasAccess
      data-testid="hasAcccess"
      fallback={fallback || (
        <Redirect
          data-testid="redirect"
          to={ROUTES.base}
        />
      )}
      conditions={conditions}
    >
      {children}
    </HasAccess>
  </Route>
);

ProtectedRoute.propTypes = {
  fallback: PropTypes.node,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  conditions: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ProtectedRoute.defaultProps = {
  fallback: null,
  exact: false,
  conditions: undefined,
};

export default ProtectedRoute;
