import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';
import { useSelector } from 'react-redux';

import Form, { FORM_PROPS } from '../../Form';
import { FORM_FIELD_NAMES } from './constants';
import TextField from '../../Form/connected/TextField';
import PickerField from '../../Form/connected/PickerField';
import { USER_ROLES } from '../../../constants/roles';
import {
  SEARCH_POSITION,
  SELECTION_MODE,
} from '../../Form/PickerField/PickerField';
import { userIdSelector } from '../../../redux/slices/access';
import { useValidationSchema } from './hooks/useValidationSchema';

const UpdateUserForm = ({
  defaultValues,
  onSubmit,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const currentUserId = useSelector(userIdSelector);
  const { id: userId } = defaultValues;
  const validationSchema = useValidationSchema();
  const isAbleToChangeRole = userId !== currentUserId;

  const roleItems = useMemo(() => ([{
    label: formatMessage({ id: 'common.role.portalContributor' }),
    id: USER_ROLES.PORTAL_CONTRIBUTOR,
  }, {
    label: formatMessage({ id: 'common.role.admin' }),
    id: USER_ROLES.ADMIN,
  }]), [formatMessage]);

  const resultDefaultValues = useMemo(
    () => ({
      ...pick(defaultValues, [
        FORM_FIELD_NAMES.FIRST_NAME,
        FORM_FIELD_NAMES.LAST_NAME,
        FORM_FIELD_NAMES.EMAIL,
        FORM_FIELD_NAMES.ROLE,
      ]),
    }),
    [defaultValues],
  );

  const handleSubmit = useCallback((formValues) => (onSubmit({
    ...formValues,
    id: userId,
  })), [
    onSubmit,
    userId,
  ]);

  return (
    <Form
      defaultValues={resultDefaultValues}
      validationSchema={validationSchema}
      hideButtons
      onSubmit={handleSubmit}
      {...rest}
    >
      <TextField
        name={FORM_FIELD_NAMES.FIRST_NAME}
        label={formatMessage({ id: 'updateUserForm.firstNameField.label' })}
        isRequired
      />
      <TextField
        name={FORM_FIELD_NAMES.LAST_NAME}
        label={formatMessage({ id: 'updateUserForm.lastNameField.label' })}
        isRequired
      />
      <TextField
        name={FORM_FIELD_NAMES.EMAIL}
        label={formatMessage({ id: 'updateUserForm.emailField.label' })}
        isReadonly
      />
      <PickerField
        name={FORM_FIELD_NAMES.ROLE}
        label={formatMessage({ id: 'updateUserForm.roleField.label' })}
        items={roleItems}
        selectionMode={SELECTION_MODE.SINGLE}
        searchPosition={SEARCH_POSITION.NONE}
        isReadonly={!isAbleToChangeRole}
        sorting={{}}
        disableClear
        isRequired
      />
    </Form>
  );
};

UpdateUserForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
  ]),
  defaultValues: PropTypes.shape({
    id: PropTypes.number.isRequired,
    [FORM_FIELD_NAMES.FIRST_NAME]: PropTypes.string.isRequired,
    [FORM_FIELD_NAMES.LAST_NAME]: PropTypes.string.isRequired,
    [FORM_FIELD_NAMES.EMAIL]: PropTypes.string.isRequired,
    [FORM_FIELD_NAMES.ROLE]: PropTypes.oneOf(Object.values(USER_ROLES)).isRequired,
  }).isRequired,
};

export default UpdateUserForm;
