import React, { useEffect } from 'react';
import { ACTIVE_EVENT_HRID } from '../../constants/event';
import { isAxiosError } from '../../redux/api/fetchClient';
import { useGetIdeasQuery } from '../../redux/api/slices/ideaApi';

import Banner from './Banner';
import Spinner from '../Spinner';
import EventStats from '../EventStats';

const WinnerWelcome = () => {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetIdeasQuery({
    eventHrid: ACTIVE_EVENT_HRID,
    isArchive: false,
  });

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <Spinner data-testid="spinner" />;
  }

  return (
    <>
      <Banner
        data-testid="banner"
        winners={data.items}
      />
      <EventStats data-testid="eventStats" />
    </>
  );
};

export default WinnerWelcome;
