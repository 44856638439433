import React from 'react';
import PropTypes from 'prop-types';
import { useArrayDataSource } from '@epam/uui';

import PickerInput from '../PickerInput';
import Label from '../Label';

export const SELECTION_MODE = {
  MULTI: 'multi',
  SINGLE: 'single',
};

export const SEARCH_POSITION = {
  INPUT: 'input',
  BODY: 'body',
  NONE: 'none',
};

export const getName = (item) => item.label;

const PickerField = ({
  label,
  labelClassName,
  tooltip,
  errorMessage,
  onChange,
  isRequired,
  items,
  footer,
  sorting,
  ...props
}) => {
  const dataSource = useArrayDataSource({ items }, []);

  return (
    <Label
      data-testid="label"
      label={label}
      isInvalid={!!errorMessage}
      validationMessage={errorMessage}
      info={tooltip}
      isRequired={isRequired}
      footer={footer}
      className={labelClassName}
    >
      <PickerInput
        data-testid="pickerInputBase"
        isInvalid={!!errorMessage}
        onValueChange={onChange}
        dataSource={dataSource}
        size="42"
        getName={getName}
        sorting={sorting}
        emptyValue={[]}
        {...props}
      />
    </Label>
  );
};

PickerField.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
  selectionMode: PropTypes.oneOf(Object.values(SELECTION_MODE)),
  searchPosition: PropTypes.oneOf(Object.values(SEARCH_POSITION)),
  isSingleLine: PropTypes.bool,
  footer: PropTypes.node,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
    PropTypes.string,
  ]),
  sorting: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }),
};

PickerField.defaultProps = {
  name: null,
  tooltip: null,
  label: null,
  labelClassName: '',
  placeholder: '',
  errorMessage: '',
  isRequired: false,
  selectionMode: SELECTION_MODE.MULTI,
  searchPosition: SEARCH_POSITION.BODY,
  isSingleLine: true,
  items: [],
  footer: null,
  value: null,
  sorting: { field: 'label', direction: 'asc' },
  onBlur: null,
};

export default PickerField;
