import React from 'react';
import { useIntl } from 'react-intl';

import Animation from '../../Animation';
import winnerSelectionAnimationData from './animations/winnerSelection.json';
import {
  EVENT_STAGES,
  EVENT_STAGES_ORDER,
} from '../../../constants/event';
import StageBadge from '../../StageBadge';

import styles from './Banner.module.scss';

const Banner = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <StageBadge
          data-testid="stageBadge"
          stageNumber={EVENT_STAGES_ORDER[EVENT_STAGES.ANNOUNCE_WINNER]}
        />
        <h2 className={styles.header}>
          {formatMessage({ id: 'winnerAnnouncementWelcome.banner.header' })}
        </h2>
        <div className={styles.description}>
          {formatMessage({ id: 'winnerAnnouncementWelcome.banner.description' })}
        </div>
        <Animation
          data-testid="winnerSelection"
          className={styles.animation}
          animationData={winnerSelectionAnimationData}
        />
      </div>
    </div>
  );
};

export default Banner;
