import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import qs from 'qs';

import Card, { CARD_TYPES } from '../Card';
import { ROUTES } from '../../../constants/routes';
import {
  FILTERING_TYPES,
  SORTING_TYPES,
  FILTERS_URL_PARAM_NAME,
  SORTING_TYPE_URL_PARAM_NAME,
} from '../../Ideas/IdeasList';

const BizCategory = ({
  CardProps,
  bizCategory: {
    id,
    name,
  },
}) => {
  const { formatMessage } = useIntl();

  return (
    <Card
      {...CardProps}
      title={name}
      description={formatMessage({ id: 'topIdeas.bizCategory.description' })}
      LinkButtonProps={{
        caption: formatMessage({ id: 'topIdeas.bizCategory.link' }),
        link: {
          pathname: ROUTES.ideas,
          search: qs.stringify({
            [FILTERS_URL_PARAM_NAME]: {
              [FILTERING_TYPES.BIZ_OBJECTIVE]: [id],
            },
            [SORTING_TYPE_URL_PARAM_NAME]: SORTING_TYPES.likesAndComments,
          }),
        },
      }}
    />
  );
};

BizCategory.propTypes = {
  CardProps: PropTypes.shape({
    type: PropTypes.oneOf(
      Object.values(CARD_TYPES),
    ).isRequired,
  }).isRequired,
  bizCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default BizCategory;
