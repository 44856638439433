import { EVENT_STAGES } from '../../../../../constants/event';
import { ICON_TYPES } from '../../../../Icon';

export const NO_IDEAS_ICON_TYPE_BY_ACTIVE_STAGE_TYPE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: ICON_TYPES.editorList,
  [EVENT_STAGES.DOWN_SELECTION]: ICON_TYPES.folder,
  [EVENT_STAGES.VOTING]: ICON_TYPES.folder,
  [EVENT_STAGES.ANNOUNCE_WINNER]: ICON_TYPES.folder,
};

export const NO_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'adminPanel.ideasBoard.ideasTotalTable.noIdeas.ideaSubmission.description',
  [EVENT_STAGES.DOWN_SELECTION]: 'adminPanel.ideasBoard.ideasTotalTable.noIdeas.description',
  [EVENT_STAGES.VOTING]: 'adminPanel.ideasBoard.ideasTotalTable.noIdeas.description',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'adminPanel.ideasBoard.ideasTotalTable.noIdeas.description',
};
