import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { generatePath } from 'react-router-dom';
import IconButton from '../IconButton';
import LikeIcon from './LikeIcon';
import { ROUTES } from '../../constants/routes';

import styles from './LikeButton.module.scss';

const LikeButton = ({
  ideaId,
  likes,
  onLike,
  isLiked,
  isDisabled,
}) => {
  const isInactive = (!!onLike && isDisabled);

  return (
    <div
      className={
        clsx(styles.likeButton, {
          [styles.likeButton__liked]: isLiked,
          [styles.likeButton__inactive]: isInactive,
          [styles.likeButton__disabled]: isDisabled,
        })
      }
    >
      <IconButton
        data-testid="iconButton"
        icon={LikeIcon}
        onClick={() => onLike?.(!isLiked)}
        cx={styles.button}
        isDisabled={isInactive}
        {...(ideaId ? {
          link: { pathname: generatePath(ROUTES.idea, { ideaId }) },
        } : {})}
      />
      <span className={styles.number}>
        {likes}
      </span>
    </div>
  );
};

LikeButton.propTypes = {
  ideaId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  likes: PropTypes.number.isRequired,
  onLike: PropTypes.func,
  isLiked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

LikeButton.defaultProps = {
  ideaId: null,
  onLike: null,
  isDisabled: false,
};

export default LikeButton;
