import { useLocation } from 'react-router-dom';
import qs from 'qs';

let lastSearchString;
let lastCache;

export const useQueryParams = () => {
  const { search } = useLocation();

  if (lastSearchString === search) {
    return lastCache;
  }

  const paramsString = search.slice(1);

  lastSearchString = search;
  lastCache = qs.parse(paramsString, { arrayLimit: 100 });

  return lastCache;
};
