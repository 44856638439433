import React from 'react';
import { useIntl } from 'react-intl';

import PageInfo from '../../PageInfo';
import PageHeader from '../../PageHeader';
import Profile from '../../Profile';

const ProfilePage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'profilePage.pageTitle' })}
      />
      <PageHeader
        data-testid="pageHeader"
        title={formatMessage({ id: 'profilePage.title' })}
        description={formatMessage({ id: 'profilePage.description' })}
      />
      <Profile data-testid="profile" />
    </>
  );
};

export default ProfilePage;
