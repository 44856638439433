import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_TYPES,
} from '../../../../Icon';

const RestoreIcon = () => (
  <Icon
    type={ICON_TYPES.restore}
    color={ICON_COLOR.quaternary}
    data-testid="restoreIcon"
  />
);

export default RestoreIcon;
