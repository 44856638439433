import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';
import Badge, {
  BADGE_COLORS,
} from '../Badge';
import Breadcrumbs from '../Breadcrumbs';

import styles from './PageHeader.module.scss';

const PageHeader = ({
  showBreadcrumbs,
  title,
  description,
  status,
  subContent,
}) => (
  <header className={styles.pageHeader}>
    <div className={styles.content}>
      {showBreadcrumbs && (
        <Breadcrumbs
          data-testid="breadcrumbs"
          className={styles.breadcrumbs}
        />
      )}
      <div className={styles.mainInfo}>
        <div className={styles.textInfo}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>
              {title}
            </h1>
            {status && (
              <Tooltip
                data-testid="tooltip"
                content={status}
              >
                <Badge
                  data-testid="badge"
                  caption={status}
                  color={BADGE_COLORS.GRAY_DARK}
                  size={24}
                  className={styles.status}
                  ellipsis
                />
              </Tooltip>
            )}
          </div>
          {description && (
            <p className={styles.description}>
              {description}
            </p>
          )}
        </div>
        {subContent}
      </div>
    </div>
  </header>
);

PageHeader.propTypes = {
  showBreadcrumbs: PropTypes.bool,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  status: PropTypes.string,
  subContent: PropTypes.node,
};

PageHeader.defaultProps = {
  showBreadcrumbs: true,
  description: null,
  status: null,
  subContent: null,
};

export default PageHeader;
