import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CategoryItemMenu from './CategoryItemMenu';
import CategoryItemContent from './CategoryItemContent';

import styles from './CategoryItem.module.scss';

const CategoryItem = ({
  category,
  onToggleAvailability,
  onUpdate,
  updateButtonLabel,
  deactivateButtonLabel,
  activateButtonLabel,
}) => {
  const {
    name,
    description,
    is_active: isActive,
  } = category;

  const handleUpdate = useCallback(
    () => onUpdate(category),
    [category, onUpdate],
  );

  const handleToggleAvailability = useCallback(
    () => onToggleAvailability(category),
    [onToggleAvailability, category],
  );

  return (
    <div className={clsx(
      styles.wrapper,
      { [styles.wrapper__active]: isActive, [styles.wrapper__inactive]: !isActive },
    )}
    >
      <CategoryItemContent
        data-testid="categoryItemContent"
        name={name}
        description={description}
        className={styles.content}
      />
      <CategoryItemMenu
        data-testid="categoryItemMenu"
        onUpdate={handleUpdate}
        onToggleAvailability={handleToggleAvailability}
        className={styles.menu}
        updateButtonLabel={updateButtonLabel}
        deactivateButtonLabel={deactivateButtonLabel}
        activateButtonLabel={activateButtonLabel}
        isActive={isActive}
      />
    </div>
  );
};

CategoryItem.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    is_active: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleAvailability: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  deactivateButtonLabel: PropTypes.string.isRequired,
  activateButtonLabel: PropTypes.string.isRequired,
};

export default CategoryItem;
