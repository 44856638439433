import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '../../Button';
import TrashCanIcon from './TrashCanIcon';
import PencilIcon from './PencilIcon';
import ReplyIcon from './ReplyIcon';
import CommentForm, { COMMENT_FORM_ACTION_TYPES } from '../../forms/CommentForm';
import { formatDate, getUserFullName } from '../../../utils';
import Blocker from '../../Blocker';
import DeleteCommentConfirmationModal from '../../modals/DeleteCommentConfirmationModal';
import { useModal } from '../../../hooks';

import styles from './Comment.module.scss';

const Comment = ({
  comment,
  currentUser,
  parentCommentId,
  canDeleteAnothersComments,
  canUpdateAnothersComments,
  onDelete,
  onUpdate,
  onReply,
  isReadOnly,
}) => {
  const { showModal } = useModal();
  const { id: currentUserId } = currentUser;
  const { formatMessage } = useIntl();
  const [isReplied, setIsReplied] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const {
    author,
    data,
    created_at: date,
    reply_to: inReplyTo,
    id: commentId,
  } = comment;
  const isCurrentUserComment = currentUserId === author.id;

  const handleReplyButtonClick = useCallback(() => (
    setIsReplied(true)
  ), []);

  const handleCancelReply = useCallback(() => (
    setIsReplied(false)
  ), []);

  const handleReplySubmit = useCallback((formData) => (
    onReply(
      formData,
      parentCommentId || commentId,
      author.id,
    )
  ), [
    author.id,
    commentId,
    parentCommentId,
    onReply,
  ]);

  const handleReplySuccessSubmit = useCallback(() => setIsReplied(false), []);

  const handleUpdate = useCallback(() => {
    setIsUpdated(true);
    setIsReplied(false);
  }, []);

  const handleCancelUpdate = useCallback(() => setIsUpdated(false), []);

  const handleUpdateSuccessSubmit = useCallback(() => setIsUpdated(false), []);

  const handleUpdateSubmit = useCallback(async ({ comment: message }) => {
    await onUpdate({ commentId, comment: message, parentCommentId });
  }, [onUpdate, parentCommentId, commentId]);

  const handleDeleteFail = useCallback(() => setIsDeleted(false), []);

  const handleDeleteConfirm = useCallback((success) => {
    setIsDeleted(true);
    setIsReplied(false);

    onDelete(
      commentId,
      handleDeleteFail,
      parentCommentId,
    );

    success();
  }, [
    onDelete,
    commentId,
    handleDeleteFail,
    parentCommentId,
  ]);

  const showDeleteCommentConfirmationModal = useCallback(() => showModal(
    ({ success, ...props }) => (
      <DeleteCommentConfirmationModal
        {...props}
        success={() => handleDeleteConfirm(success)}
      />
    ),
  ), [
    showModal,
    handleDeleteConfirm,
  ]);

  useEffect(() => {
    setIsReplied(false);
    setIsUpdated(false);
  }, [isReadOnly]);

  return (
    <>
      {isUpdated && !isReadOnly
        ? (
          <div
            className={
              clsx(
                styles.update,
                {
                  [styles.update__subcomment]: !!parentCommentId,
                  [styles.update__currentUser]: isCurrentUserComment,
                },
              )
            }
          >
            <CommentForm
              author={getUserFullName(author)}
              actionType={COMMENT_FORM_ACTION_TYPES.UPDATE}
              onCancel={handleCancelUpdate}
              onSubmit={handleUpdateSubmit}
              onSubmitSuccess={handleUpdateSuccessSubmit}
              defaultValue={data}
              className={styles.updateForm}
            />
          </div>
        )
        : (
          <div
            className={
              clsx(
                styles.comment,
                {
                  [styles.comment__currentUser]: isCurrentUserComment,
                  [styles.comment__subcomment]: !!parentCommentId,
                },
              )
            }
          >
            {isDeleted && <Blocker />}
            <div className={styles.heading}>
              <span className={styles.author}>{getUserFullName(author)}</span>
              {inReplyTo && (
                <span className={styles.inReplyTo}>
                  <FormattedMessage
                    id="comments.comment.inReplyTo"
                    values={{ name: getUserFullName(inReplyTo) }}
                  />
                </span>
              )}
            </div>
            <pre className={styles.content}>
              {data}
            </pre>
            <div className={styles.footer}>
              <div className={styles.date}>{formatDate(date)}</div>
              {!isReadOnly && (
                <div className={styles.actions}>
                  {(isCurrentUserComment || canDeleteAnothersComments) && (
                  <Button
                    icon={TrashCanIcon}
                    color="gray50"
                    fill="light"
                    caption={formatMessage({ id: 'common.delete' })}
                    onClick={showDeleteCommentConfirmationModal}
                    cx={styles.action}
                    captionCX={styles.buttonCaption}
                  />
                  )}
                  {(isCurrentUserComment || canUpdateAnothersComments) && (
                  <Button
                    icon={PencilIcon}
                    color="gray50"
                    fill="light"
                    caption={formatMessage({ id: 'common.edit' })}
                    onClick={handleUpdate}
                    cx={styles.action}
                    captionCX={styles.buttonCaption}
                  />
                  )}
                  <Button
                    icon={ReplyIcon}
                    color="gray50"
                    fill="light"
                    caption={formatMessage({ id: 'common.reply' })}
                    onClick={handleReplyButtonClick}
                    cx={styles.action}
                    captionCX={styles.buttonCaption}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      {isReplied && !isReadOnly && (
        <div
          className={
              clsx(
                styles.reply,
                {
                  [styles.reply__subcomment]: !!parentCommentId,
                },
              )
            }
        >
          <CommentForm
            actionType={COMMENT_FORM_ACTION_TYPES.REPLY}
            onCancel={handleCancelReply}
            onSubmit={handleReplySubmit}
            onSubmitSuccess={handleReplySuccessSubmit}
            author={getUserFullName(currentUser)}
            className={styles.replyForm}
          />
        </div>
      )}
    </>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape({
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    data: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    reply_to: PropTypes.object,
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  parentCommentId: PropTypes.number,
  canDeleteAnothersComments: PropTypes.bool,
  canUpdateAnothersComments: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

Comment.defaultProps = {
  parentCommentId: null,
  canDeleteAnothersComments: false,
  canUpdateAnothersComments: false,
  isReadOnly: false,
};

export default Comment;
