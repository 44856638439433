import * as yup from 'yup';
import { LATIN_EMAIL_REGEX } from './constants/validation';

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'emailLatin', function (message) {
  return this.matches(LATIN_EMAIL_REGEX, { message });
});

export default yup;
export { ValidationError } from 'yup';
