import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import styles from './MultiColumnContent.module.scss';

const MultiColumnContent = ({ columns, className }) => (
  <div className={clsx(styles.multiColumnContent, className)}>
    {columns.map(({
      header,
      content,
      footer,
      className: columnClassName,
      isContentCentered,
    }, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={clsx(styles.column, columnClassName)}
      >
        {header && (
          <header className={styles.columnHeader}>
            {header}
          </header>
        )}
        <div
          className={
            clsx(
              styles.columnContent,
              { [styles.columnContent__centered]: isContentCentered },
            )
          }
        >
          {content}
        </div>
        {footer && (
          <footer className={styles.columnFooter}>
            {footer}
          </footer>
        )}
      </div>
    ))}
  </div>
);

MultiColumnContent.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node,
      content: PropTypes.node.isRequired,
      footer: PropTypes.node,
      className: PropTypes.string,
      isContentCentered: PropTypes.bool,
    }),
  ).isRequired,
  className: PropTypes.string,
};

MultiColumnContent.defaultProps = {
  className: '',
};

export default MultiColumnContent;
