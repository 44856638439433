export const FILTERS_URL_PARAM_NAME = 'votingIdeasFilters';
export const PAGE_URL_PARAM_NAME = 'votingIdeasPage';
export const SORTING_URL_PARAM_NAME = 'votingIdeasSorting';
export const TABLE_ID = 'votingIdeasTable';

export const ACCESSOR_KEYS = {
  IDEA_TITLE: 'title',
  VOTES: 'votes',
  ACTIONS: 'actions',
};
