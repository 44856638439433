import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import LinkButton from '../../LinkButton';
import Tooltip from '../../Tooltip';
import NavigationIcon from './NavigationIcon';

import styles from './Card.module.scss';

export const CARD_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  quaternary: 'quaternary',
};

const IMAGE_CLASSNAME_BY_TYPE = {
  [CARD_TYPES.primary]: styles.image__primary,
  [CARD_TYPES.secondary]: styles.image__secondary,
  [CARD_TYPES.tertiary]: styles.image__tertiary,
  [CARD_TYPES.quaternary]: styles.image__quaternary,
};

const Card = ({
  type,
  title,
  description,
  LinkButtonProps,
}) => (
  <div className={styles.card}>
    <div className={clsx(styles.image, IMAGE_CLASSNAME_BY_TYPE[type])} />
    <div className={styles.content}>
      <h4 className={styles.title}>
        <Tooltip content={title}>
          <span className={styles.titleContent}>
            {title}
          </span>
        </Tooltip>
      </h4>
      <p className={styles.description}>{description}</p>
      <LinkButton
        iconPosition="right"
        icon={NavigationIcon}
        captionCX={styles.linkCaption}
        {...LinkButtonProps}
      />
    </div>
  </div>
);

Card.propTypes = {
  type: PropTypes.oneOf(
    Object.values(CARD_TYPES),
  ).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  LinkButtonProps: PropTypes.shape({
    caption: PropTypes.string.isRequired,
    link: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Card;
