import { isAxiosError } from '../redux/api/fetchClient';
import { userApi } from '../redux/api/slices/userApi';

export const signUpFork = ({
  dispatch,
  formData,
  handleErrors = true,
}) => async (forkApi) => {
  const { pause } = forkApi;

  const request = dispatch(
    userApi.endpoints.signUp.initiate(formData),
  );

  try {
    const userInfo = await pause(request.unwrap());

    return userInfo;
  } catch (error) {
    if (handleErrors) {
      isAxiosError(error) && error.handleGlobally();
    }

    throw error;
  } finally {
    request.unsubscribe();
  }
};
