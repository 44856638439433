import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { generatePath } from 'react-router-dom';
import qs from 'qs';

import IconButton from '../IconButton';
import CommentIcon from './CommentIcon';
import {
  IDEA_TAB_URL_PARAM_NAME,
  IDEA_TAB_IDS,
} from '../Idea/constants';
import { ROUTES } from '../../constants/routes';

import styles from './CommentButton.module.scss';

const CommentButton = ({
  ideaId,
  comments,
  onComment,
  isDisabled,
}) => {
  const isInactive = (!!onComment && isDisabled);

  return (
    <div
      className={
        clsx(styles.commentButton, {
          [styles.commentButton__inactive]: isInactive,
          [styles.commentButton__disabled]: isDisabled,
        })
      }
    >
      <IconButton
        data-testid="iconButton"
        icon={CommentIcon}
        onClick={() => onComment?.()}
        cx={styles.button}
        isDisabled={isInactive}
        {...(ideaId ? {
          link: {
            pathname: generatePath(ROUTES.idea, { ideaId }),
            search: qs.stringify({ [IDEA_TAB_URL_PARAM_NAME]: IDEA_TAB_IDS.comments }),
          },
        } : {})}
      />
      <span className={styles.number}>
        {comments}
      </span>
    </div>
  );
};

CommentButton.propTypes = {
  comments: PropTypes.number.isRequired,
  onComment: PropTypes.func,
  ideaId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isDisabled: PropTypes.bool,
};

CommentButton.defaultProps = {
  ideaId: null,
  onComment: null,
  isDisabled: false,
};

export default CommentButton;
