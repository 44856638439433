import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Tooltip from '../Tooltip';
import Button from '../Button';
import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../Icon';

import styles from './EmptyContent.module.scss';

const EmptyContent = ({
  iconProps,
  title,
  description,
  buttonProps,
  customButton,
  buttonTooltip,
  className,
}) => {
  const resultButton = customButton || (buttonProps && (
    <Tooltip
      data-testid="tooltip"
      content={buttonTooltip}
    >
      <Button
        data-testid="emptyContentButton"
        size="42"
        {...buttonProps}
      />
    </Tooltip>
  ));

  return (
    <div
      className={clsx(styles.emptyContent, className)}
      data-testid="emptyContent"
    >
      {iconProps && (
        <div className={styles.iconWrapper}>
          <Icon
            data-testid="icon"
            size={ICON_SIZE.md}
            color={ICON_COLOR.quaternary}
            {...iconProps}
          />
        </div>
      )}
      <h4 className={styles.title}>
        {title}
      </h4>
      {description && (
        <p className={styles.description}>
          {description}
        </p>
      )}
      {resultButton}
    </div>
  );
};

EmptyContent.propTypes = {
  iconProps: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(ICON_TYPES)).isRequired,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonProps: PropTypes.shape({
    link: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    caption: PropTypes.string.isRequired,
  }),
  customButton: PropTypes.node,
  buttonTooltip: PropTypes.node,
  className: PropTypes.string,
};

EmptyContent.defaultProps = {
  iconProps: null,
  description: null,
  buttonProps: null,
  customButton: null,
  buttonTooltip: '',
  className: '',
};

export default EmptyContent;
