import React, {
  useRef,
  memo,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import clsx from 'clsx';

import styles from './Animation.module.scss';

const Animation = ({
  getAnimationApi,
  className,
  renderer,
  loop,
  autoplay,
  animationData,
}) => {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      renderer,
      loop,
      autoplay,
      animationData,
    });

    getAnimationApi?.(animation);

    return () => animation.destroy();
  }, [
    renderer,
    loop,
    autoplay,
    animationData,
    getAnimationApi,
  ]);

  return (
    <div
      ref={containerRef}
      className={clsx(styles.animation, className)}
    />
  );
};

Animation.propTypes = {
  renderer: PropTypes.oneOf(['svg', 'canvas', 'html']),
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  animationData: PropTypes.object.isRequired,
  getAnimationApi: PropTypes.func,
  className: PropTypes.string,
};

Animation.defaultProps = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  getAnimationApi: null,
  className: '',
};

export default memo(Animation);
