import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import EditIcon from './EditIcon';
import DeactivateIcon from './DeactivateIcon';
import ActivateIcon from './ActivateIcon';
import Menu from '../../../Menu';

import styles from './CategoryItemMenu.module.scss';

const CategoryItemMenu = ({
  onUpdate,
  onToggleAvailability,
  className,
  updateButtonLabel,
  deactivateButtonLabel,
  activateButtonLabel,
  isActive,
}) => {
  const groups = useMemo(() => ([{
    id: 'actions',
    items: [{
      id: 'edit',
      label: updateButtonLabel,
      onClick: onUpdate,
      icon: EditIcon,
    }, {
      id: 'toggleAvailability',
      label: isActive ? deactivateButtonLabel : activateButtonLabel,
      onClick: onToggleAvailability,
      icon: isActive ? DeactivateIcon : ActivateIcon,
    }],
  }]), [
    onUpdate,
    onToggleAvailability,
    updateButtonLabel,
    deactivateButtonLabel,
    activateButtonLabel,
    isActive,
  ]);

  return (
    <Menu
      data-testid="categoryItemMenu"
      className={className}
      dropdownMenuProps={{
        groups,
        bodyClassName: styles.dropdownBody,
      }}
    />
  );
};

CategoryItemMenu.propTypes = {
  className: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onToggleAvailability: PropTypes.func.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  deactivateButtonLabel: PropTypes.string.isRequired,
  activateButtonLabel: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

CategoryItemMenu.defaultProps = {
  className: '',
};

export default CategoryItemMenu;
