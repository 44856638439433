import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EVENT_STAGES,
  EVENT_STAGE_LABEL_TRANSLATION_KEYS,
} from '../../../constants/event';

export const stages = [
  {
    id: EVENT_STAGES.IDEA_SUBMISSION,
    title: <FormattedMessage
      id={EVENT_STAGE_LABEL_TRANSLATION_KEYS[EVENT_STAGES.IDEA_SUBMISSION]}
    />,
  }, {
    id: EVENT_STAGES.DOWN_SELECTION,
    title: <FormattedMessage
      id={EVENT_STAGE_LABEL_TRANSLATION_KEYS[EVENT_STAGES.DOWN_SELECTION]}
    />,
  }, {
    id: EVENT_STAGES.VOTING,
    title: <FormattedMessage
      id={EVENT_STAGE_LABEL_TRANSLATION_KEYS[EVENT_STAGES.VOTING]}
    />,
  }, {
    id: EVENT_STAGES.ANNOUNCE_WINNER,
    title: <FormattedMessage
      id={EVENT_STAGE_LABEL_TRANSLATION_KEYS[EVENT_STAGES.ANNOUNCE_WINNER]}
    />,
  },
];
