import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaCommentsApi } from '../../../../redux/api/slices/ideaCommentsApi';

export const getCommentsListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      entityId: ideaId,
      page,
      parentId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const getComments = dispatch(
    ideaCommentsApi.endpoints.getIdeaComments.initiate({
      ideaId,
      page,
      parentId,
      eventHrid,
    }, { forceRefetch: true }),
  );

  try {
    const commentsResult = await pause(getComments.unwrap());

    resolve(commentsResult);
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    getComments.unsubscribe();
  }
};

export const getCommentsActionCreator = createAction(ACTION_TYPES.ideaComments.getComments);
