import React, {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import TabsNav from './TabsNav';
import TabsContent from './TabsContent';
import { useQueryParams } from '../../hooks/useQueryParams';

const Tabs = ({
  items,
  urlParamName,
  tabsNavClassName,
  tabsContentClassName,
  isStretch,
}) => {
  const { [urlParamName]: urlActiveTabId } = useQueryParams();
  const tabIds = useMemo(() => (items.map(({ id }) => id)), [items]);
  const [activeTabId, setActiveTabId] = useState(
    urlParamName
      ? null
      : items[0]?.id,
  );
  const resultActiveTabId = useMemo(() => {
    if (urlParamName) {
      return tabIds.includes(urlActiveTabId)
        ? urlActiveTabId
        : items[0]?.id;
    }

    return activeTabId;
  }, [
    activeTabId,
    urlActiveTabId,
    items,
    tabIds,
    urlParamName,
  ]);

  return (
    <div>
      <TabsNav
        activeTabId={resultActiveTabId}
        items={items}
        urlParamName={urlParamName}
        className={tabsNavClassName}
        onItemChange={urlParamName ? null : setActiveTabId}
        isStretch={isStretch}
      />
      <TabsContent
        activeTabId={resultActiveTabId}
        items={items}
        className={tabsContentClassName}
      />
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    caption: PropTypes.node.isRequired,
    Component: PropTypes.func.isRequired,
    componentProps: PropTypes.object,
  })).isRequired,
  urlParamName: PropTypes.string,
  tabsNavClassName: PropTypes.string,
  tabsContentClassName: PropTypes.string,
  isStretch: PropTypes.bool,
};

Tabs.defaultProps = {
  urlParamName: null,
  tabsNavClassName: '',
  tabsContentClassName: '',
  isStretch: false,
};

export default Tabs;
