import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { useGetActiveBusinessObjectivesQuery } from '../../../../redux/api/slices/businessObjectivesApi';
import { EntityCell } from '../../../Table/cellsRenders';
import LikesAndCommentsBar from '../../../LikesAndCommentsBar';
import Button from '../../../Button';
import IdeaDetails from '../../../Table/expandedRowRenders/IdeaDetails';
import NoIdeas from './NoIdeas';
import NoFilterResults from '../../../Filters/NoFilterResults';
import {
  ACCESSOR_KEYS,
  TABLE_ID,
  SORTING_URL_PARAM_NAME,
  PAGE_URL_PARAM_NAME,
  FILTERS_URL_PARAM_NAME,
} from './constants';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import Spinner from '../../../Spinner';
import Table from '../../../Table';
import { useGetIdeas } from './hooks/useGetIdeas';
import ExportToExcelButton from '../ExportToExcelButton';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';
import { getFullUrl } from '../../../../utils';
import { ROUTES } from '../../../../constants/routes';
import { useModal, useListener } from '../../../../hooks';
import ArchiveIdeaConfirmationModal from '../../../modals/ArchiveIdeaConfirmationModal';
import {
  toggleIdeaForVoteListener,
  toggleIdeaForVoteActionCreator,
} from './listeners/toggleIdeaForVoteListener';
import { IDEA_STATUSES } from '../../../../constants/idea';
import {
  useGetFilters,
  useGetSorting,
} from '../hooks';

import styles from './IdeasDownSelectionTab.module.scss';

const IdeasDownSelectionTab = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();

  const [isTogglingForVote, setIsTogglingForVote] = useState(false);

  const [toggleForVote] = useListener({
    listener: toggleIdeaForVoteListener,
    actionCreator: toggleIdeaForVoteActionCreator,
  });

  const {
    data: bizObjectivesData,
    isLoading: areBizObjectivesLoading,
    error: bizObjectivesError,
  } = useGetActiveBusinessObjectivesQuery();

  const renderTitleCell = useCallback(({ row }) => (
    <EntityCell
      humanReadableId={
        formatMessage(
          { id: 'common.ideaTitle' },
          {
            isArchived: false,
            name: row.original.human_readable_id,
          },
        )
      }
      title={row.original.title}
      url={getFullUrl(generatePath(ROUTES.idea, { ideaId: row.original.human_readable_id }))}
    />
  ), [formatMessage]);

  const renderCollaborationsCell = useCallback(({ row }) => (
    <LikesAndCommentsBar
      idea={row.original}
    />
  ), []);

  const showArchiveIdeaConfirmatonModal = useCallback((ideaId) => showModal((props) => (
    <ArchiveIdeaConfirmationModal
      {...props}
      ideaId={ideaId}
    />
  )), [showModal]);

  const handleToggleForVote = useCallback(
    async (ideaId, isVoting) => {
      setIsTogglingForVote(true);

      try {
        await toggleForVote({
          ideaId,
          eventHrid: ACTIVE_EVENT_HRID,
          isVoting,
        });
      } finally {
        setIsTogglingForVote(false);
      }
    },
    [toggleForVote],
  );

  const renderActionsCell = useCallback(({ row }) => {
    const isCollaboratorsReview = row.original.status === IDEA_STATUSES.COLLABORATORS_REVIEW;

    return (
      <div className={styles.actionsWrapper}>
        <Button
          cx={styles.moveToVoteButton}
          caption={formatMessage(
            { id: 'adminPanel.ideasBoard.ideasDownSelectionTable.columns.actions.moveToVote' },
            { isCollaboratorsReview },
          )}
          size="42"
          onClick={() => handleToggleForVote(
            row.original.human_readable_id,
            isCollaboratorsReview,
          )}
          {...(isCollaboratorsReview && { fill: 'white' })}
        />
        <Button
          caption={formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.columns.actions.archive' })}
          size="42"
          fill="white"
          onClick={() => showArchiveIdeaConfirmatonModal(row.original.human_readable_id)}
        />
      </div>
    );
  }, [
    formatMessage,
    showArchiveIdeaConfirmatonModal,
    handleToggleForVote,
  ]);

  const renderDetailPanel = useCallback(({ row }) => (
    <IdeaDetails
      idea={row.original}
    />
  ), []);

  const renderEmptyState = useCallback(({
    hasActiveFilters,
    clearFilters,
  }) => {
    if (hasActiveFilters) {
      return <NoFilterResults onClick={clearFilters} />;
    }

    return <NoIdeas />;
  }, []);

  const columns = useMemo(() => ([
    {
      accessorKey: ACCESSOR_KEYS.IDEA_TITLE,
      header: formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.columns.title' }),
      cell: renderTitleCell,
    },
    {
      accessorKey: ACCESSOR_KEYS.COLLABORATION,
      header: formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.columns.collaboration' }),
      cell: renderCollaborationsCell,
      size: 27,
    },
    {
      accessorKey: ACCESSOR_KEYS.ACTIONS,
      header: formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.columns.actions' }),
      cell: renderActionsCell,
      size: 45,
    },
  ]), [
    renderTitleCell,
    renderCollaborationsCell,
    renderActionsCell,
    formatMessage,
  ]);

  const filters = useGetFilters({ bizObjectives: bizObjectivesData });
  const sortingOptions = useGetSorting();

  useEffect(() => {
    isAxiosError(bizObjectivesError) && bizObjectivesError.handleGlobally();
  }, [bizObjectivesError]);

  if (areBizObjectivesLoading) {
    return <Spinner cx={styles.spinner} />;
  }

  return (
    <Table
      emptyState={renderEmptyState}
      isInProgress={isTogglingForVote}
      tableId={TABLE_ID}
      columns={columns}
      useGetData={useGetIdeas}
      renderDetailPanel={renderDetailPanel}
      pageUrlParamName={PAGE_URL_PARAM_NAME}
      totalResultsLabelId="adminPanel.ideasBoard.ideasDownSelectionTable.totalResultsLabel"
      actions={<ExportToExcelButton />}
      description={formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.description' })}
      filtersProps={{
        urlParamName: FILTERS_URL_PARAM_NAME,
        title: formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.filters.title' }),
        filters,
        reportLabelId: 'adminPanel.ideasBoard.ideasDownSelectionTable.filters.reportLabel',
      }}
      sortingProps={{
        urlParamName: SORTING_URL_PARAM_NAME,
        items: sortingOptions,
      }}
      getDataAdditionalParams={{ eventHrid: ACTIVE_EVENT_HRID }}
    />
  );
};

export default IdeasDownSelectionTab;
