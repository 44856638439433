import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_PROPS } from '../../Form';
import { useValidationSchema } from './hooks/useValidationSchema';
import TextField from '../../Form/connected/TextField';
import TextAreaField from '../../Form/connected/TextAreaField';
import { FORM_FIELD_NAMES } from './constants';

const BizCategoryForm = ({
  defaultValues,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const resultDefaultValues = useMemo(() => (defaultValues || {
    [FORM_FIELD_NAMES.NAME]: '',
    [FORM_FIELD_NAMES.DESCRIPTION]: '',
  }), [defaultValues]);

  const validationSchema = useValidationSchema();

  return (
    <Form
      defaultValues={resultDefaultValues}
      validationSchema={validationSchema}
      hideButtons
      {...props}
    >
      <TextField
        name={FORM_FIELD_NAMES.NAME}
        label={formatMessage({ id: 'bizCategoryForm.nameField.label' })}
        placeholder={formatMessage({ id: 'bizCategoryForm.nameField.placeholder' })}
        isRequired
      />
      <TextAreaField
        name={FORM_FIELD_NAMES.DESCRIPTION}
        label={formatMessage({ id: 'bizCategoryForm.descriptionField.label' })}
        placeholder={formatMessage({
          id: 'bizCategoryForm.descriptionField.placeholder',
        })}
      />
    </Form>
  );
};

BizCategoryForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
};

BizCategoryForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  getFormApi: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  values: null,
};

export default BizCategoryForm;
