import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_TITLE_SIZES, FORM_PROPS } from '../../Form';
import RadioGroupField from '../../Form/connected/RadioGroupField';
import FormDescription from '../../Form/FormDescription';
import { FORM_FIELD_NAMES } from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';

const BusinessObjectiveForm = ({
  defaultValues,
  businessObjectives,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const resultDefaultValues = useMemo(() => (defaultValues || {
    [FORM_FIELD_NAMES.BUSINESS_OBJECTIVE]: null,
  }), [defaultValues]);
  const validationSchema = useValidationSchema();

  return (
    <Form
      data-testid="form"
      defaultValues={resultDefaultValues}
      validationSchema={validationSchema}
      title={formatMessage({ id: 'businessObjectiveForm.title' })}
      titleSize={FORM_TITLE_SIZES.SM}
      hideButtons
      {...props}
    >
      <FormDescription
        data-testid="formDescription"
      >
        <FormattedMessage
          id="businessObjectiveForm.description"
        />
      </FormDescription>
      <RadioGroupField
        data-testid="businessObjective"
        name={FORM_FIELD_NAMES.BUSINESS_OBJECTIVE}
        items={businessObjectives}
      />
    </Form>
  );
};

BusinessObjectiveForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
  defaultValues: PropTypes.object,
  businessObjectives: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ),
};

BusinessObjectiveForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  getFormApi: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  values: null,
  defaultValues: null,
  businessObjectives: [],
};

export default BusinessObjectiveForm;
