import {
  EVENT_STATUSES,
} from '../../constants/event';
import { USER_ROLES } from '../../constants/roles';

export const adminPanelCondition = ({ userRole }) => userRole === USER_ROLES.ADMIN;

export const ideasCatalogCondition = ({ activeEventStatus }) => (
  [
    EVENT_STATUSES.IN_PROGRESS,
    EVENT_STATUSES.FINISHED,
  ].includes(activeEventStatus)
);

export const eventTimerCondition = ({ activeEventStatus }) => (
  [
    EVENT_STATUSES.IN_PROGRESS,
  ].includes(activeEventStatus)
);
