import { useGetUserAccountsQuery } from '../../../redux/api/slices/userApi';

export const useGetPeopleListData = ({
  page,
  pageSize,
  search,
}) => {
  const result = useGetUserAccountsQuery({
    page,
    pageSize,
    search,
  }, { refetchOnMountOrArgChange: true });

  return result;
};
