import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_PROPS } from '../../Form';
import RadioGroupField from '../../Form/connected/RadioGroupField';
import TextAreaField from '../../Form/connected/TextAreaField';
import { FORM_FIELD_NAMES } from './constants';
import WatchFormValues from '../../Form/WatchFormValues';
import {
  IDEA_INACTIVATE_REASON_TYPES,
  INACTIVATE_REASON_TYPE_TRANSLATION_KEY,
} from '../../../constants/idea';

const InactivateIdeaFormPattern = ({
  defaultValues,
  validationSchema,
  reasonTypeFieldLabel,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const reasonTypes = useMemo(() => ([{
    id: IDEA_INACTIVATE_REASON_TYPES.SOLUTION_EXIST,
    name: formatMessage({
      id: INACTIVATE_REASON_TYPE_TRANSLATION_KEY[IDEA_INACTIVATE_REASON_TYPES.SOLUTION_EXIST],
    }),
  }, {
    id: IDEA_INACTIVATE_REASON_TYPES.SOLUTION_IN_PROGRESS,
    name: formatMessage({
      id: INACTIVATE_REASON_TYPE_TRANSLATION_KEY[IDEA_INACTIVATE_REASON_TYPES.SOLUTION_IN_PROGRESS],
    }),
  }, {
    id: IDEA_INACTIVATE_REASON_TYPES.NOT_AN_IMMEDIATE_PRIORITY,
    name: formatMessage({
      id: INACTIVATE_REASON_TYPE_TRANSLATION_KEY[IDEA_INACTIVATE_REASON_TYPES.NOT_AN_IMMEDIATE_PRIORITY], // eslint-disable-line max-len
    }),
  }, {
    id: IDEA_INACTIVATE_REASON_TYPES.OTHER,
    name: formatMessage({
      id: INACTIVATE_REASON_TYPE_TRANSLATION_KEY[IDEA_INACTIVATE_REASON_TYPES.OTHER],
    }),
  }]), [formatMessage]);

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      hideButtons
      {...props}
    >
      <RadioGroupField
        name={FORM_FIELD_NAMES.REASON_TYPE}
        label={reasonTypeFieldLabel}
        items={reasonTypes}
        isRequired
      />
      <WatchFormValues
        name={FORM_FIELD_NAMES.REASON_TYPE}
      >
        {(value) => (
          value && (
            <TextAreaField
              name={FORM_FIELD_NAMES.REASON_DESCRIPTION}
              label={formatMessage({ id: 'inactivateIdeaFormPattern.reasonDescription.label' })}
              placeholder={formatMessage({ id: 'inactivateIdeaFormPattern.reasonDescription.placeholder' })}
              isRequired
            />
          )
        )}
      </WatchFormValues>
    </Form>
  );
};

InactivateIdeaFormPattern.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
  reasonTypeFieldLabel: PropTypes.string.isRequired,
  defaultValues: PropTypes.shape({
    [FORM_FIELD_NAMES.REASON_TYPE]: PropTypes.string.isRequired,
    [FORM_FIELD_NAMES.REASON_DESCRIPTION]: PropTypes.string.isRequired,
  }),
  validationSchema: PropTypes.object.isRequired,
};

InactivateIdeaFormPattern.defaultProps = {
  defaultValues: {
    [FORM_FIELD_NAMES.REASON_TYPE]: '',
    [FORM_FIELD_NAMES.REASON_DESCRIPTION]: '',
  },
};

export default InactivateIdeaFormPattern;
