import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaCommentsApi } from '../../../../redux/api/slices/ideaCommentsApi';

export const deleteCommentListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      commentId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const deleteComment = dispatch(
    ideaCommentsApi.endpoints.deleteIdeaComment.initiate({ commentId, eventHrid }),
  );

  try {
    await pause(deleteComment.unwrap());

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    deleteComment.unsubscribe();
  }
};

export const deleteCommentActionCreator = createAction(ACTION_TYPES.ideaComments.deleteComment);
