import { baseApi } from '../baseApi';
import { createAuthorizationHeader } from '../../../utils';
import { API_TAG_TYPES } from './constants';
import { BASE_URL, ROUTES } from '../../../constants/routes';

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query({
      query: (token) => ({
        url: '/accounts/me',
        headers: token ? { authorization: createAuthorizationHeader(token) } : {},
      }),
      providesTags: (result, error) => (error ? [] : [{ type: API_TAG_TYPES.userInfo }]),
    }),
    login: build.mutation({
      query: (data) => ({
        url: '/auth/token',
        method: 'POST',
        data,
      }),
    }),
    signUp: build.mutation({
      query: (data) => ({
        url: '/accounts',
        method: 'POST',
        data,
      }),
    }),
    ssoAuth: build.mutation({
      query: ({ sessionState, code }) => ({
        url: '/auth/everest/sso/accept',
        params: {
          redirect_uri: `${BASE_URL}${ROUTES.ssoAuth}`,
          session_state: sessionState,
          code,
        },
      }),
    }),
    updateUserData: build.mutation({
      query: ({ id, ...restData }) => ({
        url: `/accounts/${id}`,
        method: 'PATCH',
        data: restData,
      }),
      invalidatesTags: (result, error, { id }) => (
        error
          ? []
          : [{ type: API_TAG_TYPES.userInfo }, { type: API_TAG_TYPES.account, id }]),
    }),
    getResetPasswordLink: build.mutation({
      query: (data) => ({
        url: '/auth/password/reset/',
        method: 'POST',
        data,
      }),
    }),
    setNewPassword: build.mutation({
      query: (data) => ({
        url: '/auth/password/reset/confirm/',
        method: 'POST',
        data,
      }),
    }),
    getUserAccounts: build.query({
      query: ({
        page,
        pageSize,
        search,
      }) => ({
        url: '/accounts',
        params: {
          page,
          size: pageSize,
          query_search: search,
        },
      }),
      providesTags:
        (result, error) => (error ? [] : result.items.map(
          ({ id }) => ({ type: API_TAG_TYPES.account, id }),
        )),
    }),
    getUserVotesMeta: build.query({
      query: ({ eventHrid }) => ({
        url: `/metadata/${eventHrid}/users/me`,
      }),
      providesTags: (result, error) => (error ? [] : [API_TAG_TYPES.userVotesMeta]),
    }),
    contactUs: build.mutation({
      query: ({ data }) => ({
        url: '/contact-us',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useGetUserAccountsQuery,
  useGetUserVotesMetaQuery,
} = userApi;
