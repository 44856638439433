import { flushSync } from 'react-dom';
import { createAction } from '@reduxjs/toolkit';

import { isAxiosError } from '../../../redux/api/fetchClient';
import { ACTION_TYPES } from '../../../constants/actionTypes';
import { getActiveEventFork } from '../../../listenerForks';
import {
  ACTIVE_EVENT_HRID,
  EVENT_STATUSES,
} from '../../../constants/event';
import {
  setActiveEvent,
  activeEventSelector,
} from '../../../redux/slices/events';
import { NETWORK_ERROR_STATUS } from '../../../constants/common';

export const handleActiveEventTransitionListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      disableLeavePrompt,
      enableLeavePrompt,
    },
  } = action;
  const {
    dispatch,
    fork,
    delay,
    getState,
  } = listenerApi;

  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const state = getState();
      const activeEvent = activeEventSelector(state);

      // 1 minute event request delay
      const eventRequestMsDelay = 60000;

      // finish listener if event is finished(in status finished)
      if (activeEvent.status === EVENT_STATUSES.FINISHED) {
        resolve();
        return;
      }

      await delay(eventRequestMsDelay);

      const getActiveEventForkResult = await fork(getActiveEventFork({
        dispatch,
        activeEventHrid: ACTIVE_EVENT_HRID,
        handleErrors: false,
      })).result;

      if (getActiveEventForkResult.status === 'ok') {
        flushSync(() => {
          disableLeavePrompt();
        });

        flushSync(() => {
          dispatch(setActiveEvent(getActiveEventForkResult.value));
        });

        flushSync(() => {
          enableLeavePrompt();
        });
      } else if (isAxiosError(getActiveEventForkResult.error)) {
        if (
          !(
            getActiveEventForkResult.error.response?.status >= 500
            && getActiveEventForkResult.error.response?.status < 600
          )
          && getActiveEventForkResult.error.code !== NETWORK_ERROR_STATUS
        ) {
          getActiveEventForkResult.error.handleGlobally();

          reject();
          return;
        }
      } else {
        reject();
        return;
      }
    }
  } catch (error) {
    reject();
  }
};

export const handleActiveEventTransitionActionCreator = createAction(
  ACTION_TYPES.app.handleActiveEventTransition,
);
