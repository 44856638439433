import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CHART_TYPES } from '../../../Chart/constants';
import {
  useGetBusinessObjectiveByTypeOverviewInfoQuery,
} from '../../../../redux/api/slices/analyticsApi';
import ChartPattern from '../../../ChartPattern';
import DataTypePicker from '../DataTypePicker';
import { CHART_DATA_TYPES } from '../constants';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';

const BusinessObjectivesOverview = ({
  period,
  className,
}) => {
  const { formatMessage } = useIntl();

  const [dataType, setDataType] = useState(CHART_DATA_TYPES.ideas);

  const pickerAction = useMemo(() => (
    <DataTypePicker
      value={dataType}
      onChange={setDataType}
    />
  ), [
    dataType,
  ]);

  return (
    <ChartPattern
      chartType={CHART_TYPES.doughnut}
      title={formatMessage({ id: 'adminPanel.dashboard.businessObjectiveOverview.title' })}
      requestProps={{
        ...period,
        type: dataType,
        eventHrid: ACTIVE_EVENT_HRID,
      }}
      useGetData={useGetBusinessObjectiveByTypeOverviewInfoQuery}
      className={className}
      actions={pickerAction}
    />
  );
};

BusinessObjectivesOverview.propTypes = {
  period: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

BusinessObjectivesOverview.defaultProps = {
  className: '',
};

export default BusinessObjectivesOverview;
