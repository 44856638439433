import React from 'react';
import PropTypes from 'prop-types';

import CheckboxGroup from '../CheckboxGroup';
import Label, { LABEL_SIZES } from '../Label';

import styles from './CheckboxGroupField.module.scss';

const DIRECTIONS = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};

const CheckboxGroupField = ({
  value,
  onValueChange,
  items,
  direction,
  errorMessage,
  label,
  tooltip,
  isRequired,
  footer,
  labelSize,
  ...props
}) => (
  <Label
    data-testid="label"
    label={label}
    isInvalid={!!errorMessage}
    validationMessage={errorMessage}
    info={tooltip}
    isRequired={isRequired}
    footer={footer}
    size={labelSize}
  >
    <CheckboxGroup
      value={value}
      onValueChange={onValueChange}
      items={items}
      direction={direction}
      cx={styles.checkboxGroup}
      {...props}
    />
  </Label>
);

CheckboxGroupField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
  onValueChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
  errorMessage: PropTypes.string,
  tooltip: PropTypes.string,
  isRequired: PropTypes.bool,
  footer: PropTypes.node,
  labelSize: PropTypes.oneOf(Object.values(LABEL_SIZES)),
};

CheckboxGroupField.defaultProps = {
  direction: DIRECTIONS.vertical,
  errorMessage: '',
  tooltip: null,
  isRequired: false,
  footer: null,
  labelSize: undefined,
};

export default CheckboxGroupField;
