import React from 'react';
import { HintNotification as BaseHintNotification } from '@epam/promo';
import PropTypes from 'prop-types';

import InfoIcon from './InfoIcon';

const HintNotification = ({ children, ...props }) => (
  <BaseHintNotification
    data-testid="baseHintNotification"
    icon={InfoIcon}
    {...props}
  >
    {children}
  </BaseHintNotification>
);

HintNotification.propTypes = {
  children: PropTypes.node,
};

HintNotification.defaultProps = {
  children: null,
};

export default HintNotification;
