import React from 'react';
import { useIntl } from 'react-intl';

import Modal from '../../Modal';

const LeavePromptConfirmationModal = ({ ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      title={formatMessage({ id: 'leavePromptConfirmationModal.title' })}
      content={formatMessage({ id: 'leavePromptConfirmationModal.description' })}
      confirmButtonCaption={formatMessage({ id: 'leavePromptConfirmationModal.confirmButtonLabel' })}
      {...props}
    />
  );
};

export default LeavePromptConfirmationModal;
