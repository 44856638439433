import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  IDEA_STATUSES,
  IDEA_STATUS_LABEL_TRANSLATION_KEYS,
} from '../../../constants/idea';
import {
  formatDate,
  getUserFullName,
} from '../../../utils';
import DraftIdeaCardMenu from './DraftIdeaCardMenu';

import styles from './DraftIdeaCard.module.scss';

const DraftIdeaCard = ({
  idea,
  onDelete,
}) => {
  const { formatMessage } = useIntl();
  const {
    author,
    title,
    reason,
    status,
    created_at: date,
  } = idea;
  const ideaStatus = formatMessage({ id: IDEA_STATUS_LABEL_TRANSLATION_KEYS[status] });
  const ideaTitle = title || formatMessage({ id: 'ideas.draftIdeaCard.ideaTitlePlaceholder' });
  const ideaReason = reason || formatMessage({ id: 'ideas.draftIdeaCard.ideaReasonPlaceholder' });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.status}>
          {ideaStatus}
        </div>
        <DraftIdeaCardMenu
          data-testid="draftIdeaCardMenu"
          idea={idea}
          onDelete={onDelete}
        />
      </div>
      <div className={styles.subHeader}>
        <h4 className={styles.title}>
          {ideaTitle}
        </h4>
      </div>
      <p className={styles.reason}>
        {ideaReason}
      </p>
      <div className={styles.footer}>
        <div className={styles.author}>
          {getUserFullName(author)}
        </div>
        <div className={styles.date}>
          {formatDate(date)}
        </div>
      </div>
    </div>
  );
};

DraftIdeaCard.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    author: PropTypes.object.isRequired,
    title: PropTypes.string,
    reason: PropTypes.string,
    status: PropTypes.oneOf(Object.values(IDEA_STATUSES)).isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DraftIdeaCard;
