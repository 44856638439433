import React from 'react';
import { useIntl } from 'react-intl';

import ProductField from '../ProductField';

const SimilarProductField = ({ ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <ProductField
      label={formatMessage({ id: 'similarProductField.label' })}
      {...props}
    />
  );
};

export default SimilarProductField;
