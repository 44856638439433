import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { activeEventStatusSelector } from '../../../../redux/slices/events';
import { EVENT_STATUSES } from '../../../../constants/event';
import { SORTING_TYPES } from '../constants';

export const useGetSorting = () => {
  const { formatMessage } = useIntl();
  const activeEventStatus = useSelector(activeEventStatusSelector);

  const sorting = useMemo(() => (
    activeEventStatus === EVENT_STATUSES.FINISHED
      ? undefined
      : [
        {
          id: SORTING_TYPES.likesAndComments,
          label: formatMessage({ id: 'ideas.ideasList.sorting.likesAndComments.label' }),
        },
        {
          id: SORTING_TYPES.newestIdeas,
          label: formatMessage({ id: 'ideas.ideasList.sorting.newestIdeas.label' }),
        },
        {
          id: SORTING_TYPES.oldestIdeas,
          label: formatMessage({ id: 'ideas.ideasList.sorting.oldestIdeas.label' }),
        },
      ]
  ), [
    activeEventStatus,
    formatMessage,
  ]);

  return sorting;
};
