import React from 'react';
import { useIntl } from 'react-intl';

import SsoAuthButton from '../SsoAuthButton';

import styles from './JoinOurCommunity.module.scss';

const JoinOurCommunity = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.joinOurCommunity}>
      <div className={styles.container}>
        <div className={styles.communityInfoBlock}>
          <h2 className={styles.heading}>
            {formatMessage({ id: 'joinOurCommunity.heading' })}
          </h2>
          <p className={styles.description}>
            {formatMessage({ id: 'joinOurCommunity.description' })}
          </p>
          <SsoAuthButton
            data-testid="ssoAuthButton"
            className={styles.ssoAuthButton}
          />
        </div>
        <div className={styles.community} />
      </div>
    </div>
  );
};

export default JoinOurCommunity;
