import React from 'react';
import { useParams } from 'react-router-dom';

import Spinner, { SPINNER_SIZE } from '../../Spinner';
import { useGetIdeaByIdQuery } from '../../../redux/api/slices/ideaApi';
import { ACTIVE_EVENT_HRID } from '../../../constants/event';
import Breadcrumb from '../Breadcrumb';
import { IDEA_STATUSES } from '../../../constants/idea';

const IdeaBreadcrumb = (props) => {
  const { ideaId } = useParams();
  const {
    data: ideaData,
    isLoading,
  } = useGetIdeaByIdQuery({
    ideaId,
    eventHrid: ACTIVE_EVENT_HRID,
  });

  if (isLoading) {
    return <Spinner size={SPINNER_SIZE.xs} />;
  }

  return (
    <Breadcrumb
      isLink={ideaData?.status !== IDEA_STATUSES.DRAFT}
      {...props}
    />
  );
};

export default IdeaBreadcrumb;
