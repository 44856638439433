import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CHART_DATA_TYPES } from '../constants';
import PickerField, {
  SEARCH_POSITION,
  SELECTION_MODE,
} from '../../../Form/PickerField';

import styles from './DataTypePicker.module.scss';

const DataTypePicker = ({
  onChange,
  value,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const dataTypePickerItems = useMemo(() => ([
    {
      id: CHART_DATA_TYPES.ideas,
      label: formatMessage({ id: 'adminPanel.dashboard.dataTypePicker.option.ideas' }),
    },
    {
      id: CHART_DATA_TYPES.people,
      label: formatMessage({ id: 'adminPanel.dashboard.dataTypePicker.option.people' }),
    },
  ]), [formatMessage]);

  return (
    <div className={styles.container}>
      <PickerField
        searchPosition={SEARCH_POSITION.NONE}
        selectionMode={SELECTION_MODE.SINGLE}
        minBodyWidth={1}
        disableClear
        onChange={onChange}
        items={dataTypePickerItems}
        value={value}
        labelClassName={styles.label}
        {...props}
      />
    </div>
  );
};

DataTypePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(CHART_DATA_TYPES)).isRequired,
};

export default DataTypePicker;
