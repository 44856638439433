import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import {
  pick,
  isEqual,
} from 'lodash';

import Spinner from '../Spinner';
import Blocker from '../Blocker';
import UserGeneralInformationForm, {
  FORM_FIELD_NAMES as GENERAL_INFORMATION_FORM_FIELD_NAMES,
} from '../forms/UserGeneralInformationForm';
import UserAreasOfInterestForm, {
  FORM_FIELD_NAMES as AREAS_OF_INTEREST_FORM_FIELD_NAMES,
} from '../forms/UserAreasOfInterestForm';
import { useGetUserInfoQuery } from '../../redux/api/slices/userApi';
import { isAxiosError } from '../../redux/api/fetchClient';
import { updateUserDataListener, updateUserDataListenerActionCreator } from './listeners/updateUserDataListener';
import {
  useListener,
  useShowNotification,
} from '../../hooks';
import { useGetActiveBusinessObjectivesQuery } from '../../redux/api/slices/businessObjectivesApi';
// import {
//   useGetActiveTechnicalCategoriesQuery
// } from '../../redux/api/slices/technicalCategoriesApi';
import { NOTIFICATION_TYPES } from '../Notification/Notification';
import {
  convertBusinessObjectives,
  // convertTechnicalCategories,
} from './utils';

import styles from './Profile.module.scss';

const Profile = () => {
  const { formatMessage } = useIntl();
  const {
    data: userData,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    isError: isUserError,
    error: userError,
  } = useGetUserInfoQuery(
    null,
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: businessObjectives,
    isLoading: isLoadingBusinessObjectives,
    isFetching: isFetchingBusinessObjectives,
    isError: isBusinessObjectivesError,
    error: businessObjectivesError,
  } = useGetActiveBusinessObjectivesQuery();
  // const {
  //   data: technicalCategories,
  //   isLoading: isLoadingTechnicalCategories,
  //   isFetching: isFetchingTechnicalCategories,
  //   isError: isTechnicalCategoriesError,
  //   error: technicalCategoriesError,
  // } = useGetActiveTechnicalCategoriesQuery();

  const isLoading = isLoadingUser
    || isLoadingBusinessObjectives;
  // || isLoadingTechnicalCategories;
  const isFetching = isFetchingUser
    || isFetchingBusinessObjectives;
  // || isFetchingTechnicalCategories;

  const resultBusinessObjectives = useMemo(() => {
    if (isLoadingBusinessObjectives || isBusinessObjectivesError) {
      return [];
    }

    return businessObjectives.map(convertBusinessObjectives);
  }, [
    businessObjectives,
    isLoadingBusinessObjectives,
    isBusinessObjectivesError,
  ]);

  // const resultTechnicalCategories = useMemo(() => {
  //   if (isLoadingTechnicalCategories || isTechnicalCategoriesError) {
  //     return [];
  //   }

  //   return technicalCategories.map(convertTechnicalCategories);
  // }, [
  //   technicalCategories,
  //   isLoadingTechnicalCategories,
  //   isTechnicalCategoriesError,
  // ]);

  const showToast = useShowNotification();

  const showSuccessProfileUpdateToast = useCallback(() => {
    showToast({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: formatMessage({ id: 'profile.successUpdateNotification' }),
    });
  }, [showToast, formatMessage]);

  const [updateUserData] = useListener({
    listener: updateUserDataListener,
    actionCreator: updateUserDataListenerActionCreator,
  });

  const handleSubmit = useCallback((formData) => updateUserData({
    newUserData: { id: userData.id, ...formData },
    onSuccess: showSuccessProfileUpdateToast,
  }), [
    updateUserData,
    userData,
    showSuccessProfileUpdateToast,
  ]);

  useEffect(() => {
    isAxiosError(userError) && userError.handleGlobally();
  }, [userError]);

  useEffect(() => {
    isAxiosError(businessObjectivesError) && businessObjectivesError.handleGlobally();
  }, [businessObjectivesError]);

  // useEffect(() => {
  //   isAxiosError(technicalCategoriesError) && technicalCategoriesError.handleGlobally();
  // }, [technicalCategoriesError]);

  const lastGeneralInformationDefaultValues = useRef();
  const generalInformationDefaultValues = useMemo(() => {
    if (!userData) {
      return null;
    }

    const newGeneralInformation = pick(
      userData,
      Object.values(GENERAL_INFORMATION_FORM_FIELD_NAMES),
    );

    if (isEqual(newGeneralInformation, lastGeneralInformationDefaultValues.current)) {
      return lastGeneralInformationDefaultValues.current;
    }

    lastGeneralInformationDefaultValues.current = newGeneralInformation;
    return newGeneralInformation;
  }, [userData]);

  const lastAreasOfInterestDefaultValues = useRef();
  const areasOfInterestDefaultValues = useMemo(() => {
    if (!userData) {
      return null;
    }

    const newAreasOfInterestDefaultValues = {
      ...pick(userData, Object.values(AREAS_OF_INTEREST_FORM_FIELD_NAMES)),
      [AREAS_OF_INTEREST_FORM_FIELD_NAMES.BUSINESS_OBJECTIVES]:
        userData[AREAS_OF_INTEREST_FORM_FIELD_NAMES.BUSINESS_OBJECTIVES].map(({ id }) => id),
      // [AREAS_OF_INTEREST_FORM_FIELD_NAMES.TECHNICAL_CATEGORIES]:
      //   userData[AREAS_OF_INTEREST_FORM_FIELD_NAMES.TECHNICAL_CATEGORIES].map(({ id }) => id),
    };

    if (isEqual(newAreasOfInterestDefaultValues, lastAreasOfInterestDefaultValues.current)) {
      return lastAreasOfInterestDefaultValues.current;
    }

    lastAreasOfInterestDefaultValues.current = newAreasOfInterestDefaultValues;
    return newAreasOfInterestDefaultValues;
  }, [userData]);

  const content = (() => {
    if (isUserError) {
      return null;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <UserGeneralInformationForm
            className={styles.form}
            defaultValues={generalInformationDefaultValues}
            onSubmit={handleSubmit}
          />
        </li>
        <li className={styles.listItem}>
          <UserAreasOfInterestForm
            className={styles.form}
            defaultValues={areasOfInterestDefaultValues}
            businessObjectives={resultBusinessObjectives}
            // technicalCategories={resultTechnicalCategories}
            onSubmit={handleSubmit}
          />
        </li>
      </ul>
    );
  })();

  return (
    <div className={styles.container}>
      {isFetching && !isLoading && <Blocker />}
      {content}
    </div>
  );
};

export default Profile;
