import {
  configureStore,
  createListenerMiddleware,
} from '@reduxjs/toolkit';

import { baseApi } from './api/baseApi';
import { rootReducer } from './reducers';

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware).concat(baseApi.middleware)
  ),
});
