import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Tag from '../../../../Tag';

import styles from './IdeaDataTag.module.scss';

const IdeaDataTag = ({
  label,
  content,
}) => {
  const tagCaption = useMemo(() => (
    <>
      <b className={styles.tagTitle}>
        {label}
      </b>
      <span className={styles.tagContent}>
        {content}
      </span>
    </>
  ), [label, content]);

  return (
    <Tag
      caption={tagCaption}
      data-testid="tag"
      className={styles.ideaDataTag}
    />
  );
};

IdeaDataTag.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default IdeaDataTag;
