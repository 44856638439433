import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedNumber,
  useIntl,
} from 'react-intl';
import clsx from 'clsx';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
} from '../../../../Icon';
import {
  DESCRIPTION_TRANSLATION_KEYS_BY_INFO_TYPE,
  ICON_BY_INFO_TYPE,
} from './constants';

import styles from './SummaryInfoItem.module.scss';

const SummaryInfoItem = ({
  type,
  value,
  className,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Icon
        type={ICON_BY_INFO_TYPE[type]}
        color={ICON_COLOR.primary}
        size={ICON_SIZE.msm}
        className={styles.icon}
      />
      <p className={styles.number}>
        <FormattedNumber
          value={value}
        />
      </p>
      <p className={styles.description}>
        {formatMessage({ id: DESCRIPTION_TRANSLATION_KEYS_BY_INFO_TYPE[type] })}
      </p>
    </div>
  );
};

SummaryInfoItem.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
};

SummaryInfoItem.defaultProps = {
  className: '',
};

export default SummaryInfoItem;
