import { useSelector } from 'react-redux';

import { EVENT_STAGES } from '../constants/event';
import { activeEventActiveStageTypeSelector } from '../redux/slices/events';

export const useIsAbleToLikeIdea = (idea) => {
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);

  if (!idea) {
    return false;
  }

  const { archive } = idea;

  return activeStageType === EVENT_STAGES.IDEA_SUBMISSION && !archive;
};
