import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ViewContext } from '../viewContext';

const OneColumnContent = ({ children }) => {
  const { content: { oneColumn: { className } } } = useContext(ViewContext);

  return (
    <div className={className}>
      {children}
    </div>
  );
};

OneColumnContent.propTypes = {
  children: PropTypes.node,
};

OneColumnContent.defaultProps = {
  children: null,
};

export default OneColumnContent;
