import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { generatePath } from 'react-router-dom';

import history from '../../../../browserHistory';
import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { ROUTES } from '../../../../constants/routes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaSolutionApi } from '../../../../redux/api/slices/ideaSolutionApi';
import {
  IDEA_TAB_IDS,
  IDEA_TAB_URL_PARAM_NAME,
  SOLUTION_ID_URL_PARAM_NAME,
} from '../../../Idea/constants';

export const updateSolutionListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      solutionId,
      formData,
      ideaId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;
  const updateSolution = dispatch(
    ideaSolutionApi.endpoints.updateProposedSolution.initiate({
      solutionId,
      data: formData,
      eventHrid,
    }),
  );

  try {
    await pause(updateSolution.unwrap());

    history.push({
      pathname: generatePath(ROUTES.idea, { ideaId }),
      search: qs.stringify({
        [IDEA_TAB_URL_PARAM_NAME]: IDEA_TAB_IDS.solutions,
        [SOLUTION_ID_URL_PARAM_NAME]: solutionId,
      }),
    });

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    updateSolution.unsubscribe();
  }
};

export const updateSolutionActionCreator = createAction(ACTION_TYPES.idea.updateSolution);
