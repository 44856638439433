import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { initializeAppFork } from '../../../listenerForks';

export const initializeAppListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
    },
  } = action;
  const { dispatch, fork } = listenerApi;

  try {
    const initializeAppForkResult = await fork(initializeAppFork({
      dispatch,
      fork,
    })).result;

    if (initializeAppForkResult.status !== 'ok') {
      reject();
      return;
    }

    resolve();
  } catch (error) {
    reject();
  }
};

export const initializeAppActionCreator = createAction(ACTION_TYPES.app.initialize);
