import React from 'react';
import { useIntl } from 'react-intl';

import SignUp from '../../SignUp';
import PageInfo from '../../PageInfo';

const SignUpPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'signUpPage.pageTitle' })}
      />
      <SignUp data-testid="signUp" />
    </>
  );
};

export default SignUpPage;
