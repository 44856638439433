import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form from '../../Form';
import TextField from '../../Form/connected/TextField';
import { useValidationSchema } from './hooks/useValidationSchema';
import { FORM_FIELD_NAMES } from './constants';
import { FORM_PROPS } from '../../Form/Form';

const SignUpForm = (props) => {
  const { formatMessage } = useIntl();
  const defaultValues = useMemo(() => ({
    [FORM_FIELD_NAMES.FIRST_NAME_FIELD]: '',
    [FORM_FIELD_NAMES.LAST_NAME_FIELD]: '',
    [FORM_FIELD_NAMES.USER_EMAIL_FIELD]: '',
    [FORM_FIELD_NAMES.USER_PASSWD_FIELD]: '',
    [FORM_FIELD_NAMES.USER_CONFIRMED_PASSWD_FIELD]: '',
  }), []);
  const validationSchema = useValidationSchema();

  return (
    <Form
      data-testid="form"
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      blockPageOnSubmit
      title={formatMessage({ id: 'signUpForm.heading' })}
      submitButtonCaption={formatMessage({ id: 'common.signUp.label' })}
      {...props}
    >
      <TextField
        data-testid={`${FORM_FIELD_NAMES.FIRST_NAME_FIELD}Field`}
        placeholder={formatMessage({ id: 'signUpForm.firstNameField.placeholder' })}
        label={formatMessage({ id: 'signUpForm.firstNameField.label' })}
        name={FORM_FIELD_NAMES.FIRST_NAME_FIELD}
        isRequired
      />
      <TextField
        data-testid={`${FORM_FIELD_NAMES.LAST_NAME_FIELD}Field`}
        placeholder={formatMessage({ id: 'signUpForm.lastNameField.placeholder' })}
        label={formatMessage({ id: 'signUpForm.lastNameField.label' })}
        name={FORM_FIELD_NAMES.LAST_NAME_FIELD}
        isRequired
      />
      <TextField
        data-testid={`${FORM_FIELD_NAMES.USER_EMAIL_FIELD}Field`}
        placeholder={formatMessage({ id: 'signUpForm.userEmailField.placeholder' })}
        label={formatMessage({ id: 'signUpForm.userEmailField.label' })}
        name={FORM_FIELD_NAMES.USER_EMAIL_FIELD}
        isRequired
      />
      <TextField
        data-testid={`${FORM_FIELD_NAMES.USER_PASSWD_FIELD}Field`}
        placeholder={formatMessage({ id: 'signUpForm.userPasswordField.placeholder' })}
        type="password"
        label={formatMessage({ id: 'signUpForm.userPasswordField.label' })}
        name={FORM_FIELD_NAMES.USER_PASSWD_FIELD}
        deps={FORM_FIELD_NAMES.USER_CONFIRMED_PASSWD_FIELD}
        isRequired
      />
      <TextField
        data-testid={`${FORM_FIELD_NAMES.USER_CONFIRMED_PASSWD_FIELD}Field`}
        placeholder={formatMessage({ id: 'signUpForm.userConfirmPasswordField.placeholder' })}
        type="password"
        label={formatMessage({ id: 'signUpForm.userConfirmPasswordField.label' })}
        name={FORM_FIELD_NAMES.USER_CONFIRMED_PASSWD_FIELD}
        isRequired
      />
    </Form>
  );
};

SignUpForm.propTypes = pick(FORM_PROPS, [
  'onSubmit',
  'onSubmitFail',
  'onSubmitSuccess',
  'getFormApi',
]);

SignUpForm.defaultProps = {
  onSubmitFail: null,
  onSubmitSuccess: null,
  getFormApi: null,
};

export default SignUpForm;
