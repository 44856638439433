export const BACKEND_ERRORS_TRANSLATION_KEYS = {
  LOGIN: {
    400: {
      error: {
        no_active_account: 'loginForm.error.userEmailWasNotFound',
        wrong_password: 'loginForm.error.invalidPassword',
      },
    },
  },
  SIGNUP: {
    400: {
      email: {
        account_already_exists: 'signUpForm.error.userEmailAlreadyExists',
      },
    },
  },
  RESET_PASSWORD: {
    400: {
      token: {
        invalid: 'resetPasswordForm.error.linkExpired',
      },
    },
  },
};
