import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import EmptyContent from '../../EmptyContent';
import { ICON_TYPES } from '../../Icon';

const NoFilterResults = ({ onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      data-testid="emptyContent"
      iconProps={{
        type: ICON_TYPES.eyeCrossedOut,
      }}
      title={formatMessage({ id: 'filters.noFilterResults.title' })}
      description={formatMessage({ id: 'filters.noFilterResults.description' })}
      buttonProps={{
        caption: formatMessage({ id: 'filters.noFilterResults.button.label' }),
        onClick,
      }}
    />
  );
};

NoFilterResults.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NoFilterResults;
