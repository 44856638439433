import React, { useCallback } from 'react';
import { useUuiContext } from '@epam/uui';

import Notification from '../components/Notification';

export const DEFAULT_NOTIFICATION_DURATION = 8;

export const useShowNotification = () => {
  const { uuiNotifications } = useUuiContext();

  return useCallback(({
    type,
    message,
    duration,
    ...aditionalProps
  }) => {
    uuiNotifications.show((props) => (
      <Notification
        data-testid="notification"
        type={type}
        message={message}
        {...props}
        {...aditionalProps}
      />
    ), {
      position: 'top-center',
      duration: duration || DEFAULT_NOTIFICATION_DURATION,
    })
      .catch(() => null);
  }, [uuiNotifications]);
};
