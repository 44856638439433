import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import yup from '../../../../yup';
import { FORM_FIELD_NAMES } from '../constants';

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => (yup.object().shape({
    [FORM_FIELD_NAMES.FIRST_NAME_FIELD]: yup.string()
      .trim()
      .required(formatMessage({ id: 'signUpForm.error.firstNameRequired' }))
      .max(30, formatMessage({ id: 'signUpForm.error.firstNameMax' })),
    [FORM_FIELD_NAMES.LAST_NAME_FIELD]: yup.string()
      .trim()
      .required(formatMessage({ id: 'signUpForm.error.lastNameRequired' }))
      .max(30, formatMessage({ id: 'signUpForm.error.lastNameMax' })),
    [FORM_FIELD_NAMES.USER_EMAIL_FIELD]: yup.string()
      .emailLatin(formatMessage({ id: 'signUpForm.error.emailFormat' }))
      .required(formatMessage({ id: 'signUpForm.error.emailFormat' })),
    [FORM_FIELD_NAMES.USER_PASSWD_FIELD]: yup.string()
      .trim()
      .required(formatMessage({ id: 'signUpForm.error.passwordRequired' }))
      .min(8, formatMessage({ id: 'signUpForm.error.passwordMinLength' })),
    [FORM_FIELD_NAMES.USER_CONFIRMED_PASSWD_FIELD]: yup.string()
      .trim()
      .required(formatMessage({ id: 'signUpForm.error.passwordRequired' }))
      .oneOf(
        [yup.ref(FORM_FIELD_NAMES.USER_PASSWD_FIELD, {
          map: (value) => value?.trim(),
        })],
        formatMessage({ id: 'signUpForm.error.passwordMatch' }),
      ),
  })), [formatMessage]);
};
