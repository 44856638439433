import React from 'react';

import HasAccess from '../../HasAccess';
import UserInfo from './UserInfo';
import SsoAuthButton from '../../SsoAuthButton';

import styles from './Settings.module.scss';

const Settings = () => (
  <HasAccess
    data-testid="hasAccess"
    fallback={(
      <SsoAuthButton
        className={styles.ssoAuthButton}
      />
    )}
  >
    <UserInfo data-testid="userInfo" />
  </HasAccess>
);

export default Settings;
