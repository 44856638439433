import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Form from '../../Form';
import TextAreaField from '../../Form/connected/TextAreaField';
import RadioGroupField from '../../Form/connected/RadioGroupField';
import {
  FORM_FIELD_NAMES,
  QUESTION_CATEGORIES,
  QUESTION_CATEGORY_LABEL_ID_BY_TYPE,
} from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';

const ContactUsForm = (props) => {
  const { formatMessage } = useIntl();
  const validationSchema = useValidationSchema();

  const questionCategoryItems = useMemo(() => (
    Object.values(QUESTION_CATEGORIES).map((category) => ({
      id: category,
      name: formatMessage({ id: QUESTION_CATEGORY_LABEL_ID_BY_TYPE[category] }),
    }))), [formatMessage]);

  const defaultValues = useMemo(() => ({
    [FORM_FIELD_NAMES.QUESTION_CATEGORY]: QUESTION_CATEGORIES.GENERAL,
    [FORM_FIELD_NAMES.MESSAGE]: null,
  }), []);

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      hideButtons
      {...props}
    >
      <RadioGroupField
        name={FORM_FIELD_NAMES.QUESTION_CATEGORY}
        label={formatMessage({ id: 'contactUsForm.fields.questionCategory.label' })}
        items={questionCategoryItems}
      />
      <TextAreaField
        name={FORM_FIELD_NAMES.MESSAGE}
        label={formatMessage({ id: 'contactUsForm.fields.message.label' })}
        placeholder={formatMessage({ id: 'contactUsForm.fields.message.placeholder' })}
        isRequired
      />
    </Form>
  );
};

export default ContactUsForm;
