import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import DynamicFields from '../DynamicFields';
import TextField from '../TextField';
import TextAreaField from '../TextAreaField';
import { FIELD_NAMES } from './constants';

const ProductField = ({
  name,
  label,
  maxItemsCount,
}) => {
  const { formatMessage } = useIntl();
  const newItem = useMemo(() => ({
    [FIELD_NAMES.TITLE]: '',
    [FIELD_NAMES.DESCRIPTION]: '',
    [FIELD_NAMES.URL]: '',
  }), []);
  const resultLabel = useMemo(
    () => (label || formatMessage({ id: 'productField.label' })),
    [label, formatMessage],
  );

  const renderItem = useCallback((namePath) => (
    <>
      <TextField
        name={`${namePath}.${FIELD_NAMES.TITLE}`}
        label={formatMessage({ id: 'productField.titleField.label' })}
        placeholder={formatMessage({ id: 'productField.titleField.placeholder' })}
        isRequired
      />
      <TextAreaField
        name={`${namePath}.${FIELD_NAMES.DESCRIPTION}`}
        label={formatMessage({ id: 'productField.descriptionField.label' })}
        placeholder={formatMessage({ id: 'productField.descriptionField.placeholder' })}
        isRequired
      />
      <TextField
        name={`${namePath}.${FIELD_NAMES.URL}`}
        label={formatMessage({ id: 'productField.urlField.label' })}
        placeholder={formatMessage({ id: 'productField.urlField.placeholder' })}
        isRequired
      />
    </>
  ), [formatMessage]);

  return (
    <DynamicFields
      name={name}
      label={resultLabel}
      addButtonCaption={formatMessage({ id: 'productField.addButton.label' })}
      deleteButtonCaption={formatMessage({ id: 'productField.deleteButton.label' })}
      maxItemsCount={maxItemsCount}
      renderItem={renderItem}
      newItem={newItem}
      hint={formatMessage({ id: 'productField.hint' })}
    />
  );
};

ProductField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxItemsCount: PropTypes.number,
};

ProductField.defaultProps = {
  label: '',
  maxItemsCount: null,
};

export default ProductField;
