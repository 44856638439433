import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import IdeaData from './IdeaData';
import LikesAndCommentsBar from '../../LikesAndCommentsBar';
import IdeaTabs from './IdeaTabs';

import styles from './IdeaMainInfo.module.scss';

const IdeaMainInfo = ({
  ideaDataProps,
  likesAndCommentsProps,
  ideaTabsProps,
  className,
}) => (
  <div className={clsx(styles.wrapper, className)}>
    <IdeaData
      className={styles.ideaData}
      {...ideaDataProps}
      data-testid="ideaData"
    />
    <LikesAndCommentsBar
      className={styles.likesAndComments}
      {...likesAndCommentsProps}
      data-testid="likesAndCommentsBar"
    />
    <IdeaTabs
      {...ideaTabsProps}
      data-testid="ideaTabs"
    />
  </div>
);

IdeaMainInfo.propTypes = {
  ideaDataProps: PropTypes.object.isRequired,
  likesAndCommentsProps: PropTypes.object.isRequired,
  ideaTabsProps: PropTypes.object.isRequired,
  className: PropTypes.string,
};

IdeaMainInfo.defaultProps = {
  className: '',
};

export default IdeaMainInfo;
