import { useSelector } from 'react-redux';
import { EVENT_STAGES } from '../constants/event';
import { activeEventActiveStageTypeSelector } from '../redux/slices/events';

export const useIsAbleToVoteIdea = (idea) => {
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);

  if (!idea) {
    return false;
  }

  const { archive } = idea;

  if (archive) {
    return false;
  }

  return activeStageType === EVENT_STAGES.VOTING;
};
