import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Timer from '../Timer';
import { TIMER_TYPES } from '../Timer/constants';
import { activeEventSelector } from '../../redux/slices/events';

const EventTimer = () => {
  const { formatMessage } = useIntl();
  const { end_at: eventEndDate } = useSelector(activeEventSelector);

  return (
    <Timer
      data-testid="eventTimer"
      title={formatMessage({ id: 'eventTimer.title' })}
      date={eventEndDate}
      type={TIMER_TYPES.primary}
    />
  );
};

export default EventTimer;
