import React from 'react';
import { LabeledInput } from '@epam/promo';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { LABEL_SIZES } from './constants';

import styles from './Label.module.scss';

const Label = ({
  footer,
  children,
  className,
  size,
  ...props
}) => {
  const sizeClass = styles[`labelWrapper__size_${size}`];

  return (
    <div
      className={clsx(styles.labelWrapper, className, sizeClass)}
    >
      <LabeledInput
        cx={styles.label}
        {...props}
      >
        {children}
      </LabeledInput>
      {footer}
    </div>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  footer: PropTypes.node,
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.values(LABEL_SIZES)),
};

Label.defaultProps = {
  footer: null,
  children: null,
  className: '',
  size: LABEL_SIZES.md,
};

export default Label;
