import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Blocker as BlockerBase } from '@epam/promo';

import Spinner from '../Spinner';
import styles from './Blocker.module.scss';

const Blocker = ({ isFullPage, ...props }) => (
  <BlockerBase
    data-testid="blocker"
    isEnabled
    renderSpinner={() => <Spinner data-testid="spinner" />}
    cx={clsx(
      styles.blocker,
      { [styles.blocker__fullPage]: isFullPage },
    )}
    {...props}
  />
);

Blocker.defaultProps = {
  isFullPage: false,
};

Blocker.propTypes = {
  isFullPage: PropTypes.bool,
};

export default Blocker;
