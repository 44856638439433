import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Stage from './Stage';
import { EVENT_STAGES } from '../../constants/event';

import styles from './ProgressBar.module.scss';

const ProgressBar = ({
  stages,
  activeStageId,
  title,
  className,
  isCompleted,
}) => (
  <div className={clsx(styles.wrapper, className)}>
    <h3 className={styles.heading}>{title}</h3>
    <ul className={styles.list}>
      {stages.reduce(
        (acc, { id, title: stageTitle }) => {
          const isStageCompleted = (!acc.isActiveStepFound && activeStageId !== id) || isCompleted;
          const isStageActive = activeStageId === id && !isStageCompleted;

          return ({
            stageNodes: [
              ...acc.stageNodes,
              <li
                className={clsx(styles.item, {
                  [styles.item__active]: isStageActive,
                  [styles.item__completed]: isStageCompleted,
                })}
                key={id}
              >
                <Stage
                  data-testid={id}
                  title={stageTitle}
                  completed={isStageCompleted}
                  active={isStageActive}
                />
              </li>,
            ],
            isActiveStepFound: acc.isActiveStepFound || isStageActive,
          });
        },
        { stageNodes: [], isActiveStepFound: false },
      ).stageNodes}
    </ul>
  </div>
);

ProgressBar.propTypes = {
  title: PropTypes.string.isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.node.isRequired,
    }),
  ).isRequired,
  activeStageId: PropTypes.oneOf(Object.values(EVENT_STAGES)),
  className: PropTypes.string,
  isCompleted: PropTypes.bool.isRequired,
};

ProgressBar.defaultProps = {
  className: '',
  activeStageId: null,
};

export default ProgressBar;
