import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Tabs from '../Tabs';
import DashboardIcon from './DashboardIcon';
import PeopleIcon from './PeopleIcon';
import ConfigurationIcon from './ConfigurationIcon';
import PortalConfiguration from './PortalConfiguration';
import PeopleBoard from './PeopleBoard';
import {
  ADMIN_PANEL_TAB_IDS,
  ADMIN_PANEL_TAB_PARAM_NAME,
} from './constants';
import IdeasIcon from './IdeasIcon';
import IdeasBoard from './IdeasBoard';
import Dashboard from './Dashboard';
import EventNotStartedIdeasBoard from './IdeasBoard/EventNotStarted';
import EventNotStartedDashboard from './Dashboard/EventNotStarted';
import { activeEventStatusSelector } from '../../redux/slices/events';
import { EVENT_STATUSES } from '../../constants/event';

import styles from './AdminPanel.module.scss';

const AdminPanel = () => {
  const { formatMessage } = useIntl();
  const activeEventStatus = useSelector(activeEventStatusSelector);

  const tabItems = useMemo(() => ([
    {
      id: ADMIN_PANEL_TAB_IDS.people,
      caption: formatMessage({ id: 'adminPanel.adminPanelTabs.people.tabLabel' }),
      icon: PeopleIcon,
      iconPosition: 'left',
      Component: PeopleBoard,
    },
    {
      id: ADMIN_PANEL_TAB_IDS.ideas,
      caption: formatMessage({ id: 'adminPanel.adminPanelTabs.ideas.tabLabel' }),
      icon: IdeasIcon,
      iconPosition: 'left',
      Component: activeEventStatus === EVENT_STATUSES.FUTURE
        ? EventNotStartedIdeasBoard
        : IdeasBoard,
    },
    {
      id: ADMIN_PANEL_TAB_IDS.portalConfiguration,
      caption: formatMessage({ id: 'adminPanel.adminPanelTabs.portalConfiguration.tabLabel' }),
      icon: ConfigurationIcon,
      iconPosition: 'left',
      Component: PortalConfiguration,
    },
    {
      id: ADMIN_PANEL_TAB_IDS.dashboard,
      caption: formatMessage({ id: 'adminPanel.adminPanelTabs.dashboard.tabLabel' }),
      icon: DashboardIcon,
      iconPosition: 'left',
      Component: activeEventStatus === EVENT_STATUSES.FUTURE
        ? EventNotStartedDashboard
        : Dashboard,
    },
  ]), [formatMessage, activeEventStatus]);

  return (
    <div className={styles.wrapper}>
      <Tabs
        items={tabItems}
        isStretch
        tabsNavClassName={styles.tabsNav}
        urlParamName={ADMIN_PANEL_TAB_PARAM_NAME}
      />
    </div>
  );
};

export default AdminPanel;
