import React from 'react';
import PropTypes from 'prop-types';

import LikeableCheckboxGroup from '../LikeableCheckboxGroup';
import Label from '../Label';

const LikeableCheckboxGroupField = ({
  label,
  errorMessage,
  isRequired,
  footer,
  ...props
}) => (
  <Label
    label={label}
    isInvalid={!!errorMessage}
    validationMessage={errorMessage}
    isRequired={isRequired}
    footer={footer}
  >
    <LikeableCheckboxGroup {...props} />
  </Label>
);

LikeableCheckboxGroupField.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
  ).isRequired,
  footer: PropTypes.node,
  name: PropTypes.string.isRequired,
};

LikeableCheckboxGroupField.defaultProps = {
  errorMessage: '',
  isRequired: false,
  footer: null,
  label: null,
  items: [],
};

export default LikeableCheckboxGroupField;
