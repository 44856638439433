import {
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';
import { useUuiContext } from '@epam/uui';

import { uuid } from '../utils';

export const useModal = () => {
  const svc = useUuiContext();
  const modals = useRef({});

  const showModal = useCallback((callback) => {
    svc.uuiModals.show(({
      abort,
      success,
      ...props
    }) => {
      const modalId = uuid();

      modals.current[modalId] = abort;

      return callback({
        ...props,
        abort: (...params) => {
          delete modals.current[modalId];

          return abort(...params);
        },
        success: (...params) => {
          delete modals.current[modalId];

          return success(...params);
        },
      });
    }).then(() => {}).catch(() => {});
  }, [svc]);

  useLayoutEffect(() => (
    () => {
      Object.values(modals.current).forEach((abort) => abort());
    }
  ), []);

  return { showModal };
};
