import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeEvent: null,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setActiveEvent: (state, action) => {
      state.activeEvent = action.payload;
    },
  },
});

export const eventsSelector = (state) => state.events;
export const activeEventSelector = (state) => eventsSelector(state).activeEvent;
export const activeEventStatusSelector = (state) => activeEventSelector(state)?.status || null;
export const activeEventActiveStageSelector = (state) => (
  activeEventSelector(state)?.stages.find((stage) => stage.is_active) || null
);
export const activeEventActiveStageTypeSelector = (state) => (
  activeEventActiveStageSelector(state)?.type || null
);

export const {
  setActiveEvent,
} = eventsSlice.actions;

export default eventsSlice.reducer;
