import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { updateAccess } from '../../../redux/slices/access';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { updateUserDataFork } from '../../../listenerForks';

export const updateUserDataListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      newUserData,
      onSuccess,
    },
  } = action;
  const { dispatch, fork } = listenerApi;

  try {
    const updateUserDataForkResult = await fork(
      updateUserDataFork(dispatch, newUserData),
    ).result;

    if (updateUserDataForkResult.status !== 'ok') {
      isAxiosError(updateUserDataForkResult.error)
        && updateUserDataForkResult.error.handleGlobally();

      reject();
      return;
    }

    dispatch(updateAccess({
      user: updateUserDataForkResult.value,
    }));

    resolve();
    onSuccess();
  } catch (error) {
    reject();
  }
};

export const updateUserDataListenerActionCreator = createAction(ACTION_TYPES.user.updateUserData);
