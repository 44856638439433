import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Filters from '../Filters';
import Search from '../Search';

import styles from './SearchPanel.module.scss';

const SearchPanel = ({
  children,
  className,
  searchProps,
  filtersProps,
  id,
}) => (
  <div
    className={clsx(styles.searchPanel, className)}
    id={id}
  >
    {searchProps && (
      <div className={styles.search}>
        <Search
          {...searchProps}
          data-testid="search"
        />
      </div>
    )}
    <div className={styles.wrapper}>
      {filtersProps?.filters && (
        <Filters
          className={styles.filters}
          {...filtersProps}
          data-testid="filters"
        />
      )}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  </div>
);

SearchPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  searchProps: PropTypes.shape({
    defaultValue: PropTypes.string.isRequired,
    urlParamName: PropTypes.string.isRequired,
  }),
  filtersProps: PropTypes.shape({
    filters: PropTypes.array.isRequired,
    urlParamName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    defaultValues: PropTypes.object.isRequired,
    reportLabel: PropTypes.string.isRequired,
    hasActiveFilters: PropTypes.bool.isRequired,
  }),
};

SearchPanel.defaultProps = {
  children: null,
  className: '',
  searchProps: null,
  filtersProps: null,
  id: null,
};

export default SearchPanel;
