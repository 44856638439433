import React from 'react';
import { Switch as SwitchBase } from '@epam/promo';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { SWITCH_LABEL_POSITIONS } from './constants';

import styles from './Switch.module.scss';

const Switch = ({
  labelPosition,
  ...props
}) => (
  <SwitchBase
    {...props}
    cx={clsx(styles.switch, {
      [styles.switch__labelPosition_start]: labelPosition === SWITCH_LABEL_POSITIONS.start,
    })}
  />
);

Switch.propTypes = {
  labelPosition: PropTypes.oneOf(Object.values(SWITCH_LABEL_POSITIONS)),
};

Switch.defaultProps = {
  labelPosition: SWITCH_LABEL_POSITIONS.end,
};

export default Switch;
