import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import EmptyContent from '../../EmptyContent';
import { ICON_TYPES } from '../../Icon';

const NoSearchPeople = ({ value }) => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.semicirclesCrossed,
      }}
      title={formatMessage(
        { id: 'peopleList.noSearchPeople.title' },
        { value },
      )}
      description={formatMessage({ id: 'peopleList.noSearchPeople.description' })}
    />
  );
};

NoSearchPeople.propTypes = {
  value: PropTypes.string.isRequired,
};

export default NoSearchPeople;
