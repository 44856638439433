import React, {
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import Label from '../../Label';
import LinkButton from '../../../LinkButton';
import AddIcon from './AddIcon';
import DeleteIcon from './DeleteIcon';
import Hint from '../../../Hint';

import styles from './DynamicFields.module.scss';

const DynamicFields = ({
  name,
  label,
  renderItem,
  isRequired,
  footer,
  newItem,
  addButtonCaption,
  deleteButtonCaption,
  maxItemsCount,
  hint,
}) => {
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name,
  });

  const handleAddItemButtonClick = useCallback(
    () => append(newItem),
    [append, newItem],
  );
  const isAddButtonDisabled = useMemo(
    () => !!(maxItemsCount && fields.length >= maxItemsCount),
    [fields.length, maxItemsCount],
  );

  return (
    <Label
      data-testid="label"
      label={label}
      isRequired={isRequired}
      footer={footer}
      className={styles.dynamicFields}
      cx={styles.label}
    >
      {fields.map((field, index) => (
        <div
          className={styles.item}
          key={field.id}
        >
          {renderItem(`${name}.${index}`)}
          {hint && (
            <Hint
              hint={hint}
              className={styles.hint}
            />
          )}
          <div className={styles.itemActions}>
            <LinkButton
              data-testid={`deleteButton${field.id}`}
              size="42"
              caption={deleteButtonCaption}
              onClick={() => remove(index)}
              rawProps={{
                type: 'button',
              }}
              color="red"
              cx={styles.deleteButton}
              icon={DeleteIcon}
            />
          </div>
        </div>
      ))}
      <LinkButton
        data-testid="addButton"
        size="42"
        caption={addButtonCaption}
        onClick={handleAddItemButtonClick}
        rawProps={{
          type: 'button',
        }}
        cx={styles.addButton}
        icon={AddIcon}
        isDisabled={isAddButtonDisabled}
      />
    </Label>
  );
};

DynamicFields.propTypes = {
  name: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  footer: PropTypes.node,
  newItem: PropTypes.object.isRequired,
  addButtonCaption: PropTypes.string.isRequired,
  deleteButtonCaption: PropTypes.string.isRequired,
  maxItemsCount: PropTypes.number,
  hint: PropTypes.node,
};

DynamicFields.defaultProps = {
  isRequired: false,
  footer: null,
  maxItemsCount: null,
  hint: null,
};

export default DynamicFields;
