export const API_TAG_TYPES = {
  idea: 'Idea',
  userInfo: 'UserInfo',
  account: 'Account',
  bizObjective: 'BizObjective',
  techCategory: 'TechCategory',
  ideaSolution: 'IdeaSolution',
  announceWinnerMetaData: 'AnnounceWinnerMetaData',
  userVotesMeta: 'UserVotesMeta',
};
