import React, {
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { useIntl } from 'react-intl';

import Tooltip from '../Tooltip';

import styles from './CopyToClipboard.module.scss';

const CopyToClipboard = ({
  dataToCopy,
  onCopy,
  entityName,
  children,
}) => {
  const elementBeforeRef = useRef(null);
  const { formatMessage } = useIntl();
  const beforeCopyTooltipMessage = formatMessage({ id: 'copyToClipboard.beforeCopyLabel' }, { entityName });
  const afterCopyTooltipMessage = formatMessage({ id: 'copyToClipboard.afterCopyLabel' }, { entityName });
  const [tooltipMessage, setTooltipMessage] = useState(beforeCopyTooltipMessage);

  const copyToClipboard = useCallback(() => {
    copy(dataToCopy);
    onCopy?.();
    setTooltipMessage(afterCopyTooltipMessage);
  }, [
    dataToCopy,
    onCopy,
    afterCopyTooltipMessage,
  ]);

  const restoreTooltipMessage = useCallback(
    () => setTooltipMessage(beforeCopyTooltipMessage),
    [
      setTooltipMessage,
      beforeCopyTooltipMessage,
    ],
  );

  useLayoutEffect(() => {
    const span = elementBeforeRef.current;

    span.nextSibling.addEventListener('click', copyToClipboard);
    span.nextSibling.addEventListener('mouseleave', restoreTooltipMessage);

    return () => {
      span.nextSibling.removeEventListener('click', copyToClipboard);
      span.nextSibling.removeEventListener('mouseleave', restoreTooltipMessage);
    };
  });

  return (
    <>
      <span
        className={styles.elementBefore}
        ref={elementBeforeRef}
      />
      <Tooltip
        data-testid="tooltip"
        content={tooltipMessage}
        trigger="hover"
        placement="auto"
      >
        {children}
      </Tooltip>
    </>
  );
};

CopyToClipboard.propTypes = {
  dataToCopy: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
  entityName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CopyToClipboard.defaultProps = {
  entityName: null,
  onCopy: null,
};

export default CopyToClipboard;
