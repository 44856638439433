import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../../constants/actionTypes';
import { isAxiosError } from '../../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../../redux/api/slices/ideaApi';

export const deselectIdeaAsWinnerListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      ideaId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const deselectIdeaAsWinner = dispatch(
    ideaApi.endpoints.deselectIdeaAsWinner.initiate({
      eventHrid,
      ideaId,
    }),
  );

  try {
    await pause(deselectIdeaAsWinner.unwrap());

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    deselectIdeaAsWinner.unsubscribe();
  }
};

export const deselectIdeaAsWinnerActionCreator = createAction(
  ACTION_TYPES.idea.deselectIdeaAsWinner,
);
