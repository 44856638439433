import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import StepperBase, { STEPPER_VIEWS } from '../../Stepper';
import SelectBusinessObjectiveStep from './SelectBusinessObjectiveStep';
import ProvideGeneralOverviewStep from './ProvideGeneralOverviewStep';
import AttachLinksStep from './AttachLinksStep/AttachLinksStep';
import { STEPS_IDS } from './constants';
import {
  convertStepperValuesToIdea,
  replaceLinkInvalidUrl,
} from './utils';
import { FORM_FIELD_NAMES } from '../../forms/AttachLinksForm/constants';
import { ACTIVE_EVENT_HRID } from '../../../constants/event';

const { links: linksFieldName } = FORM_FIELD_NAMES;

const IdeaStepper = ({
  onDone,
  onSaveAsDraft,
  defaultValues,
  onDiscard,
  doneButtonCaption,
}) => {
  const { ideaId } = useParams();
  const { formatMessage } = useIntl();
  const steps = useMemo(() => ([{
    id: STEPS_IDS.SELECT_BUSINESS_OBJECTIVE,
    Step: SelectBusinessObjectiveStep,
    title: formatMessage({ id: 'newIdea.steps.selectBusinessObjective.stepTitle' }),
  }, {
    id: STEPS_IDS.PROVIDE_GENERAL_OVERVIEW,
    Step: ProvideGeneralOverviewStep,
    title: formatMessage({ id: 'newIdea.steps.provideGeneralOverview.stepTitle' }),
  }, {
    id: STEPS_IDS.ATTACH_LINKS,
    Step: AttachLinksStep,
    title: formatMessage({ id: 'newIdea.steps.attachLinks.stepTitle' }),
  }]), [formatMessage]);

  const handleDone = useCallback(
    (formValues) => onDone({
      formData: convertStepperValuesToIdea(formValues),
      ideaId,
      eventHrid: ACTIVE_EVENT_HRID,
    }),
    [
      onDone,
      ideaId,
    ],
  );
  const handleSaveAsDraft = useCallback(
    (formValues) => {
      const data = convertStepperValuesToIdea(formValues);
      const formData = {
        ...data,
        [linksFieldName]: data[linksFieldName]?.map(replaceLinkInvalidUrl),
      };

      return onSaveAsDraft({
        formData,
        ideaId,
        eventHrid: ACTIVE_EVENT_HRID,
      });
    },
    [
      onSaveAsDraft,
      ideaId,
    ],
  );

  return (
    <StepperBase
      data-testid="stepperBase"
      steps={steps}
      onDone={handleDone}
      onDiscard={onDiscard}
      doneButtonCaption={doneButtonCaption}
      onSaveAsDraft={onSaveAsDraft ? handleSaveAsDraft : null}
      view={STEPPER_VIEWS.secondary}
      defaultValues={defaultValues}
      preventLeavingWhenDirty
    />
  );
};

IdeaStepper.propTypes = {
  onDone: PropTypes.func.isRequired,
  onSaveAsDraft: PropTypes.func,
  defaultValues: PropTypes.object,
  onDiscard: PropTypes.func.isRequired,
  doneButtonCaption: PropTypes.string.isRequired,
};

IdeaStepper.defaultProps = {
  defaultValues: {},
  onSaveAsDraft: null,
};

export default IdeaStepper;
