import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../Icon';

const AvatarIcon = () => (
  <Icon
    data-testid="icon"
    type={ICON_TYPES.avatar}
    color={ICON_COLOR.secondary}
    size={ICON_SIZE.xxs}
  />
);

export default AvatarIcon;
