import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../../Icon';

const DeactivateIcon = () => (
  <Icon
    data-testid="icon"
    type={ICON_TYPES.deactivate}
    size={ICON_SIZE.xxs}
    color={ICON_COLOR.quaternary}
  />
);

export default DeactivateIcon;
