import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../Button';
import { ROUTES } from '../../../constants/routes';
import Animation from '../../Animation';
import leadershipCheckpointAnimationData from './animations/leadershipCheckpoint.json';
import StageBadge from '../../StageBadge';
import {
  EVENT_STAGES,
  EVENT_STAGES_ORDER,
} from '../../../constants/event';

import styles from './Banner.module.scss';

const Banner = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <StageBadge
          data-testid="stageBadge"
          stageNumber={EVENT_STAGES_ORDER[EVENT_STAGES.DOWN_SELECTION]}
        />
        <h2 className={styles.header}>
          {formatMessage({ id: 'leadershipWelcome.banner.header' })}
        </h2>
        <div className={styles.description}>
          {formatMessage({ id: 'leadershipWelcome.banner.description' })}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            data-testid="viewIdeaButton"
            caption={formatMessage({ id: 'leadershipWelcome.banner.viewIdeaButtonCaption' })}
            link={{ pathname: ROUTES.ideas }}
            size="42"
          />
        </div>
        <Animation
          data-testid="leadershipCheckpointAnimation"
          className={styles.animation}
          animationData={leadershipCheckpointAnimationData}
        />
      </div>
    </div>
  );
};

export default Banner;
