import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import AvatarIcon from './AvatarIcon';
import CalendarIcon from './CalendarIcon';
import FolderIcon from './FolderIcon';

import styles from './EntityMainAttributes.module.scss';

const EntityMainAttributes = ({
  id,
  author,
  date,
  className,
}) => (
  <div className={clsx(styles.wrapper, className)}>
    {id && (
      <div className={styles.badge}>
        <FolderIcon data-testid="folderIcon" />
        <p className={styles.lable}>{`${id}`}</p>
      </div>
    )}
    {author && (
      <div className={styles.badge}>
        <AvatarIcon data-testid="avatarIcon" />
        <p className={styles.lable}>{author}</p>
      </div>
    )}
    {date && (
      <div className={styles.badge}>
        <CalendarIcon data-testid="calendarIcon" />
        <p className={styles.lable}>{date}</p>
      </div>
    )}
  </div>
);

EntityMainAttributes.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  author: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
};

EntityMainAttributes.defaultProps = {
  id: null,
  author: null,
  date: null,
  className: '',
};

export default EntityMainAttributes;
