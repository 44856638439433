import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  activeEventActiveStageTypeSelector,
  activeEventStatusSelector,
} from '../../../redux/slices/events';
import { EVENT_STATUSES } from '../../../constants/event';
import { BREADCRUMB_TRANSLATION_KEY_BY_STAGE_TYPE } from './constants';

const IdeasCatalogBreadcrumb = () => {
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const activeEventStatus = useSelector(activeEventStatusSelector);
  const { formatMessage } = useIntl();

  return formatMessage(
    {
      id: activeEventStatus === EVENT_STATUSES.FINISHED
        ? 'breadcrumbs.pages.winnersList'
        : BREADCRUMB_TRANSLATION_KEY_BY_STAGE_TYPE[activeStageType],
    },
  );
};

export default IdeasCatalogBreadcrumb;
