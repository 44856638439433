import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Winners from './Winners';
import Animation from '../../Animation';
import winnerAnimationData from './animations/winners.json';

import styles from './Banner.module.scss';

const Banner = ({
  winners,
}) => {
  const { formatMessage } = useIntl();
  const winnersAmount = winners.length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <div className={styles.badge}>
          {formatMessage(
            { id: 'winnerWelcome.banner.title' },
            { amount: winnersAmount },
          )}
        </div>
        <h2 className={styles.header}>
          {formatMessage(
            { id: 'winnerWelcome.banner.header' },
            { amount: winnersAmount },
          )}
        </h2>
        <div className={styles.description}>
          {formatMessage({ id: 'winnerWelcome.banner.description' })}
        </div>
        <Winners
          data-testid="winners"
          ideas={winners}
        />
        <Animation
          className={styles.animation}
          animationData={winnerAnimationData}
        />
      </div>
    </div>
  );
};

Banner.propTypes = {
  winners: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Banner;
