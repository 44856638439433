import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TabsNav from '../../../Tabs/TabsNav';
import {
  IDEA_TAB_IDS,
  IDEA_TAB_URL_PARAM_NAME,
} from '../../constants';

const IdeaTabs = ({
  activeTabId,
}) => {
  const { formatMessage } = useIntl();
  const items = useMemo(() => (
    [
      {
        id: IDEA_TAB_IDS.comments,
        caption: formatMessage({ id: 'idea.ideaTabs.comments.label' }),
      },
      {
        id: IDEA_TAB_IDS.links,
        caption: formatMessage({ id: 'idea.ideaTabs.linksTab.tabLabel' }),
      },
      // {
      //   id: IDEA_TAB_IDS.solutions,
      //   caption: <FormattedMessage id="idea.ideaTabs.solutionsTab.tabLabel" />,
      // },
    ]
  ), [formatMessage]);

  return (
    <TabsNav
      items={items}
      activeTabId={activeTabId}
      urlParamName={IDEA_TAB_URL_PARAM_NAME}
      data-testid="tabsNav"
    />
  );
};

IdeaTabs.propTypes = {
  activeTabId: PropTypes.string.isRequired,
};

export default IdeaTabs;
