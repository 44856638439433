import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Form, { FORM_TITLE_SIZES } from '../../Form';
import {
  FILTER_COMPONENT_BY_TYPE,
  FILTER_DEFAULT_PROPS_BY_TYPE,
  FILTER_DEFAULT_VALUES_BY_TYPE,
  FILTER_PROPS_BY_TYPE,
} from './constants';
import { LABEL_SIZES } from '../../Form/Label';

import styles from './FiltersForm.module.scss';

const FiltersForm = ({
  filters,
  onFilterChange,
  defaultValues,
  ...props
}) => {
  const resultDefaultValues = useMemo(() => ({
    ...filters.reduce((currentValue, { name, type }) => ({
      ...currentValue,
      [name]: FILTER_DEFAULT_VALUES_BY_TYPE[type],
    }), {}),
    ...defaultValues,
  }), [defaultValues, filters]);

  return (
    <Form
      titleSize={FORM_TITLE_SIZES.XS}
      onWatch={onFilterChange}
      defaultValues={resultDefaultValues}
      titleClassName={styles.title}
      hideButtons
      {...props}
    >
      {
        filters.map(({ name, type, ...rest }) => {
          const Component = FILTER_COMPONENT_BY_TYPE[type];

          return (
            <Component
              key={name}
              name={name}
              labelSize={LABEL_SIZES.lg}
              {...FILTER_DEFAULT_PROPS_BY_TYPE[type] || {}}
              {...rest}
            />
          );
        })
      }
    </Form>
  );
};

FiltersForm.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.oneOfType(
      Object.values(FILTER_PROPS_BY_TYPE)
        .map((propsCollection) => PropTypes.shape(propsCollection)),
    ),
  ).isRequired,
  defaultValues: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

FiltersForm.defaultProps = {
  defaultValues: {},
};

export default FiltersForm;
