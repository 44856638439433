import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { TAB_TYPES } from '../constants';
import {
  EVENT_STAGES,
  EVENT_STATUSES,
} from '../../../../constants/event';
import {
  activeEventActiveStageTypeSelector,
  activeEventStatusSelector,
} from '../../../../redux/slices/events';

export const useGetTabs = () => {
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const activeEventStatus = useSelector(activeEventStatusSelector);
  const { formatMessage } = useIntl();

  const tabs = useMemo(() => (
    activeEventStatus === EVENT_STATUSES.FINISHED
      ? undefined
      : [
        {
          id: TAB_TYPES.allIdeas,
          caption: formatMessage({ id: 'ideas.ideasList.allIdeas.filterLabel' }),
        },
        {
          id: TAB_TYPES.likedIdeas,
          caption: formatMessage({ id: 'ideas.ideasList.likedIdeas.filterLabel' }),
        },
        {
          id: TAB_TYPES.myIdeas,
          caption: formatMessage({ id: 'ideas.ideasList.myIdeas.filterLabel' }),
        },
        ...(activeStageType === EVENT_STAGES.IDEA_SUBMISSION
          ? [{
            id: TAB_TYPES.draftIdeas,
            caption: formatMessage({ id: 'ideas.ideasList.draftIdeas.filterLabel' }),
          }]
          : []),
        {
          id: TAB_TYPES.archivedIdeas,
          caption: formatMessage({ id: 'ideas.ideasList.archivedIdeas.filterLabel' }),
        },
      ]
  ), [
    activeEventStatus,
    activeStageType,
    formatMessage,
  ]);

  return tabs;
};
