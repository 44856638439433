import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_TITLE_SIZES, FORM_PROPS } from '../../Form';
import TextField from '../../Form/connected/TextField';
import { FORM_FIELD_NAMES } from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';

import styles from './UserGeneralInformationForm.module.scss';

const UserGeneralInformationForm = ({
  className,
  defaultValues,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const validationSchema = useValidationSchema();

  return (
    <Form
      className={className}
      titleClassName={styles.formTitle}
      data-testid="form"
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      title={formatMessage({ id: 'userGeneralInformationForm.title' })}
      titleSize={FORM_TITLE_SIZES.SM}
      blockPageOnSubmit
      preventLeavingWhenDirty
      {...props}
    >
      <TextField
        name={FORM_FIELD_NAMES.FIRST_NAME}
        label={formatMessage({ id: 'userGeneralInformationForm.firstNameField.label' })}
        isRequired
      />
      <TextField
        name={FORM_FIELD_NAMES.LAST_NAME}
        label={formatMessage({ id: 'userGeneralInformationForm.lastNameField.label' })}
        isRequired
      />
      <TextField
        name={FORM_FIELD_NAMES.EMAIL}
        label={formatMessage({ id: 'userGeneralInformationForm.emailField.label' })}
        isRequired
        isDisabled
      />
    </Form>
  );
};

UserGeneralInformationForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
  ]),
  defaultValues: PropTypes.shape({
    [FORM_FIELD_NAMES.FIRST_NAME]: PropTypes.string.isRequired,
    [FORM_FIELD_NAMES.LAST_NAME]: PropTypes.string.isRequired,
    [FORM_FIELD_NAMES.EMAIL]: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
};

UserGeneralInformationForm.defaultProps = {
  defaultValues: {
    [FORM_FIELD_NAMES.FIRST_NAME]: '',
    [FORM_FIELD_NAMES.LAST_NAME]: '',
    [FORM_FIELD_NAMES.EMAIL]: '',
  },
  className: '',
};

export default UserGeneralInformationForm;
