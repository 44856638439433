import { ICON_TYPES } from '../../../../Icon/Icon';

export const INFO_BY_TYPE = {
  IDEAS: 'ideas',
  USERS: 'users',
  PROPOSED_SOLUTIONS: 'proposed_solutions',
  LIKES: 'likes',
  COMMENTS: 'comments',
};

export const ICON_BY_INFO_TYPE = {
  [INFO_BY_TYPE.IDEAS]: ICON_TYPES.file,
  [INFO_BY_TYPE.USERS]: ICON_TYPES.person,
  [INFO_BY_TYPE.PROPOSED_SOLUTIONS]: ICON_TYPES.fileWithStats,
  [INFO_BY_TYPE.LIKES]: ICON_TYPES.like,
  [INFO_BY_TYPE.COMMENTS]: ICON_TYPES.comment,
};

export const DESCRIPTION_TRANSLATION_KEYS_BY_INFO_TYPE = {
  [INFO_BY_TYPE.IDEAS]: 'adminPanel.dashboard.summaryInfoItem.ideas.description',
  [INFO_BY_TYPE.USERS]: 'adminPanel.dashboard.summaryInfoItem.users.description',
  [INFO_BY_TYPE.PROPOSED_SOLUTIONS]: 'adminPanel.dashboard.summaryInfoItem.proposedSolutions.description',
  [INFO_BY_TYPE.LIKES]: 'adminPanel.dashboard.summaryInfoItem.likes.description',
  [INFO_BY_TYPE.COMMENTS]: 'adminPanel.dashboard.summaryInfoItem.comments.description',
};
