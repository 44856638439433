import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import Logo from '../../Logo';

import styles from './Sidebar.module.scss';

const Sidebar = ({ className }) => (
  <aside className={clsx(styles.sidebar, className)}>
    <div className={styles.logoWrapper}>
      <Logo data-testid="logo" />
    </div>
  </aside>
);

Sidebar.propTypes = {
  className: PropTypes.string,
};

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;
