import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import SignUpSuggestion from './SignUpSuggestion';
import LoginSuggestion from './LoginSuggestion';

import styles from './Header.module.scss';

const Header = ({ className }) => (
  <header className={className}>
    <Switch data-testid="switch">
      <Route
        data-testid="loginRoute"
        exact
        path={[ROUTES.login]}
      >
        <div className={styles.content}>
          <SignUpSuggestion data-testid="signUpSuggestion" />
        </div>
      </Route>
      <Route
        data-testid="signUpRoute"
        exact
        path={[
          ROUTES.signUp,
          ROUTES.forgotPassword,
          ROUTES.resetPassword,
        ]}
      >
        <div className={styles.content}>
          <LoginSuggestion data-testid="loginSuggestion" />
        </div>
      </Route>
    </Switch>
  </header>
);

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
