import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from '../../Modal';

const DeactivateBusinessCategoryConfirmationModal = ({
  bizCategory,
  success,
  abort,
  onDeactivate,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivateConfirm = useCallback(async () => {
    setIsLoading(true);
    const { id } = bizCategory;
    try {
      await onDeactivate(id);

      success();
    } finally {
      setIsLoading(false);
    }
  }, [
    success,
    onDeactivate,
    bizCategory,
  ]);

  return (
    <Modal
      title={
        formatMessage({ id: 'deactivateBizCategoryConfirmationModal.title' })
      }
      content={
        formatMessage({ id: 'deactivateBizCategoryConfirmationModal.description' })
      }
      onClose={abort}
      onReject={abort}
      onConfirm={handleDeactivateConfirm}
      confirmButtonCaption={
        formatMessage({ id: 'deactivateBizCategoryConfirmationModal.deactivateButton.label' })
      }
      isLoading={isLoading}
      {...props}
    />
  );
};

DeactivateBusinessCategoryConfirmationModal.propTypes = {
  bizCategory: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  success: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

export default DeactivateBusinessCategoryConfirmationModal;
