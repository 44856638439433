import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './FormDescription.module.scss';

const FormDescription = ({ children, className }) => (
  <p className={clsx(className, styles.formDescription)}>
    {children}
  </p>
);

FormDescription.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

FormDescription.defaultProps = {
  className: '',
  children: null,
};

export default FormDescription;
