import { createAction } from '@reduxjs/toolkit';
import { generatePath } from 'react-router-dom';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { ROUTES } from '../../../../constants/routes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../redux/api/slices/ideaApi';
import history from '../../../../browserHistory';

export const updateIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formData,
      showToast,
      formatMessage,
      ideaId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;
  const updateIdea = dispatch(ideaApi.endpoints.updateIdeaById.initiate({
    ideaId,
    eventHrid,
    data: formData,
  }));

  try {
    await pause(updateIdea.unwrap());

    history.push(generatePath(ROUTES.idea, { ideaId }));

    showToast({
      message: formatMessage({ id: 'updateIdea.stepper.successIdeaUpdate' }),
    });

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    updateIdea.unsubscribe();
  }
};

export const updateIdeaActionCreator = createAction(ACTION_TYPES.idea.updateIdea);
