import { useSelector } from 'react-redux';

import { useGetIdeasQuery } from '../../../../redux/api/slices/ideaApi';
import { userIdSelector } from '../../../../redux/slices/access';
import { TAB_TYPES } from '../constants';

export const useGetIdeas = ({
  page,
  pageSize,
  tab,
  sort,
  filters = {},
  eventHrid,
}) => {
  const additionalParams = {};
  const currentUserId = useSelector(userIdSelector);
  additionalParams.isArchive = false;

  switch (tab) {
    case TAB_TYPES.myIdeas:
      additionalParams.authorId = currentUserId;
      break;
    case TAB_TYPES.likedIdeas:
      additionalParams.likedBy = currentUserId;
      break;
    case TAB_TYPES.draftIdeas:
      additionalParams.isDraft = true;
      break;
    case TAB_TYPES.archivedIdeas:
      additionalParams.isArchive = true;
      break;
    default:
      break;
  }

  return useGetIdeasQuery({
    eventHrid,
    page,
    pageSize,
    sort,
    ...filters,
    ...additionalParams,
  }, { refetchOnMountOrArgChange: true });
};
