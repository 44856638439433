import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import InfoBadge from '../InfoBadge';
import indicatorAnimationData from './animations/indicator.json';
import Animation from '../Animation';

import styles from './StageBadge.module.scss';

const StageBadge = ({ isActive, stageNumber }) => {
  const { formatMessage } = useIntl();

  return (
    <InfoBadge
      data-testid="infoBage"
      isActive={isActive}
    >
      {isActive && (
        <Animation
          data-testid="indicatorAnimation"
          animationData={indicatorAnimationData}
          className={styles.indicator}
        />
      )}
      {formatMessage({ id: 'common.stage' })}
      {' '}
      {stageNumber}
    </InfoBadge>
  );
};

StageBadge.propTypes = {
  stageNumber: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
};

StageBadge.defaultProps = {
  isActive: true,
};

export default StageBadge;
