import { createSlice } from '@reduxjs/toolkit';

import { getTokenFromLocalStorage } from '../../utils';
import { resetActionCreator } from '../actionCreators';

const initialState = {
  token: getTokenFromLocalStorage() || null,
  user: {},
};

const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    updateAccess: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetActionCreator, (state) => {
        state.token = null;
      })
      .addDefaultCase((state) => state); // I did not find if addDefaultCase is optional so added it
  },
});

export const tokenSelector = (state) => state.access.token;
export const userSelector = (state) => state.access.user;
export const userRoleSelector = (state) => state.access.user.role;
export const userIdSelector = (state) => state.access.user.id;
export const userBizCategoriesInterestSelector = (state) => state
  .access.user.biz_category_interests || [];

export const {
  updateAccess,
  setToken,
} = accessSlice.actions;

export default accessSlice.reducer;
