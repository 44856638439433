import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import StepperBase from '../../Stepper';
import BusinessObjectivesStep from './BusinessObjectivesStep';
// import TechnicalCategoriesStep from './TechnicalCategoriesStep';
import { STEPS_IDS } from './constants';
import { useListener } from '../../../hooks';
import {
  updateUserDataListener,
  updateUserDataActionCreator,
} from '../listeners/updateUserDataListener';
import { userIdSelector } from '../../../redux/slices/access';

const Stepper = () => {
  const currentUserId = useSelector(userIdSelector);
  const [updateUserData] = useListener({
    listener: updateUserDataListener,
    actionCreator: updateUserDataActionCreator,
  });
  const { formatMessage } = useIntl();
  const steps = useMemo(() => ([{
    id: STEPS_IDS.BUSINESS_OBJECTIVES,
    Step: BusinessObjectivesStep,
    title: formatMessage({ id: 'onboarding.steps.businessObjectives.stepTitle' }),
  },
    // {
    //   id: STEPS_IDS.TECHNICAL_CATEGORIES,
    //   Step: TechnicalCategoriesStep,
    //   title: formatMessage({ id: 'onboarding.steps.technicalCategories.stepTitle' }),
    // }
  ]), [formatMessage]);

  const handleDone = useCallback((data) => {
    const userData = {
      id: currentUserId,
      ...data[STEPS_IDS.BUSINESS_OBJECTIVES],
      // ...data[STEPS_IDS.TECHNICAL_CATEGORIES],
    };

    return updateUserData({ userData });
  }, [
    updateUserData,
    currentUserId,
  ]);

  return (
    <StepperBase
      data-testid="stepper"
      steps={steps}
      onDone={handleDone}
      doneButtonCaption={formatMessage({ id: 'onboarding.stepper.doneButtonCaption' })}
      preventLeavingWhenDirty
    />
  );
};

export default Stepper;
