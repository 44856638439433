import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useGenerateLink } from '../../../hooks';

export const useGetFiltersClearHandler = ({ urlParamName }) => {
  const { generateLink } = useGenerateLink();
  const history = useHistory();

  const clearFilters = useCallback(() => {
    history.push(generateLink({ [urlParamName]: undefined }));
  }, [
    history,
    generateLink,
    urlParamName,
  ]);

  return useMemo(
    () => ({ clearFilters }),
    [clearFilters],
  );
};
