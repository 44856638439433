import { flushSync } from 'react-dom';
import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { ROUTES } from '../../../constants/routes';
import history from '../../../browserHistory';
import { updateUserDataFork } from '../../../listenerForks';
import { updateAccess } from '../../../redux/slices/access';
import { isAxiosError } from '../../../redux/api/fetchClient';

export const updateUserDataListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      userData,
    },
  } = action;
  const { dispatch, fork } = listenerApi;

  try {
    const updateUserDataForkResult = await fork(
      updateUserDataFork(dispatch, userData),
    ).result;

    if (updateUserDataForkResult.status !== 'ok') {
      isAxiosError(updateUserDataForkResult.error)
        && updateUserDataForkResult.error.handleGlobally();

      reject();
      return;
    }

    flushSync(() => {
      dispatch(updateAccess({
        user: updateUserDataForkResult.value,
      }));

      history.push(ROUTES.base);
    });

    resolve();
  } catch (error) {
    reject();
  }
};

export const updateUserDataActionCreator = createAction(
  ACTION_TYPES.onboarding.updateUserData,
);
