import React, { useCallback } from 'react';

import SignUpForm from '../forms/SignUpForm';
import { signUpListener, signUpListenerActionCreator } from './listeners/signUpListener';
import { useListener } from '../../hooks';

import styles from './SignUp.module.scss';

const SignUp = () => {
  const [signUpUser] = useListener({
    listener: signUpListener,
    actionCreator: signUpListenerActionCreator,
  });
  const handleSubmit = useCallback(
    (formData) => signUpUser({ formData }),
    [signUpUser],
  );

  return (
    <div className={styles.formContainer}>
      <SignUpForm
        data-testid="signUpForm"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default SignUp;
