import React from 'react';
import PropTypes from 'prop-types';

import RadioGroup from '../RadioGroup';
import Label from '../Label';

const RadioGroupField = ({
  label,
  errorMessage,
  onChange,
  isRequired,
  footer,
  onBlur,
  tooltip,
  ...props
}) => (
  <Label
    data-testid="label"
    label={label}
    isInvalid={!!errorMessage}
    validationMessage={errorMessage}
    isRequired={isRequired}
    footer={footer}
    info={tooltip}
  >
    <RadioGroup
      data-testid="radioGroup"
      isInvalid={!!errorMessage}
      onValueChange={onChange}
      radioInputProps={{
        rawProps: {
          onBlur,
        },
      }}
      {...props}
    />
  </Label>
);

RadioGroupField.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
  isRequired: PropTypes.bool,
  footer: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

RadioGroupField.defaultProps = {
  value: null,
  label: null,
  errorMessage: '',
  tooltip: null,
  isRequired: false,
  footer: null,
  items: [],
};

export default RadioGroupField;
