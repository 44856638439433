import {
  IDEA_TYPES,
} from '../../../constants/idea';

export const FORM_FIELD_NAMES = {
  IDEA_TITLE: 'title',
  IDEA_TYPE: 'idea_type',
  REASON: 'reason',
  POTENTIAL_SOLUTION: 'potential_solution',
  BUSINESS_BENEFITS: 'business_benefits',
};

export const REASON_FIELD_TRANSLATION_IDS_BY_IDEA_TYPE = {
  [IDEA_TYPES.UNSOLVED_CHALLENGE]: {
    label: 'generalOverviewForm.reason.unsolvedChallenge.label',
    placeholder: 'generalOverviewForm.reason.unsolvedChallenge.placeholder',
  },
  [IDEA_TYPES.UNTAPPED_OPPORTUNITY]: {
    label: 'generalOverviewForm.reason.untappedOpportunity.label',
    placeholder: 'generalOverviewForm.reason.untappedOpportunity.placeholder',
  },
};
