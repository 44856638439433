import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import StepperBase, { STEPPER_VIEWS } from '../../Stepper';
import ProposeIdeaSolutionStep from './ProposeIdeaSolutionStep';
import { STEPS_IDS } from './constants';

const SolutionStepper = ({
  onDone,
  doneButtonCaption,
  defaultValues,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const steps = useMemo(() => ([{
    id: STEPS_IDS.PROPOSE_IDEA_SOLUTION,
    Step: ProposeIdeaSolutionStep,
    title: '',
  }]), []);

  const handleDiscard = useCallback(
    () => history.goBack(),
    [history],
  );

  return (
    <StepperBase
      steps={steps}
      onDone={onDone}
      onDiscard={handleDiscard}
      doneButtonCaption={doneButtonCaption}
      discardButtonCaption={formatMessage({ id: 'common.back' })}
      view={STEPPER_VIEWS.primary}
      defaultValues={defaultValues}
      preventLeavingWhenDirty
    />
  );
};

SolutionStepper.propTypes = {
  onDone: PropTypes.func.isRequired,
  doneButtonCaption: PropTypes.string.isRequired,
  defaultValues: PropTypes.shape({
    [STEPS_IDS.PROPOSE_IDEA_SOLUTION]: PropTypes.object,
  }),
};

SolutionStepper.defaultProps = {
  defaultValues: {},
};

export default SolutionStepper;
