import React from 'react';
import { createAction } from '@reduxjs/toolkit';
import copy from 'copy-to-clipboard';
import { generatePath } from 'react-router-dom';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { ROUTES } from '../../../../constants/routes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../redux/api/slices/ideaApi';
import history from '../../../../browserHistory';
import TextLink from '../../../TextLink';
import {
  MATOMO_EVENT_CATEGORIES,
  MATOMO_EVENT_ACTIONS,
} from '../../../../constants/matomo';
import {
  getFullUrl,
  trackMatomoEvent,
} from '../../../../utils';

export const createIdeaFromDraftIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formData,
      showToast,
      formatMessage,
      ideaId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const createIdeaFromDraftIdea = dispatch(ideaApi.endpoints.createIdeaFromDraftIdea.initiate({
    ideaId,
    eventHrid,
    data: formData,
  }));

  try {
    const idea = await pause(createIdeaFromDraftIdea.unwrap());

    dispatch(
      ideaApi.util.updateQueryData(
        'getIdeaById',
        {
          ideaId: idea.human_readable_id,
          eventHrid,
        },
        () => idea,
      ),
    );

    const ideaPath = generatePath(ROUTES.idea, { ideaId: idea.human_readable_id });

    showToast({
      message: formatMessage({ id: 'updateIdea.stepper.successIdeaCreationFromDraft' }, {
        ideaCatalog: (chunk) => (
          <TextLink
            label={chunk}
            path={ROUTES.ideas}
            inheritSize
          />
        ),
        ideaLink: (chunk) => (
          <TextLink
            label={chunk}
            path={ideaPath}
            onClick={(e) => {
              e.preventDefault();
              copy(
                getFullUrl(ideaPath),
              );
              trackMatomoEvent(
                MATOMO_EVENT_CATEGORIES.idea,
                MATOMO_EVENT_ACTIONS.shareIdea,
                idea.id,
              );
            }}
            inheritSize
          />
        ),
      }),
    });

    history.push(ideaPath);

    trackMatomoEvent(
      MATOMO_EVENT_CATEGORIES.idea,
      MATOMO_EVENT_ACTIONS.createIdea,
    );

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    createIdeaFromDraftIdea.unsubscribe();
  }
};

export const createIdeaFromDraftIdeaActionCreator = createAction(
  ACTION_TYPES.idea.createIdeaFromDraftIdea,
);
