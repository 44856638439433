import { omit, uniqBy } from 'lodash';

export const prepareComment = (comment) => ({
  ...omit(comment, ['latest_child', 'children_number']),
  subcomments: {
    items: comment.latest_child ? [comment.latest_child] : [],
    total: comment.children_number,
  },
});

export const mergeComments = (currentComments, newComments) => uniqBy([
  ...currentComments.reverse(),
  ...newComments,
], 'id')
  .reverse();
