import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';

import BrowserPrompt from './BrowserPrompt';
import RouterPrompt from './RouterPrompt';

export const LeavePromptContext = React.createContext();

const LeavePromptProvider = ({ children }) => {
  const [subscriptionsAmount, setSubscriptionsAmount] = useState(0);
  const [disableAmount, setDisableAmount] = useState(0);
  const hasSubscriptions = subscriptionsAmount > 0;
  const isEnabled = disableAmount === 0;
  const isLeaveBlocked = isEnabled && hasSubscriptions;

  const addSubscription = useCallback(() => {
    setSubscriptionsAmount((previousSubscriptionsAmount) => previousSubscriptionsAmount + 1);
  }, []);

  const removeSubscription = useCallback(() => {
    setSubscriptionsAmount((previousSubscriptionsAmount) => previousSubscriptionsAmount - 1);
  }, []);

  const disableLeavePrompt = useCallback(() => {
    flushSync(() => {
      setDisableAmount((previousDisableAmount) => previousDisableAmount + 1);
    });
  }, []);

  const enableLeavePrompt = useCallback(() => {
    setDisableAmount((previousDisableAmount) => previousDisableAmount - 1);
  }, []);

  const leavePromptProviderApi = useMemo(() => ({
    addSubscription,
    removeSubscription,
    disableLeavePrompt,
    enableLeavePrompt,
    isLeaveBlocked,
  }), [
    addSubscription,
    removeSubscription,
    disableLeavePrompt,
    enableLeavePrompt,
    isLeaveBlocked,
  ]);

  return (
    <LeavePromptContext.Provider value={leavePromptProviderApi}>
      {children}
      <RouterPrompt
        isLeaveBlocked={isLeaveBlocked}
      />
      <BrowserPrompt
        isLeaveBlocked={isLeaveBlocked}
      />
    </LeavePromptContext.Provider>
  );
};

LeavePromptProvider.propTypes = {
  children: PropTypes.node,
};

LeavePromptProvider.defaultProps = {
  children: null,
};

export default LeavePromptProvider;
