import { EVENT_STAGES } from '../../../../constants/event';

export const NO_IDEAS_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'ideas.noIdeas.ideaSubmitionStage.title',
  [EVENT_STAGES.DOWN_SELECTION]: 'ideas.noIdeas.title',
  [EVENT_STAGES.VOTING]: 'ideas.noIdeas.title',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'ideas.noIdeas.title',
};

export const NO_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE = {
  [EVENT_STAGES.IDEA_SUBMISSION]: 'ideas.noIdeas.ideaSubmitionStage.description',
  [EVENT_STAGES.DOWN_SELECTION]: 'ideas.noIdeas.description',
  [EVENT_STAGES.VOTING]: 'ideas.noIdeas.description',
  [EVENT_STAGES.ANNOUNCE_WINNER]: 'ideas.noIdeas.description',
};
