import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import yup from '../../../../yup';
import { FORM_FIELD_NAMES } from '../constants';
import { FIELD_NAMES } from '../../../Form/connected/LinkField';

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => (yup.object().shape({
    [FORM_FIELD_NAMES.links]: yup.array()
      .of(
        yup.object({
          [FIELD_NAMES.DESCRIPTION]: yup.string()
            .trim()
            .required(formatMessage({ id: 'attachLinksForm.error.desciptionRequired' }))
            .min(3, formatMessage({ id: 'attachLinksForm.error.desciptionMinLength' }))
            .max(150, formatMessage({ id: 'attachLinksForm.error.desciptionMaxLength' })),
          [FIELD_NAMES.URL]: yup.string()
            .trim()
            .required(formatMessage({ id: 'attachLinksForm.error.urlRequired' }))
            .url(formatMessage({ id: 'attachLinksForm.error.urlFormat' })),
        }),
      ),
  })), [formatMessage]);
};
