import { combineReducers } from '@reduxjs/toolkit';

import { baseApi } from './api/baseApi';
import accessReducer from './slices/access';
import eventsReducer from './slices/events';
import { resetActionCreator } from './actionCreators';

export const combinedReducers = combineReducers({
  access: accessReducer,
  events: eventsReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const rootReducer = (state, action) => {
  if (resetActionCreator.match(action)) {
    state = undefined;
  }

  return combinedReducers(state, action);
};
