import React from 'react';

import Banner from './Banner';
import EventJourney from '../EventJourney';
import EventStats from '../EventStats';
import TopIdeas from '../TopIdeas';

const VotingWelcome = () => (
  <>
    <Banner data-testid="banner" />
    <EventJourney data-testid="eventJourney" />
    <TopIdeas data-testid="topIdeas" />
    <EventStats data-testid="eventStats" />
  </>
);

export default VotingWelcome;
