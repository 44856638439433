import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './InfoBage.module.scss';

const InfoBage = ({ isActive, children }) => (
  <div className={clsx(
    styles.badge,
    { [styles.badge__inactive]: !isActive },
  )}
  >
    {children}
  </div>
);

InfoBage.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
};

InfoBage.defaultProps = {
  children: null,
  isActive: true,
};

export default InfoBage;
