import React from 'react';

import Icon, {
  ICON_TYPES,
  ICON_SIZE,
  ICON_COLOR,
} from '../../../../Icon';

const DottedMenuIcon = () => (
  <Icon
    type={ICON_TYPES.menuDots}
    size={ICON_SIZE.mxxs}
    color={ICON_COLOR.tertiary}
  />
);

export default DottedMenuIcon;
