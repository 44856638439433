import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaCommentsApi } from '../../../../redux/api/slices/ideaCommentsApi';

export const updateCommentListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      commentId,
      data,
      eventHrid,
    },
  } = action;

  const { dispatch, pause } = listenerApi;

  const updateComment = dispatch(
    ideaCommentsApi.endpoints.updateIdeaComment.initiate({
      commentId,
      data,
      eventHrid,
    }),
  );

  try {
    const updateCommentResult = await pause(updateComment.unwrap());

    resolve(updateCommentResult);
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    updateComment.unsubscribe();
  }
};

export const updateCommentActionCreator = createAction(ACTION_TYPES.ideaComments.updateComment);
