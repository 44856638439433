import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import copy from 'copy-to-clipboard';
import { useParams, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from '../../../../Button';
import { ROUTES } from '../../../../../constants/routes';
import CopyLinkIcon from '../CopyLinkIcon';
import UpdateIcon from '../UpdateIcon';
import ArchiveIcon from '../ArchiveIcon';
import DottedMenuIcon from '../DottedMenuIcon';
import Menu from '../../../../Menu';
import {
  getFullUrl,
  getRawId,
  trackMatomoEvent,
} from '../../../../../utils';
import { getIdeaSelector } from '../../../../../redux/api/slices/ideaApi';
import {
  MATOMO_EVENT_CATEGORIES,
  MATOMO_EVENT_ACTIONS,
} from '../../../../../constants/matomo';
import {
  useIsAbleToArchiveIdea,
  useIsAbleToUpdateIdea,
} from '../../../../../hooks';
import { ACTIVE_EVENT_HRID } from '../../../../../constants/event';
import { ICON_COLOR, ICON_SIZE } from '../../../../Icon';
import CopyToClipboard from '../../../../CopyToClipboard';

import styles from './IdeaActionsMenu.module.scss';

const renderDropdownButton = (props) => (
  <Button
    size={42}
    fill="solid"
    icon={DottedMenuIcon}
    {...props}
    isDropdown={false}
  />
);

const IdeaActionsMenu = ({ onArchive }) => {
  const { formatMessage } = useIntl();
  const { ideaId } = useParams();
  const ideaSelector = useMemo(
    () => getIdeaSelector(ideaId, ACTIVE_EVENT_HRID),
    [ideaId],
  );
  const idea = useSelector(ideaSelector);

  const handleShareLink = useCallback(() => {
    copy(getFullUrl(generatePath(ROUTES.idea, { ideaId })));
    trackMatomoEvent(
      MATOMO_EVENT_CATEGORIES.idea,
      MATOMO_EVENT_ACTIONS.shareIdea,
      getRawId(ideaId),
    );
  }, [ideaId]);

  const isAbleToArchive = useIsAbleToArchiveIdea(idea);
  const isAbleToUpdate = useIsAbleToUpdateIdea(idea);

  const groups = useMemo(() => ([{
    id: 'actions',
    items: [{
      id: 'share',
      label: formatMessage({ id: 'idea.header.actions.dottedMenu.copySharingLink' }),
      onClick: handleShareLink,
      icon: CopyLinkIcon,
    }, ...(isAbleToUpdate ? [{
      id: 'update',
      label: formatMessage({ id: 'idea.header.actions.dottedMenu.updateIdea' }),
      icon: UpdateIcon,
      link: {
        pathname: generatePath(ROUTES.updateIdea, { ideaId }),
      },
    }] : []), ...(isAbleToArchive ? [{
      id: 'archive',
      label: formatMessage({ id: 'idea.header.actions.dottedMenu.archiveIdea' }),
      onClick: onArchive,
      icon: ArchiveIcon,
    }] : [])],
  }]), [
    onArchive,
    formatMessage,
    handleShareLink,
    isAbleToArchive,
    isAbleToUpdate,
    ideaId,
  ]);

  return groups[0].items.length > 1
    ? (
      <Menu
        dropdownMenuProps={{
          groups,
          bodyClassName: styles.dropdownBody,
        }}
        renderDropdownButton={renderDropdownButton}
      />
    ) : (
      <CopyToClipboard
        data-testid="copyToClipboard"
        dataToCopy={getFullUrl(
          generatePath(ROUTES.idea, { ideaId }),
        )}
      >
        <Button
          data-testid="iconButton"
          size={42}
          fill="solid"
          icon={() => CopyLinkIcon({
            size: ICON_SIZE.mxxs,
            color: ICON_COLOR.tertiary,
          })}
          onClick={() => { }}
        />
      </CopyToClipboard>
    );
};

IdeaActionsMenu.propTypes = {
  onArchive: PropTypes.func.isRequired,
};

export default IdeaActionsMenu;
