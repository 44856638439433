import { isAxiosError } from '../redux/api/fetchClient';
import { userApi } from '../redux/api/slices/userApi';

export const getUserDataFork = (dispatch, token) => async (forkApi) => {
  const { pause } = forkApi;

  const getUserInfo = dispatch(
    userApi.endpoints.getUserInfo.initiate(token || null, { forceRefetch: true }),
  );

  try {
    return await pause(getUserInfo.unwrap());
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    throw error;
  } finally {
    getUserInfo.unsubscribe();
  }
};
