import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useGetOveralSummaryInfoQuery } from '../../../../redux/api/slices/analyticsApi';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import Spinner from '../../../Spinner';
import Blocker from '../../../Blocker';
import SummaryInfoItem from './SummaryInfoItem/SummaryInfoItem';
import { ORDER_OF_INFO } from './constants';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';

import styles from './SummaryInfo.module.scss';

const SummaryInfo = ({
  period,
  className,
}) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetOveralSummaryInfoQuery({
    ...period,
    eventHrid: ACTIVE_EVENT_HRID,
  }, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  if (isError) {
    return null;
  }

  return (
    <div className={clsx(styles.wrapper, className)}>
      {
        isLoading
          ? (
            <Spinner
              cx={styles.spinner}
            />
          )
          : (
            <div className={styles.itemsList}>
              {ORDER_OF_INFO.map((type) => (
                <SummaryInfoItem
                  key={type}
                  type={type}
                  value={data[type]}
                  className={styles.item}
                />
              ))}
            </div>
          )
      }
      {isFetching && !isLoading && <Blocker />}
    </div>
  );
};

SummaryInfo.propTypes = {
  period: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

SummaryInfo.defaultProps = {
  className: '',
};

export default SummaryInfo;
