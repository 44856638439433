import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from '../../Modal';
import BizCategoryForm from '../../forms/BizCategoryForm';
import {
  submitNewBizCategoryActionCreator,
  submitNewBizCategoryListener,
  updateBizCategoryListener,
  updateBusinessObjectiveActionCreator,
} from '../../BusinessCategories';
import { useListener } from '../../../hooks';

const BusinessCategoryModal = ({
  abort,
  success,
  currentBizCategory,
  isEdited,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [{
    submitForm,
    isSubmitting,
  }, setFormApi] = useState({});

  const modalTitle = useMemo(() => (
    formatMessage({
      id: isEdited
        ? 'bizCategoryModal.updateCategory.title'
        : 'bizCategoryModal.addNewCategory.title',
    })
  ), [formatMessage, isEdited]);

  const confirmButtonCaption = useMemo(() => (
    formatMessage({
      id: isEdited
        ? 'bizCategoryModal.updateCategory.confirmButton.label'
        : 'bizCategoryModal.addNewCategory.confirmButton.label',
    })
  ), [formatMessage, isEdited]);

  const [addNewBusinessCategory] = useListener({
    listener: submitNewBizCategoryListener,
    actionCreator: submitNewBizCategoryActionCreator,
  });

  const [updateBusinessCategory] = useListener({
    listener: updateBizCategoryListener,
    actionCreator: updateBusinessObjectiveActionCreator,
  });

  const handleAddNewBizCategory = useCallback(
    (formData) => addNewBusinessCategory({ formData }),
    [addNewBusinessCategory],
  );

  const handleUpdateBizCategory = useCallback(
    ({ id, ...data }) => updateBusinessCategory({ bizCategoryId: id, data }),
    [updateBusinessCategory],
  );

  return (
    <Modal
      data-testid="modal"
      title={modalTitle}
      content={(
        <BizCategoryForm
          onSubmit={
            isEdited
              ? handleUpdateBizCategory
              : handleAddNewBizCategory
          }
          onSubmitSuccess={success}
          getFormApi={setFormApi}
          defaultValues={currentBizCategory}
        />
      )}
      onClose={abort}
      onReject={abort}
      onConfirm={submitForm}
      confirmButtonCaption={confirmButtonCaption}
      isLoading={isSubmitting}
      withFooterBorderTop
      {...props}
    />
  );
};

BusinessCategoryModal.propTypes = {
  success: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
  currentBizCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    is_active: PropTypes.bool.isRequired,
  }),
  isEdited: PropTypes.bool,
};

BusinessCategoryModal.defaultProps = {
  isEdited: false,
  currentBizCategory: null,
};

export default BusinessCategoryModal;
