import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { createAuthorizationHeader } from '../../utils';

export const baseApi = createApi({
  baseQuery: baseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().access;

      if (token) {
        headers.authorization = createAuthorizationHeader(token);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});
