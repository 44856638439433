import { isNumber } from 'lodash';

import { useQueryParams } from '../../../hooks';
import { FILTER_PANEL_FILTER_TYPES } from '../constants';

export const useGetFiltersPanelValuesFromUrl = (filters, urlParamName) => {
  const { [urlParamName]: filtersFromUrl } = useQueryParams();
  const newFilters = (() => {
    const resultFilters = {};

    if (!filtersFromUrl || !filters) {
      return resultFilters;
    }

    Object.keys(filtersFromUrl).forEach((field) => {
      const currentFilter = filters.find((filter) => filter.field === field);

      if (!currentFilter) {
        return;
      }

      switch (currentFilter.type) {
        case (FILTER_PANEL_FILTER_TYPES.MULTI_PICKER):
          resultFilters[field] = currentFilter.dataSource.props.items
            .filter(({ id: filterId }) => (
              filtersFromUrl[field]?.some((filterIdFromUrl) => (
                (isNumber(filterId) ? Number(filterIdFromUrl) : filterIdFromUrl) === filterId))))
            .map(({ id: filterId }) => filterId);
          break;
        default:
          break;
      }
    });

    return resultFilters;
  })();

  return newFilters;
};
