import React from 'react';
import PropTypes from 'prop-types';
import { HintAlert as BaseHintAlert } from '@epam/promo';

import InfoIcon from './InfoIcon';

const HintAlert = ({ children, ...props }) => (
  <BaseHintAlert
    data-testid="baseHintAlert"
    icon={InfoIcon}
    {...props}
  >
    {children}
  </BaseHintAlert>
);

HintAlert.propTypes = {
  children: PropTypes.node,
};

HintAlert.defaultProps = {
  children: null,
};

export default HintAlert;
