export const ROUTES = {
  base: '/',
  get login() {
    return `${this.base}login`;
  },
  get ssoAuth() {
    return `${this.base}sso-auth`;
  },
  get signUp() {
    return `${this.base}sign-up`;
  },
  get profile() {
    return `${this.base}profile`;
  },
  get forgotPassword() {
    return `${this.base}forgot-password`;
  },
  get resetPassword() {
    return `${this.base}reset-password`;
  },
  get onboarding() {
    return `${this.base}onboarding`;
  },
  get ideas() {
    return `${this.base}ideas`;
  },
  get createIdea() {
    return `${this.ideas}/create`;
  },
  get idea() {
    return `${this.ideas}/:ideaId`;
  },
  get updateIdea() {
    return `${this.idea}/update`;
  },
  get adminPanel() {
    return `${this.base}admin-panel`;
  },
  get proposeIdeaSolution() {
    return `${this.idea}/propose-solution`;
  },
  get updateIdeaSolution() {
    return `${this.idea}/update-solution`;
  },
};

export const BASE_URL = window.location.origin;
