import React from 'react';
import PropTypes from 'prop-types';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import clsx from 'clsx';

import { routes } from './constants';
import Breadcrumb from './Breadcrumb';
import { ROUTES } from '../../constants/routes';
import IdeaBreadcrumb from './IdeaBreadcrumb';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({
  breadcrumbs,
  className,
}) => (
  <div className={clsx(styles.wrapper, className)}>
    {breadcrumbs.map(({
      match,
      breadcrumb,
    }, index) => {
      const isLastBreadcrumb = (index === breadcrumbs.length - 1);

      switch (match.path) {
        case ROUTES.idea:
          return (
            <IdeaBreadcrumb
              data-testid={match.url}
              key={match.url}
              match={match}
              breadcrumb={breadcrumb}
              isLast={isLastBreadcrumb}
            />
          );
        default:
          return (
            <Breadcrumb
              data-testid={match.url}
              key={match.url}
              match={match}
              breadcrumb={breadcrumb}
              isLast={isLastBreadcrumb}
            />
          );
      }
    })}
  </div>
);

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    breadcrumb: PropTypes.node.isRequired,
  })).isRequired,
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: '',
};

export default withBreadcrumbs(routes)(Breadcrumbs);
