import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../../constants/actionTypes';
import { isAxiosError } from '../../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../../redux/api/slices/ideaApi';
import { saveFile } from '../../../../../utils';

export const getIdeasReportListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formatMessage,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const getIdeasReport = dispatch(
    ideaApi.endpoints.getIdeasReport.initiate({ eventHrid }),
  );

  try {
    const result = await pause(getIdeasReport.unwrap());
    const fileName = `${formatMessage({ id: 'adminPanel.ideasBoard.ideasReport.label' })}`;

    saveFile(result, fileName);

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    getIdeasReport.unsubscribe();
  }
};

export const getIdeasReportActionCreator = createAction(ACTION_TYPES.idea.getReport);
