import React from 'react';
import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../Icon';

const InfoIcon = () => (
  <Icon
    data-testid="icon"
    type={ICON_TYPES.info}
    size={ICON_SIZE.msm}
    color={ICON_COLOR.primary}
  />
);

export default InfoIcon;
