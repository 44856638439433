import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { userApi } from '../../../redux/api/slices/userApi';

export const getResetLinkListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formatMessage,
      formData,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const getResetLink = dispatch(
    userApi.endpoints.getResetPasswordLink.initiate(formData),
  );

  try {
    await pause(getResetLink.unwrap());

    resolve({
      success: [
        formatMessage({ id: 'forgotPasswordForm.successSubmit.alertMessage' }),
      ],
    });
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    getResetLink.unsubscribe();
  }
};

export const getResetLinkActionCreator = createAction(ACTION_TYPES.user.getResetLink);
