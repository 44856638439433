import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../Icon';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({
  match,
  breadcrumb,
  isLink,
  isLast,
}) => (
  <>
    {isLink && !isLast
      ? (
        <NavLink
          data-testid="navLink"
          className={styles.breadcrumb}
          to={match.url}
        >
          {breadcrumb}
        </NavLink>
      )
      : (
        <div
          className={clsx(
            styles.breadcrumb,
            styles.breadcrumb__active,
          )}
        >
          {breadcrumb}
        </div>
      )}
    {!isLast && (
      <Icon
        data-testid="icon"
        className={styles.delimiter}
        type={ICON_TYPES.arrowRight}
        size={ICON_SIZE.xxxxs}
        color={ICON_COLOR.secondary}
      />
    )}
  </>
);

Breadcrumb.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  breadcrumb: PropTypes.node.isRequired,
  isLink: PropTypes.bool,
  isLast: PropTypes.bool.isRequired,
};

Breadcrumb.defaultProps = {
  isLink: true,
};

export default Breadcrumb;
