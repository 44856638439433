import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import VisibleSecretIcon from './secretIcons/VisibleSecretIcon';
import HiddenSecretIcon from './secretIcons/HiddenSecretIcon';
import Input from '../Input';
import Label from '../Label';

import styles from './TextField.module.scss';

export const TYPES = {
  text: 'text',
  password: 'password',
};

const TextField = ({
  label,
  tooltip,
  errorMessage,
  type,
  onChange,
  isRequired,
  footer,
  ...props
}) => {
  const [resultType, setResultType] = useState(type);

  const togglePasswordVisibility = useCallback(
    () => setResultType(resultType === TYPES.password ? TYPES.text : TYPES.password),
    [resultType],
  );

  let additionalProps = {};

  if (type === TYPES.password) {
    additionalProps = {
      iconPosition: 'right',
      icon: resultType === TYPES.password
        ? HiddenSecretIcon
        : VisibleSecretIcon,
      onIconClick: togglePasswordVisibility,
    };
  }

  return (
    <Label
      data-testid="label"
      label={label}
      isInvalid={!!errorMessage}
      validationMessage={errorMessage}
      info={tooltip}
      isRequired={isRequired}
      footer={footer}
    >
      <Input
        inputCx={clsx({
          [styles.password]: type === TYPES.password,
        })}
        data-testid="input"
        isInvalid={!!errorMessage}
        type={resultType}
        onValueChange={onChange}
        size="42"
        {...props}
        {...additionalProps}
      />
    </Label>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  footer: PropTypes.node,
  name: PropTypes.string.isRequired,
};

TextField.defaultProps = {
  tooltip: null,
  errorMessage: '',
  placeholder: '',
  type: 'text',
  isRequired: false,
  footer: null,
};

export default TextField;
