export const FILTERS_URL_PARAM_NAME = 'archivedIdeasFilters';
export const PAGE_URL_PARAM_NAME = 'archivedIdeasPage';
export const SORTING_URL_PARAM_NAME = 'archivedIdeasSorting';
export const TABLE_ID = 'archivedIdeasTable';

export const ACCESSOR_KEYS = {
  IDEA_TITLE: 'title',
  STATUS: 'status',
  ACTIONS: 'actions',
};
