import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { ANIMATION_TYPES, TIMER_TYPES } from './constants';
import { getDiffInDays } from '../../utils';
import Animation from '../Animation';
import clockAnimationData from './animations/clock.json';
import gobletAnimationData from './animations/goblet.json';

import styles from './Timer.module.scss';

export const TIMER_CLASS_BY_TYPE = {
  [TIMER_TYPES.primary]: styles.timer__primary,
  [TIMER_TYPES.secondary]: styles.timer__secondary,
};

const ANIMATION_DATA_BY_TYPE = {
  [ANIMATION_TYPES.primary]: clockAnimationData,
  [ANIMATION_TYPES.secondary]: gobletAnimationData,
};

const Timer = ({
  title,
  date,
  action,
  type,
  animationType,
}) => {
  const { formatMessage } = useIntl();
  const daysLeft = useMemo(
    () => getDiffInDays(date),
    [date],
  );
  const daysAmount = daysLeft >= 0 ? daysLeft : 0;

  return (
    <div
      data-testid="timerWrapper"
      className={clsx(styles.timer, TIMER_CLASS_BY_TYPE[type])}
    >
      <div className={styles.baseInfo}>
        <Animation
          animationData={ANIMATION_DATA_BY_TYPE[animationType]}
          className={styles.animation}
          data-testid="animation"
        />
        <div className={styles.content}>
          <h2 className={styles.title}>
            {title}
          </h2>
          {date && (
            <p className={styles.description}>
              {formatMessage(
                { id: 'timer.daysLeft' },
                { daysAmount },
              )}
            </p>
          )}
        </div>
      </div>
      {action}
    </div>
  );
};

Timer.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  action: PropTypes.node,
  type: PropTypes.oneOf(Object.values(TIMER_TYPES)),
  animationType: PropTypes.oneOf(Object.values(ANIMATION_TYPES)),
};

Timer.defaultProps = {
  action: null,
  date: null,
  type: TIMER_TYPES.primary,
  animationType: ANIMATION_TYPES.primary,
};

export default Timer;
