import React from 'react';

import { ICON_TYPES } from '../../../../Icon';
import BaseIcon from '../BaseIcon';

export const VisibleSecretIcon = () => (
  <BaseIcon type={ICON_TYPES.visibleSecret} />
);

export default VisibleSecretIcon;
