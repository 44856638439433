import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CommentsList from './CommentsList';
import NoComments from './NoComments';
import CommentForm from '../forms/CommentForm';
import Spinner from '../Spinner';
import { userSelector } from '../../redux/slices/access';
import { mergeComments, prepareComment } from './utils';
import { USER_ROLES } from '../../constants/roles';
import { ACTIVE_EVENT_HRID } from '../../constants/event';
import { activeEventActiveStageTypeSelector } from '../../redux/slices/events';

import styles from './Comments.module.scss';

const Comments = ({
  entityId,
  className,
  id,
  dataId,
  isReadOnly,
  getComments,
  createComment,
  updateComment,
  deleteComment,
}) => {
  const [comments, setComments] = useState({
    items: [],
    total: 0,
  });
  const [isInitialCommentsLoading, setIsInitialCommentsLoading] = useState(true);
  const currentUser = useSelector(userSelector);
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);

  const handleUpdateComment = useCallback(async ({ comment, commentId, parentCommentId }) => {
    const updatedComment = await updateComment({
      commentId,
      data: { data: comment },
      eventHrid: ACTIVE_EVENT_HRID,
    });

    setComments((prevCommentsState) => {
      const newComments = [...prevCommentsState.items];

      if (parentCommentId) {
        const parentCommentIndex = newComments
          .findIndex((newComment) => newComment.id === parentCommentId);

        const subCommentIndex = newComments[parentCommentIndex]
          .subcomments
          .items
          .findIndex((subComment) => subComment.id === commentId);

        newComments[parentCommentIndex].subcomments.items[subCommentIndex] = {
          ...newComments[parentCommentIndex].subcomments.items[subCommentIndex],
          data: updatedComment.data,
        };

        return { ...prevCommentsState, items: newComments };
      }

      const commentIndex = newComments
        .findIndex((newComment) => newComment.id === commentId);

      newComments[commentIndex] = {
        ...newComments[commentIndex],
        data: updatedComment.data,
      };

      return { ...prevCommentsState, items: newComments };
    });
  }, [updateComment]);

  const deleteCommentInState = useCallback((commentId, parentId) => {
    if (parentId) {
      setComments((prevCommentsState) => {
        const {
          items: currentComments,
          total,
        } = prevCommentsState;
        const indexOfParentComment = currentComments
          .findIndex((comment) => comment.id === parentId);
        const currentSubcomments = currentComments[indexOfParentComment].subcomments;
        const indexOfDeletedSubcomment = currentSubcomments.items
          .findIndex((comment) => comment.id === commentId);

        currentSubcomments.items.splice(indexOfDeletedSubcomment, 1);
        currentSubcomments.total -= 1;
        currentComments[indexOfParentComment].subcomments = currentSubcomments;

        return {
          items: currentComments,
          total,
        };
      });
    } else {
      setComments((prevCommentsState) => {
        const {
          items: currentComments,
          total,
        } = prevCommentsState;
        const indexOfDeletedComment = currentComments
          .findIndex((comment) => comment.id === commentId);

        currentComments.splice(indexOfDeletedComment, 1);

        return {
          items: currentComments,
          total: total - 1,
        };
      });
    }
  }, []);

  const handleDeleteComment = useCallback(async (
    commentId,
    onDeleteFail,
    parentId,
  ) => {
    try {
      await deleteComment({
        commentId,
        eventHrid: ACTIVE_EVENT_HRID,
      });

      deleteCommentInState(commentId, parentId);
    } catch (error) {
      onDeleteFail();
    }
  }, [
    deleteComment,
    deleteCommentInState,
  ]);

  const addComments = useCallback((newComments) => {
    setComments((prevCommentsState) => {
      const {
        items,
        total,
      } = newComments;
      const preparedNewComments = items.map(prepareComment);
      const currentComments = [...prevCommentsState.items];

      const uniqCommentsWithCorrectOrder = mergeComments(currentComments, preparedNewComments);

      return {
        items: uniqCommentsWithCorrectOrder,
        total,
      };
    });
  }, []);

  const addSubcomments = useCallback((subcomments) => {
    setComments((prevCommentsState) => {
      const currentComments = prevCommentsState.items;
      const {
        items: newSubcomments,
        total: totalSubcomments,
      } = subcomments;
      const parentCommentId = newSubcomments[0].parent_id;
      const parentCommentIndex = currentComments
        .findIndex((comment) => comment.id === parentCommentId);
      const currentSubcomments = currentComments[parentCommentIndex].subcomments.items;

      const uniqSubcommentsWithCorrectOrder = mergeComments(currentSubcomments, newSubcomments);

      const parentCommentWithNewSubcomments = {
        ...currentComments[parentCommentIndex],
        subcomments: {
          items: uniqSubcommentsWithCorrectOrder,
          total: totalSubcomments,
        },
      };

      currentComments.splice(parentCommentIndex, 1, parentCommentWithNewSubcomments);

      return {
        items: currentComments,
        total: prevCommentsState.total,
      };
    });
  }, []);

  const loadComments = useCallback(async (page, parentId) => {
    const newComments = await getComments({
      entityId,
      parentId,
      page,
      eventHrid: ACTIVE_EVENT_HRID,
    });

    if (parentId) {
      addSubcomments(newComments);
    } else {
      addComments(newComments);
    }
  }, [
    addSubcomments,
    getComments,
    entityId,
    addComments,
  ]);

  const loadInitialComments = useCallback(async () => {
    try {
      await loadComments(1);
    } finally {
      setIsInitialCommentsLoading(false);
    }
  }, [loadComments]);

  const handleMoreCommentsView = useCallback(async (page, parentId, onDone) => {
    try {
      await loadComments(page, parentId);
      // eslint-disable-next-line no-empty
    } catch (error) { } finally {
      onDone();
    }
  }, [loadComments]);

  const addComment = useCallback(async (formData, repliedCommentId, replyToId) => {
    const newComment = await createComment({
      entityId,
      data: {
        data: formData.comment.trim(),
        ...(repliedCommentId
          ? {
            parent: repliedCommentId,
            reply_to: replyToId,
          }
          : {}),
      },
      eventHrid: ACTIVE_EVENT_HRID,
    });

    if (repliedCommentId) {
      setComments((prevCommentsState) => {
        const currentComments = prevCommentsState.items;
        const repliedCommentIndex = currentComments
          .findIndex((comment) => comment.id === repliedCommentId);
        const newComments = [
          ...prevCommentsState.items,
        ];
        newComments[repliedCommentIndex] = {
          ...newComments[repliedCommentIndex],
          subcomments: {
            ...newComments[repliedCommentIndex].subcomments,
            total: newComments[repliedCommentIndex].subcomments.total + 1,
            items: [
              ...newComments[repliedCommentIndex].subcomments.items,
              newComment,
            ],
          },
        };

        return {
          ...prevCommentsState,
          items: newComments,
        };
      });
    } else {
      setComments((prevCommentsState) => ({
        items: [
          ...prevCommentsState.items,
          prepareComment(newComment),
        ],
        total: prevCommentsState.total + 1,
      }));
    }
  }, [
    createComment,
    entityId,
  ]);

  useEffect(() => {
    loadInitialComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (() => {
    if (isInitialCommentsLoading) {
      return (
        <Spinner cx={styles.spinner} />
      );
    }

    return (
      <div id={dataId}>
        {
          comments.total
            ? (
              <CommentsList
                comments={comments}
                currentUser={currentUser}
                onMoreCommentsView={handleMoreCommentsView}
                canDeleteAnothersComments={currentUser.role === USER_ROLES.ADMIN}
                canUpdateAnothersComments={currentUser.role === USER_ROLES.ADMIN}
                onDelete={handleDeleteComment}
                onUpdate={handleUpdateComment}
                onReply={addComment}
                isReadOnly={isReadOnly}
              />
            )
            : (
              <NoComments
                activeStageType={activeStageType}
              />
            )
        }
        {!isReadOnly && (
          <CommentForm
            onSubmit={addComment}
          />
        )}
      </div>
    );
  })();

  return (
    <div
      className={className}
      id={id}
    >
      {content}
    </div>
  );
};

Comments.propTypes = {
  entityId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  dataId: PropTypes.string,
  isReadOnly: PropTypes.bool,
  getComments: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

Comments.defaultProps = {
  className: '',
  id: null,
  dataId: null,
  isReadOnly: false,
};

export default Comments;
