import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { ideaApi } from '../../../redux/api/slices/ideaApi';
import { NOTIFICATION_TYPES } from '../../Notification';

export const voteIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      showToast,
      formatMessage,
      ideaId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const voteIdea = dispatch(ideaApi.endpoints.voteIdea.initiate({
    eventHrid,
    ideaId,
  }));

  try {
    await pause(voteIdea.unwrap());

    showToast({
      message: formatMessage({ id: 'idea.toast.vote.success' }),
    });

    resolve();
  } catch (error) {
    if (isAxiosError(error)) {
      const { status } = error.response || {};

      switch (status) {
        case 403:
          showToast({
            type: NOTIFICATION_TYPES.WARNING,
            message: formatMessage({ id: 'idea.toast.vote.error.maxNumberOfVotes' }),
          });
          break;
        default:
          error.handleGlobally();
          break;
      }
    }

    reject();
  } finally {
    voteIdea.unsubscribe();
  }
};

export const voteIdeaActionCreator = createAction(ACTION_TYPES.idea.vote);
