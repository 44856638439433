import React from 'react';

import IdeasList from './IdeasList';

import styles from './Ideas.module.scss';

const Ideas = () => (
  <div className={styles.container}>
    <IdeasList />
  </div>
);

export default Ideas;
