import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { userApi } from '../../../redux/api/slices/userApi';
import { handleSetNewPasswordErrors } from './handleSetNewPasswordErrors';

export const setNewPasswordListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formatMessage,
      formData,
    },
  } = action;
  const { dispatch, pause } = listenerApi;
  const setNewPassword = dispatch(
    userApi.endpoints.setNewPassword.initiate(formData),
  );

  try {
    await pause(setNewPassword.unwrap());

    resolve();
  } catch (error) {
    const errors = handleSetNewPasswordErrors(error, formatMessage);

    reject({
      error: errors,
    });
  } finally {
    setNewPassword.unsubscribe();
  }
};

export const setNewPasswordActionCreator = createAction(ACTION_TYPES.user.setNewPassword);
