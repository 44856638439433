export const TABLE_ID = 'peopleTable';
export const SORTING_TYPE_URL_PARAM_NAME = 'peopleSort';
export const PAGE_URL_PARAM_NAME = 'peoplePage';
export const SEARCH_URL_PARAM_NAME = 'peopleSearch';

export const ACCESSOR_KEYS = {
  NAME: 'name',
  EMAIL: 'email',
  ROLE: 'role',
  ACTIONS: 'actions',
};
