import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './CategoryItemContent.module.scss';

const CategoryItemContent = ({
  name,
  description,
  className,
}) => (
  <div className={clsx(styles.wrapper, className)}>
    <p className={styles.title}>
      {name}
    </p>
    <p className={styles.description}>
      {description}
    </p>
  </div>
);

CategoryItemContent.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CategoryItemContent.defaultProps = {
  className: '',
};

export default CategoryItemContent;
