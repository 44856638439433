import { baseApi } from '../baseApi';
import { API_TAG_TYPES } from './constants';
import { getRawId } from '../../../utils';

export const ideaSolutionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    submitIdeaSolution: build.mutation({
      query: ({ ideaId, data, eventHrid }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/solutions`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [{ type: API_TAG_TYPES.idea, id: getRawId(ideaId) }]
      ),
    }),
    likeProposedSolution: build.mutation({
      query: ({ solutionId, eventHrid }) => ({
        url: `/events/${eventHrid}/solutions/${solutionId}/likes`,
        method: 'PATCH',
      }),
      onQueryStarted: async ({
        solutionId,
        isLiked,
        userId,
      }, { dispatch, queryFulfilled, getState }) => {
        const patchResults = [];

        baseApi.util.selectInvalidatedBy(
          getState(),
          [{ type: API_TAG_TYPES.ideaSolution, id: solutionId }],
        ).forEach(({ endpointName, originalArgs }) => {
          switch (endpointName) {
            case 'getSolutions':
              patchResults.push(
                dispatch(
                  baseApi.util.updateQueryData(
                    endpointName,
                    originalArgs,
                    (solutions) => {
                      const index = solutions.findIndex(({ id }) => id === solutionId);
                      solutions[index] = {
                        ...solutions[index],
                        likes: isLiked
                          ? [...solutions[index].likes, userId]
                          : solutions[index].likes.filter((like) => like !== userId),
                      };
                    },
                  ),
                ),
              );
              break;

            default:
              break;
          }
        });

        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach((result) => result.undo());
        }
      },
    }),
    updateProposedSolution: build.mutation({
      query: ({ solutionId, data, eventHrid }) => ({
        url: `/events/${eventHrid}/solutions/${solutionId}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, { solutionId }) => (
        error ? [] : [{ type: API_TAG_TYPES.ideaSolution, id: solutionId }]
      ),
    }),
    getSolution: build.query({
      query: ({ solutionId, eventHrid }) => ({
        url: `/events/${eventHrid}/solutions/${solutionId}`,
      }),
    }),
    deleteSolution: build.mutation({
      query: ({ solutionId, eventHrid }) => ({
        url: `/events/${eventHrid}/solutions/${solutionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { solutionId }) => (
        error ? [] : [{ type: API_TAG_TYPES.ideaSolution, id: solutionId }]
      ),
    }),
  }),
});

export const {
  useLikeProposedSolutionMutation,
  useGetSolutionQuery,
} = ideaSolutionApi;
