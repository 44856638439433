import dayjs from 'dayjs';

import { DATE_FORMAT } from '../constants/date';

export const formatDate = (date, dateFormat = DATE_FORMAT) => dayjs(date).format(dateFormat);

export const formatDateWithTimezone = (date) => dayjs(date).format();

export const subtractDaysFromToday = (number) => dayjs().subtract(number, 'day');

export const getDiffInDays = (dateOne, dateTwo = dayjs()) => dayjs(dateOne).diff(dateTwo, 'd');
