import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { logoutFork } from '../../../listenerForks';

export const tokenExpiredListener = async (action, listenerApi) => {
  const {
    payload: { resolve, reject },
  } = action;
  const { dispatch, fork } = listenerApi;

  try {
    await fork(logoutFork(dispatch));
    resolve();
  } catch (error) {
    reject();
  }
};

export const tokenExpiredActionCreator = createAction(ACTION_TYPES.apiErrorsHandler.tokenExpired);
