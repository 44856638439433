import React, {
  Fragment,
  useMemo,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import Comment from '../Comment';
import LinkButton from '../../LinkButton';
import Spinner from '../../Spinner';
import {
  getViewMoreCount,
} from './utils';
import {
  COMMENTS_PER_PAGE,
} from '../constants';

import styles from './CommentsList.module.scss';

const CommentsList = ({
  comments,
  parentCommentId,
  currentUser,
  onMoreCommentsView,
  canDeleteAnothersComments,
  canUpdateAnothersComments,
  onDelete,
  onUpdate,
  onReply,
  isReadOnly,
}) => {
  const {
    items,
    total,
  } = comments;
  const [isCommentsFetching, setIsCommentsFetching] = useState(false);
  const isMoreCommentsLeft = total > items.length;
  const { formatMessage } = useIntl();
  const viewMoreButtonCaption = useMemo(() => (
    parentCommentId
      ? formatMessage(
        { id: 'comments.commentsList.previousSubcomments' },
        { count: getViewMoreCount(total, items.length) },
      )
      : formatMessage(
        { id: 'comments.commentsList.previousComments' },
        {
          count: getViewMoreCount(total, items.length),
          totalCount: total - items.length,
        },
      )
  ), [
    formatMessage,
    items,
    total,
    parentCommentId,
  ]);

  const handleMoreCommentsView = useCallback(() => {
    const nextPage = Math.floor((items.length / COMMENTS_PER_PAGE) + 1);

    setIsCommentsFetching(true);

    onMoreCommentsView(
      nextPage,
      parentCommentId,
      () => setIsCommentsFetching(false),
    );
  }, [
    parentCommentId,
    onMoreCommentsView,
    items.length,
  ]);

  return (
    <>
      {isCommentsFetching
        ? (
          <Spinner cx={styles.spinner} />
        )
        : (isMoreCommentsLeft && (
        <LinkButton
          onClick={handleMoreCommentsView}
          caption={viewMoreButtonCaption}
          cx={clsx(
            styles.loadMore,
            { [styles.loadMore__subcomment]: !!parentCommentId },
          )}
        />
        ))}

      {items.map((comment) => (
        <Fragment key={comment.id}>
          <Comment
            comment={comment}
            currentUser={currentUser}
            canDeleteAnothersComments={canDeleteAnothersComments}
            canUpdateAnothersComments={canUpdateAnothersComments}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onReply={onReply}
            parentCommentId={parentCommentId}
            isReadOnly={isReadOnly}
          />
          {!!comment.subcomments?.total && (
            <CommentsList
              comments={comment.subcomments}
              parentCommentId={comment.id}
              onMoreCommentsView={onMoreCommentsView}
              currentUser={currentUser}
              canDeleteAnothersComments={canDeleteAnothersComments}
              canUpdateAnothersComments={canUpdateAnothersComments}
              onDelete={onDelete}
              onUpdate={onUpdate}
              onReply={onReply}
              isReadOnly={isReadOnly}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

CommentsList.propTypes = {
  comments: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      author: PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }).isRequired,
      data: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      subcomments: PropTypes.shape({
        total: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.object),
      }),
    })).isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  parentCommentId: PropTypes.number,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  onMoreCommentsView: PropTypes.func.isRequired,
  canDeleteAnothersComments: PropTypes.bool,
  canUpdateAnothersComments: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

CommentsList.defaultProps = {
  parentCommentId: null,
  canDeleteAnothersComments: false,
  canUpdateAnothersComments: false,
  isReadOnly: false,
};

export default React.memo(CommentsList);
