import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
} from '../../../../Icon';

const BaseIcon = (props) => (
  <Icon
    color={ICON_COLOR.secondary}
    size={ICON_SIZE.xs}
    {...props}
  />
);

export default BaseIcon;
