import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import PageInfo from '../../PageInfo';
import InactiveWelcome from '../../InactiveWelcome';
import IdeaSubmissionWelcome from '../../IdeaSubmissionWelcome';
import LeadershipWelcome from '../../LeadershipWelcome';
import VotingWelcome from '../../VotingWelcome';
import WinnerAnnouncementWelcome from '../../WinnerAnnouncementWelcome';
import WinnerWelcome from '../../WinnerWelcome';
import { EVENT_STAGES, EVENT_STATUSES } from '../../../constants/event';
import {
  activeEventStatusSelector,
  activeEventActiveStageTypeSelector,
} from '../../../redux/slices/events';

const WelcomePage = () => {
  const activeEventStatus = useSelector(activeEventStatusSelector);
  const activeEventActiveStageType = useSelector(activeEventActiveStageTypeSelector);
  const { formatMessage } = useIntl();

  const welcomePage = useMemo(() => {
    switch (activeEventStatus) {
      case EVENT_STATUSES.FUTURE:
        return <InactiveWelcome data-testid="inactiveWelcome" />;
      case EVENT_STATUSES.IN_PROGRESS:
        switch (activeEventActiveStageType) {
          case EVENT_STAGES.IDEA_SUBMISSION:
            return <IdeaSubmissionWelcome data-testid="ideaSubmissionWelcome" />;
          case EVENT_STAGES.DOWN_SELECTION:
            return <LeadershipWelcome data-testid="leadershipWelcome" />;
          case EVENT_STAGES.VOTING:
            return <VotingWelcome data-testid="votingWelcome" />;
          case EVENT_STAGES.ANNOUNCE_WINNER:
            return <WinnerAnnouncementWelcome data-testid="winnerAnnouncementWelcome" />;
          default:
            return;
        }
      case EVENT_STATUSES.FINISHED:
        return <WinnerWelcome data-testid="winnerWelcome" />;
      default:
    }
  }, [activeEventStatus, activeEventActiveStageType]);

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'welcome.pageTitle' })}
      />
      {welcomePage}
    </>
  );
};

export default WelcomePage;
