import React from 'react';
import PropTypes from 'prop-types';
import { ErrorHandler } from '@epam/promo';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

import styles from './PrimaryLayout.module.scss';

const PrimaryLayout = ({ children }) => (
  <div className={styles.primaryLayout}>
    <Header
      data-testid="header"
      className={styles.header}
    />
    <Content
      data-testid="content"
      className={styles.content}
    >
      <ErrorHandler cx={styles.errorHandler}>
        {children}
      </ErrorHandler>
    </Content>
    <Footer
      data-testid="footer"
      className={styles.footer}
    />
  </div>
);

PrimaryLayout.propTypes = {
  children: PropTypes.node,
};

PrimaryLayout.defaultProps = {
  children: null,
};

export default PrimaryLayout;
