import React from 'react';
import PropTypes from 'prop-types';
import { ErrorHandler } from '@epam/promo';

import Header from './Header';
import Content from './Content';
import Sidebar from './Sidebar';

import styles from './SecondaryLayout.module.scss';

const SecondaryLayout = ({ children }) => (
  <div className={styles.secondaryLayout}>
    <Sidebar
      data-testid="sidebar"
      className={styles.sidebar}
    />
    <ErrorHandler cx={styles.errorHandler}>
      <Header
        data-testid="header"
        className={styles.header}
      />
      <Content
        data-testid="content"
        className={styles.content}
      >
        { children }
      </Content>
    </ErrorHandler>
  </div>
);

SecondaryLayout.propTypes = {
  children: PropTypes.node,
};

SecondaryLayout.defaultProps = {
  children: null,
};

export default SecondaryLayout;
