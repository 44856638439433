import React from 'react';
import PropTypes from 'prop-types';

import Icon, {
  ICON_TYPES,
  ICON_SIZE,
  ICON_COLOR,
} from '../../../../Icon';

const CopyLinkIcon = ({ size, color }) => (
  <Icon
    type={ICON_TYPES.link}
    size={size}
    color={color}
  />
);

CopyLinkIcon.propTypes = {
  size: PropTypes.oneOf(
    Object.values(ICON_SIZE),
  ),
  color: PropTypes.oneOf(
    Object.values(ICON_COLOR),
  ),
};

CopyLinkIcon.defaultProps = {
  size: ICON_SIZE.xs,
  color: ICON_COLOR.quaternary,
};

export default CopyLinkIcon;
