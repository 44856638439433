import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import { Tooltip as TooltipBase } from '@epam/promo';
import PropTypes from 'prop-types';

import { TRIGGER_TYPES } from './constants';

import styles from './Tooltip.module.scss';

const Tooltip = ({
  trigger,
  delay,
  children,
  ...props
}) => {
  const elementAfterRef = useRef(null);
  const delaySubscription = useRef();
  const [isVisible, setIsVisible] = useState(false);

  // now we only support delay for hover trigger
  const hasDelay = useMemo(() => (
    delay && trigger === TRIGGER_TYPES.HOVER
  ), [delay, trigger]);

  const handleMouseEnter = useCallback(() => {
    delaySubscription.current = setTimeout(() => setIsVisible(true), delay);
  }, [delay]);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(delaySubscription.current);
    setIsVisible(false);
  }, []);

  useLayoutEffect(() => {
    const span = elementAfterRef.current;

    span.previousSibling.addEventListener('mouseenter', handleMouseEnter);
    span.previousSibling.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      span.previousSibling.removeEventListener('mouseenter', handleMouseEnter);
      span.previousSibling.removeEventListener('mouseleave', handleMouseLeave);
    };
  });

  return (
    <>
      <TooltipBase
        {...props}
        isVisible={isVisible}
        trigger={hasDelay ? TRIGGER_TYPES.MANUAL : trigger}
      >
        {children}
      </TooltipBase>
      <span
        className={styles.elementAfter}
        ref={elementAfterRef}
      />
    </>
  );
};

Tooltip.propTypes = {
  trigger: PropTypes.oneOf(Object.values(TRIGGER_TYPES)),
  delay: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  trigger: TRIGGER_TYPES.HOVER,
  delay: null,
};

export default Tooltip;
