import React from 'react';
import { useIntl } from 'react-intl';

import { ICON_TYPES } from '../../../../Icon';
import EmptyContent from '../../../../EmptyContent';

const NoArchivedIdeas = () => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.archive,
      }}
      title={formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.noArchivedIdeas.title' })}
      description={formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.noArchivedIdeas.description' })}
    />
  );
};

export default NoArchivedIdeas;
