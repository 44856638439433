import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  SuccessAlert,
  WarningAlert,
  ErrorAlert,
} from '@epam/promo';

import HintAlert from './HintAlert';

import styles from './Alert.module.scss';

export const ALERT_TYPES = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  hint: 'hint',
};

export const ALERTS_BY_TYPE = {
  [ALERT_TYPES.success]: SuccessAlert,
  [ALERT_TYPES.error]: ErrorAlert,
  [ALERT_TYPES.warning]: WarningAlert,
  [ALERT_TYPES.hint]: HintAlert,
};

const Alert = ({
  type,
  children,
  className,
  ...props
}) => {
  const ResultAlert = ALERTS_BY_TYPE[type];

  return (
    <ResultAlert
      data-testid={type}
      cx={clsx(styles.alert, className)}
      {...props}
    >
      <p className={styles.content}>
        {children}
      </p>
    </ResultAlert>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Alert.defaultProps = {
  type: ALERT_TYPES.success,
  children: null,
  className: null,
};

export default Alert;
