import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import MainMenu from '../../MainMenu';

import styles from './Header.module.scss';

const Header = ({ className }) => (
  <header className={clsx(styles.header, className)}>
    <div className={styles.content}>
      <MainMenu data-testid="mainMenu" />
    </div>
  </header>
);

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
