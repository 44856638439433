import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { ROUTES } from '../../../../constants/routes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../redux/api/slices/ideaApi';
import history from '../../../../browserHistory';
import {
  TAB_TYPES,
  TAB_TYPE_URL_PARAM_NAME,
} from '../../IdeasList/constants';

export const updateDraftIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formData,
      showToast,
      formatMessage,
      ideaId,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const updateIdea = dispatch(ideaApi.endpoints.updateIdeaById.initiate({
    ideaId,
    data: formData,
    eventHrid,
    isDraft: true,
  }));

  try {
    await pause(updateIdea.unwrap());

    history.push({
      pathname: ROUTES.ideas,
      search: qs.stringify({ [TAB_TYPE_URL_PARAM_NAME]: TAB_TYPES.draftIdeas }),
    });

    showToast({
      message: formatMessage({ id: 'updateIdea.stepper.successDraftIdeaUpdate' }),
    });

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    updateIdea.unsubscribe();
  }
};

export const updateDraftIdeaActionCreator = createAction(ACTION_TYPES.idea.updateDraftIdea);
