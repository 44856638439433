import React from 'react';
import PropTypes from 'prop-types';

import styles from './EntityCell.module.scss';

const EntityCell = ({
  humanReadableId,
  title,
  url,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.humanReadableId}>
      {humanReadableId}
    </div>
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles.link}
    >
      {title}
    </a>
  </div>
);

EntityCell.propTypes = {
  humanReadableId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default EntityCell;
