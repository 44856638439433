import React from 'react';
import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { BACKEND_ERRORS_TRANSLATION_KEYS } from '../../../constants/backendErrors';
import { ROUTES } from '../../../constants/routes';
import TextLink from '../../TextLink';
import {
  getTokenFork,
  initializeAppFork,
} from '../../../listenerForks';
import { isAxiosError } from '../../../redux/api/fetchClient';

export const loginListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formatMessage,
      formData,
    },
  } = action;
  const { dispatch, fork } = listenerApi;

  try {
    const tokenForkResult = await fork(getTokenFork({
      dispatch,
      formData,
      handleErrors: false,
    })).result;

    if (tokenForkResult.status !== 'ok') {
      const errorIds = [];

      if (isAxiosError(tokenForkResult.error)) {
        const { status, data } = tokenForkResult.error.response || {};

        switch (status) {
          case 400:
            Object.keys(data)
              .forEach((key) => {
                data[key].forEach(({ code }) => {
                  const translationId = BACKEND_ERRORS_TRANSLATION_KEYS.LOGIN[status][key]?.[code];

                  translationId
                    ? errorIds.push(translationId)
                    : tokenForkResult.error.handleGlobally();
                });
              });

            break;
          default:
            tokenForkResult.error.handleGlobally();
        }
      }

      const errors = errorIds.map((id) => (
        formatMessage({ id }, {
          signUpLink: (chunk) => (
            <TextLink
              inheritSize
              label={chunk[0]}
              path={ROUTES.signUp}
            />
          ),
        })
      ));

      reject({
        error: errors,
      });
      return;
    }

    const initializeAppForkResult = await fork(initializeAppFork({
      dispatch,
      fork,
      token: tokenForkResult.value,
      redirectPath: ROUTES.base,
    })).result;

    if (initializeAppForkResult.status !== 'ok') {
      reject();
      return;
    }

    resolve();
  } catch (error) {
    reject();
  }
};

export const loginListenerActionCreator = createAction(ACTION_TYPES.user.login);
