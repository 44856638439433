import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Icon, {
  ICON_TYPES,
  ICON_SIZE,
} from '../../Icon';
import styles from './LikeableCheckbox.module.scss';

const LikeableCheckbox = ({
  label,
  onChange,
  onBlur,
  value,
  className,
  ...props
}) => (
  <div className={clsx(styles.likeableCheckbox, className)}>
    <input
      data-testid="checkbox"
      checked={value}
      className={styles.hiddenCheckbox}
      type="checkbox"
      onChange={(event) => onChange(event.target.checked)}
      onBlur={onBlur}
      {...props}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label
      className={styles.label}
    >
      {label}
      <Icon
        data-testid="icon"
        className={styles.icon}
        type={ICON_TYPES[value ? 'heartFilled' : 'heart']}
        size={ICON_SIZE.xs}
      />
    </label>
  </div>
);

LikeableCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

LikeableCheckbox.defaultProps = {
  className: '',
};

export default LikeableCheckbox;
