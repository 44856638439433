import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { ROUTES } from '../../../../constants/routes';
import UpdateIcon from './UpdateIcon';
import Menu from '../../../Menu';
import { IDEA_STATUSES } from '../../../../constants/idea';
import LinkIcon from './LinkIcon';
import {
  MATOMO_EVENT_ACTIONS,
  MATOMO_EVENT_CATEGORIES,
} from '../../../../constants/matomo';
import { getFullUrl, trackMatomoEvent } from '../../../../utils';
import {
  useIsAbleToArchiveIdea,
  useIsAbleToUpdateIdea,
} from '../../../../hooks';
import ArchiveIcon from './ArchiveIcon';
import IdeaSharableLink from '../../../IdeaSharableLink';

import styles from './IdeaCardMenu.module.scss';

const IdeaCardMenu = ({
  idea,
  onArchive,
}) => {
  const { formatMessage } = useIntl();
  const {
    id: ideaId,
    human_readable_id: ideaHrid,
  } = idea;
  const isAbleToUpdate = useIsAbleToUpdateIdea(idea);
  const isAbleToArchive = useIsAbleToArchiveIdea(idea);

  const trackIdeaLinkCopy = useCallback(
    () => trackMatomoEvent(
      MATOMO_EVENT_CATEGORIES.idea,
      MATOMO_EVENT_ACTIONS.shareIdea,
      ideaId,
    ),
    [ideaId],
  );

  const handleCopyLink = useCallback(() => {
    copy(getFullUrl(
      generatePath(ROUTES.idea, { ideaId: ideaHrid }),
    ));
    trackIdeaLinkCopy();
  }, [
    ideaHrid,
    trackIdeaLinkCopy,
  ]);

  const groups = useMemo(() => ([{
    id: 'actions',
    items: [{
      id: 'share',
      label: formatMessage({ id: 'ideas.ideaCard.ideaCardMenu.shareButtonLabel' }),
      icon: LinkIcon,
      onClick: handleCopyLink,
    },
    ...(isAbleToUpdate
      ? [{
        id: 'update',
        label: formatMessage({ id: 'ideas.ideaCard.ideaCardMenu.updateButtonLabel' }),
        link: {
          pathname: generatePath(ROUTES.updateIdea, { ideaId: ideaHrid }),
        },
        icon: UpdateIcon,
      }]
      : []),
    ...(isAbleToArchive
      ? [{
        id: 'archive',
        label: formatMessage({ id: 'ideas.ideaCard.ideaCardMenu.archiveButtonLabel' }),
        onClick: onArchive,
        icon: ArchiveIcon,
      }]
      : []),
    ],
  }]), [
    formatMessage,
    ideaHrid,
    handleCopyLink,
    isAbleToUpdate,
    isAbleToArchive,
    onArchive,
  ]);

  return groups[0].items.length > 1
    ? (
      <Menu
        data-testid="menu"
        dropdownMenuProps={{
          groups,
          bodyClassName: styles.dropdownBody,
        }}
      />
    ) : (
      <IdeaSharableLink
        data-testid="sharableLink"
        id={ideaHrid}
      />
    );
};

IdeaCardMenu.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    human_readable_id: PropTypes.string.isRequired,
    archive: PropTypes.object,
    status: PropTypes.oneOf(Object.values(IDEA_STATUSES)).isRequired,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onArchive: PropTypes.func.isRequired,
};

export default IdeaCardMenu;
