import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Icon, {
  ICON_TYPES,
  ICON_COLOR,
  ICON_SIZE,
} from '../Icon';
import IconButton from '../IconButton';
import DropdownMenu from '../DropdownMenu';

const Menu = ({
  iconButtonProps,
  dropdownMenuProps,
  renderDropdownButton,
  className,
}) => {
  const getMenuDotsIcon = useCallback((isOpen) => {
    const {
      type = ICON_TYPES.menuDots,
      size = ICON_SIZE.mxxxs,
      color = ICON_COLOR.secondary,
      openStateColor = ICON_COLOR.primary,
    } = iconButtonProps;

    return (
      <Icon
        type={type}
        size={size}
        color={isOpen ? openStateColor : color}
      />
    );
  }, [iconButtonProps]);

  const renderDropdownTarget = useCallback(
    (props) => {
      // eslint-disable-next-line react/prop-types
      const { isOpen } = props;

      return (
        <IconButton
          data-testid="iconButton"
          icon={() => getMenuDotsIcon(isOpen)}
          cx={className}
          {...props}
        />
      );
    },
    [getMenuDotsIcon, className],
  );

  return (
    <DropdownMenu
      data-testid="dropdownMenu"
      renderDropdownTarget={renderDropdownButton || renderDropdownTarget}
      {...dropdownMenuProps}
    />
  );
};

Menu.propTypes = {
  iconButtonProps: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(ICON_TYPES)),
    size: PropTypes.oneOf(Object.values(ICON_SIZE)),
    color: PropTypes.oneOf(Object.values(ICON_COLOR)),
    openStateColor: PropTypes.oneOf(Object.values(ICON_COLOR)),
  }),
  dropdownMenuProps: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.func,
        onClick: PropTypes.func,
        link: PropTypes.shape({
          pathname: PropTypes.string,
          search: PropTypes.string,
        }),
      })).isRequired,
    })).isRequired,
    bodyClassName: PropTypes.string,
  }).isRequired,
  renderDropdownButton: PropTypes.func,
  className: PropTypes.string,
};

Menu.defaultProps = {
  iconButtonProps: {},
  renderDropdownButton: null,
  className: '',
};

export default Menu;
