export const MATOMO_INSTRUCTION_NAMES = {
  trackPageView: 'trackPageView',
  trackEvent: 'trackEvent',
  trackGoal: 'trackGoal',
  setUserId: 'setUserId',
  resetUserId: 'resetUserId',
  enableLinkTracking: 'enableLinkTracking',
  setDocumentTitle: 'setDocumentTitle',
  setCustomUrl: 'setCustomUrl',
  enableHeartBeatTimer: 'enableHeartBeatTimer',
};

export const MATOMO_EVENT_CATEGORIES = {
  idea: 'Idea',
  ideaSolution: 'Idea solution',
  app: 'Application',
};

export const MATOMO_EVENT_ACTIONS = {
  commentIdea: 'Comment idea',
  createIdea: 'Create idea',
  shareIdea: 'Share idea',
  shareIdeaSolution: 'Share idea solution',
  signUp: 'Sign up',
  contactUs: 'Contact us',
};
