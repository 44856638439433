export const IDEA_TAB_URL_PARAM_NAME = 'tab';
export const SOLUTION_ID_URL_PARAM_NAME = 'solutionId';

export const IDEA_TAB_IDS = {
  comments: 'comments',
  links: 'links',
  // solutions: 'solutions',
};

export const DEFAULT_TAB_ID = IDEA_TAB_IDS.comments;

export const COMMENTS_ID = 'ideaComments';
export const COMMENTS_DATA_ID = 'ideaCommentsData';
export const SOLUTIONS_ID = 'solutions';
