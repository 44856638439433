import React from 'react';

import JoinOurCommunity from '../../JoinOurCommunity';
import LetsInnovate from '../../LetsInnovate';
import PageInfo from '../../PageInfo';

const LandingPage = () => (
  <>
    <PageInfo data-testid="pageInfo" />
    <LetsInnovate data-testid="letsInnovate" />
    <JoinOurCommunity data-testid="joinOurCommunity" />
  </>
);

export default LandingPage;
