import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CHART_TYPES } from '../../../Chart/constants';
import { useGetIdeaOverviewInfoQuery } from '../../../../redux/api/slices/analyticsApi';
import ChartPattern from '../../../ChartPattern';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';

const IdeasOverview = ({
  period,
  className,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ChartPattern
      chartType={CHART_TYPES.line}
      title={formatMessage({ id: 'adminPanel.dashboard.ideasOverview.title' })}
      requestProps={{ ...period, eventHrid: ACTIVE_EVENT_HRID }}
      useGetData={useGetIdeaOverviewInfoQuery}
      className={className}
    />
  );
};

IdeasOverview.propTypes = {
  period: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

IdeasOverview.defaultProps = {
  className: '',
};

export default IdeasOverview;
