import React, {
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import IdeaStepper from '../IdeaStepper';
import { ROUTES } from '../../../constants/routes';
import { useListener } from '../../../hooks';
import {
  createIdeaActionCreator,
  createIdeaListener,
} from './listeners/createIdeaListener';
import {
  createDraftIdeaListener,
  createDraftIdeaActionCreator,
} from './listeners/createDraftIdeaListener';

import styles from './NewIdea.module.scss';

const NewIdea = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [createIdea] = useListener({
    listener: createIdeaListener,
    actionCreator: createIdeaActionCreator,
  });
  const [createDraftIdea] = useListener({
    listener: createDraftIdeaListener,
    actionCreator: createDraftIdeaActionCreator,
  });

  const handleDiscard = useCallback(() => (
    history.push(ROUTES.ideas)
  ), [history]);

  return (
    <div className={styles.newIdea}>
      <IdeaStepper
        data-testid="stepper"
        onDone={createIdea}
        onSaveAsDraft={createDraftIdea}
        onDiscard={handleDiscard}
        doneButtonCaption={formatMessage({
          id: 'newIdea.stepper.submitButtonCaption',
        })}
      />
    </div>
  );
};

export default NewIdea;
