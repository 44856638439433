import { isAxiosError } from '../redux/api/fetchClient';
import { eventsApi } from '../redux/api/slices/eventsApi';

export const getActiveEventFork = ({
  dispatch,
  token,
  activeEventHrid,
  handleErrors = true,
}) => async (forkApi) => {
  const { pause } = forkApi;

  const getEvent = dispatch(
    eventsApi.endpoints.getEvent.initiate({
      token: token || null,
      eventHrid: activeEventHrid,
    }, { forceRefetch: true }),
  );

  try {
    return await pause(getEvent.unwrap());
  } catch (error) {
    if (handleErrors) {
      isAxiosError(error) && error.handleGlobally();
    }

    throw error;
  } finally {
    getEvent.unsubscribe();
  }
};
