export const FILTERS_URL_PARAM_NAME = 'ideasDownSelectionFilters';
export const TABLE_ID = 'ideasDownSelectionTable';
export const SORTING_URL_PARAM_NAME = 'ideasDownSelectionSorting';
export const PAGE_URL_PARAM_NAME = 'ideasDownSelectionPage';

export const ACCESSOR_KEYS = {
  IDEA_TITLE: 'title',
  COLLABORATION: 'collaborations',
  ACTIONS: 'ideasDownSelectionActions',
};
