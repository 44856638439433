import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaApi } from '../../../../redux/api/slices/ideaApi';
import history from '../../../../browserHistory';

export const deleteIdeaListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      ideaId,
      eventHrid,
      redirectPath,
      isDraft,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const deleteIdea = dispatch(
    ideaApi.endpoints.deleteIdea.initiate({ ideaId, eventHrid, isDraft }),
  );

  try {
    await pause(deleteIdea.unwrap());

    redirectPath && history.push(redirectPath);

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    deleteIdea.unsubscribe();
  }
};

export const deleteIdeaActionCreator = createAction(ACTION_TYPES.idea.deleteIdea);
