import React from 'react';
import PropTypes from 'prop-types';

import LikeableCheckbox from '../LikeableCheckbox';
import styles from './LikeableCheckboxGroup.module.scss';

const LikeableCheckboxGroup = ({
  items,
  onChange,
  onBlur,
  value,
  name,
}) => (
  <div className={styles.likeableCheckboxGroup}>
    {items.map(({ label, value: itemValue }) => (
      <LikeableCheckbox
        data-testid={`likeableCheckbox${itemValue}`}
        className={styles.likeableCheckbox}
        key={itemValue}
        value={value.includes(itemValue)}
        label={label}
        name={`${name}.${itemValue}`}
        onBlur={onBlur}
        onChange={(newItemValue) => {
          let newValue;

          if (newItemValue) {
            newValue = [...value, itemValue];
          } else {
            newValue = value.filter((currentItemValue) => currentItemValue !== itemValue);
          }

          onChange(newValue);
        }}
      />
    ))}
  </div>
);

LikeableCheckboxGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

LikeableCheckboxGroup.defaultProps = {
  items: [],
};

export default LikeableCheckboxGroup;
