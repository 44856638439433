import React, {
  useCallback,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import {
  DropdownMenuButton,
  Dropdown,
  DropdownMenuBody,
  DropdownMenuHeader,
  DropdownMenuSplitter,
} from '@epam/promo';

import styles from './DropdownMenu.module.scss';

const DropdownMenu = ({
  header,
  groups,
  renderDropdownTarget,
  closeOnItemClick,
  bodyClassName,
}) => {
  const renderDropdownBody = useCallback(({ onClose, ...props }) => (
    <DropdownMenuBody
      onClose={onClose}
      cx={bodyClassName}
      {...props}
    >
      {header && (
        <>
          <DropdownMenuHeader
            cx={styles.dropdownHeader}
            caption={header}
          />
          <DropdownMenuSplitter />
        </>
      )}
      {
        groups.map((group) => (
          <Fragment key={group.id}>
            {group.items.map(({
              id,
              label,
              onClick,
              link,
              ...restProps
            }) => (
              <DropdownMenuButton
                key={id}
                caption={label}
                onClick={() => {
                  onClick?.(id, onClose);
                  closeOnItemClick && onClose();
                }}
                link={link}
                cx={styles.menuItem}
                {...restProps}
              />
            ))}
            {group !== groups.at(-1) && (
              <DropdownMenuSplitter />
            )}
          </Fragment>
        ))
      }
    </DropdownMenuBody>

  ), [
    header,
    groups,
    closeOnItemClick,
    bodyClassName,
  ]);

  return (
    <Dropdown
      renderTarget={renderDropdownTarget}
      renderBody={renderDropdownBody}
      placement="bottom-end"
      closeOnClickOutside
    />
  );
};

DropdownMenu.propTypes = {
  header: PropTypes.node,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.func,
      onClick: PropTypes.func,
      link: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
    })).isRequired,
  })).isRequired,
  renderDropdownTarget: PropTypes.func.isRequired,
  closeOnItemClick: PropTypes.bool,
  bodyClassName: PropTypes.string,
};

DropdownMenu.defaultProps = {
  header: '',
  closeOnItemClick: true,
  bodyClassName: '',
};

export default DropdownMenu;
