import React, {
  useReducer,
  useMemo,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';
import {
  isUndefined,
  findIndex,
} from 'lodash';

import { useWithReducerDispatch } from '../../hooks';
import PrimaryView from './PrimaryView';
import SecondaryView from './SecondaryView';
import LeavePrompt from '../LeavePromptProvider/LeavePrompt';

export const StepperContext = React.createContext();

const resetActionCreator = createAction('reset');
const setActiveStepStateActionCreator = createAction('setActiveStepState');
const goNextActionCreator = createAction('goNext');
const goBackActionCreator = createAction('goBack');
const saveAsDraftActionCreator = createAction('saveAsDraft');
const setDoneInProgressActionCreator = createAction('setDoneInProgress');
const setSaveAsDraftInProgressActionCreator = createAction('setSaveAsDraftInProgress');

const reducer = createReducer({}, {
  [resetActionCreator]: (state, action) => action.payload,
  [setActiveStepStateActionCreator]: (state, action) => {
    state.activeStepState = action.payload;
  },
  [goNextActionCreator]: (state, {
    payload: {
      values,
      stepId,
      isDoneInProgress,
      isDirty,
    },
  }) => {
    state.values[state.activeStepId] = values;
    state.activeStepState = {};
    state.dirtySteps = {
      ...state.dirtySteps,
      [state.activeStepId]: isDirty,
    };

    if (!isUndefined(stepId)) {
      state.activeStepId = stepId;
    }

    if (!isUndefined(isDoneInProgress)) {
      state.isDoneInProgress = isDoneInProgress;
    }
  },
  [goBackActionCreator]: (state, {
    payload: {
      values,
      stepId,
      isDirty,
    },
  }) => {
    state.values[state.activeStepId] = values;
    state.activeStepState = {};
    state.dirtySteps = {
      ...state.dirtySteps,
      [state.activeStepId]: isDirty,
    };

    if (!isUndefined(stepId)) {
      state.activeStepId = stepId;
    }
  },
  [saveAsDraftActionCreator]: (state, {
    payload: {
      values,
    },
  }) => {
    state.values[state.activeStepId] = values;
    state.isSaveAsDraftInProgress = true;
  },
  [setDoneInProgressActionCreator]: (state, action) => {
    state.isDoneInProgress = action.payload;
  },
  [setSaveAsDraftInProgressActionCreator]: (state, action) => {
    state.isSaveAsDraftInProgress = action.payload.isSaveAsDraftInProgress;
  },
});

export const STEPPER_VIEWS = {
  primary: 'primary',
  secondary: 'secondary',
};

const VIEWS = {
  [STEPPER_VIEWS.primary]: PrimaryView,
  [STEPPER_VIEWS.secondary]: SecondaryView,
};

const Stepper = ({
  steps,
  defaultValues,
  doneButtonCaption,
  saveAsDraftButtonCaption,
  discardButtonCaption,
  onDone,
  onSaveAsDraft,
  onDiscard,
  view,
  primaryAdditionalActionsProps,
  preventLeavingWhenDirty,
  globalData,
}) => {
  const activeDefaultValues = useRef(defaultValues);
  const activeStepKey = useRef(1);
  const firstStepId = steps?.[0]?.id || null;
  const initialState = {
    activeStepId: firstStepId,
    activeStepState: {},
    values: defaultValues,
    isDoneInProgress: false,
    isSaveAsDraftInProgress: false,
    dirtySteps: {},
  };
  const [{
    activeStepId,
    activeStepState: {
      isLoading,
      isInProgress,
      isFetching,
      isDirty,
      nextButtonCaption,
      backButtonCaption,
      onGoNext,
      onGoBack,
      onSaveAsDraft: onStepSaveAsDraft,
    },
    values,
    isDoneInProgress,
    isSaveAsDraftInProgress,
    dirtySteps,
  }, dispatch] = useReducer(reducer, initialState);

  const isStepDirty = useRef();
  isStepDirty.current = isDirty;

  const resetAction = useWithReducerDispatch(resetActionCreator, dispatch);
  const goNextAction = useWithReducerDispatch(goNextActionCreator, dispatch);
  const goBackAction = useWithReducerDispatch(goBackActionCreator, dispatch);
  const saveAsDraftAction = useWithReducerDispatch(saveAsDraftActionCreator, dispatch);
  const setActiveStepStateAction = useWithReducerDispatch(
    setActiveStepStateActionCreator,
    dispatch,
  );
  const setDoneInProgressAction = useWithReducerDispatch(setDoneInProgressActionCreator, dispatch);
  const setSaveAsDraftInProgressAction = useWithReducerDispatch(
    setSaveAsDraftInProgressActionCreator,
    dispatch,
  );

  const shouldUpdateDefaultValues = activeDefaultValues.current !== defaultValues;

  const activeStepIndex = findIndex(steps, { id: activeStepId });

  const hasSteps = !!steps.length;
  const areActionsDisabled = isLoading
    || isFetching
    || isInProgress
    || isDoneInProgress
    || isSaveAsDraftInProgress;
  const isProcessing = isInProgress || isDoneInProgress || isSaveAsDraftInProgress;
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === steps.length - 1;

  const ActiveStep = hasSteps
    ? steps[activeStepIndex].Step
    : null;

  const activeStepValues = values[activeStepId];
  const activeStepDefaultValues = defaultValues[activeStepId];

  const nextStepId = (!hasSteps || isLastStep) ? null : steps[activeStepIndex + 1].id;
  const previousStepId = (!hasSteps || isFirstStep) ? null : steps[activeStepIndex - 1].id;

  // eslint-disable-next-line no-unused-vars
  const isStepperDirty = useMemo(() => (
    Object.values({
      ...dirtySteps,
      [activeStepId]: isDirty,
    }).some((dirtyStep) => dirtyStep)
  ), [
    dirtySteps,
    isDirty,
    activeStepId,
  ]);
  const shouldBlockPageLeave = preventLeavingWhenDirty
    && isStepperDirty
    && !isDoneInProgress
    && !isSaveAsDraftInProgress;

  const goNext = useCallback((stepValues) => {
    const payload = {
      values: stepValues,
      isDirty: isStepDirty.current,
    };

    if (nextStepId) {
      payload.stepId = nextStepId;
    } else {
      payload.isDoneInProgress = true;
    }

    goNextAction(payload);
  }, [
    goNextAction,
    nextStepId,
  ]);

  const goBack = useCallback((stepValues) => {
    const payload = {
      values: stepValues,
      isDirty: isStepDirty.current,
    };

    if (previousStepId) {
      payload.stepId = previousStepId;
    }

    goBackAction(payload);
  }, [
    goBackAction,
    previousStepId,
  ]);

  const saveAsDraft = useCallback((stepValues) => {
    saveAsDraftAction({
      values: stepValues,
    });
  }, [saveAsDraftAction]);

  const stepperApi = useMemo(() => ({
    registerStep: setActiveStepStateAction,
    goNext,
    goBack,
    saveAsDraft,
    stepsDefaultValues: defaultValues,
    stepsValues: values,
    stepValues: activeStepValues,
    stepDefaultValues: activeStepDefaultValues,
    globalData,
  }), [
    setActiveStepStateAction,
    goNext,
    goBack,
    saveAsDraft,
    defaultValues,
    values,
    activeStepValues,
    activeStepDefaultValues,
    globalData,
  ]);

  const handleNextButtonClick = useCallback(() => {
    if (onGoNext) {
      onGoNext();
    } else {
      goNext();
    }
  }, [
    goNext,
    onGoNext,
  ]);

  const handleBackButtonClick = useCallback(() => {
    if (onGoBack) {
      onGoBack();
    } else {
      goBack();
    }
  }, [
    goBack,
    onGoBack,
  ]);

  const handleSaveAsDraftButtonClick = useCallback(() => {
    if (onStepSaveAsDraft) {
      onStepSaveAsDraft();
    } else {
      saveAsDraft();
    }
  }, [
    saveAsDraft,
    onStepSaveAsDraft,
  ]);

  useLayoutEffect(() => {
    const runOnDone = async () => {
      try {
        await onDone(values);
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        setDoneInProgressAction(false);
      }
    };

    if (isDoneInProgress) {
      runOnDone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDoneInProgress,
  ]);

  useLayoutEffect(() => {
    const runOnSaveAsDraft = async () => {
      try {
        await onSaveAsDraft(values);
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        setSaveAsDraftInProgressAction(false);
      }
    };

    if (isSaveAsDraftInProgress) {
      runOnSaveAsDraft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSaveAsDraftInProgress,
  ]);

  useLayoutEffect(() => {
    if (shouldUpdateDefaultValues && !isProcessing) {
      activeDefaultValues.current = defaultValues;
      activeStepKey.current += 1;
      resetAction(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldUpdateDefaultValues,
    isProcessing,
  ]);

  if (!hasSteps) {
    return null;
  }

  const View = VIEWS[view];

  return (
    <>
      <StepperContext.Provider value={stepperApi}>
        <View
          steps={steps}
          ActiveStep={ActiveStep}
          activeStepId={activeStepId}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          isFetching={isFetching}
          isProcessing={isProcessing}
          backButtonCaption={backButtonCaption}
          doneButtonCaption={doneButtonCaption}
          nextButtonCaption={nextButtonCaption}
          saveAsDraftButtonCaption={saveAsDraftButtonCaption}
          discardButtonCaption={discardButtonCaption}
          areActionsDisabled={areActionsDisabled}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          onSaveAsDraftButtonClick={onSaveAsDraft && handleSaveAsDraftButtonClick}
          onDiscardButtonClick={onDiscard}
          activeStepKey={activeStepKey.current}
          primaryAdditionalActionsProps={primaryAdditionalActionsProps}
        />
      </StepperContext.Provider>
      {shouldBlockPageLeave && (
        <LeavePrompt />
      )}
    </>

  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      Step: PropTypes.elementType.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  defaultValues: PropTypes.object,
  doneButtonCaption: PropTypes.string,
  discardButtonCaption: PropTypes.string,
  saveAsDraftButtonCaption: PropTypes.string,
  onDone: PropTypes.func.isRequired,
  onSaveAsDraft: PropTypes.func,
  onDiscard: PropTypes.func,
  view: PropTypes.oneOf(Object.values(STEPPER_VIEWS)),
  primaryAdditionalActionsProps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    caption: PropTypes.string.isRequired,
  })),
  preventLeavingWhenDirty: PropTypes.bool,
  globalData: PropTypes.object,
};

Stepper.defaultProps = {
  view: STEPPER_VIEWS.primary,
  steps: [],
  defaultValues: {},
  doneButtonCaption: null,
  discardButtonCaption: null,
  saveAsDraftButtonCaption: null,
  onDiscard: null,
  onSaveAsDraft: null,
  primaryAdditionalActionsProps: [],
  preventLeavingWhenDirty: false,
  globalData: null,
};

export default Stepper;
