import { baseApi } from '../baseApi';
import { API_TAG_TYPES } from './constants';
import { getRawId } from '../../../utils';

export const ideaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getIdeas: build.query({
      query: ({
        page,
        pageSize,
        sort,
        bizCategoryIds,
        isDraft,
        authorId,
        likedBy,
        isArchive,
        eventHrid,
      }) => ({
        url: isDraft
          ? `/events/${eventHrid}/draft-ideas`
          : `/events/${eventHrid}/ideas`,
        params: {
          page,
          size: pageSize,
          sort,
          biz_categories: bizCategoryIds,
          author_id: authorId,
          liked_by: likedBy,
          is_archive: isArchive,
        },
      }),
      providesTags: (result, error) => (error ? [] : result.items.map(
        ({ id }) => ({ type: API_TAG_TYPES.idea, id }),
      )),
    }),
    getIdeaById: build.query({
      query: ({ ideaId, eventHrid }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}`,
      }),
      providesTags: (result, error) => (error ? [] : [{ type: API_TAG_TYPES.idea, id: result.id }]),
    }),
    createIdea: build.mutation({
      query: ({ data, eventHrid }) => ({
        url: `/events/${eventHrid}/ideas`,
        method: 'POST',
        data,
      }),
    }),
    createDraftIdea: build.mutation({
      query: ({ data, eventHrid }) => ({
        url: `/events/${eventHrid}/draft-ideas`,
        method: 'POST',
        data,
      }),
    }),
    likeIdea: build.mutation({
      query: ({ ideaId, eventHrid }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/likes`,
        method: 'POST',
      }),
      onQueryStarted: async ({
        ideaId, isLiked, userId,
      }, { dispatch, queryFulfilled, getState }) => {
        const rawIdeaId = getRawId(ideaId);
        const patchResults = [];

        baseApi.util.selectInvalidatedBy(
          getState(),
          [{ type: API_TAG_TYPES.idea, id: rawIdeaId }],
        ).forEach(({ endpointName, originalArgs }) => {
          switch (endpointName) {
            case ('getIdeaById'): {
              const patchResult = dispatch(
                baseApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (idea) => {
                    Object.assign(idea, {
                      likes: isLiked
                        ? [...idea.likes, userId]
                        : idea.likes.filter((like) => like !== userId),
                    });
                  },
                ),
              );

              patchResults.push(patchResult);
              break;
            }
            case ('getIdeas'): {
              const patchResult = dispatch(
                baseApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (result) => {
                    const ideaIndex = result.items.findIndex(
                      (idea) => idea.id === rawIdeaId,
                    );

                    result.items[ideaIndex] = Object.assign(result.items[ideaIndex], {
                      likes: isLiked
                        ? [...result.items[ideaIndex].likes, userId]
                        : result.items[ideaIndex].likes.filter((like) => like !== userId),
                    });

                    return result;
                  },
                ),
              );

              patchResults.push(patchResult);
              break;
            }
            default:
              break;
          }
        });

        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach((result) => result.undo());
        }
      },
    }),
    archiveIdea: build.mutation({
      query: ({
        ideaId, eventHrid, data,
      }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/archives`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [
          { type: API_TAG_TYPES.idea, id: getRawId(ideaId) },
          API_TAG_TYPES.announceWinnerMetaData,
          API_TAG_TYPES.userVotesMeta,
        ]
      ),
    }),
    restoreIdea: build.mutation({
      query: ({
        ideaId, eventHrid,
      }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/archives`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [{ type: API_TAG_TYPES.idea, id: getRawId(ideaId) }]
      ),
    }),
    deleteIdea: build.mutation({
      query: ({ ideaId, eventHrid, isDraft }) => ({
        url: isDraft
          ? `/events/${eventHrid}/draft-ideas/${getRawId(ideaId)}`
          : `/events/${eventHrid}/ideas/${getRawId(ideaId)}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [{ type: API_TAG_TYPES.idea, id: getRawId(ideaId) }]
      ),
    }),
    updateIdeaById: build.mutation({
      query: ({
        ideaId, eventHrid, data, isDraft,
      }) => ({
        url: isDraft
          ? `/events/${eventHrid}/draft-ideas/${getRawId(ideaId)}`
          : `/events/${eventHrid}/ideas/${getRawId(ideaId)}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [{ type: API_TAG_TYPES.idea, id: getRawId(ideaId) }]
      ),
    }),
    createIdeaFromDraftIdea: build.mutation({
      query: ({ ideaId, data, eventHrid }) => ({
        url: `/events/${eventHrid}/draft-ideas/${getRawId(ideaId)}/submit`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [{ type: API_TAG_TYPES.idea, id: getRawId(ideaId) }]
      ),
    }),
    getSolutions: build.query({
      query: ({
        ideaId, sortingOption, eventHrid,
      }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/solutions`,
        params: {
          sort: sortingOption,
        },
      }),
      providesTags: (solutions, error) => (error ? [] : solutions.map(
        ({ id }) => ({ type: API_TAG_TYPES.ideaSolution, id }),
      )),
    }),
    getIdeasReport: build.mutation({
      query: ({ eventHrid }) => ({
        url: `/events/${eventHrid}/ideas/export`,
        responseType: 'blob',
      }),
    }),
    toggleIdeaForVote: build.mutation({
      query: ({ eventHrid, ideaId, isVoting }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/for-voting`,
        method: 'PUT',
        data: { is_voting: isVoting },
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [{ type: API_TAG_TYPES.idea, id: getRawId(ideaId) }]
      ),
    }),
    selectIdeaAsWinner: build.mutation({
      query: ({ eventHrid, ideaId }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/winner`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        !error || error.response.status === 403
          ? [
            { type: API_TAG_TYPES.idea, id: getRawId(ideaId) },
            API_TAG_TYPES.announceWinnerMetaData,
          ] : []
      ),
    }),
    deselectIdeaAsWinner: build.mutation({
      query: ({ eventHrid, ideaId }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/remove-winner`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [
          { type: API_TAG_TYPES.idea, id: getRawId(ideaId) },
          API_TAG_TYPES.announceWinnerMetaData,
        ]
      ),
    }),
    voteIdea: build.mutation({
      query: ({ eventHrid, ideaId }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/vote`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        !error || error.response?.status === 403
          ? [
            { type: API_TAG_TYPES.idea, id: getRawId(ideaId) },
            API_TAG_TYPES.userVotesMeta,
          ]
          : []
      ),
    }),
    unvoteIdea: build.mutation({
      query: ({ eventHrid, ideaId }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/remove-vote`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { ideaId }) => (
        error ? [] : [
          { type: API_TAG_TYPES.idea, id: getRawId(ideaId) },
          API_TAG_TYPES.userVotesMeta,
        ]
      ),
    }),
  }),
});

export const {
  useGetIdeasQuery,
  useGetIdeaByIdQuery,
  useLikeIdeaMutation,
  useGetSolutionsQuery,
} = ideaApi;

export const getIdeaSelector = (ideaId, eventHrid) => (state) => (
  ideaApi.endpoints.getIdeaById.select({
    ideaId,
    eventHrid,
  })(state).data
);
