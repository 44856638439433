import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { businessObjectivesApi } from '../../../redux/api/slices/businessObjectivesApi';

export const toggleBizCategoryAvailabilityListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      bizCategoryId,
      isActive,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const toggleAvailabilityBizCategory = dispatch(
    businessObjectivesApi.endpoints.updateBusinessObjective.initiate({
      id: bizCategoryId,
      data: { is_active: isActive },
    }),
  );

  try {
    await pause(toggleAvailabilityBizCategory.unwrap());

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    toggleAvailabilityBizCategory.unsubscribe();
  }
};

export const toggleBizCategoryAvailabilityActionCreator = createAction(
  ACTION_TYPES.bizCategory.toggleBizCategoryAvailability,
);
