import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { ROUTES } from '../../../../constants/routes';
import { getFullUrl } from '../../../../utils';
import Table from '../../../Table';
import { EntityCell, TextCell } from '../../../Table/cellsRenders';
import IdeaDetails from '../../../Table/expandedRowRenders/IdeaDetails';
import {
  ACCESSOR_KEYS,
  FILTERS_URL_PARAM_NAME,
  PAGE_URL_PARAM_NAME,
  SORTING_URL_PARAM_NAME,
  TABLE_ID,
} from './constants';
import NoArchivedIdeas from './NoArchivedIdeas';
import Spinner from '../../../Spinner';
import NoFilterResults from '../../../Filters/NoFilterResults';
import { useGetIdeas } from './hooks/useGetIdeas';
import { useGetActiveBusinessObjectivesQuery } from '../../../../redux/api/slices/businessObjectivesApi';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';
import {
  toggleArchiveIdeaListener,
  toggleArchiveIdeaActionCreator,
} from '../../../Idea/listeners/toggleArchiveIdeaListener';
import { useListener, useModal } from '../../../../hooks';
import DeleteIdeaConfirmationModal from '../../../modals/DeleteIdeaConfirmationModal';
import ExportToExcelButton from '../ExportToExcelButton';
import {
  useGetFilters,
  useGetSorting,
} from '../hooks';
import Actions from './Actions';

import styles from './ArchivedIdeasTab.module.scss';

const ArchivedIdeasTab = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();

  const [isRestoring, setIsRestoring] = useState(false);

  const {
    data: bizObjectivesData,
    isLoading: areBizObjectivesLoading,
    error: bizObjectivesError,
  } = useGetActiveBusinessObjectivesQuery();

  const [restore] = useListener({
    listener: toggleArchiveIdeaListener,
    actionCreator: toggleArchiveIdeaActionCreator,
  });

  const handleRestore = useCallback(
    async (ideaId) => {
      setIsRestoring(true);

      try {
        await restore({
          ideaId,
          eventHrid: ACTIVE_EVENT_HRID,
          isArchive: false,
        });
      } finally {
        setIsRestoring(false);
      }
    },
    [restore],
  );

  const renderTitleCell = useCallback(({ row }) => (
    <EntityCell
      humanReadableId={
        formatMessage(
          { id: 'common.ideaTitle' },
          {
            isArchived: !!row.original.archive,
            name: row.original.human_readable_id,
          },
        )
      }
      title={row.original.title}
      url={getFullUrl(generatePath(ROUTES.idea, { ideaId: row.original.human_readable_id }))}
    />
  ), [formatMessage]);

  const showDeleteIdeaConfirmationModal = useCallback((ideaId) => showModal((props) => (
    <DeleteIdeaConfirmationModal
      {...props}
      ideaId={ideaId}
    />
  )), [
    showModal,
  ]);

  const renderStatusCell = useCallback(() => (
    <TextCell
      value={formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.columns.status.archived' })}
    />
  ), [formatMessage]);

  const renderActionsCell = useCallback(({ row }) => (
    <Actions
      idea={row.original}
      onRestore={() => handleRestore(row.original.human_readable_id)}
      onDelete={() => showDeleteIdeaConfirmationModal(row.original.human_readable_id)}
    />
  ), [
    showDeleteIdeaConfirmationModal,
    handleRestore,
  ]);

  const renderDetailPanel = useCallback(({ row }) => (
    <IdeaDetails
      idea={row.original}
    />
  ), []);

  const renderEmptyState = useCallback(({
    hasActiveFilters,
    clearFilters,
  }) => {
    if (hasActiveFilters) {
      return <NoFilterResults onClick={clearFilters} />;
    }
    return <NoArchivedIdeas />;
  }, []);

  const columns = useMemo(() => ([
    {
      accessorKey: ACCESSOR_KEYS.IDEA_TITLE,
      header: formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.columns.title' }),
      cell: renderTitleCell,
    },
    {
      accessorKey: ACCESSOR_KEYS.STATUS,
      header: formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.columns.status' }),
      cell: renderStatusCell,
      size: 20,
    },
    {
      accessorKey: ACCESSOR_KEYS.ACTIONS,
      header: formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.columns.actions' }),
      cell: renderActionsCell,
      size: 48,
    },
  ]), [
    formatMessage,
    renderTitleCell,
    renderStatusCell,
    renderActionsCell,
  ]);

  const filters = useGetFilters({ bizObjectives: bizObjectivesData });
  const sortingItems = useGetSorting();

  useEffect(() => {
    isAxiosError(bizObjectivesError) && bizObjectivesError.handleGlobally();
  }, [bizObjectivesError]);

  if (areBizObjectivesLoading) {
    return <Spinner cx={styles.spinner} />;
  }

  return (
    <Table
      emptyState={renderEmptyState}
      isInProgress={isRestoring}
      tableId={TABLE_ID}
      columns={columns}
      useGetData={useGetIdeas}
      renderDetailPanel={renderDetailPanel}
      pageUrlParamName={PAGE_URL_PARAM_NAME}
      totalResultsLabelId="adminPanel.ideasBoard.archivedIdeasTable.totalResultsLabel"
      actions={<ExportToExcelButton />}
      filtersProps={{
        urlParamName: FILTERS_URL_PARAM_NAME,
        title: formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.filters.title' }),
        filters,
        reportLabelId: 'adminPanel.ideasBoard.archivedIdeasTable.filters.reportLabel',
      }}
      sortingProps={{
        urlParamName: SORTING_URL_PARAM_NAME,
        items: sortingItems,
      }}
      getDataAdditionalParams={{ eventHrid: ACTIVE_EVENT_HRID }}
    />
  );
};
export default ArchivedIdeasTab;
