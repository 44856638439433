import React from 'react';
import PropTypes from 'prop-types';

import styles from './Section.module.scss';

const Section = ({
  heading,
  description,
  menu,
}) => (
  <div className={styles.section}>
    <div className={styles.header}>
      <h5 className={styles.heading}>{heading}</h5>
      {menu}
    </div>
    {
      typeof description === 'string'
        ? <p className={styles.description}>{description}</p>
        : description
    }
  </div>
);

Section.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  menu: PropTypes.node,
};

Section.defaultProps = {
  menu: null,
};

export default Section;
