import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../Icon';

const NavigationIcon = () => (
  <Icon
    color={ICON_COLOR.primary}
    size={ICON_SIZE.mxxxs}
    type={ICON_TYPES.arrowRight}
  />
);

export default NavigationIcon;
