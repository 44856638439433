import React, { useEffect } from 'react';

import OnboardingModal from '../modals/OnboardingModal';
import Stepper from './Stepper';
import { useModal } from '../../hooks';

import styles from './Onboarding.module.scss';

const Onboarding = () => {
  const { showModal } = useModal();

  useEffect(() => {
    showModal((props) => <OnboardingModal {...props} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.onboarding}>
      <Stepper data-testid="stepper" />
    </div>
  );
};

export default Onboarding;
