import React from 'react';

import Icon, {
  ICON_COLOR,
  ICON_TYPES,
} from '../../../../Icon';

const LinkIcon = () => (
  <Icon
    type={ICON_TYPES.link}
    color={ICON_COLOR.quaternary}
    data-testid="linkIcon"
  />
);

export default LinkIcon;
