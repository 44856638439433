import { flushSync } from 'react-dom';

import history from '../browserHistory';
import { ROUTES } from '../constants/routes';
import { resetActionCreator } from '../redux/actionCreators';
import { removeTokenFromLocalStorage } from '../utils';

export const logoutFork = (dispatch) => async () => {
  removeTokenFromLocalStorage();

  flushSync(() => {
    dispatch(resetActionCreator());
    history.push(ROUTES.base);
  });
};
