import React from 'react';
import { useIntl } from 'react-intl';

import SsoAuthButton from '../SsoAuthButton';
import Animation from '../Animation';
import squaresAnimationData from './animations/squares.json';

import styles from './LetsInnovate.module.scss';

const LetsInnovate = () => {
  const { formatMessage } = useIntl();

  return (
    <section className={styles.letsInnovate}>
      <div className={styles.container}>
        <h2 className={styles.heading}>
          {formatMessage({ id: 'letsInnovate.heading' })}
        </h2>
        <p className={styles.description}>
          {formatMessage({ id: 'letsInnovate.description' })}
        </p>
        <SsoAuthButton
          data-testid="ssoAuthButton"
          className={styles.ssoAuthButton}
        />
        <Animation
          data-testid="squaresAnimation"
          className={styles.animation}
          animationData={squaresAnimationData}
        />
      </div>
    </section>
  );
};

export default LetsInnovate;
