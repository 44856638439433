import React from 'react';
import { useIntl } from 'react-intl';

import Onboarding from '../../Onboarding';
import PageInfo from '../../PageInfo';

const OnboardingPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage({ id: 'onboardingPage.pageTitle' })}
      />
      <Onboarding data-testid="onboarding" />
    </>
  );
};

export default OnboardingPage;
