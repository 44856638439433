import React, {
  useState,
  useCallback,
} from 'react';

import AttachLinksForm from '../../../forms/AttachLinksForm';
import {
  useStep,
  useStepperApi,
  OneColumnContent,
} from '../../../Stepper';

const AttachLinksStep = () => {
  const {
    goNext,
    goBack,
    stepDefaultValues,
    stepValues,
    saveAsDraft,
  } = useStepperApi();
  const [{
    getFormValues,
    isSubmitting,
    submitForm,
    isDirty,
  }, setFormApi] = useState({});
  const handleSubmit = useCallback((formValues) => goNext(formValues), [goNext]);
  const handleGoBack = useCallback(() => goBack(getFormValues()), [goBack, getFormValues]);
  const handleSaveAsDraft = useCallback(
    () => saveAsDraft(getFormValues()),
    [saveAsDraft, getFormValues],
  );

  useStep({
    isInProgress: isSubmitting,
    onGoNext: submitForm,
    onGoBack: handleGoBack,
    onSaveAsDraft: handleSaveAsDraft,
    isDirty,
  });

  return (
    <OneColumnContent>
      <AttachLinksForm
        data-testid="attachLinksForm"
        onSubmit={handleSubmit}
        getFormApi={setFormApi}
        defaultValues={stepDefaultValues}
        values={stepValues}
      />
    </OneColumnContent>
  );
};

export default AttachLinksStep;
