import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedNumber } from 'react-intl';
import clsx from 'clsx';

import Icon, {
  ICON_COLOR,
  ICON_TYPES,
  ICON_SIZE,
} from '../../Icon';

import styles from './StatCard.module.scss';

export const STAT_CARD_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  quaternary: 'quaternary',
};

const ICON_WRAPPER_CLASSNAME_BY_TYPE = {
  [STAT_CARD_TYPES.primary]: styles.iconWrapper__primary,
  [STAT_CARD_TYPES.secondary]: styles.iconWrapper__secondary,
  [STAT_CARD_TYPES.tertiary]: styles.iconWrapper__tertiary,
  [STAT_CARD_TYPES.quaternary]: styles.iconWrapper__quaternary,
};

const StatCard = ({
  type,
  entityName,
  total,
  lastWeekTotal,
  iconType,
  className,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div
        className={
          clsx(
            styles.iconWrapper,
            ICON_WRAPPER_CLASSNAME_BY_TYPE[type],
          )
        }
      >
        <Icon
          type={iconType}
          size={ICON_SIZE.mxxs}
          color={ICON_COLOR.tertiary}
          data-testid="statCardIcon"
        />
      </div>
      <p className={styles.title}>
        {formatMessage(
          { id: 'eventStats.statCard.entityInfo' },
          { entityName },
        )}
      </p>
      <p className={styles.total}>
        <FormattedNumber value={total} />
      </p>
      <p className={styles.headway}>
        {formatMessage(
          { id: 'eventStats.statCard.lastWeekTotal' },
          { lastWeekTotal: <FormattedNumber value={lastWeekTotal} /> },
        )}
      </p>
    </div>
  );
};

StatCard.propTypes = {
  type: PropTypes.oneOf(
    Object.values(STAT_CARD_TYPES),
  ).isRequired,
  entityName: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  lastWeekTotal: PropTypes.number.isRequired,
  iconType: PropTypes.oneOf(
    Object.values(ICON_TYPES),
  ).isRequired,
  className: PropTypes.string,
};

StatCard.defaultProps = {
  className: null,
};

export default StatCard;
