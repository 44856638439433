import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FiltersPanel as FiltersPanelBase } from '@epam/promo';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { useGenerateLink } from '../../hooks';

import styles from './FiltersPanel.module.scss';

const FiltersPanel = ({
  filters,
  urlParamName,
  activeFilters,
  linkAdditionalProps,
}) => {
  const { generateLink } = useGenerateLink();
  const history = useHistory();
  const isAllFiltersAlwaysVisible = useMemo(
    () => filters.every((filter) => filter.isAlwaysVisible),
    [filters],
  );

  const handleFilterChange = useCallback(({ filter }) => {
    history.push(generateLink({
      [urlParamName]: filter,
      ...linkAdditionalProps,
    }));
  }, [
    history,
    generateLink,
    urlParamName,
    linkAdditionalProps,
  ]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.hideAddFilterButton]: isAllFiltersAlwaysVisible,
      })}
    >
      <FiltersPanelBase
        filters={filters}
        tableState={{ filter: activeFilters }}
        setTableState={handleFilterChange}
      />
    </div>
  );
};

FiltersPanel.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isAlwaysVisible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    dataSource: PropTypes.oneOfType([
      PropTypes.object,
    ]).isRequired,
  })).isRequired,
  urlParamName: PropTypes.string.isRequired,
  activeFilters: PropTypes.object,
  linkAdditionalProps: PropTypes.object,
};

FiltersPanel.defaultProps = {
  activeFilters: {},
  linkAdditionalProps: {},
};

export default FiltersPanel;
