import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import {
  IDEA_INACTIVATE_REASON_TYPES,
  IDEA_STATUSES,
} from '../../../constants/idea';
import { formatDate, getUserFullName } from '../../../utils';
import { ROUTES } from '../../../constants/routes';
import ArchivedIdeaCardDescription from './ArchivedIdeaCardDescription';
import ArchivedIdeaCardMenu from './ArchivedIdeaCardMenu';
import HasAccess from '../../HasAccess';
import { ideaAuthorAccessConditions } from '../../../utils/accessConditions';

import styles from './ArchivedIdeaCard.module.scss';

const ArchivedIdeaCard = ({
  idea,
  onDelete,
  onRestore,
}) => {
  const {
    author,
    title,
    created_at: date,
    archive,
    human_readable_id: ideaHrid,
  } = idea;

  const ideaTitle = useMemo(() => (
    <Link
      to={generatePath(ROUTES.idea, { ideaId: ideaHrid })}
      className={styles.ideaLink}
      data-testid="ideaLink"
    >
      {title}
    </Link>
  ), [
    title,
    ideaHrid,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.subHeader}>
        <h4 className={styles.title}>
          {ideaTitle}
        </h4>
        <ArchivedIdeaCardMenu
          onRestore={onRestore}
          onDelete={onDelete}
          idea={idea}
          className={styles.menu}
          data-testid="archivedIdeaCardMenu"
        />
      </div>
      <ArchivedIdeaCardDescription
        archive={archive}
        className={styles.description}
        data-testid="archivedIdeaCardDescription"
      />
      <HasAccess
        data-testid="ideaAuthorHasAccess"
        conditions={ideaAuthorAccessConditions}
      >
        <div className={styles.footer}>
          <div className={styles.author}>
            {getUserFullName(author)}
          </div>
          <div className={styles.date}>
            {formatDate(date)}
          </div>
        </div>
      </HasAccess>
    </div>
  );
};

ArchivedIdeaCard.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    human_readable_id: PropTypes.string.isRequired,
    author: PropTypes.object.isRequired,
    title: PropTypes.string,
    status: PropTypes.oneOf(Object.values(IDEA_STATUSES)).isRequired,
    likes: PropTypes.arrayOf(PropTypes.number).isRequired,
    total_comments_with_replies: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    archive: PropTypes.shape({
      author: PropTypes.object.isRequired,
      reason: PropTypes.string,
      reason_type: PropTypes.oneOf(Object.values(IDEA_INACTIVATE_REASON_TYPES)),
      created_at: PropTypes.string.isRequired,
    }).isRequired,
    biz_category: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
};

ArchivedIdeaCard.defaultProps = {
  onDelete: null,
  onRestore: null,
};

export default ArchivedIdeaCard;
