import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Modal from '../../Modal';

const DeleteCommentConfirmationModal = ({ abort, success, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      title={formatMessage({ id: 'deleteCommentModal.title' })}
      content={formatMessage({ id: 'deleteCommentModal.description' })}
      onClose={abort}
      onReject={abort}
      onConfirm={success}
      confirmButtonCaption={formatMessage({ id: 'common.delete' })}
      {...props}
    />
  );
};

DeleteCommentConfirmationModal.propTypes = {
  success: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
};

export default DeleteCommentConfirmationModal;
