import React from 'react';

import Comments from '../../Comments';
import { useListener } from '../../../hooks';
import {
  getCommentsListener,
  getCommentsActionCreator,
} from './listeners/getCommentsListener';
import {
  createCommentListener,
  createCommentActionCreator,
} from './listeners/createCommentListener';
import {
  updateCommentListener,
  updateCommentActionCreator,
} from './listeners/updateCommentListener';
import {
  deleteCommentListener,
  deleteCommentActionCreator,
} from './listeners/deleteCommentListener';

const IdeaComments = (props) => {
  const [getComments] = useListener({
    listener: getCommentsListener,
    actionCreator: getCommentsActionCreator,
  });

  const [createComment] = useListener({
    listener: createCommentListener,
    actionCreator: createCommentActionCreator,
  });

  const [updateComment] = useListener({
    listener: updateCommentListener,
    actionCreator: updateCommentActionCreator,
  });

  const [deleteComment] = useListener({
    listener: deleteCommentListener,
    actionCreator: deleteCommentActionCreator,
  });

  return (
    <Comments
      {...props}
      getComments={getComments}
      createComment={createComment}
      updateComment={updateComment}
      deleteComment={deleteComment}
      data-testid="comments"
    />
  );
};

export default IdeaComments;
