import React from 'react';
import { useIntl } from 'react-intl';

import Card, { CARD_TYPES } from '../Card';
import { ROUTES } from '../../../constants/routes';

const AddBizCategories = () => {
  const { formatMessage } = useIntl();

  return (
    <Card
      type={CARD_TYPES.quaternary}
      title={formatMessage({ id: 'topIdeas.addBizCategories.title' })}
      description={formatMessage({ id: 'topIdeas.addBizCategories.description' })}
      LinkButtonProps={{
        caption: formatMessage({ id: 'topIdeas.addBizCategories.link' }),
        link: { pathname: ROUTES.profile },
      }}
    />
  );
};

export default AddBizCategories;
