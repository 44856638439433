import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../Icon';

import styles from './Hint.module.scss';

const Hint = ({
  hint,
  className,
}) => (
  <div className={clsx(styles.hintWrapper, className)}>
    <Icon
      data-testid="hintIcon"
      type={ICON_TYPES.hint}
      size={ICON_SIZE.mxxs}
      color={ICON_COLOR.quinary}
    />
    <div className={styles.hint}>
      {hint}
    </div>
  </div>
);

Hint.propTypes = {
  hint: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Hint.defaultProps = {
  className: '',
};

export default Hint;
