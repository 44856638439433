/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useIntl } from 'react-intl';
import {
  MainMenuButton,
} from '@epam/promo';
import {
  MainMenuCustomElement,
} from '@epam/uui-components';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { userSelector } from '../../../../redux/slices/access';
import LogoutIcon from './LogoutIcon';
import { getUserFullName } from '../../../../utils';
import { ROUTES } from '../../../../constants/routes';
import {
  useListener,
  useModal,
} from '../../../../hooks';
import { logoutListener, logoutActionCreator } from './listeners/logoutListener';
import DropdownMenu from '../../../DropdownMenu';
import { LeavePromptContext } from '../../../LeavePromptProvider';
import LeavePromptConfirmationModal from '../../../modals/LeavePromptConfirmationModal';

import styles from './UserInfo.module.scss';

const UserInfo = () => {
  const currentUser = useSelector(userSelector);
  const { formatMessage } = useIntl();
  const { showModal } = useModal();
  const {
    disableLeavePrompt,
    enableLeavePrompt,
    isLeaveBlocked,
  } = useContext(LeavePromptContext);

  const [logoutUser] = useListener({
    listener: logoutListener,
    actionCreator: logoutActionCreator,
  });

  const renderDropdownTarget = useCallback((props) => {
    const { isOpen } = props;

    return (
      <MainMenuButton
        data-testid="dropdownLinkButton"
        caption={formatMessage(
          { id: 'userInfo.userWelcome' },
          { name: currentUser.first_name },
        )}
        isDropdown
        cx={clsx(styles.mainMenuDropdown, { [styles.mainMenuDropdown__open]: isOpen })}
        captionCX={styles.dropdownButtonCaption}
        {...props}
      />
    );
  }, [formatMessage, currentUser]);

  const runLogoutUser = useCallback(async () => {
    disableLeavePrompt();

    try {
      await logoutUser();
    } finally {
      enableLeavePrompt();
    }
  }, [
    disableLeavePrompt,
    enableLeavePrompt,
    logoutUser,
  ]);

  const handleLogoutUser = useCallback(() => {
    if (isLeaveBlocked) {
      showModal(({
        success,
        abort,
        ...props
      }) => {
        const handleModalConfirm = () => {
          runLogoutUser();
          success();
        };

        return (
          <LeavePromptConfirmationModal
            onConfirm={handleModalConfirm}
            onReject={abort}
            onClose={abort}
            {...props}
          />
        );
      });
    } else {
      runLogoutUser();
    }
  }, [
    runLogoutUser,
    showModal,
    isLeaveBlocked,
  ]);

  const groups = useMemo(() => ([{
    id: 'main',
    items: [{
      id: 'profileSettings',
      link: { pathname: ROUTES.profile },
      label: formatMessage({ id: 'userInfo.menu.profileSettings' }),
    }],
  }, {
    id: 'bottom',
    items: [{
      id: 'logout',
      onClick: handleLogoutUser,
      icon: LogoutIcon,
      label: formatMessage({ id: 'userInfo.menu.logOut' }),
    }],
  }]), [
    formatMessage,
    handleLogoutUser,
  ]);

  return (
    <MainMenuCustomElement data-testid="mainMenuCustomElement">
      <DropdownMenu
        renderDropdownTarget={renderDropdownTarget}
        header={getUserFullName(currentUser)}
        groups={groups}
        bodyClassName={styles.dropdownBody}
      />
    </MainMenuCustomElement>
  );
};

export default UserInfo;
