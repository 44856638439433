import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useGenerateLink } from '../../../hooks';
import TabButton from '../../TabButton';

import styles from './TabsNav.module.scss';

const TABS_SIZE = {
  xl: '60',
};

const TabsNav = ({
  items,
  onItemChange,
  activeTabId,
  className,
  size,
  urlParamName,
  linkAdditionalProps,
  isStretch,
}) => {
  const { generateLink } = useGenerateLink();

  return (
    <ul
      className={clsx(
        styles.tabsNav,
        className,
        {
          [styles.tabsNav__stretch]: isStretch,
        },
      )}
    >
      {
        items.map(({
          id,
          caption,
          icon,
          iconPosition,
        }) => (
          <li
            key={id}
            className={styles.item}
          >
            <TabButton
              caption={caption}
              onClick={() => onItemChange?.(id)}
              isLinkActive={id === activeTabId}
              size={size}
              cx={clsx(styles.itemButton, {
                [styles.itemButton__withIcon]: icon,
              })}
              captionCX={styles.itemButtonCaption}
              icon={icon}
              iconPosition={iconPosition}
              {...(urlParamName
                ? {
                  link: generateLink({
                    [urlParamName]: id,
                    ...linkAdditionalProps,
                  }),
                }
                : {}
              )}
            />
          </li>
        ))
      }
    </ul>
  );
};

TabsNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    caption: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    iconPosition: PropTypes.oneOf(['left', 'right']),
  })).isRequired,
  onItemChange: PropTypes.func,
  activeTabId: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  urlParamName: PropTypes.string,
  linkAdditionalProps: PropTypes.object,
  itemClassName: PropTypes.string,
  tabButtonClassName: PropTypes.string,
  captionClassName: PropTypes.string,
  isStretch: PropTypes.bool,
};

TabsNav.defaultProps = {
  className: '',
  size: TABS_SIZE.xl,
  urlParamName: null,
  linkAdditionalProps: {},
  onItemChange: null,
  itemClassName: null,
  tabButtonClassName: null,
  captionClassName: null,
  isStretch: false,
};

export default TabsNav;
