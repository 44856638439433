import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { businessObjectivesApi } from '../../../redux/api/slices/businessObjectivesApi';

export const updateBizCategoryListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      bizCategoryId,
      data,
    },
  } = action;

  const { dispatch, pause } = listenerApi;

  const updateBizCategory = dispatch(
    businessObjectivesApi.endpoints.updateBusinessObjective.initiate({
      id: bizCategoryId,
      data,
    }),
  );

  try {
    await pause(updateBizCategory.unwrap());

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    updateBizCategory.unsubscribe();
  }
};

export const updateBusinessObjectiveActionCreator = createAction(
  ACTION_TYPES.bizCategory.updateBizCategory,
);
