import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { formatDate, getUserFullName } from '../../../../utils';
import {
  IDEA_INACTIVATE_REASON_TYPES,
  INACTIVATE_REASON_TYPE_TRANSLATION_KEY,
} from '../../../../constants/idea';
import { userIdSelector } from '../../../../redux/slices/access';

import styles from './ArchivedIdeaCardDescription.module.scss';

const ArchivedIdeaCardDescription = ({
  archive,
  className,
}) => {
  const {
    author,
    reason,
    reason_type: reasonType,
    created_at: createdAt,
  } = archive;
  const { formatMessage } = useIntl();

  const userId = useSelector(userIdSelector);

  const resultReason = useMemo(() => (
    reasonType === IDEA_INACTIVATE_REASON_TYPES.OTHER
      ? reason
      : formatMessage({ id: INACTIVATE_REASON_TYPE_TRANSLATION_KEY[reasonType] })
  ), [formatMessage, reason, reasonType]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.author}>
        {formatMessage({ id: 'ideas.archivedIdeaCard.archivedIdeaCardDescription.archivedBy' }, {
          author: getUserFullName(author),
          isCurrentUser: userId === author.id,
        })}
      </div>
      <div className={styles.reason}>
        {resultReason}
      </div>
      <div className={styles.date}>{formatDate(createdAt)}</div>
    </div>
  );
};

ArchivedIdeaCardDescription.propTypes = {
  archive: PropTypes.shape({
    author: PropTypes.object.isRequired,
    reason: PropTypes.string,
    reason_type: PropTypes.oneOf(Object.values(IDEA_INACTIVATE_REASON_TYPES)),
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

ArchivedIdeaCardDescription.defaultProps = {
  className: '',
};

export default ArchivedIdeaCardDescription;
