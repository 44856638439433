import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { ROUTES } from '../../../../constants/routes';
import UpdateIcon from './UpdateIcon';
import TrashIcon from './TrashIcon';
import Menu from '../../../Menu';
import { useIsAbleToDeleteIdea } from '../../../../hooks';
import { IDEA_STATUSES } from '../../../../constants/idea';

import styles from './DraftIdeaCardMenu.module.scss';

const DraftIdeaCardMenu = ({
  idea,
  onDelete,
}) => {
  const { formatMessage } = useIntl();
  const {
    human_readable_id: ideaHrid,
  } = idea;
  const isAbleToDeleteIdea = useIsAbleToDeleteIdea(idea);

  const groups = useMemo(() => ([{
    id: 'actions',
    items: [{
      id: 'update',
      label: formatMessage({ id: 'ideas.draftIdeaCard.draftIdeaCardMenu.updateButtonLabel' }),
      link: {
        pathname: generatePath(ROUTES.updateIdea, { ideaId: ideaHrid }),
      },
      icon: UpdateIcon,
    },
    ...(isAbleToDeleteIdea
      ? [{
        id: 'delete',
        label: formatMessage({ id: 'ideas.draftIdeaCard.draftIdeaCardMenu.deleteButtonLabel' }),
        onClick: onDelete,
        icon: TrashIcon,
      }]
      : [])],
  }]), [
    formatMessage,
    ideaHrid,
    onDelete,
    isAbleToDeleteIdea,
  ]);

  return (
    <Menu
      data-testid="menu"
      dropdownMenuProps={{
        groups,
        bodyClassName: styles.dropdownBody,
      }}
    />
  );
};

DraftIdeaCardMenu.propTypes = {
  idea: PropTypes.shape({
    human_readable_id: PropTypes.string.isRequired,
    archive: PropTypes.object,
    status: PropTypes.oneOf(Object.values(IDEA_STATUSES)).isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
};

DraftIdeaCardMenu.defaultProps = {
  onDelete: null,
};

export default DraftIdeaCardMenu;
