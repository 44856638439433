import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { userApi } from '../../../../redux/api/slices/userApi';
import {
  MATOMO_EVENT_ACTIONS,
  MATOMO_EVENT_CATEGORIES,
} from '../../../../constants/matomo';
import { trackMatomoEvent } from '../../../../utils';

export const contactUsListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      data,
      showToast,
      formatMessage,
    },
  } = action;
  const { dispatch, pause } = listenerApi;
  const contactUs = dispatch(userApi.endpoints.contactUs.initiate({ data }));

  try {
    await pause(contactUs.unwrap());

    showToast({
      message: formatMessage({ id: 'contactUsModal.success.message' }),
    });

    trackMatomoEvent(
      MATOMO_EVENT_CATEGORIES.app,
      MATOMO_EVENT_ACTIONS.contactUs,
    );

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    contactUs.unsubscribe();
  }
};

export const contactUsListenerCreator = createAction(ACTION_TYPES.user.contactUs);
