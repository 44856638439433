import React from 'react';

import Icon, {
  ICON_TYPES,
  ICON_SIZE,
  ICON_COLOR,
} from '../../../../Icon';

const LinkIcon = () => (
  <Icon
    type={ICON_TYPES.link}
    size={ICON_SIZE.smd}
    color={ICON_COLOR.quaternary}
  />
);

export default LinkIcon;
