import React from 'react';
import PropTypes from 'prop-types';
import { Tag as TagBase } from '@epam/promo';
import clsx from 'clsx';

import styles from './Tag.module.scss';

const TAG_TYPES = {
  primary: 'primary',
};

const TAG_SIZE = {
  xxl: '42',
};

const Tag = ({
  type,
  caption,
  size,
  className,
  ...props
}) => (
  <TagBase
    cx={clsx(styles[type], className)}
    caption={caption}
    size={size}
    {...props}
  />
);

Tag.propTypes = {
  type: PropTypes.oneOf(Object.values(TAG_TYPES)),
  caption: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(TAG_SIZE)),
  className: PropTypes.string,
};

Tag.defaultProps = {
  type: TAG_TYPES.primary,
  size: TAG_SIZE.xxl,
  className: '',
};

export default Tag;
