import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  ICON_TYPES,
} from '../../../Icon';
import CreateNewIdeaButton from '../../../CreateNewIdeaButton';
import EmptyContent from '../../../EmptyContent';
import { activeEventActiveStageTypeSelector } from '../../../../redux/slices/events';
import {
  NO_IDEAS_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE,
  NO_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE,
} from './constants';

const NoIdeas = () => {
  const { formatMessage } = useIntl();
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.editorList,
      }}
      title={formatMessage(
        { id: NO_IDEAS_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE[activeStageType] },
      )}
      description={formatMessage(
        { id: NO_IDEAS_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE_TYPE[activeStageType] },
      )}
      customButton={<CreateNewIdeaButton />}
    />
  );
};

export default NoIdeas;
