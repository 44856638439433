export const LEGEND_POSITIONS = {
  top: 'top',
  right: 'right',
};

export const CHART_TYPES = {
  pie: 'pie',
  doughnut: 'doughnut',
  line: 'line',
  bar: 'bar',
};

export const HTML_LEGEND_PLUGIN_ID = 'htmlLegend';
