import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, {
  FORM_PROPS,
} from '../../Form';
import TextField from '../../Form/connected/TextField';
import PickerField from '../../Form/connected/PickerField';
import TextAreaField from '../../Form/connected/TextAreaField';
import NumericField from '../../Form/connected/NumericField';
import SimilarProductField from '../../Form/connected/SimilarProductField';
import LinkField from '../../Form/connected/LinkField';
import { FORM_FIELD_NAMES } from './constants';
import { useValidationSchema } from './hooks/useValidationSchema';

const ProposeIdeaSolutionForm = ({
  defaultValues,
  technicalCategories,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const resultDefaultValues = useMemo(() => (defaultValues || {
    [FORM_FIELD_NAMES.TITLE]: '',
    [FORM_FIELD_NAMES.TECH_CATEGORIES]: [],
    [FORM_FIELD_NAMES.TECH_REQUIREMENTS]: '',
    [FORM_FIELD_NAMES.RISKS]: '',
    [FORM_FIELD_NAMES.ASSUMPTIONS]: '',
    [FORM_FIELD_NAMES.DEPENDENCIES]: '',
    [FORM_FIELD_NAMES.DURATION]: null,
    [FORM_FIELD_NAMES.SIMILAR_PRODUCTS]: [],
    [FORM_FIELD_NAMES.LINKS]: [],
  }), [defaultValues]);
  const validationSchema = useValidationSchema();

  return (
    <Form
      defaultValues={resultDefaultValues}
      validationSchema={validationSchema}
      hideButtons
      {...props}
    >
      <TextField
        name={FORM_FIELD_NAMES.TITLE}
        label={formatMessage({ id: 'proposeIdeaSolutionForm.titleField.label' })}
        placeholder={formatMessage({ id: 'proposeIdeaSolutionForm.titleField.placeholder' })}
        isRequired
      />
      <PickerField
        name={FORM_FIELD_NAMES.TECH_CATEGORIES}
        label={formatMessage({ id: 'proposeIdeaSolutionForm.techCategoriesField.label' })}
        placeholder={formatMessage({ id: 'proposeIdeaSolutionForm.techCategoriesField.placeholder' })}
        items={technicalCategories}
        isRequired
      />
      <TextAreaField
        name={FORM_FIELD_NAMES.TECH_REQUIREMENTS}
        label={formatMessage({ id: 'proposeIdeaSolutionForm.techRequirementsField.label' })}
        placeholder={formatMessage({ id: 'proposeIdeaSolutionForm.techRequirementsField.placeholder' })}
        tooltip={formatMessage({ id: 'proposeIdeaSolutionForm.techRequirementsField.tooltip' })}
        isRequired
      />
      <TextAreaField
        name={FORM_FIELD_NAMES.RISKS}
        label={formatMessage({ id: 'proposeIdeaSolutionForm.risksField.label' })}
        placeholder={formatMessage({ id: 'proposeIdeaSolutionForm.risksField.placeholder' })}
        tooltip={formatMessage({ id: 'proposeIdeaSolutionForm.risksField.tooltip' })}
        isRequired
      />
      <TextAreaField
        name={FORM_FIELD_NAMES.ASSUMPTIONS}
        label={formatMessage({ id: 'proposeIdeaSolutionForm.assumptionsField.label' })}
        placeholder={formatMessage({ id: 'proposeIdeaSolutionForm.assumptionsField.placeholder' })}
        tooltip={formatMessage({ id: 'proposeIdeaSolutionForm.assumptionsField.tooltip' })}
        isRequired
      />
      <TextAreaField
        name={FORM_FIELD_NAMES.DEPENDENCIES}
        label={formatMessage({ id: 'proposeIdeaSolutionForm.dependenciesField.label' })}
        placeholder={formatMessage({ id: 'proposeIdeaSolutionForm.dependenciesField.placeholder' })}
        tooltip={formatMessage({ id: 'proposeIdeaSolutionForm.dependenciesField.tooltip' })}
        isRequired
      />
      <NumericField
        name={FORM_FIELD_NAMES.DURATION}
        label={formatMessage({ id: 'proposeIdeaSolutionForm.durationField.label' })}
        placeholder={formatMessage({ id: 'proposeIdeaSolutionForm.durationField.placeholder' })}
        tooltip={formatMessage({ id: 'proposeIdeaSolutionForm.durationField.tooltip' })}
        formatOptions={{
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }}
        isRequired
      />
      <SimilarProductField
        name={FORM_FIELD_NAMES.SIMILAR_PRODUCTS}
        maxItemsCount={10}
      />
      <LinkField
        name={FORM_FIELD_NAMES.LINKS}
        maxItemsCount={10}
      />
    </Form>
  );
};

ProposeIdeaSolutionForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
  defaultValues: PropTypes.object,
  technicalCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ),
};

ProposeIdeaSolutionForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  getFormApi: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  values: null,
  defaultValues: null,
  technicalCategories: [],
};

export default ProposeIdeaSolutionForm;
