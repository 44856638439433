import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import PickerFieldBase from '../../PickerField';

const PickerField = ({
  name,
  ...props
}) => {
  const {
    field: {
      onChange,
      onBlur,
      value,
    },
    fieldState: { error },
  } = useController({ name });

  return (
    <PickerFieldBase
      data-testid="pickerFieldBase"
      name={name}
      value={value}
      errorMessage={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  );
};

PickerField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

PickerField.defaultProps = {
  placeholder: '',
  items: [],
};

export default PickerField;
