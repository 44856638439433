import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import CreateNewIdeaButton from '../../CreateNewIdeaButton';
import PageInfo from '../../PageInfo';
import PageHeader from '../../PageHeader';
import Ideas from '../../Ideas';
import StageTimer from '../../StageTimer';
import {
  activeEventActiveStageTypeSelector,
  activeEventStatusSelector,
} from '../../../redux/slices/events';
import {
  PAGE_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE,
  PAGE_INFO_TRANSLATION_KEY_BY_ACTIVE_STAGE,
  PAGE_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE,
} from './constants';
import { EVENT_STATUSES } from '../../../constants/event';

const IdeasPage = () => {
  const activeStageType = useSelector(activeEventActiveStageTypeSelector);
  const activeEventStatus = useSelector(activeEventStatusSelector);
  const { formatMessage } = useIntl();
  const isEventFinished = activeEventStatus === EVENT_STATUSES.FINISHED;

  return (
    <>
      <PageInfo
        data-testid="pageInfo"
        title={formatMessage(
          {
            id: isEventFinished
              ? 'ideasPage.winners.pageTitle'
              : PAGE_INFO_TRANSLATION_KEY_BY_ACTIVE_STAGE[activeStageType],
          },
        )}
      />
      <PageHeader
        data-testid="pageHeader"
        title={formatMessage(
          {
            id: isEventFinished
              ? 'ideasPage.winners.title'
              : PAGE_TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE[activeStageType],
          },
        )}
        description={formatMessage(
          {
            id: isEventFinished
              ? 'ideasPage.winners.description'
              : PAGE_DESCRIPTION_TRANSLATION_KEY_BY_ACTIVE_STAGE[activeStageType],
          },
        )}
        subContent={(
          <StageTimer
            data-testid="stageTimer"
            action={(
              <CreateNewIdeaButton
                data-testid="createNewIdeaButton"
                fill="white"
              />
            )}
          />
        )}
        showBreadcrumbs={false}
      />
      <Ideas data-testid="ideas" />
    </>
  );
};

export default IdeasPage;
