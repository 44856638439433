import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Timer from '../Timer';
import Spinner from '../Spinner';
import {
  ANIMATION_TYPES,
  TIMER_TYPES,
} from '../Timer/constants';
import {
  ACTIVE_EVENT_HRID,
  EVENT_STAGES,
  EVENT_STATUSES,
} from '../../constants/event';
import {
  activeEventActiveStageSelector,
  activeEventStatusSelector,
} from '../../redux/slices/events';
import { useGetUserVotesMetaQuery } from '../../redux/api/slices/userApi';
import { isAxiosError } from '../../redux/api/fetchClient';
import { TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE } from './constants';

const StageTimer = ({
  action,
}) => {
  const { formatMessage } = useIntl();
  const {
    type: stageType,
    end_at: stageEndDate,
  } = useSelector(activeEventActiveStageSelector) || {};
  const activeEventStatus = useSelector(activeEventStatusSelector);
  const isEventFinished = activeEventStatus === EVENT_STATUSES.FINISHED;
  const isVoteStage = stageType === EVENT_STAGES.VOTING;
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetUserVotesMetaQuery(
    { eventHrid: ACTIVE_EVENT_HRID },
    {
      refetchOnMountOrArgChange: true,
      skip: !isVoteStage,
    },
  );

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return null;
  }

  return (
    <Timer
      data-testid="stageTimer"
      title={formatMessage(
        {
          id: isEventFinished
            ? 'stageTimer.winners.title'
            : TITLE_TRANSLATION_KEY_BY_ACTIVE_STAGE[stageType],
        },
        isVoteStage
          ? {
            amount: data.voting.max_number_of_votes - data.voting.used_number_of_votes,
          }
          : {},
      )}
      action={action}
      date={stageEndDate}
      type={TIMER_TYPES.secondary}
      animationType={
        isEventFinished
          ? ANIMATION_TYPES.secondary
          : ANIMATION_TYPES.primary
      }
    />
  );
};

StageTimer.propTypes = {
  action: PropTypes.node,
};

StageTimer.defaultProps = {
  action: null,
};

export default StageTimer;
