import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { SORTING_TYPES } from '../constants';

export const useGetSorting = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => ([
    {
      id: SORTING_TYPES.likesAndComments,
      label: formatMessage({ id: 'adminPanel.ideasBoard.sorting.likesAndComments.label' }),
    },
    {
      id: SORTING_TYPES.newestIdeas,
      label: formatMessage({ id: 'adminPanel.ideasBoard.sorting.newestIdeas.label' }),
    },
    {
      id: SORTING_TYPES.oldestIdeas,
      label: formatMessage({ id: 'adminPanel.ideasBoard.sorting.oldestIdeas.label' }),
    },
  ]), [formatMessage]);
};
