import yup from '../../../yup';
import { STEPS_IDS } from './constants';

export const convertBusinessObjectives = ({ name, id }) => ({
  name,
  id,
});

export const convertTechnicalCategories = ({ name, id }) => ({
  label: name,
  id,
});

export const convertStepperValuesToIdea = (formValues) => (
  Object.values(STEPS_IDS).reduce((value, id) => ({
    ...value,
    ...(formValues[id] || {}),
  }), {})
);

const urlSchema = yup.string().trim().url();

export const replaceLinkInvalidUrl = ({ title, link }) => {
  let resultLink = '';

  try {
    resultLink = urlSchema.validateSync(link);
    // eslint-disable-next-line no-empty
  } catch (e) { }

  return {
    title,
    link: resultLink,
  };
};
