import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedNumber } from 'react-intl';
import { isEmpty, isFunction } from 'lodash';

import Paginator from '../Paginator';
import Spinner from '../Spinner';
import Blocker from '../Blocker';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import {
  EXPAND_CELL_ACCESSOR,
  COLUMN_DEFAULT_WIDTH,
} from './constants';
import {
  convertToTablePage,
  convertToUrlPage,
} from './utils';
import { isAxiosError } from '../../redux/api/fetchClient';
import {
  ExpanderCell,
} from './cellsRenders';
import {
  useGenerateLink,
  useQueryParams,
} from '../../hooks';
import { scrollToWithAppHeaderOffset } from '../../utils';
import SearchPanel from '../SearchPanel';
import { useGetSearchValueFromUrl } from '../Search';
import {
  useGetFiltersClearHandler,
  useGetFilterValuesFromUrl,
} from '../Filters';
import Select, { SELECT_TYPE } from '../Select';

import styles from './Table.module.scss';

const Table = ({
  columns,
  useGetData,
  emptyState,
  getRowId,
  renderDetailPanel,
  pageUrlParamName,
  tableId,
  pageSize,
  totalResultsLabelId,
  actions,
  isInProgress,
  searchProps,
  sortingProps: {
    urlParamName: sortingUrlParamName,
    items: sortingOptions,
  },
  description,
  title,
  filtersProps: {
    reportLabelId,
    ...filtersProps
  },
  getDataAdditionalParams,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { generateLink } = useGenerateLink();
  const {
    [sortingUrlParamName]: urlActiveSorting,
    [pageUrlParamName]: urlActivePage,
  } = useQueryParams();
  const [expanded, setExpanded] = useState({});
  const {
    filters,
    urlParamName: filtersUrlParamName,
  } = filtersProps;
  const { clearFilters } = useGetFiltersClearHandler({ urlParamName: filtersUrlParamName });
  const { urlParamName: searchUrlParamName } = searchProps;
  const resultActiveSearch = useGetSearchValueFromUrl(searchUrlParamName);
  const resultActiveFilters = useGetFilterValuesFromUrl(filters, filtersUrlParamName);
  const hasActiveFilters = !isEmpty(resultActiveFilters);
  const resetPagination = useMemo(
    () => ({ [pageUrlParamName]: 1 }),
    [pageUrlParamName],
  );

  const defaultColumn = useMemo(() => ({
    minSize: COLUMN_DEFAULT_WIDTH,
    size: COLUMN_DEFAULT_WIDTH,
    enableSorting: false,
  }), []);

  const pagination = useMemo(
    () => ({
      pageIndex: urlActivePage ? convertToTablePage(Number(urlActivePage)) : 0,
      pageSize,
    }),
    [urlActivePage, pageSize],
  );

  const resultActiveSorting = useMemo(() => (
    sortingUrlParamName && sortingOptions
      ? sortingOptions.find(({ id }) => id === urlActiveSorting)?.id || sortingOptions[0].id
      : undefined
  ), [
    sortingOptions,
    sortingUrlParamName,
    urlActiveSorting,
  ]);

  const {
    data,
    isLoading,
    isFetching,
    error,
  } = useGetData({
    page: convertToUrlPage(pagination.pageIndex),
    pageSize,
    sort: resultActiveSorting,
    filters: resultActiveFilters,
    search: resultActiveSearch.trim(),
    ...getDataAdditionalParams,
  });

  const totalItemsCount = data?.total_items || 0;
  const totalFilteredItemsCount = data?.total || 0;
  const tableData = data?.items || [];
  const numberOfPages = data?.pages || 0;

  const renderExpanderCell = useCallback(({ row }) => (
    <ExpanderCell
      isExpanded={row.getIsExpanded()}
      onClick={row.getToggleExpandedHandler()}
    />
  ), []);

  const handlePageChange = useCallback((getNewPaginationState) => {
    const newPaginationState = getNewPaginationState(pagination);

    history.push(
      generateLink({
        [pageUrlParamName]: convertToUrlPage(newPaginationState.pageIndex),
      }),
    );

    scrollToWithAppHeaderOffset(tableId);
  }, [
    generateLink,
    history,
    tableId,
    pageUrlParamName,
    pagination,
  ]);

  const handleSortingChange = useCallback((sortingId) => {
    history.push(
      generateLink({
        [sortingUrlParamName]: sortingId,
        ...resetPagination,
      }),
    );
  }, [
    generateLink,
    history,
    sortingUrlParamName,
    resetPagination,
  ]);

  const resultColumns = useMemo(() => ([
    ...(renderDetailPanel
      ? [{
        accessorKey: EXPAND_CELL_ACCESSOR,
        header: '',
        cell: renderExpanderCell,
      }]
      : []),
    ...columns,
  ]), [
    renderDetailPanel,
    columns,
    renderExpanderCell,
  ]);

  const table = useReactTable({
    data: tableData,
    columns: resultColumns,
    defaultColumn,
    state: {
      pagination,
      expanded,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: numberOfPages,
    getRowId,
    getRowCanExpand: () => !!renderDetailPanel,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: handlePageChange,
  });

  const content = (() => {
    if (isLoading) {
      return <Spinner cx={styles.spinner} />;
    }

    if (!totalFilteredItemsCount && !numberOfPages) {
      return isFunction(emptyState)
        ? emptyState({
          hasActiveFilters,
          search: resultActiveSearch,
          clearFilters,
        })
        : emptyState;
    }

    return (
      <table className={styles.table}>
        <TableHeader table={table} />
        <TableBody
          table={table}
          renderDetailPanel={renderDetailPanel}
        />
      </table>
    );
  })();

  const resultSearchProps = searchUrlParamName
    ? {
      ...searchProps,
      defaultValue: resultActiveSearch,
      linkAdditionalProps: resetPagination,
    }
    : null;
  const resultFiltersProps = filters
    ? {
      ...filtersProps,
      reportLabel: formatMessage(
        { id: reportLabelId },
        { amount: totalFilteredItemsCount },
      ),
      hasActiveFilters,
      defaultValues: resultActiveFilters,
      onFiltersClear: clearFilters,
      linkAdditionalProps: resetPagination,
    }
    : null;

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  return (
    <div
      className={styles.wrapper}
      id={tableId}
    >
      {(totalResultsLabelId || title || actions || sortingOptions) && (
        <header className={styles.header}>
          {(totalResultsLabelId || title) && (
            <div>
              <h3 className={styles.totalResults}>
                {title || formatMessage(
                  { id: totalResultsLabelId },
                  { amount: <FormattedNumber value={totalItemsCount} /> },
                )}
              </h3>
              {description && (
                <p className={styles.description}>
                  {description}
                </p>
              )}
            </div>
          )}
          {(sortingOptions || actions) && (
            <div className={styles.actions}>
              {
                sortingOptions && (
                  <Select
                    items={sortingOptions}
                    type={SELECT_TYPE.secondary}
                    onChange={handleSortingChange}
                    selectedValueId={resultActiveSorting}
                  />
                )
              }
              {actions}
            </div>
          )}
        </header>
      )}
      <SearchPanel
        searchProps={resultSearchProps}
        filtersProps={resultFiltersProps}
      >
        <div className={styles.tableWrapper}>
          {(isInProgress || (!isLoading && isFetching)) && <Blocker />}
          {content}
          {
            (numberOfPages > 1) && (
              <div className={styles.paginator}>
                <Paginator
                  value={convertToUrlPage(pagination.pageIndex)}
                  onValueChange={(newPage) => table.setPageIndex(convertToTablePage(newPage))}
                  totalPages={numberOfPages}
                />
              </div>
            )
          }
        </div>
      </SearchPanel>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    // if you want to sort data by this field, your accessorKey should match with sort_by BE key
    accessorKey: PropTypes.string, // helps to get data by key from item
    accessorFn: PropTypes.func, // you can pass func instead of accessorKey
    header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func, // you can do the custom render of header with this func
    ]).isRequired,
    cell: PropTypes.func, // you can pass custom render function here
    size: PropTypes.number, /* cell size in percents
    if you want to use expander, one of your columns should have no size */
  })).isRequired,
  useGetData: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  emptyState: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  getRowId: PropTypes.func,
  renderDetailPanel: PropTypes.func, // accepts row object as prop
  pageUrlParamName: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
  searchProps: PropTypes.object,
  sortingProps: PropTypes.object,
  filtersProps: PropTypes.object,
  totalResultsLabelId: PropTypes.string,
  actions: PropTypes.node,
  getDataAdditionalParams: PropTypes.object,
  isInProgress: PropTypes.bool,
  description: PropTypes.node,
  title: PropTypes.node,
};

Table.defaultProps = {
  getRowId: (row) => row.id,
  pageSize: 20,
  renderDetailPanel: null,
  searchProps: {},
  sortingProps: {},
  filtersProps: {},
  totalResultsLabelId: null,
  actions: null,
  getDataAdditionalParams: {},
  isInProgress: false,
  description: null,
  title: null,
};

export default Table;
