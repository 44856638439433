import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form from '../../Form';
import TextField from '../../Form/connected/TextField';
import { useValidationSchema } from './hooks/useValidationSchema';
import { FORM_FIELD_NAMES } from './constants';
import { FORM_PROPS } from '../../Form/Form';
// import TextLink from '../../TextLink';
// import { ROUTES } from '../../../constants/routes';

import styles from './LoginForm.module.scss';

const LoginForm = (props) => {
  const { formatMessage } = useIntl();
  const defaultValues = useMemo(() => ({
    [FORM_FIELD_NAMES.EMAIL]: '',
    [FORM_FIELD_NAMES.PASSWORD]: '',
  }), []);
  const validationSchema = useValidationSchema();

  return (
    <Form
      data-testid="form"
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      blockPageOnSubmit
      title={formatMessage({ id: 'loginForm.heading' })}
      submitButtonCaption={formatMessage({ id: 'common.login.label' })}
      className={styles.form}
      {...props}
    >
      <TextField
        data-testid={`${FORM_FIELD_NAMES.EMAIL}Field`}
        name={FORM_FIELD_NAMES.EMAIL}
        label={formatMessage({ id: 'loginForm.emailField.label' })}
        placeholder={formatMessage({ id: 'loginForm.emailField.placeholder' })}
        isRequired
      />
      <TextField
        data-testid={`${FORM_FIELD_NAMES.PASSWORD}Field`}
        name={FORM_FIELD_NAMES.PASSWORD}
        label={formatMessage({ id: 'loginForm.passwordField.label' })}
        placeholder={formatMessage({ id: 'loginForm.passwordField.placeholder' })}
        type="password"
        isRequired
        // footer={(
        //   <TextLink
        //     data-testid="forgotYourPasswordLink"
        //     path={ROUTES.forgotPassword}
        //     label={formatMessage({ id: 'common.forgotYourPasswordLink.label' })}
        //   />
        // )}
      />
    </Form>
  );
};

LoginForm.propTypes = pick(FORM_PROPS, [
  'onSubmit',
  'onSubmitFail',
  'onSubmitSuccess',
  'getFormApi',
]);

LoginForm.defaultProps = {
  onSubmitFail: null,
  onSubmitSuccess: null,
  getFormApi: null,
};

export default LoginForm;
