import React from 'react';
import { useIntl } from 'react-intl';

import eventNotStartedImgSrc from '../../../../assets/images/event-not-started-dashboard.svg';

import styles from './EventNotStarted.module.scss';

const EventNotStarted = () => {
  const { formatMessage } = useIntl();

  const title = formatMessage({ id: 'adminPanel.dashboard.eventNotStarted.title' });

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        {title}
      </h2>
      <p className={styles.description}>
        {formatMessage({ id: 'adminPanel.dashboard.eventNotStarted.description' })}
      </p>
      <img
        className={styles.image}
        src={eventNotStartedImgSrc}
        alt={title}
      />
    </div>
  );
};

export default EventNotStarted;
