import { baseApi } from '../baseApi';
import { API_TAG_TYPES } from './constants';

export const businessObjectivesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllBusinessObjectives: build.query({
      query: () => ({
        url: '/biz-categories',
      }),
      providesTags: (result, error) => (error ? [] : result.map(
        ({ id }) => ({ type: API_TAG_TYPES.bizObjective, id }),
      )),
    }),
    getActiveBusinessObjectives: build.query({
      query: () => ({
        url: '/biz-categories',
        params: {
          is_active: true,
        },
      }),
      providesTags: (result, error) => (error ? [] : result.map(
        ({ id }) => ({ type: API_TAG_TYPES.bizObjective, id }),
      )),
    }),
    getBusinessObjectiveById: build.query({
      query: (id) => ({
        url: `/biz-categories/${id}`,
      }),
      providesTags: (result, error, id) => (
        error ? [] : [{ type: API_TAG_TYPES.bizObjective, id }]
      ),
    }),
    createBusinessObjective: build.mutation({
      query: (data) => ({
        url: '/biz-categories',
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error) => (
        error ? [] : [API_TAG_TYPES.bizObjective]
      ),
    }),
    updateBusinessObjective: build.mutation({
      query: ({ id, data }) => ({
        url: `/biz-categories/${id}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, { id }) => (
        error ? [] : [{ type: API_TAG_TYPES.bizObjective, id }]
      ),
    }),
  }),
});

export const {
  useGetAllBusinessObjectivesQuery,
  useGetActiveBusinessObjectivesQuery,
  useGetBusinessObjectiveByIdQuery,
} = businessObjectivesApi;
