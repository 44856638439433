import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import yup from '../../../../yup';
import { FORM_FIELD_NAMES } from '../constants';

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  return useMemo(() => (yup.object().shape({
    [FORM_FIELD_NAMES.FIRST_NAME]: yup.string()
      .trim()
      .required(formatMessage({ id: 'userGeneralInformationForm.error.firstNameRequired' }))
      .max(30, formatMessage({ id: 'userGeneralInformationForm.error.firstNameMax' })),
    [FORM_FIELD_NAMES.LAST_NAME]: yup.string()
      .trim()
      .required(formatMessage({ id: 'userGeneralInformationForm.error.lastNameRequired' }))
      .max(30, formatMessage({ id: 'userGeneralInformationForm.error.lastNameMax' })),
  })), [formatMessage]);
};
