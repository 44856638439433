import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Badge as BadgeBase } from '@epam/promo';
import clsx from 'clsx';

import styles from './Badge.module.scss';

export const BADGE_COLORS = {
  BLUE: 'blue',
  GREEN: 'green',
  RED: 'red',
  AMBER: 'amber',
  GRAY_LIGHT: 'gray30',
  GRAY_DARK: 'gray80',
  CYAN: 'cyan',
  ORANGE: 'orange',
  PURPLE: 'purple',
  VIOLETE: 'violet',
};

export const BADGE_FILL = {
  SOLID: 'solid',
  TRANSPARENT: 'transparent',
  SEMITRANSPARENT: 'semitransparent',
};

const Badge = forwardRef(({
  className,
  color,
  fill,
  ellipsis,
  ...props
}, ref) => (
  <BadgeBase
    ref={ref}
    data-testid="badge"
    {...(color === BADGE_COLORS.GRAY_DARK
      ? {
        cx: clsx(styles.badge, className, {
          [styles.badge__grayDark]: color === BADGE_COLORS.GRAY_DARK,
          [styles.badge__solid]: fill === BADGE_FILL.SOLID,
          [styles.badge__ellipsis]: ellipsis,
        }),
      }
      : {
        color,
        fill,
        cx: clsx(styles.badge, className, {
          [styles.badge__ellipsis]: ellipsis,
        }),
      })
  }
    {...props}
  />
));

Badge.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(BADGE_COLORS)),
  fill: PropTypes.oneOf(Object.values(BADGE_FILL)),
  size: PropTypes.oneOf([18, 24, 30, 36, 42, 48]),
  ellipsis: PropTypes.bool,
};

Badge.defaultProps = {
  className: '',
  color: BADGE_COLORS.BLUE,
  fill: 'solid',
  size: 36,
  ellipsis: false,
};

export default Badge;
