import { baseApi } from '../baseApi';
import { createAuthorizationHeader } from '../../../utils';

export const eventsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEvent: build.query({
      query: ({ eventHrid, token }) => ({
        url: `/events/${eventHrid}`,
        headers: token ? { authorization: createAuthorizationHeader(token) } : {},
      }),
    }),
  }),
});
