import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useListener, useQueryParams } from '../../../hooks';
import {
  ssoAuthListener,
  ssoAuthActionCreator,
} from './listeners/ssoAuthListener';
import { ROUTES } from '../../../constants/routes';
import PageBlocker from '../../PageBlockerProvider/PageBlocker';

const SsoAuthPage = () => {
  const history = useHistory();
  const [ssoAuth] = useListener({
    listener: ssoAuthListener,
    actionCreator: ssoAuthActionCreator,
  });
  const {
    session_state: sessionState,
    code,
  } = useQueryParams();

  useEffect(() => {
    if (!sessionState || !code) {
      history.push(ROUTES.base);

      return;
    }

    ssoAuth({
      data: {
        sessionState,
        code,
      },
    });
  }, [
    ssoAuth,
    sessionState,
    code,
    history,
  ]);

  return (
    <PageBlocker />
  );
};

export default SsoAuthPage;
