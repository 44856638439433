import {
  CHART_TYPES,
  HTML_LEGEND_PLUGIN_ID,
} from '../constants';

import styles from './htmlLegend.module.scss';

const getOrCreateLegendList = (legendContent) => {
  let listContainer = legendContent.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.classList.add(styles.list);
    legendContent.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: HTML_LEGEND_PLUGIN_ID,
  afterUpdate(chart, args, options) {
    const { type } = chart.config;

    const rootContainer = document.getElementById(options.rootContainerId);
    rootContainer.classList.add(styles.root);
    rootContainer.classList.add(styles[`root_legend__${options.position}`]);

    const legendContainer = document.getElementById(options.legendContainerId);
    legendContainer.classList.add(styles.legend);

    let legendContent = legendContainer.querySelector(`.${styles.legendContent}`);

    if (!legendContent) {
      legendContent = document.createElement('div');
      legendContent.classList.add(styles.legendContent);
      legendContainer.appendChild(legendContent);
    }

    const chartContainer = document.getElementById(options.chartContainerId);
    chartContainer.classList.add(styles.chart);

    const ul = getOrCreateLegendList(legendContent);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, itemIndex) => {
      const li = document.createElement('li');
      li.classList.add(styles.item);

      li.onclick = () => {
        if ([CHART_TYPES.pie, CHART_TYPES.doughnut].includes(type)) {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      const labelIndicator = document.createElement('span');
      labelIndicator.classList.add(styles.labelIndicator);
      labelIndicator.style.background = item.fillStyle;

      const label = document.createElement('span');
      label.classList.add(styles.label);
      label.style.textDecoration = item.hidden ? 'line-through' : '';

      const labelText = document.createTextNode(item.text);
      label.appendChild(labelText);

      if ([CHART_TYPES.pie, CHART_TYPES.doughnut].includes(type)) {
        const totalValue = document.createElement('span');
        const totalValueText = document.createTextNode(` (${chart.data.datasets[0].data[itemIndex]})`);
        totalValue.appendChild(totalValueText);
        label.appendChild(totalValue);
      }

      li.appendChild(labelIndicator);
      li.appendChild(label);
      ul.appendChild(li);
    });
  },
};
