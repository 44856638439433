import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../../constants/actionTypes';
import { isAxiosError } from '../../../../redux/api/fetchClient';
import { ideaCommentsApi } from '../../../../redux/api/slices/ideaCommentsApi';
import { getRawId, trackMatomoEvent } from '../../../../utils';
import {
  MATOMO_EVENT_CATEGORIES,
  MATOMO_EVENT_ACTIONS,
} from '../../../../constants/matomo';

export const createCommentListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      entityId: ideaId,
      data,
      eventHrid,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const createComment = dispatch(
    ideaCommentsApi.endpoints.createIdeaComment.initiate({
      ideaId,
      data,
      eventHrid,
    }),
  );

  try {
    const createCommentResult = await pause(createComment.unwrap());

    trackMatomoEvent(
      MATOMO_EVENT_CATEGORIES.idea,
      MATOMO_EVENT_ACTIONS.commentIdea,
      getRawId(ideaId),
    );

    resolve(createCommentResult);
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    createComment.unsubscribe();
  }
};

export const createCommentActionCreator = createAction(ACTION_TYPES.ideaComments.createComment);
