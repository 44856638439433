import React, {
  useEffect,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { useGetPublicOveralSummaryInfoQuery } from '../../redux/api/slices/analyticsApi';
import { isAxiosError } from '../../redux/api/fetchClient';
import { ICON_TYPES } from '../Icon';
import Spinner from '../Spinner';
import StatCard, { STAT_CARD_TYPES } from './StatCard';
import { ACTIVE_EVENT_HRID } from '../../constants/event';
import { formatDateWithTimezone } from '../../utils';

import styles from './EventStats.module.scss';

const EventStats = () => {
  const { formatMessage } = useIntl();
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetPublicOveralSummaryInfoQuery({
    from: formatDateWithTimezone(dayjs()),
    eventHrid: ACTIVE_EVENT_HRID,
  });

  const STATS = useMemo(() => ([
    {
      id: 'ideas',
      dataKey: 'ideas',
      type: STAT_CARD_TYPES.primary,
      entityName: formatMessage({ id: 'eventStats.entityNames.ideas' }),
      iconType: ICON_TYPES.textSnippet,
    },
    {
      id: 'users',
      dataKey: 'users',
      type: STAT_CARD_TYPES.secondary,
      entityName: formatMessage({ id: 'eventStats.entityNames.users' }),
      iconType: ICON_TYPES.people,
    },
    {
      id: 'likes',
      dataKey: 'likes',
      type: STAT_CARD_TYPES.tertiary,
      entityName: formatMessage({ id: 'eventStats.entityNames.likes' }),
      iconType: ICON_TYPES.like,
    },
    {
      id: 'comments',
      dataKey: 'comments',
      type: STAT_CARD_TYPES.quaternary,
      entityName: formatMessage({ id: 'eventStats.entityNames.comments' }),
      iconType: ICON_TYPES.comment,
    },
  ]), [formatMessage]);

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  if (isError) {
    return null;
  }

  return (
    <div data-testid="wrapper" className={styles.eventStats}>
      <div className={styles.container}>
        <h2 className={styles.heading}>
          {formatMessage({ id: 'eventStats.heading' })}
        </h2>
        <p className={styles.description}>
          {formatMessage({ id: 'eventStats.description' })}
        </p>
        {isLoading
          ? (
            <Spinner data-testid="spinner" />
          ) : (
            <ul className={styles.list}>
              {STATS
                .map(({
                  id,
                  dataKey,
                  type,
                  entityName,
                  iconType,
                }) => (
                  <li
                    className={styles.item}
                    key={id}
                  >
                    <StatCard
                      data-testid={id}
                      type={type}
                      entityName={entityName}
                      total={data.total[dataKey]}
                      lastWeekTotal={data.last_week[dataKey]}
                      iconType={iconType}
                    />
                  </li>
                ))}
            </ul>
          )}
      </div>
    </div>
  );
};

export default EventStats;
