import React from 'react';

import BusinessCategories from '../../BusinessCategories';
// import TechnologyCategories from '../../TechnologyCategories';

import styles from './PortalConfiguration.module.scss';

const PortalConfiguration = () => (
  <div className={styles.wrapper}>
    <BusinessCategories />
    {/* <TechnologyCategories /> */}
  </div>
);

export default PortalConfiguration;
