import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import Icon, { ICON_TYPES, ICON_SIZE, ICON_COLOR } from '../../Icon';
import Animation from '../../Animation';
import activeStageAnimationData from './animations/activeStage.json';

import styles from './Stage.module.scss';

const Stage = ({
  title,
  completed,
  active,
}) => (
  <div className={clsx(styles.wrapper, {
    [styles.wrapper__active]: active,
    [styles.wrapper__completed]: completed,
  })}
  >
    {
      active
        ? (
          <Animation
            className={styles.stage}
            animationData={activeStageAnimationData}
          />
        )
        : (
          <div className={clsx(styles.stage, styles.stage__static)}>
            {completed && (
            <Icon
              type={ICON_TYPES.checkMark}
              size={ICON_SIZE.xxxs}
              color={ICON_COLOR.inherit}
            />
            )}
          </div>
        )
    }
    <div>
      <p className={styles.step__num}>
        <FormattedMessage id="common.stage" />
      </p>
      <p className={styles.step__title}>
        {title}
      </p>
    </div>
  </div>
);

Stage.propTypes = {
  title: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
};

export default Stage;
