import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from '../../constants/routes';
import IdeasCatalogBreadcrumb from './IdeasCatalogBreadcrumb';

export const routes = [
  { path: ROUTES.base, breadcrumb: () => <FormattedMessage id="breadcrumbs.pages.welcome" /> },
  { path: ROUTES.createIdea, breadcrumb: () => <FormattedMessage id="breadcrumbs.pages.newIdea" /> },
  { path: ROUTES.ideas, breadcrumb: () => <IdeasCatalogBreadcrumb /> },
  { path: ROUTES.idea, breadcrumb: ({ match }) => match.params.ideaId },
  { path: ROUTES.profile, breadcrumb: () => <FormattedMessage id="breadcrumbs.pages.profile" /> },
  // {
  //   path: ROUTES.proposeIdeaSolution,
  //   breadcrumb: () => <FormattedMessage id="breadcrumbs.pages.proposeIdeaSolution" />,
  // },
];
