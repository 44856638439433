import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ className, children }) => (
  <main className={className}>
    {children}
  </main>
);

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Content.defaultProps = {
  children: null,
  className: '',
};

export default Content;
