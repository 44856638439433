import { STEPS_IDS } from '../IdeaStepper/constants';
import { FORM_FIELD_NAMES as BUSINESS_OBJECTIVE_FORM_FIELDS } from '../../forms/BusinessObjectiveForm/constants';
import { FORM_FIELD_NAMES as GENERAL_OVERVIEW_FORM_FIELDS } from '../../forms/GeneralOverviewForm/constants';
import { FORM_FIELD_NAMES as ATTACH_LINKS_FORM_FIELDS } from '../../forms/AttachLinksForm/constants';

const extractFormValues = (stepValues, data) => (
  Object.values(stepValues).reduce((result, value) => ({
    ...result,
    [value]: data[value],
  }), {}));

export const prepareIdeaStepperData = (idea) => (
  idea
    ? {
      [STEPS_IDS.SELECT_BUSINESS_OBJECTIVE]: {
        ...extractFormValues(BUSINESS_OBJECTIVE_FORM_FIELDS, idea),
        [BUSINESS_OBJECTIVE_FORM_FIELDS.BUSINESS_OBJECTIVE]:
          idea.biz_category?.id,
      },
      [STEPS_IDS.PROVIDE_GENERAL_OVERVIEW]: {
        ...extractFormValues(GENERAL_OVERVIEW_FORM_FIELDS, idea),
      },
      [STEPS_IDS.ATTACH_LINKS]: {
        ...extractFormValues(ATTACH_LINKS_FORM_FIELDS, idea),
      },
    } : {}
);
