import React from 'react';
import { useIntl } from 'react-intl';

import EmptyContent from '../../../../EmptyContent';
import { ICON_TYPES } from '../../../../Icon';

const NoIdeas = () => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.folder,
      }}
      title={formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.noIdeas.title' })}
      description={formatMessage({ id: 'adminPanel.ideasBoard.ideasDownSelectionTable.noIdeas.description' })}
    />
  );
};

export default NoIdeas;
