import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Button from '../../../../Button';
import {
  useIsAbleToDeleteIdea,
  useIsAbleToRestoreIdea,
} from '../../../../../hooks';
import { IDEA_STATUSES } from '../../../../../constants/idea';

import styles from './Actions.module.scss';

const Actions = ({
  idea,
  onRestore,
  onDelete,
}) => {
  const { formatMessage } = useIntl();
  const isAbleToRestoreIdea = useIsAbleToRestoreIdea(idea);
  const isAbleToDeleteIdea = useIsAbleToDeleteIdea(idea);

  return (
    <div className={styles.actionsWrapper}>
      <Button
        cx={styles.restoreButton}
        caption={formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.actions.restoreButton' })}
        size="42"
        fill="white"
        onClick={onRestore}
        isDisabled={!isAbleToRestoreIdea}
      />
      <Button
        caption={formatMessage({ id: 'adminPanel.ideasBoard.archivedIdeasTable.actions.deleteButton' })}
        size="42"
        fill="white"
        onClick={onDelete}
        isDisabled={!isAbleToDeleteIdea}
      />
    </div>
  );
};

Actions.propTypes = {
  idea: PropTypes.shape({
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    archive: PropTypes.object,
    status: PropTypes.oneOf(Object.values(IDEA_STATUSES)).isRequired,
  }).isRequired,
  onRestore: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Actions;
