import { createAction } from '@reduxjs/toolkit';

import { ACTION_TYPES } from '../../../constants/actionTypes';
import { isAxiosError } from '../../../redux/api/fetchClient';
import { businessObjectivesApi } from '../../../redux/api/slices/businessObjectivesApi';

export const submitNewBizCategoryListener = async (action, listenerApi) => {
  const {
    payload: {
      resolve,
      reject,
      formData,
    },
  } = action;
  const { dispatch, pause } = listenerApi;

  const submitObjective = dispatch(
    businessObjectivesApi.endpoints.createBusinessObjective.initiate(formData),
  );

  try {
    await pause(submitObjective.unwrap());

    resolve();
  } catch (error) {
    isAxiosError(error) && error.handleGlobally();

    reject();
  } finally {
    submitObjective.unsubscribe();
  }
};

export const submitNewBizCategoryActionCreator = createAction(
  ACTION_TYPES.bizCategory.createNewBizCategory,
);
