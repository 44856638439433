import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { TITLE_TRANSLATION_KEY_BY_ACTIVE_TAB_TYPE } from '../constants';
import { activeEventStatusSelector } from '../../../../redux/slices/events';
import { EVENT_STATUSES } from '../../../../constants/event';

export const useGetTitleRenderer = () => {
  const { formatMessage } = useIntl();
  const activeEventStatus = useSelector(activeEventStatusSelector);

  const renderTitle = useCallback(
    ({ activeTabId, items }) => formatMessage({
      id: activeEventStatus === EVENT_STATUSES.FINISHED
        ? 'ideas.ideasList.winners.title'
        : TITLE_TRANSLATION_KEY_BY_ACTIVE_TAB_TYPE[activeTabId],
    }, {
      count: items,
    }),
    [activeEventStatus, formatMessage],
  );

  return renderTitle;
};
