import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { activeEventSelector } from '../../../redux/slices/events';
import { getDiffInDays } from '../../../utils/dateUtils';
import Animation from '../../Animation';
import squaresAnimationData from './animations/squares.json';

import styles from './Banner.module.scss';

const Banner = () => {
  const { formatMessage } = useIntl();
  const activeEvent = useSelector(activeEventSelector);
  const eventDaysLeft = useMemo(() => {
    const daysLeft = getDiffInDays(activeEvent.start_at);

    return daysLeft < 0 ? 0 : daysLeft;
  }, [activeEvent]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <h2 className={styles.title}>
          {formatMessage({ id: 'inactiveWelcome.banner.title' })}
        </h2>
        <p className={styles.subtitle}>
          {formatMessage({ id: 'inactiveWelcome.banner.description' })}
        </p>
        <div className={styles.counter}>
          <p className={styles.amount}>
            {eventDaysLeft}
          </p>
          <p className={styles.description}>
            {formatMessage({ id: 'inactiveWelcome.banner.timeLeft' }, {
              daysAmount: eventDaysLeft,
            })}
          </p>
        </div>
        <Animation
          data-testid="squaresAnimation"
          className={styles.animation}
          animationData={squaresAnimationData}
        />
      </div>
    </div>
  );
};

export default Banner;
