import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../Button';
import ExcelFileIcon from './ExcelFileIcon';
import { useListener } from '../../../../hooks';
import {
  getIdeasReportActionCreator,
  getIdeasReportListener,
} from './listeners/getIdeasReportListener';
import { ACTIVE_EVENT_HRID } from '../../../../constants/event';

const ExportToExcelButton = () => {
  const { formatMessage } = useIntl();
  const [getIdeasReport] = useListener({
    listener: getIdeasReportListener,
    actionCreator: getIdeasReportActionCreator,
  });

  const handleGetIdeasReport = useCallback(
    () => getIdeasReport({ eventHrid: ACTIVE_EVENT_HRID }),
    [getIdeasReport],
  );

  return (
    <Button
      data-testid="exportToExcelButton"
      onClick={handleGetIdeasReport}
      caption={formatMessage({ id: 'adminPanel.ideasBoard.ideasTabs.exportToExcelButton.label' })}
      size="42"
      icon={ExcelFileIcon}
      fill="white"
    />
  );
};

export default ExportToExcelButton;
