import React from 'react';
import { useParams } from 'react-router-dom';

import PageInfo from '../../PageInfo';
import Idea from '../../Idea';
import { useGetIdeaByIdQuery } from '../../../redux/api/slices/ideaApi';
import { ACTIVE_EVENT_HRID } from '../../../constants/event';

const IdeaPage = () => {
  const { ideaId } = useParams();
  const {
    data: ideaData,
  } = useGetIdeaByIdQuery({
    ideaId,
    eventHrid: ACTIVE_EVENT_HRID,
  });

  return (
    <>
      {ideaData && (
        <PageInfo
          data-testid="pageInfo"
          title={`${ideaData.human_readable_id} ${ideaData.title}`}
        />
      )}
      <Idea data-testid="idea" />
    </>
  );
};

export default IdeaPage;
