import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import ArchiveIdeaForm from '../../forms/ArchiveIdeaForm';
import {
  toggleArchiveIdeaListener,
  toggleArchiveIdeaActionCreator,
} from '../../Idea/listeners/toggleArchiveIdeaListener';
import { useListener } from '../../../hooks';
import { ACTIVE_EVENT_HRID } from '../../../constants/event';

const ArchiveIdeaConfirmationModal = ({
  ideaId,
  abort,
  success,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [{
    submitForm,
    isSubmitting,
  }, setFormApi] = useState({});

  const [toggleArchive] = useListener({
    listener: toggleArchiveIdeaListener,
    actionCreator: toggleArchiveIdeaActionCreator,
  });

  const handleArchive = useCallback((data) => toggleArchive({
    ideaId,
    eventHrid: ACTIVE_EVENT_HRID,
    data,
  }), [
    toggleArchive,
    ideaId,
  ]);

  return (
    <Modal
      title={formatMessage({ id: 'archiveIdeaConfirmationModal.title' })}
      content={(
        <ArchiveIdeaForm
          onSubmit={handleArchive}
          onSubmitSuccess={success}
          getFormApi={setFormApi}
        />
      )}
      onClose={abort}
      onReject={abort}
      onConfirm={submitForm}
      confirmButtonCaption={formatMessage({ id: 'archiveIdeaConfirmationModal.confirmButton.label' })}
      isLoading={isSubmitting}
      {...props}
    />
  );
};

ArchiveIdeaConfirmationModal.propTypes = {
  ideaId: PropTypes.string.isRequired,
  success: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
};

export default ArchiveIdeaConfirmationModal;
