import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';

import Button from '../../Button';
import Blocker from '../../Blocker';
import PageBlocker from '../../PageBlockerProvider/PageBlocker';
import { ViewContext } from '../viewContext';
import styles from './PrimaryView.module.scss';

const PrimaryView = ({
  steps,
  activeStepId,
  ActiveStep,
  isFirstStep,
  isLastStep,
  backButtonCaption,
  doneButtonCaption,
  nextButtonCaption,
  discardButtonCaption,
  saveAsDraftButtonCaption,
  areActionsDisabled,
  isFetching,
  isProcessing,
  onBackButtonClick,
  onNextButtonClick,
  onSaveAsDraftButtonClick,
  onDiscardButtonClick,
  activeStepKey,
  primaryAdditionalActionsProps,
}) => {
  const { formatMessage } = useIntl();
  const viewContextValue = useMemo(() => ({
    content: {
      oneColumn: {
        className: styles.oneColumnContent,
      },
    },
  }), []);

  const isMultiStep = steps.length > 1;

  return (
    <ViewContext.Provider value={viewContextValue}>
      {isProcessing && <PageBlocker data-testid="pageBlocker" />}
      <section className={styles.stepper}>
        {isMultiStep && (
          <header>
            <ul className={styles.navigation}>
              {steps.map(({ title, id }) => (
                <li
                  className={clsx(styles.navigationItem, {
                    [styles.navigationItem__active]: id === activeStepId,
                  })}
                  key={id}
                >
                  {title}
                </li>
              ))}
            </ul>
          </header>
        )}
        <div className={styles.content}>
          {isFetching && <Blocker data-testid="blocker" />}
          <ActiveStep
            data-testid="activeStep"
            key={activeStepKey}
          />
        </div>
        <footer className={styles.footer}>
          <ul className={styles.footerActions}>
            {!isFirstStep && (
              <li>
                <Button
                  data-testid="goBackButton"
                  fill="white"
                  color="blue"
                  size="42"
                  caption={backButtonCaption || formatMessage({ id: 'common.back' })}
                  isDisabled={areActionsDisabled}
                  onClick={onBackButtonClick}
                />
              </li>
            )}
            {onDiscardButtonClick && (
              <li>
                <Button
                  data-testid="discardButton"
                  fill="white"
                  color="blue"
                  size="42"
                  caption={discardButtonCaption || formatMessage({ id: 'common.discard' })}
                  isDisabled={areActionsDisabled}
                  onClick={onDiscardButtonClick}
                />
              </li>
            )}
          </ul>
          <ul className={styles.footerActions}>
            {
              primaryAdditionalActionsProps.map(({ id, ...props }) => (
                <li key={id}>
                  <Button
                    fill="white"
                    color="blue"
                    size="42"
                    isDisabled={areActionsDisabled}
                    {...props}
                  />
                </li>
              ))
            }
            {onSaveAsDraftButtonClick && (
              <li>
                <Button
                  data-testid="saveAsDraftButton"
                  fill="white"
                  color="blue"
                  size="42"
                  caption={saveAsDraftButtonCaption || formatMessage({ id: 'common.saveAsDraft' })}
                  isDisabled={areActionsDisabled}
                  onClick={onSaveAsDraftButtonClick}
                />
              </li>
            )}
            <li>
              <Button
                data-testid="goNextButton"
                color="blue"
                size="42"
                caption={(isLastStep ? doneButtonCaption : nextButtonCaption) || formatMessage({ id: 'common.next' })}
                onClick={onNextButtonClick}
                isDisabled={areActionsDisabled}
              />
            </li>
          </ul>
        </footer>
      </section>
    </ViewContext.Provider>
  );
};

PrimaryView.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeStepId: PropTypes.string.isRequired,
  ActiveStep: PropTypes.elementType.isRequired,
  isFirstStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  isFetching: PropTypes.bool,
  isProcessing: PropTypes.bool,
  areActionsDisabled: PropTypes.bool,
  backButtonCaption: PropTypes.string,
  doneButtonCaption: PropTypes.string,
  nextButtonCaption: PropTypes.string,
  saveAsDraftButtonCaption: PropTypes.string,
  discardButtonCaption: PropTypes.string,
  activeStepKey: PropTypes.number.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  onSaveAsDraftButtonClick: PropTypes.func,
  onDiscardButtonClick: PropTypes.func,
  primaryAdditionalActionsProps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    caption: PropTypes.string.isRequired,
  })),
};

PrimaryView.defaultProps = {
  backButtonCaption: null,
  doneButtonCaption: null,
  nextButtonCaption: null,
  saveAsDraftButtonCaption: null,
  discardButtonCaption: null,
  isFirstStep: false,
  isLastStep: false,
  areActionsDisabled: false,
  isFetching: false,
  isProcessing: false,
  onDiscardButtonClick: null,
  onSaveAsDraftButtonClick: null,
  primaryAdditionalActionsProps: [],
};

export default PrimaryView;
