import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ROUTES } from '../../../../../constants/routes';
import { formatDate, getFullUrl, getUserFullName } from '../../../../../utils';
import LikesAndCommentsBar from '../../../../LikesAndCommentsBar';
import IconButton from '../../../../IconButton';
import CopyToClipboard from '../../../../CopyToClipboard';
import CopyLinkIcon from './CopyLinkIcon';

import styles from './WinnerCard.module.scss';

const WinnerCard = ({
  idea,
}) => {
  const {
    human_readable_id: ideaHrid,
    author,
    title,
    reason,
    created_at: date,
  } = idea;
  const ideaLink = useMemo(
    () => generatePath(ROUTES.idea, { ideaId: ideaHrid }),
    [ideaHrid],
  );
  const ideaTitle = useMemo(() => (
    <Link
      to={ideaLink}
      className={clsx(styles.ideaLink, styles.cut)}
    >
      {title}
    </Link>
  ), [
    title,
    ideaLink,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.status}>
          {ideaHrid}
        </div>
        <CopyToClipboard
          data-testid="copyToClicpboard"
          dataToCopy={getFullUrl(ideaLink)}
        >
          <IconButton
            data-testid="icon"
            icon={CopyLinkIcon}
            onClick={() => { }}
          />
        </CopyToClipboard>
      </div>
      <h4 className={styles.title}>
        {ideaTitle}
      </h4>
      <p className={styles.reason}>
        {reason}
      </p>
      <div className={styles.footer}>
        <div className={styles.author}>
          {getUserFullName(author)}
        </div>
        <div className={styles.date}>
          {formatDate(date)}
        </div>
      </div>
      <LikesAndCommentsBar
        data-testid="likesAndCommentsBar"
        idea={idea}
        className={styles.likesAndComments}
      />
    </div>
  );
};

WinnerCard.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    author: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    likes: PropTypes.arrayOf(PropTypes.number).isRequired,
    total_comments_with_replies: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    human_readable_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default WinnerCard;
