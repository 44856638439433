import React, {
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { isFunction, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import Spinner from '../Spinner';
import Blocker from '../Blocker';
import Paginator from '../Paginator';
import { scrollToWithAppHeaderOffset } from '../../utils';
import { isAxiosError } from '../../redux/api/fetchClient';
import Select from '../Select';
import {
  useQueryParams,
  useGenerateLink,
} from '../../hooks';
import { useGetSearchValueFromUrl } from '../Search';
import TabsNav from '../Tabs/TabsNav';
import FiltersPanel, {
  useGetFiltersPanelValuesFromUrl,
  useGetFiltersPanelClearHandler,
} from '../FiltersPanel';

import styles from './Cards.module.scss';

const Cards = ({
  tabs,
  title,
  renderCard,
  getCardId,
  emptyState,
  useData,
  pageSize,
  showOnePage,
  id,
  tabUrlParamName,
  pageUrlParamName,
  className,
  searchProps,
  sortingProps: {
    sorting,
    urlParamName: sortingUrlParamName,
  },
  filtersProps: {
    reportLabelId,
    ...filtersProps
  },
  getDataAdditionalParams,
  isInProgress,
}) => {
  const history = useHistory();
  const { generateLink } = useGenerateLink();
  const { formatMessage } = useIntl();
  const {
    [tabUrlParamName]: urlActiveTabId,
    [sortingUrlParamName]: urlActiveSortingId,
    [pageUrlParamName]: urlActivePage,
  } = useQueryParams();
  const {
    filters,
    urlParamName: filtersUrlParamName,
  } = filtersProps;
  const { clearFilters } = useGetFiltersPanelClearHandler({ urlParamName: filtersUrlParamName });
  const { urlParamName: searchUrlParamName } = searchProps;
  const resetPagination = useMemo(
    () => ({ [pageUrlParamName]: 1 }),
    [pageUrlParamName],
  );

  const resultActiveTabId = useMemo(() => (
    tabs.map((tab) => tab.id).includes(urlActiveTabId)
      ? urlActiveTabId
      : tabs?.[0]?.id
  ), [urlActiveTabId, tabs]);

  const resultActiveSortingId = useMemo(() => (
    sorting?.map(
      (sortingItem) => sortingItem.id,
    ).includes(urlActiveSortingId)
      ? urlActiveSortingId
      : sorting?.[0].id
  ), [
    urlActiveSortingId,
    sorting,
  ]);

  const resultActiveSearch = useGetSearchValueFromUrl(searchUrlParamName);
  const resultActiveFilters = useGetFiltersPanelValuesFromUrl(filters, filtersUrlParamName);
  const hasActiveFilters = !isEmpty(resultActiveFilters);
  const page = Number(urlActivePage) || 1;

  const {
    data,
    isLoading,
    isFetching,
    error,
  } = useData({
    page,
    pageSize,
    filters: resultActiveFilters,
    tab: resultActiveTabId,
    sort: resultActiveSortingId,
    search: resultActiveSearch.trim(),
    ...getDataAdditionalParams,
  });

  const totalFilteredItemsCount = data?.total || 0;
  const items = data?.items || [];
  const numberOfPages = data?.pages || 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentTabId = useMemo(() => resultActiveTabId, [
    data,
  ]);

  const handlePageChange = useCallback((newPage) => {
    history.push(
      generateLink({ [pageUrlParamName]: newPage }),
    );

    scrollToWithAppHeaderOffset(id);
  }, [
    generateLink,
    pageUrlParamName,
    id,
    history,
  ]);

  useEffect(() => {
    isAxiosError(error) && error.handleGlobally();
  }, [error]);

  const sortingItems = useMemo(
    () => sorting?.map((sortingItem) => ({
      ...sortingItem,
      link: generateLink({
        [sortingUrlParamName]: sortingItem.id,
        ...resetPagination,
      }),
    })),
    [
      sorting,
      generateLink,
      sortingUrlParamName,
      resetPagination,
    ],
  );

  const content = (() => {
    if (isLoading) {
      return (
        <Spinner
          data-testid="spinner"
          cx={styles.spinner}
        />
      );
    }

    if (!totalFilteredItemsCount && !numberOfPages) {
      return isFunction(emptyState)
        ? emptyState({
          hasActiveFilters,
          clearFilters,
          activeTabId: currentTabId,
        })
        : emptyState;
    }

    return (
      <ul className={styles.list}>
        {items.map((cardData) => (
          <li
            className={styles.listItem}
            key={getCardId(cardData)}
          >
            {renderCard({ cardData, activeTabId: currentTabId })}
          </li>
        ))}
      </ul>
    );
  })();

  const resultFiltersProps = filters
    ? {
      filters,
      urlParamName: filtersUrlParamName,
      activeFilters: resultActiveFilters,
      linkAdditionalProps: resetPagination,
    }
    : null;

  const resultSortingProps = sorting
    ? {
      label: formatMessage({ id: 'ideas.ideasList.sorting.dropdownLabel' }),
      captionClassName: styles.sortingSelectCaption,
      items: sortingItems,
      selectedValueId: resultActiveSortingId,
    }
    : null;

  return (
    <div className={clsx(styles.cards, className)}>
      <header className={styles.header}>
        {!!tabs.length && (
          <TabsNav
            data-testid="tabs"
            items={tabs}
            activeTabId={resultActiveTabId}
            urlParamName={tabUrlParamName}
            className={styles.tabs}
            linkAdditionalProps={resetPagination}
          />
        )}
        <div className={styles.subHeader}>
          {(resultFiltersProps || resultSortingProps) && (
            <div className={styles.filtersAndSortingPanel}>
              {resultFiltersProps && (
                <FiltersPanel
                  {...resultFiltersProps}
                />
              )}
              {resultSortingProps && (
                <Select
                  {...resultSortingProps}
                />
              )}
            </div>
          )}
          <h3 className={styles.title}>
            {
              isFunction(title)
                ? title({
                  activeTabId: currentTabId,
                  items: totalFilteredItemsCount,
                })
                : title
            }
          </h3>
        </div>
      </header>
      <div className={styles.listWrapper}>
        {((!isLoading && isFetching) || isInProgress) && (
          <Blocker data-testid="blocker" />
        )}
        {content}
      </div>
      {
        !showOnePage && numberOfPages > 1 && (
          <footer className={styles.footer}>
            <div className={styles.paginator}>
              <Paginator
                data-testid="paginator"
                value={page}
                onValueChange={handlePageChange}
                totalPages={numberOfPages}
              />
            </div>
          </footer>
        )
      }
    </div>
  );
};

Cards.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  emptyState: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  renderCard: PropTypes.func.isRequired,
  getCardId: PropTypes.func,
  useData: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  showOnePage: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tabUrlParamName: PropTypes.string,
  pageUrlParamName: PropTypes.string,
  className: PropTypes.string,
  sortingProps: PropTypes.object,
  searchProps: PropTypes.object,
  filtersProps: PropTypes.object,
  getDataAdditionalParams: PropTypes.object,
  isInProgress: PropTypes.bool,
};

Cards.defaultProps = {
  tabs: [],
  getCardId: (data) => data.id,
  pageSize: 20,
  showOnePage: false,
  tabUrlParamName: null,
  pageUrlParamName: null,
  className: '',
  searchProps: {},
  sortingProps: {},
  filtersProps: {},
  getDataAdditionalParams: {},
  isInProgress: false,
};

export default Cards;
