import axios, { isAxiosError } from 'axios';

const fetchClient = axios.create();

let handleGlobally = () => {};

const setHandleGlobally = (newHandleGlobally) => {
  handleGlobally = newHandleGlobally;
};

const errorComposer = (error) => () => {
  handleGlobally(error);
};

fetchClient.interceptors.response.use(undefined, (error) => {
  error.handleGlobally = errorComposer(error);

  return Promise.reject(error);
});

export {
  fetchClient,
  setHandleGlobally,
  isAxiosError,
};
