import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import Button from '../Button';
import { useModal } from '../../hooks';
import ContactUsModal from '../modals/ContactUsModal';
import HasAccess from '../HasAccess';

const ContactUs = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();
  const showContactUsModal = useCallback(() => showModal((props) => (
    <ContactUsModal
      {...props}
    />
  )), [showModal]);

  return (
    <HasAccess>
      <Button
        caption={formatMessage({ id: 'contactUs.button.label' })}
        onClick={showContactUsModal}
        size="42"
      />
    </HasAccess>
  );
};

export default ContactUs;
