import React from 'react';
import PropTypes from 'prop-types';

import Badge, {
  BADGE_COLORS,
  BADGE_FILL,
} from '../../../Badge';

const InfoCell = ({
  textInformation,
  className,
}) => (
  <Badge
    caption={textInformation}
    color={BADGE_COLORS.GRAY_LIGHT}
    fill={BADGE_FILL.SEMITRANSPARENT}
    className={className}
    ellipsis
  />
);

InfoCell.propTypes = {
  textInformation: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InfoCell.defaultProps = {
  className: '',
};

export default InfoCell;
