import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  EVENT_STAGES,
  EVENT_STATUSES,
} from '../../constants/event';
import { USER_ROLES } from '../../constants/roles';
import { ROUTES } from '../../constants/routes';

export const newIdeaPageAccessConditions = ({ activeStageType }) => (
  activeStageType === EVENT_STAGES.IDEA_SUBMISSION
);

export const ideaPageAccessConditions = ({
  activeEventStatus,
}) => [
  EVENT_STATUSES.IN_PROGRESS,
  EVENT_STATUSES.FINISHED,
].includes(activeEventStatus);

export const updateSolutionPageAccessConditions = ({
  userRole,
  activeStageType,
}) => {
  if (
    userRole === USER_ROLES.ADMIN
    && [
      EVENT_STAGES.IDEA_SUBMISSION,
      EVENT_STAGES.DOWN_SELECTION,
      EVENT_STAGES.VOTING,
    ].includes(activeStageType)
  ) {
    return true;
  }

  if (
    userRole === USER_ROLES.PORTAL_CONTRIBUTOR
    && activeStageType === EVENT_STAGES.IDEA_SUBMISSION
  ) {
    return true;
  }

  return false;
};

export const updateIdeaPageAccessConditions = ({
  userRole,
  activeStageType,
  activeEventStatus,
}) => {
  if (
    userRole === USER_ROLES.ADMIN
    && activeEventStatus === EVENT_STATUSES.IN_PROGRESS
  ) {
    return true;
  }

  if (
    userRole === USER_ROLES.PORTAL_CONTRIBUTOR
    && activeStageType === EVENT_STAGES.IDEA_SUBMISSION
  ) {
    return true;
  }

  return false;
};

export const adminPanelPageAccessConditions = ({
  isLoggedIn,
  userRole,
}) => {
  if (!isLoggedIn) {
    return (
      <Redirect
        to={ROUTES.base}
      />
    );
  }

  return userRole === USER_ROLES.ADMIN;
};

export const proposeIdeaSolutionPageAccessConditions = ({ activeStageType }) => (
  activeStageType === EVENT_STAGES.IDEA_SUBMISSION
);

export const ideasPageAccessConditions = ({ activeEventStatus }) => (
  [
    EVENT_STATUSES.IN_PROGRESS,
    EVENT_STATUSES.FINISHED,
  ].includes(activeEventStatus)
);
