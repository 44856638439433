import { baseApi } from '../baseApi';
import { COMMENTS_PER_PAGE } from '../../../components/Comments/constants';
import { getRawId } from '../../../utils';

export const ideaCommentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createIdeaComment: build.mutation({
      query: ({
        ideaId,
        data,
        eventHrid,
      }) => ({
        url: `/events/${eventHrid}/ideas/${getRawId(ideaId)}/comments`,
        method: 'POST',
        data,
      }),
    }),
    getIdeaComments: build.query({
      query: ({
        ideaId,
        page,
        parentId,
        eventHrid,
      }) => ({
        url: parentId
          ? `/events/${eventHrid}/ideas/comments/${parentId}/children`
          : `/events/${eventHrid}/ideas/${getRawId(ideaId)}/comments`,
        params: {
          size: COMMENTS_PER_PAGE,
          page,
        },
      }),
    }),
    deleteIdeaComment: build.mutation({
      query: ({ commentId, eventHrid }) => ({
        url: `/events/${eventHrid}/ideas/comments/${commentId}`,
        method: 'DELETE',
      }),
    }),
    updateIdeaComment: build.mutation({
      query: ({ commentId, data, eventHrid }) => ({
        url: `/events/${eventHrid}/ideas/comments/${commentId}`,
        method: 'PATCH',
        data,
      }),
    }),
  }),
});
