import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../Button';
import { ROUTES } from '../../../../constants/routes';

import styles from './LoginSuggestion.module.scss';

const LoginSuggestion = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.wrapper}>
      <p className={styles.description}>
        {formatMessage({ id: 'loginSuggestion.loginLabel' })}
      </p>
      <Button
        data-testid="loginButton"
        cx={styles.loginButton}
        caption={formatMessage({ id: 'common.login.label' })}
        fill="white"
        size="36"
        link={{ pathname: ROUTES.login }}
      />
    </div>
  );
};

export default LoginSuggestion;
