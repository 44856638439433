import 'react-app-polyfill/stable';
import './dayjs-init';
import './chartjs-init';
import './index.scss';
import '@epam/uui-components/styles.css';
import '@epam/promo/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { store } from './redux/store';
import { UuiEnhancedApp } from './components/UuiEnhancedApp/UuiEnhancedApp';
import IntlProvider from './components/IntlProvider';
import reportWebVitals from './reportWebVitals';
import history from './browserHistory';
import {
  isProdNodeEnv,
  enableMatomoLinkTracking,
  enableMatomoHeartBeatTimer,
} from './utils';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_COMMIT_SHA,
  enabled: isProdNodeEnv(),
  tracesSampleRate: 0.01,
});

// set matomo default settings
enableMatomoLinkTracking();
enableMatomoHeartBeatTimer();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Sentry.ErrorBoundary>
    <HelmetProvider>
      <Router history={history}>
        <IntlProvider>
          <Provider store={store}>
            <UuiEnhancedApp />
          </Provider>
        </IntlProvider>
      </Router>
    </HelmetProvider>
  </Sentry.ErrorBoundary>,
);

reportWebVitals();
