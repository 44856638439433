export const SORTING_TYPES = {
  asc: 'asc',
  desc: 'desc',
};

export const EXPAND_CELL_ACCESSOR = 'expandCell';

export const ACTIONS_MENU_CELL_ACCESSOR = 'actionsMenuCell';

export const COLUMN_DEFAULT_WIDTH = 0;
