import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckboxGroup as CheckboxGroupBase } from '@epam/promo';
import { useIntl } from 'react-intl';
import { isNumber } from 'lodash';

import LinkButton from '../../LinkButton';

import styles from './CheckboxGroup.module.scss';

const CheckboxGroup = ({
  cx,
  items,
  visibleItemsNumber,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [areAllItemsVisible, setAllItemsVisible] = useState(false);
  const isToggleItemsVisibilityButtonVisible = useMemo(
    () => isNumber(visibleItemsNumber) && visibleItemsNumber < items.length,
    [visibleItemsNumber, items],
  );
  const resultItems = useMemo(() => (
    isToggleItemsVisibilityButtonVisible && !areAllItemsVisible
      ? items.slice(0, visibleItemsNumber)
      : items
  ), [
    isToggleItemsVisibilityButtonVisible,
    items,
    visibleItemsNumber,
    areAllItemsVisible,
  ]);
  const handleToggleItemsVisibility = useCallback(
    () => setAllItemsVisible((areVisible) => !areVisible),
    [],
  );

  return (
    <>
      <CheckboxGroupBase
        {...props}
        cx={clsx(styles.checkboxGroup, cx)}
        items={resultItems}
      />
      {
        isToggleItemsVisibilityButtonVisible && (
          <LinkButton
            caption={formatMessage(
              { id: 'checkboxGroup.toggleItemsVisibilityButtonCaption' },
              {
                areAllItemsVisible,
                itemsLeft: items.length - visibleItemsNumber,
              },
            )}
            onClick={handleToggleItemsVisibility}
            size="42"
            cx={styles.toggleItemsVisibilityButton}
            captionCX={styles.toggleItemsVisibilityButtonCaption}
          />
        )
      }
    </>
  );
};

CheckboxGroup.propTypes = {
  cx: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  visibleItemsNumber: PropTypes.number,
};

CheckboxGroup.defaultProps = {
  cx: '',
  visibleItemsNumber: null,
};

export default CheckboxGroup;
