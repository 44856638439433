import {
  useContext,
  useLayoutEffect,
} from 'react';

import { StepperContext } from '../Stepper';

export const useStep = ({
  nextButtonCaption,
  backButtonCaption,
  isLoading,
  isFetching,
  isInProgress,
  isDirty,
  onGoNext,
  onGoBack,
  onSaveAsDraft,
}) => {
  const { registerStep } = useContext(StepperContext);

  useLayoutEffect(() => {
    registerStep({
      nextButtonCaption,
      backButtonCaption,
      isLoading,
      isFetching,
      isInProgress,
      isDirty,
      onGoNext,
      onGoBack,
      onSaveAsDraft,
    });
  }, [
    registerStep,
    nextButtonCaption,
    backButtonCaption,
    isLoading,
    isFetching,
    isInProgress,
    isDirty,
    onGoNext,
    onGoBack,
    onSaveAsDraft,
  ]);
};
