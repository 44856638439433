import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import clsx from 'clsx';

import LikesAndCommentsBar from '../../LikesAndCommentsBar';
import { formatDate, getUserFullName } from '../../../utils';
import { ROUTES } from '../../../constants/routes';
import IdeaCardMenu from './IdeaCardMenu';
import HasAccess from '../../HasAccess';
import { ideaAuthorAccessConditions } from '../../../utils/accessConditions';

import styles from './IdeaCard.module.scss';

const IdeaCard = ({
  idea,
  onArchive,
}) => {
  const {
    author,
    title,
    reason,
    created_at: date,
    human_readable_id: ideaHrid,
  } = idea;
  const ideaTitle = useMemo(() => (
    <Link
      data-testid="ideaTitleLink"
      to={generatePath(ROUTES.idea, { ideaId: ideaHrid })}
      className={clsx(styles.ideaLink, styles.cut)}
    >
      {title}
    </Link>
  ), [
    title,
    ideaHrid,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.ideaHrid}>
          {ideaHrid}
        </div>
        <IdeaCardMenu
          data-testid="ideaCardMenu"
          idea={idea}
          onArchive={onArchive}
        />
      </div>
      <div className={styles.subHeader}>
        <h4 className={styles.title}>
          {ideaTitle}
        </h4>
      </div>
      <p className={styles.reason}>
        {reason}
      </p>
      <HasAccess
        data-testid="authorNameHasAccess"
        conditions={ideaAuthorAccessConditions}
      >
        <div className={styles.creationInfo}>
          <div className={styles.author}>
            {getUserFullName(author)}
          </div>
          <div className={styles.date}>
            {formatDate(date)}
          </div>
        </div>
      </HasAccess>
      <div className={styles.footer}>
        <LikesAndCommentsBar
          data-testid="likesAndComments"
          idea={idea}
          className={styles.likesAndComments}
        />
      </div>
    </div>
  );
};

IdeaCard.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.number.isRequired,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    likes: PropTypes.arrayOf(PropTypes.number).isRequired,
    total_comments_with_replies: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    human_readable_id: PropTypes.string.isRequired,
  }).isRequired,
  onArchive: PropTypes.func.isRequired,
};

export default IdeaCard;
