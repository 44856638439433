import React from 'react';
import { useIntl } from 'react-intl';

import { ICON_TYPES } from '../../../Icon';
import CreateNewIdeaButton from '../../../CreateNewIdeaButton';
import EmptyContent from '../../../EmptyContent';

const NoDraftIdeas = () => {
  const { formatMessage } = useIntl();

  return (
    <EmptyContent
      iconProps={{
        type: ICON_TYPES.document,
      }}
      title={formatMessage({ id: 'ideas.noDraftIdeas.title' })}
      description={formatMessage({ id: 'ideas.noDraftIdeas.description' })}
      customButton={<CreateNewIdeaButton />}
    />
  );
};

export default NoDraftIdeas;
