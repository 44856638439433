import React from 'react';
import PropTypes from 'prop-types';

import Icon, {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TYPES,
} from '../../../Icon';

const StageIcon = ({ type }) => (
  <Icon
    type={type}
    size={ICON_SIZE.xs}
    color={ICON_COLOR.inherit}
    data-testid="stageIcon"
  />
);

StageIcon.propTypes = {
  type: PropTypes.oneOf(
    Object.values(ICON_TYPES),
  ).isRequired,
};

export default StageIcon;
