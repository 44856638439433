import React, {
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import DropdownMenu from '../DropdownMenu';
import LinkButton from '../LinkButton';
import Button from '../Button';
import SortIcon from './SortIcon';

import styles from './Select.module.scss';

export const SELECT_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
};

const Select = ({
  label,
  captionClassName,
  selectedValueId,
  items,
  onChange,
  type,
}) => {
  const selectedValueLabel = useMemo(
    () => items.find((item) => item.id === selectedValueId).label,
    [selectedValueId, items],
  );

  const resultItems = useMemo(() => (items.map((item) => ({
    ...item,
    onClick: () => onChange?.(item.id),
  }))), [
    items,
    onChange,
  ]);

  const renderDropdownTarget = useCallback((props) => {
    switch (type) {
      case SELECT_TYPE.primary:
        return (
          <div className={styles.linkButtonTargetWrapper}>
            {label && (
              <div className={styles.label}>
                {label}
              </div>
            )}
            <LinkButton
              {...props}
              captionCX={clsx(
                styles.linkButtonCaption,
                captionClassName,
              )}
              caption={selectedValueLabel}
              data-testid="linkButton"
            />
          </div>
        );
      case SELECT_TYPE.secondary:
        return (
          <Button
            cx={captionClassName}
            size="42"
            fill="white"
            icon={SortIcon}
            caption={selectedValueLabel}
            {...props}
            isDropdown={false} // to remove dropdown arrow in accordance with design
          />
        );
      default:
    }
  }, [
    captionClassName,
    label,
    selectedValueLabel,
    type,
  ]);

  return (
    <DropdownMenu
      groups={[{
        id: 'main',
        items: resultItems,
      }]}
      renderDropdownTarget={renderDropdownTarget}
      data-testid="dropdownMenu"
      bodyClassName={type === SELECT_TYPE.secondary ? styles.dropdownBody : ''}
    />
  );
};

Select.propTypes = {
  label: PropTypes.string,
  captionClassName: PropTypes.string,
  selectedValueId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(Object.values(SELECT_TYPE)),
};

Select.defaultProps = {
  label: '',
  captionClassName: '',
  onChange: null,
  type: SELECT_TYPE.primary,
};

export default Select;
