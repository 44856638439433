import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  USER_ROLES_TRANSLATION_KEYS,
} from '../../constants/roles';
import {
  ACCESSOR_KEYS,
  TABLE_ID,
  SORTING_TYPE_URL_PARAM_NAME,
  PAGE_URL_PARAM_NAME,
  // SEARCH_URL_PARAM_NAME,
} from './constants';
import { getUserFullName } from '../../utils';
import { useGetPeopleListData } from './hooks/useGetPeopleListData';
import Table from '../Table';
import Button from '../Button';
import { TextCell } from '../Table/cellsRenders';
import NoSearchPeople from './NoSearchPeople';
import UpdateUserModal from '../modals/UpdateUserModal';
import { useListener, useModal } from '../../hooks';
import {
  updateUserDataActionCreator,
  updateUserDataListener,
} from './listeners/updateUserDataListener';

const PeopleList = () => {
  const { formatMessage } = useIntl();
  const { showModal } = useModal();
  const [updateUserData] = useListener({
    listener: updateUserDataListener,
    actionCreator: updateUserDataActionCreator,
  });

  const renderEmailCell = useCallback(({ row }) => (
    <TextCell
      value={row.original.email}
    />
  ), []);

  const renderRoleCell = useCallback(({ row }) => (
    <TextCell
      value={formatMessage({ id: USER_ROLES_TRANSLATION_KEYS[row.original.role] })}
    />
  ), [formatMessage]);

  const handleUpdateUser = useCallback(
    (data) => updateUserData({ data }),
    [updateUserData],
  );

  const renderUpdateModal = useCallback((props) => (
    <UpdateUserModal
      {...props}
      onSubmit={handleUpdateUser}
    />
  ), [handleUpdateUser]);

  const renderEmptyState = useCallback(({ search }) => {
    if (search) {
      return <NoSearchPeople value={search} />;
    }

    return null;
  }, []);

  const handleUpdate = useCallback((rowData) => showModal((props) => renderUpdateModal({
    ...props,
    rowData,
  })), [
    renderUpdateModal,
    showModal,
  ]);

  const renderUpdateCell = useCallback(({ row }) => (
    <Button
      onClick={() => handleUpdate(row.original)}
      caption={formatMessage({ id: 'peopleList.table.cells.action.editButtonLabel' })}
      size="42"
    />
  ), [handleUpdate, formatMessage]);

  const renderNameCell = useCallback(({ row }) => (
    <TextCell
      value={
        getUserFullName(row.original)
      }
    />
  ), []);

  const columns = useMemo(
    () => [
      {
        accessorKey: ACCESSOR_KEYS.NAME,
        header: formatMessage({ id: 'peopleList.table.columns.name.title' }),
        cell: renderNameCell,
      },
      {
        accessorKey: ACCESSOR_KEYS.EMAIL,
        header: formatMessage({
          id: 'peopleList.table.columns.email.title',
        }),
        cell: renderEmailCell,
      },
      {
        accessorKey: ACCESSOR_KEYS.ROLE,
        header: formatMessage({
          id: 'peopleList.table.columns.role.title',
        }),
        cell: renderRoleCell,
      },
      {
        accessorKey: ACCESSOR_KEYS.ACTIONS,
        header: formatMessage({
          id: 'peopleList.table.columns.action.title',
        }),
        size: 13,
        cell: renderUpdateCell,
      },
    ],
    [
      formatMessage,
      renderNameCell,
      renderEmailCell,
      renderRoleCell,
      renderUpdateCell,
    ],
  );

  return (
    <Table
      useGetData={useGetPeopleListData}
      columns={columns}
      sortingUrlParamName={SORTING_TYPE_URL_PARAM_NAME}
      pageUrlParamName={PAGE_URL_PARAM_NAME}
      tableId={TABLE_ID}
      emptyState={renderEmptyState}
      // searchProps={{
      //   urlParamName: SEARCH_URL_PARAM_NAME,
      //   minSearchableLength: 1,
      // }}
    />
  );
};

export default PeopleList;
