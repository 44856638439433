import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { ROUTES } from '../../../../../constants/routes';
import UpdateIcon from './UpdateIcon';
import ArchiveIcon from './ArchiveIcon';
import RestoreIcon from './RestoreIcon';
import TrashIcon from './TrashIcon';
import Menu from '../../../../Menu';
import {
  useIsAbleToArchiveIdea,
  useIsAbleToRestoreIdea,
  useIsAbleToUpdateIdea,
  useIsAbleToDeleteIdea,
} from '../../../../../hooks';

import styles from './IdeasMenu.module.scss';

const IdeasMenu = ({
  idea,
  onArchive,
  onRestore,
  onDelete,
}) => {
  const { formatMessage } = useIntl();
  const { human_readable_id: ideaHrid } = idea;
  const isAbleToUpdate = useIsAbleToUpdateIdea(idea);
  const isAbleToDelete = useIsAbleToDeleteIdea(idea);
  const isAbleToRestore = useIsAbleToRestoreIdea(idea);
  const isAbleToArchive = useIsAbleToArchiveIdea(idea);

  const groups = useMemo(() => ([{
    id: 'actions',
    items: [
      ...(isAbleToRestore
        ? [{
          id: 'restore',
          label: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.actions.restore' }),
          icon: RestoreIcon,
          onClick: onRestore,
        }]
        : []),
      ...(isAbleToDelete
        ? [{
          id: 'delete',
          label: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.actions.delete' }),
          icon: TrashIcon,
          onClick: onDelete,
        }]
        : []),
      ...(isAbleToUpdate
        ? [{
          id: 'update',
          label: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.actions.update' }),
          link: {
            pathname: generatePath(ROUTES.updateIdea, { ideaId: ideaHrid }),
          },
          icon: UpdateIcon,
        }]
        : []),
      ...(isAbleToArchive
        ? [{
          id: 'archive',
          label: formatMessage({ id: 'adminPanel.ideasBoard.ideasTotalTable.columns.actions.archive' }),
          onClick: onArchive,
          icon: ArchiveIcon,
        }]
        : []),
    ],
  }]), [
    formatMessage,
    isAbleToUpdate,
    isAbleToDelete,
    isAbleToRestore,
    isAbleToArchive,
    ideaHrid,
    onArchive,
    onRestore,
    onDelete,
  ]);

  if (!groups[0].items.length) {
    return null;
  }

  return (
    <Menu
      dropdownMenuProps={{
        groups,
        bodyClassName: styles.dropdownBody,
      }}
    />
  );
};

IdeasMenu.propTypes = {
  idea: PropTypes.shape({
    human_readable_id: PropTypes.string.isRequired,
    archive: PropTypes.object,
  }).isRequired,
  onArchive: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default IdeasMenu;
