import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';

import { useModal } from '../../../hooks';
import LeavePromptConfirmationModal from '../../modals/LeavePromptConfirmationModal';

const RouterPrompt = ({ isLeaveBlocked }) => {
  const forceNavigation = useRef(false);
  const isModalOpen = useRef(false);
  const { showModal } = useModal();
  const history = useHistory();

  const handleRouteBlocking = useCallback((nextLocation) => {
    if (forceNavigation.current) {
      return true;
    }

    if (!isModalOpen.current) {
      showModal(({
        success,
        abort,
        ...props
      }) => {
        isModalOpen.current = true;

        const handleModalClose = () => {
          abort();
          isModalOpen.current = false;
        };

        const handleModalConfirm = () => {
          forceNavigation.current = true;
          history.push(nextLocation);
          forceNavigation.current = false;
          isModalOpen.current = false;
          success();
        };

        return (
          <LeavePromptConfirmationModal
            onConfirm={handleModalConfirm}
            onReject={handleModalClose}
            onClose={handleModalClose}
            {...props}
          />
        );
      });
    }

    return false;
  }, [
    forceNavigation,
    showModal,
    history,
  ]);

  return (
    <Prompt
      when={isLeaveBlocked}
      message={handleRouteBlocking}
    />
  );
};

RouterPrompt.propTypes = {
  isLeaveBlocked: PropTypes.bool.isRequired,
};

export default RouterPrompt;
