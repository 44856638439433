import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import NumericFieldBase from '../../NumericField';

const NumericField = ({
  name,
  deps,
  ...props
}) => {
  const {
    field: {
      onChange,
      onBlur,
      value,
    },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      deps,
    },
  });

  return (
    <NumericFieldBase
      data-testid="numericInputBase"
      value={value}
      errorMessage={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  );
};

NumericField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  deps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  step: PropTypes.number,
  formatter: PropTypes.func,
};

NumericField.defaultProps = {
  placeholder: '',
  deps: undefined,
  step: 1,
  formatter: null,
};

export default NumericField;
