export const FORM_FIELD_NAMES = {
  QUESTION_CATEGORY: 'type_question',
  MESSAGE: 'text',
};

export const QUESTION_CATEGORIES = {
  GENERAL: 'GENERAL',
  TECHNICAL: 'TECHNICAL',
};

export const QUESTION_CATEGORY_LABEL_ID_BY_TYPE = {
  [QUESTION_CATEGORIES.GENERAL]: 'contactUsForm.questionCategory.general',
  [QUESTION_CATEGORIES.TECHNICAL]: 'contactUsForm.questionCategory.techical',
};
