import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_TITLE_SIZES } from '../../Form';
import FormDescription from '../../Form/FormDescription';
import { FORM_FIELD_NAMES } from './constants';
import { FORM_PROPS } from '../../Form/Form';
import LikeableCheckboxGroupField from '../../Form/connected/LikeableCheckboxGroupField';

import styles from './BusinessObjectivesForm.module.scss';

const BusinessObjectivesForm = ({
  defaultValues,
  businessObjectives,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const resultDefaultValues = useMemo(() => (defaultValues || {
    [FORM_FIELD_NAMES.BUSINESS_OBJECTIVES]: [],
  }), [defaultValues]);

  return (
    <Form
      defaultValues={resultDefaultValues}
      title={formatMessage({ id: 'businessObjectivesForm.title' })}
      titleSize={FORM_TITLE_SIZES.SM}
      hideButtons
      {...props}
    >
      <FormDescription
        className={styles.description}
      >
        <FormattedMessage
          id="businessObjectivesForm.description"
        />
      </FormDescription>
      <LikeableCheckboxGroupField
        name={FORM_FIELD_NAMES.BUSINESS_OBJECTIVES}
        items={businessObjectives}
      />
    </Form>
  );
};

BusinessObjectivesForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
  defaultValues: PropTypes.object,
  businessObjectives: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
};

BusinessObjectivesForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  getFormApi: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  values: null,
  defaultValues: null,
  businessObjectives: [],
};

export default BusinessObjectivesForm;
