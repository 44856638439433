import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';
import clsx from 'clsx';

import Button from '../../Button';
import Form, { FORM_PROPS } from '../../Form';
import WatchFormValues from '../../Form/WatchFormValues';
import TextAreaField from '../../Form/connected/TextAreaField';
import { FORM_FIELD_NAMES, COMMENT_FORM_ACTION_TYPES } from './constants';

import styles from './CommentForm.module.scss';

const CommentForm = ({
  defaultValue,
  author,
  className,
  onCancel,
  actionType,
  ...props
}) => {
  const [{
    isSubmitting,
  }, setFormApi] = useState({});
  const { formatMessage } = useIntl();
  const resultDefaultValues = useMemo(() => ({
    [FORM_FIELD_NAMES.COMMENT]: defaultValue,
  }), [defaultValue]);

  return (
    <Form
      getFormApi={setFormApi}
      defaultValues={resultDefaultValues}
      className={clsx(styles.wrapper, className)}
      resetFormOnSuccessSubmit
      blockFormOnSubmit
      primaryActions={[
        ...(actionType === COMMENT_FORM_ACTION_TYPES.NEW
          ? []
          : [
            <Button
              caption={formatMessage({ id: 'common.cancel' })}
              onClick={onCancel}
              fill="light"
              rawProps={{
                type: 'button',
              }}
            />,
          ]
        ),
        <WatchFormValues
          name={FORM_FIELD_NAMES.COMMENT}
        >
          {(value) => (
            <Button
              caption={formatMessage({ id: `commentForm.submitButtonCaption.${actionType}` })}
              isDisabled={isSubmitting || !value.trim()}
              rawProps={{
                type: 'submit',
              }}
              onClick={() => {}} // to have curcor pointer
            />
          )}
        </WatchFormValues>,
      ]}
      {...props}
    >
      {author && (
        <p className={styles.author}>
          {author}
        </p>
      )}
      <TextAreaField
        name={FORM_FIELD_NAMES.COMMENT}
        autoSize
        placeholder={formatMessage({ id: 'commentForm.commentField.placeholder' })}
      />
    </Form>
  );
};

CommentForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'onSubmitSuccess',
  ]),
  defaultValue: PropTypes.string,
  author: PropTypes.string,
  className: PropTypes.string,
  actionType: PropTypes.oneOf(Object.values(COMMENT_FORM_ACTION_TYPES)),
  onCancel: PropTypes.func,
};

CommentForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  onSubmitSuccess: null,
  author: '',
  className: '',
  defaultValue: '',
  actionType: COMMENT_FORM_ACTION_TYPES.NEW,
  onCancel: null,
};

export default CommentForm;
