export const FORM_FIELD_NAMES = {
  TITLE: 'title',
  TECH_CATEGORIES: 'tech_categories_ids',
  TECH_REQUIREMENTS: 'technical_requirements',
  RISKS: 'risks',
  ASSUMPTIONS: 'assumptions',
  DEPENDENCIES: 'dependencies',
  DURATION: 'duration_in_weeks',
  SIMILAR_PRODUCTS: 'product_type_links',
  LINKS: 'links',
};
