import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Button from '../../../Button';
// import ProposeSolutionButton from '../../ProposeSolutionButton';
import IdeaActionsMenu from './IdeaActionsMenu';
import { SUPER_ADMIN_ROLE, USER_ROLES } from '../../../../constants/roles';
import DeleteIcon from './DeleteIcon';
import {
  useIsAbleToDeleteIdea,
  useIsAbleToRestoreIdea,
  useIsAbleToCommentIdea,
  useIsAbleToVoteIdea,
} from '../../../../hooks';
import { userIdSelector } from '../../../../redux/slices/access';

import styles from './IdeaActions.module.scss';

const IdeaActions = ({
  idea,
  onComment,
  onArchive,
  onRestore,
  onDelete,
  onVote,
  onUnvote,
  isVoteLimitReached,
}) => {
  const currentUserId = useSelector(userIdSelector);
  const { archive } = idea;
  const { formatMessage } = useIntl();
  const isAbleToDeleteIdea = useIsAbleToDeleteIdea(idea);
  const isAbleToRestoreIdea = useIsAbleToRestoreIdea(idea);
  const isAbleToCommentIdea = useIsAbleToCommentIdea(idea);
  const isAbleToVoteIdea = useIsAbleToVoteIdea(idea);
  const isVoted = idea.votes.includes(currentUserId);

  const content = (() => (
    <>
      {!archive && (
        <>
          {isAbleToCommentIdea && (
            <Button
              size="42"
              fill="solid"
              onClick={onComment}
              caption={formatMessage({ id: 'idea.header.actions.commentButton.label' })}
            />
          )}
          {isAbleToVoteIdea && (
            <Button
              size="42"
              fill={isVoted ? 'white' : 'solid'}
              onClick={isVoted ? onUnvote : onVote}
              caption={formatMessage(
                { id: 'idea.header.actions.toggleVoteButton.label' },
                { isVoted },
              )}
              isDisabled={!isVoted && isVoteLimitReached}
            />
          )}
          {/* <ProposeSolutionButton /> */}
          <IdeaActionsMenu
            onArchive={onArchive}
          />
        </>
      )}
      {isAbleToRestoreIdea && (
        <Button
          size="42"
          caption={formatMessage({ id: 'idea.header.actions.restoreButton.label' })}
          onClick={onRestore}
        />
      )}
      {isAbleToDeleteIdea && (
        <Button
          cx={styles.button}
          size="42"
          icon={DeleteIcon}
          onClick={onDelete}
        />
      )}
    </>
  ))();

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        {content}
      </div>
    </div>
  );
};

IdeaActions.propTypes = {
  idea: PropTypes.shape({
    archive: PropTypes.shape({
      author: PropTypes.shape({
        role: PropTypes.oneOf([
          ...Object.values(USER_ROLES),
          SUPER_ADMIN_ROLE,
        ]).isRequired,
        id: PropTypes.number.isRequired,
      }).isRequired,
    }),
    biz_category: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    votes: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  onComment: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onVote: PropTypes.func.isRequired,
  onUnvote: PropTypes.func.isRequired,
  isVoteLimitReached: PropTypes.bool.isRequired,
};

export default IdeaActions;
