import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import LoginForm from '../forms/LoginForm/LoginForm';
import { loginListener, loginListenerActionCreator } from './listeners/loginListener';
import { useListener } from '../../hooks/useListener';

import styles from './Login.module.scss';

const Login = () => {
  const [loginUser] = useListener({
    listener: loginListener,
    actionCreator: loginListenerActionCreator,
  });
  const { formatMessage } = useIntl();
  const handleSubmit = useCallback(
    (formData) => loginUser({ formatMessage, formData }),
    [formatMessage, loginUser],
  );

  return (
    <div className={styles.wrapper}>
      <LoginForm
        data-testid="loginForm"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default Login;
