import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  IDEA_TYPES,
  IDEA_TYPE_LABEL_TRANSLATION_KEYS,
} from '../../constants/idea';
import Section from '../Section';
import { REASON_TRANSLATION_KEY_BY_IDEA_TYPE } from './constants';

const IdeaDescription = ({
  ideaDetails,
  className,
}) => {
  const {
    idea_type: ideaType,
    reason,
    potential_solution: potentialSolution,
    business_benefits: businessBenefits,
  } = ideaDetails;
  const { formatMessage } = useIntl();

  const sections = useMemo(() => ([{
    id: 'ideaType',
    label: formatMessage({ id: 'ideaDescription.ideaType.label' }),
    value: formatMessage({ id: IDEA_TYPE_LABEL_TRANSLATION_KEYS[ideaType] }),
  }, {
    id: 'reason',
    label: formatMessage({
      id: REASON_TRANSLATION_KEY_BY_IDEA_TYPE[ideaType],
    }),
    value: reason,
  }, {
    id: 'potentialSolution',
    label: formatMessage({ id: 'ideaDescription.potentialSolution.label' }),
    value: potentialSolution,
  }, {
    id: 'businessBenefits',
    label: formatMessage({ id: 'ideaDescription.businessBenefits.label' }),
    value: businessBenefits,
  }]), [
    formatMessage,
    ideaType,
    reason,
    potentialSolution,
    businessBenefits,
  ]);

  return (
    <div className={className}>
      {sections.map(({ id, label, value }) => (!!value && (
        <Section
          key={id}
          heading={label}
          description={value}
          data-testid={id}
        />
      )))}
    </div>
  );
};

IdeaDescription.propTypes = {
  ideaDetails: PropTypes.shape({
    idea_type: PropTypes.oneOf(Object.values(IDEA_TYPES)).isRequired,
    reason: PropTypes.string.isRequired,
    potential_solution: PropTypes.string.isRequired,
    business_benefits: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

IdeaDescription.defaultProps = {
  className: '',
};

export default IdeaDescription;
