import {
  useLayoutEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  addListener as addListenerAction,
  removeListener as removeListenerAction,
  createAction,
} from '@reduxjs/toolkit';
import { useIntl } from 'react-intl';

import { uuid } from '../utils';
import { useWithDispatch } from './useWithDispatch';
import { useShowNotification } from './useShowNotification';

export const useListener = (hookData) => {
  const isListenerUnmounted = useRef(false);
  const showToast = useShowNotification();
  const { formatMessage } = useIntl();
  const { listener, actionCreator } = hookData;
  const resultActionCreator = useMemo(
    () => createAction(`${actionCreator}/${uuid()}`),
    [actionCreator],
  );
  const resultListener = useCallback((...params) => listener(...params), [listener]);
  const dispatchAddListener = useWithDispatch(addListenerAction);
  const dispatchActionCreator = useWithDispatch(resultActionCreator);
  const dispatchRemoveListener = useWithDispatch(removeListenerAction);

  const addListener = useCallback(() => dispatchAddListener({
    actionCreator: resultActionCreator,
    effect: resultListener,
  }), [
    dispatchAddListener,
    resultActionCreator,
    resultListener,
  ]);
  const removeListener = useCallback(() => dispatchRemoveListener({
    actionCreator: resultActionCreator,
    effect: resultListener,
    cancelActive: true,
  }), [
    dispatchRemoveListener,
    resultActionCreator,
    resultListener,
  ]);
  const abortListener = useCallback(() => {
    if (!isListenerUnmounted.current) {
      removeListener();
      addListener();
    }
  }, [
    addListener,
    removeListener,
  ]);

  useLayoutEffect(() => () => {
    isListenerUnmounted.current = true;
  }, []);

  useLayoutEffect(() => {
    addListener();
    return removeListener;
  }, [
    addListener,
    removeListener,
  ]);

  const runListener = useCallback((payload) => (
    new Promise((resolve, reject) => {
      dispatchActionCreator({
        ...payload,
        resolve,
        reject,
        showToast,
        formatMessage,
      });
    })
  ), [
    dispatchActionCreator,
    showToast,
    formatMessage,
  ]);

  return [runListener, abortListener];
};
