import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import Form, { FORM_TITLE_SIZES, FORM_PROPS } from '../../Form';
import CheckboxGroupField from '../../Form/connected/CheckboxGroupField';
import { LABEL_SIZES } from '../../Form/Label';
import { FORM_FIELD_NAMES } from './constants';

import styles from './UserAreasOfInterestForm.module.scss';

const UserAreasOfInterestForm = ({
  className,
  defaultValues,
  businessObjectives,
  technicalCategories,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      className={className}
      titleClassName={styles.title}
      data-testid="form"
      defaultValues={defaultValues}
      title={formatMessage({ id: 'userAreasOfInterestForm.title' })}
      titleSize={FORM_TITLE_SIZES.SM}
      blockPageOnSubmit
      preventLeavingWhenDirty
      {...props}
    >
      <CheckboxGroupField
        name={FORM_FIELD_NAMES.BUSINESS_OBJECTIVES}
        label={formatMessage({ id: 'userAreasOfInterestForm.businessObjectivesField.label' })}
        items={businessObjectives}
        labelSize={LABEL_SIZES.xl}
      />
      {/* <CheckboxGroupField
        name={FORM_FIELD_NAMES.TECHNICAL_CATEGORIES}
        label={formatMessage({ id: 'userAreasOfInterestForm.technicalCategoriesField.label' })}
        items={technicalCategories}
        labelSize={LABEL_SIZES.xl}
      /> */}
    </Form>
  );
};

UserAreasOfInterestForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
  ]),
  defaultValues: PropTypes.shape({
    [FORM_FIELD_NAMES.BUSINESS_OBJECTIVES]: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,
    // [FORM_FIELD_NAMES.TECHNICAL_CATEGORIES]: PropTypes.arrayOf(
    //   PropTypes.number,
    // ).isRequired,
  }),
  className: PropTypes.string,
  businessObjectives: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      id: PropTypes.number,
    }),
  ),
  // technicalCategories: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     name: PropTypes.node.isRequired,
  //     id: PropTypes.number,
  //   }),
  // ),
};

UserAreasOfInterestForm.defaultProps = {
  defaultValues: {
    [FORM_FIELD_NAMES.BUSINESS_OBJECTIVES]: [],
    // [FORM_FIELD_NAMES.TECHNICAL_CATEGORIES]: [],
  },
  className: '',
  businessObjectives: [],
  // technicalCategories: [],
};

export default UserAreasOfInterestForm;
