import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FILTER_TYPES } from '../../../Filters/constants';
import { FILTERING_TYPES } from '../constants';

export const useGetFilters = ({ bizObjectives }) => {
  const { formatMessage } = useIntl();

  return useMemo(() => ([{
    type: FILTER_TYPES.CHECKBOX,
    name: FILTERING_TYPES.BIZ_OBJECTIVE,
    label: formatMessage({ id: 'adminPanel.ideasBoard.filters.bizObjective.title' }),
    items: (bizObjectives || []).map(({ name, id }) => ({
      name,
      id,
    })),
  }]), [
    bizObjectives,
    formatMessage,
  ]);
};
