import React from 'react';
import { useIntl } from 'react-intl';
import { pick } from 'lodash';

import { FORM_PROPS } from '../../Form';
import { useValidationSchema } from './hooks/useValidationSchema';
import InactivateIdeaFormPattern from '../InactivateIdeaFormPattern';

const ArchiveIdeaForm = (props) => {
  const { formatMessage } = useIntl();

  const validationSchema = useValidationSchema();

  return (
    <InactivateIdeaFormPattern
      validationSchema={validationSchema}
      reasonTypeFieldLabel={formatMessage({ id: 'archiveIdeaForm.reasonType.label' })}
      {...props}
    />
  );
};

ArchiveIdeaForm.propTypes = {
  ...pick(FORM_PROPS, [
    'onSubmit',
    'getFormApi',
    'values',
  ]),
};

ArchiveIdeaForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  getFormApi: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  values: null,
};

export default ArchiveIdeaForm;
