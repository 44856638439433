import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { sampleSize } from 'lodash';

import { userBizCategoriesInterestSelector } from '../../redux/slices/access';
import { CARD_TYPES as TYPES } from './Card';
import AddBizCategories from './AddBizCategories';
import BizCategory from './BizCategory';

import styles from './TopIdeas.module.scss';

const BIZ_CATEGORY_CARD_TYPES_BY_INDEX = {
  0: TYPES.primary,
  1: TYPES.secondary,
  2: TYPES.tertiary,
};

const TopIdeas = () => {
  const bizCategories = useSelector(userBizCategoriesInterestSelector);

  const bizCategoriesToRender = useMemo(() => {
    if (bizCategories.length > 3) {
      return sampleSize(bizCategories, 3);
    }

    return bizCategories;
  }, [bizCategories]);

  return (
    <div className={styles.topIdeas}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          <FormattedMessage id="topIdeas.title" />
        </h2>
        <p className={styles.description}>
          <FormattedMessage id="topIdeas.description" />
        </p>
        <ul className={styles.list}>
          {bizCategoriesToRender.map((bizCategory, index) => (
            <li
              key={bizCategory.id}
              className={styles.item}
            >
              <BizCategory
                CardProps={{ type: BIZ_CATEGORY_CARD_TYPES_BY_INDEX[index] }}
                bizCategory={bizCategory}
              />
            </li>
          ))}
          {bizCategoriesToRender.length < 3 && (
            <li className={styles.item}>
              <AddBizCategories />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TopIdeas;
